import React from 'react';
import { MenuItem, ListSubheader, FormControl, Select, Checkbox } from '@mui/material';
import PropTypes from "prop-types";

const SelectComponent = (props) => {

    // props
    const { value, options, placeholder, handleSorting, defaultValue } = props

    const sortingData = (val) => {
        let sortVal = val ? val : defaultValue
        sortVal = sortVal.split("-")
        handleSorting({ sortField: sortVal[0] || "asc", sortBy: sortVal[1] || "asc" })
    }

    const getItems = (item, index) => {
        return item.fields && item.fields.map((itm, itx) => {
            return (
                <MenuItem sx={{ minWidth: 200 }} key={`k2-${index}-${itx}`} value={`${item.name}-${itm.name}`}>
                    <Checkbox
                    size='small'
                        sx={{ padding: '4px', marginRight: '8px' }}
                        checked={`${item.name}-${itm.name}` === value}
                    />
                    {itm.display}
                </MenuItem>
            )
        })
    }

    const renderValue = () =>{
        const val = value ? value.split("-") : ["","ASC"]
        const curItem = options.find(n=>n.name === val[0])
        return curItem ? `${curItem.display} : ${(val[1]||"").toUpperCase()}`  : ""
    } 

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select defaultValue="" renderValue={()=>{return renderValue()}} value={value} onChange={(e) => { sortingData(e.target.value) }}>
                <MenuItem disabled value=""><em>{placeholder}</em></MenuItem>
                {options && options.map((item, index) => {
                    return [<ListSubheader  key={`ind-li-sub-${index}`} sx={{ fontWeight: "bold", lineHeight: '40px' }}>{item.display}</ListSubheader>, getItems(item, index)]
                })}
            </Select>
        </FormControl>
    );
}

// default props
SelectComponent.defaultProps = {
    value: "",
    label: "",
    options: [],
    placeholder: "",
    handleSorting: () => { },
    defaultValue: "id-asc"
};

// prop types
SelectComponent.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    handleSorting: PropTypes.func,
    defaultValue: PropTypes.string,
};

export const GroupingSelect = SelectComponent