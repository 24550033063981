import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

// components
import { TextField } from "../textField";

/**
 * MaskInput Component
 */
function MaskInputComponent(props) {

  // props
  const { id, name, value, maskChar, placeholder, handleChange, mask, fullWidth, error, helperText, variant, className, validation, ...rest } = props;

  return (
    <InputMask
      id={id}
      name={name}
      mask={mask}
      value={value ? value : ''}
      disabled={false}
      maskChar={maskChar}
      onChange={(e, n) => {
        handleChange({ name, value: e.target.value })
      }}
    >
      {() =>
        <TextField
          id={id}
          name={name}
          margin="none"
          aria-label="Enter ssn"
          className={`${className} importField`}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          variant={variant}
          fullWidth={fullWidth}
          autoComplete="new-password"
          validation={validation}
          {...rest}
        />
      }
    </InputMask>
  );
}

// default props
MaskInputComponent.defaultProps = {
  id: "",
  name: "",
  value: "",
  type: "text",
  autoComplete: "off",
  rows: "0",
  margin: "normal",
  variant: "outlined",
  mask: "",
  fullWidth: true,
  maskChar: "_",
  error: false,
  helperText: "",
  className: "",
  placeholder: "",
  handleChange: () => { },
  validation: ""
};

// prop types
MaskInputComponent.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  validation: PropTypes.any,
};

// export component
export const InputMaskField = MaskInputComponent;
