import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

// styles
import 'leaflet/dist/leaflet.css'

// helpers
import { gets3URL } from '../../helpers';

// get marker
const LocationMarker = (props) => {

    // props
    const { position, setPosition, isDisabled } = props;

    const updatePosition = (e) => {
        if (!isDisabled) {
            setPosition([e.latlng["lat"], e.latlng["lng"]])
        }
    }

    // get marker location on click
    useMapEvents({
        click(e) {
            updatePosition(e)
        }
    })

    // icon
    const icon = L.icon({
        // iconUrl: gets3URL("assets/website/pin1.svg"),
        iconUrl: '../map-pin.svg',
        iconSize: [30, 30],
    })

    return position === null || (position && position.length !== 2) ? null : (
        <Marker position={position} icon={icon}>
            {/* <Popup>You are here</Popup> */}
        </Marker>
    )
}

// for controlling the center and zoom
const Recenter = ({center, zoom}) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, zoom);
    }, [center]);
    return null;
}

const MapComponent = (props) => {

    // props
    const { position, setPosition, scrollWheelZoom, isDisabled } = props;

    // state
    const [center, setCenter] = useState([40.0583, -74.4057])
    const [zoom, setZoom] = useState(10)

    // center on latitude change
    useEffect(()=>{
        if(position[0] && position[1]){
            setCenter(position)
            setZoom(16)
        }else{
            setCenter([40.0583, -74.4057])
            setZoom(10)    
        }
    },[position])

    // re center
    useEffect(()=>{
        setCenter(position[0] && position[1] ? position : [40.0583, -74.4057])
        setZoom(position[0] && position[1] ? 16 : 10)
    },[])

    return (
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={scrollWheelZoom} style={{ height: '100%', width: "100%" }}>
            <Recenter center={center} zoom={zoom}/> 
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker position={position} setPosition={setPosition} isDisabled={isDisabled} />
        </MapContainer>
    );
}

MapComponent.defaultProps = {
    position: [],
    scrollWheelZoom: true,
    setPosition: () => { },
    isDisabled: false
}

MapComponent.propTypes = {
    position: PropTypes.array,
    scrollWheelZoom: PropTypes.bool,
    setPosition: PropTypes.func,
    isDisabled: PropTypes.any
}

export const Maps = MapComponent
