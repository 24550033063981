import palette from "../../assets/themes/palette";

const styles = {
    '& .MuiInputAdornment-root svg': {
        fill: palette.greyshades.dark
    },
    '& .lg .MuiInputBase-input': {
        padding: '12.5px 14px'
    },
    '& .md .MuiInputBase-input': {
        padding: '7.5px 14px'
    },
    '& .sm .MuiInputBase-input': {
        padding: '5.5px 14px'
    }
}

export default styles;