export const commonConstants = {
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    FIELD_REQUIRED: "This field is required",
    EMAIL_INVALID: "Please enter a valid email id",
    PWD_INVALID: "Password is invalid",
    INVALID_ZIP :"Please enter valid zip code",
    PHONE_INVALID:"Please enter valid phone number",
    INVALID_DATE:"Please enter a valid date",
    INVALID_SSN:"Please enter a valid ssn",
    OLD_PWD_REQ:"Old password is required",
    PWD_REQUIRED:"Password is required",
    PWD_NOT_MATCH:"Password and confirm password doesn't match",
    NUMBER_INVALID:"Please enter valid numbers"
}