import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'

//Components
import { DatePicker, Button, MultiSelect } from "../../components";

// reducer
import { updateWaitingListFilter, clearWaitingListFilter, revertWaitingListFilter } from "../../redux/reducer/waitingListReducer";

// helpers
import { defaultUnitTypeOptions, affordabilityLevelOptions } from "../../helpers";
import Popover from "../../components/popover";

const FilterDrawer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { openFilter, setOpenFilter, handleFilter, className } = props;

    // redux
    const { filter, clearfilter } = useSelector(s => (s.waitingList))

    //set temporary filters to existing filter value
    useEffect(() => {
        dispatch(revertWaitingListFilter())
    },[])

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
            setOpenFilter(false)
        }
    }, [clearfilter])

    return (
        <Popover
            anchorEl={openFilter}
            onClose={() => { setOpenFilter(false) }}
            className={className}
        >
            <Grid item xs={12} className="filterContainer">
                <Grid container>
                    <Grid container justifyContent={"space-between"}>
                        <Typography variant="h5" color="" className="fs-18">Filters</Typography>
                        <Typography
                            variant="body1"
                            color={"primary.dark"}
                            className="fw-500 pointer"
                            onClick={() => { dispatch(clearWaitingListFilter()) }}
                        >
                            Clear Filter
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6} sx={{zIndex: 1}}>
                            <Typography variant="caption" color="textSecondary">Unit Type</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"unitTypeFilter"}
                                    name={"unitTypeFilter"}
                                    placeholder={"Unit Type"}
                                    className={`sm`}
                                    options = {defaultUnitTypeOptions}
                                    value={filter.unitTypeFilter}
                                    handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                                    initialValue={filter.unitTypeFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} sx={{zIndex: 1}}>
                            <Typography variant="caption" color="textSecondary">Affordability Level</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"affordabilityLevelFilter"}
                                    name={"affordabilityLevelFilter"}
                                    placeholder={"Affordability Level"}
                                    className={`sm`}
                                    options = {affordabilityLevelOptions}
                                    value={filter.affordabilityLevelFilter}
                                    handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                                    initialValue={filter.affordabilityLevelFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container flexWrap={"wrap"} spacing={4}>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="textSecondary">Updated - Start Date</Typography>
                                        <DatePicker
                                            id={"startDate"}
                                            className={` sm datePicker ${filter.startDate ? '' : ''}`}
                                            name="startDate"
                                            value={filter.startDate}
                                            handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="textSecondary">End Date</Typography>
                                        <DatePicker
                                            id={"endDate"}
                                            className={` sm datePicker ${filter.endDate ? '' : ''}`}
                                            name="endDate"
                                            value={filter.endDate}
                                            handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button variant="text" size="medium" className="mr-1" onClick={() => { 
                                    setOpenFilter(false)
                                    dispatch(revertWaitingListFilter())
                                    }}
                                    aria-label="Close"
                                >Close</Button>
                                <Button variant="contained" size="medium" color="primary" onClick={() => { handleFilter(); setOpenFilter(false) }} aria-label="Apply">Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    );
}

// default props
FilterDrawer.defaultProps = {
    handleFilter: () => { },
    openFilter: false,
    setOpenFilter: () => { },
    className: ""
};

// prop types
FilterDrawer.propTypes = {
    handleFilter: PropTypes.func,
    openFilter: PropTypes.any,
    setOpenFilter: PropTypes.func,
    className: PropTypes.string
};

export default styled(FilterDrawer)(Styles);