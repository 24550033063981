import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import cityStaffLoginImage from "../../assets/images/city_staff_login_wall.png";

// Import Styles and images
import Styles from "./style";
import { ServerCommunicationError } from "../../components";
import { updateOverrideLoader } from "../../redux/reducer/uiReducer";
// import LogoImg from "../../assets/images/svg/logo.svg"

const myUrl = process.env.REACT_APP_S3_URL;
const path = `${myUrl}assets/`
function AuthLayoutComponent({ component: Component, className, classes, srcImage, ...props }) {

    const dispatch = useDispatch();

    const { serverCommunicationError } = useSelector(s => s.ui)

    useEffect(() => {
        if(serverCommunicationError){
            dispatch(updateOverrideLoader())
        }
    }, [serverCommunicationError])

    return (
        <Box className={className}>
            <Box className={"bannerCont"}>
                <Box className={"imageCont"}>
                    <Grid container className="h-100">
                        <Grid item xs={7} className="h-100">
                            <Box className={"imBgCont"}>
                                <img
                                    width="100%"
                                    height={"100%"}
                                    alt={"BannerImg"}
                                    src={srcImage}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Box className="mainCont">
                                {!serverCommunicationError ?
                                    <Grid className="topContainer">
                                        <Component {...props} />
                                    </Grid> :
                                    <Grid className="topContainer">
                                        <ServerCommunicationError />
                                    </Grid>
                                }
                                <Grid align="center" className="pt-2 pb-2">
                                    <Typography variant="body2" className="copyRightText">Copyright © City of Jersey City, 2024</Typography>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Box>
    );
}

// prop types
AuthLayoutComponent.defaultProps = {
    srcImage: cityStaffLoginImage
};
AuthLayoutComponent.propTypes = {
    classes: PropTypes.object,
    srcImage: PropTypes.string
};

export const AuthLayout = styled(AuthLayoutComponent)(Styles)