import React, { useState, useRef } from "react";
import { Box, IconButton, List, ListItem, Button, Typography, Grid, Tooltip } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


import { formatBytesToSize, getFileNameWithTime } from "../../helpers";

export function FileUpload({ onChange, multiple, accept }) {

  const [selectedFiles, setSelectedFiles] = useState([])
  const fileRef = useRef()

  const renderFileList = () => {
    const listItems = []
    for (let i = 0; i < selectedFiles.length; i++) {
      let file = selectedFiles.item(i)
      listItems.push(
        <ListItem sx={{ pl: 0 }}
          key={`obligation-file-upload-key-${i}`}
        >
          <Tooltip title="Delete" arrow>
            <IconButton
              edge="end"
              aria-label="delete"
              className="mr-1"
              sx={{ marginLeft: '-9px' }}
              onClick={() => {
                onFilesSelected(selectedFiles, 'remove', i)
              }}
            >
              <DeleteOutlineIcon sx={{ color: '#343487' }} />
            </IconButton>
          </Tooltip>
          {(i + 1)}. {file.name} ({formatBytesToSize(file.size)})
        </ListItem>
      )
    }
    return listItems
  }

  const onFilesSelected = (files, type = 'add', removeIndex = null) => {
    let updated_files = new DataTransfer()
    // add old selected ones only if multi select
    if (multiple) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (type === 'remove' && removeIndex === i) {
          // do not add the removed file
        } else {
          const newFile = new File([selectedFiles[i]], getFileNameWithTime(selectedFiles[i]));
          updated_files.items.add(newFile)
        }
      }
    }

    if (type === 'add') {
      for (let i = 0; i < files.length; i++) {
        const newFile = new File([files[i]], getFileNameWithTime(files[i]));
        updated_files.items.add(newFile)
      }
    }

    setSelectedFiles(updated_files.files)
    onChange(updated_files.files)
  }

  return (
    <Box>
      <Box
        color="primary"
        aria-label="add-image"
      >
        <Grid container justifyContent={"space-between"}>
          <Typography variant="h3" className="fw-500">Upload Documents</Typography>
          <Button size="medium" variant="text"
            // sx={{ background: '#E5E5E5', color: '#343487', '&:hover': { background: '#E5E5E5' } }}
            onClick={() => fileRef.current.click()} disableElevation startIcon={<CloudUploadIcon />} aria-label={"Upload Files"}>
            Upload Files
          </Button>
        </Grid>
        <Typography variant="body1" className="pt-1">
          {selectedFiles.length === 0 ? 'No files selected' :
            `${selectedFiles.length} File(s) Selected`}
        </Typography>
        <input
          name="uploadDocuments"
          type="file"
          ref={fileRef}
          multiple={multiple}
          accept={accept}
          onChange={(e) => {
            onFilesSelected(e.target.files, 'add');
          }}
          value={""}
          style={{ display: 'none' }}
        />
      </Box>
      <Box
        style={{
          display: 'flex'
        }}
      >
        <List>
          {
            renderFileList()
          }
        </List>
      </Box>
    </Box>
  );
}

FileUpload.defaultProps = {
  name: "file-input",
  onChange: () => { },
  multiple: false,
  accept: '.pdf'
};
