import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "../textField";
import { styled } from '@mui/material';
import style from "./style";

function Asynchronous(props) {
  // props
  const { className, apiCallback, required, matchBy, display, label, value, handleChange, name, initialValue, validation, placeholder, disabled, ...rest } = props;

  // state
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([initialValue]);

  //val
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (initialValue) {
        const response = await apiCallback(initialValue[display]);
        if (active) {
          setOptions([...response.data.data]);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChangeHandle = async (value) => {
    if (!value || value.length > 1) {
      const response = await apiCallback(value);
      setOptions([...response.data.data]);
    }
  };

  return (
    <Autocomplete
    className={className}
      open={open}
      value={value}
      name={name}
      disabled={disabled}
      onOpen={() => {
        onChangeHandle("")
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        option[matchBy] === value[matchBy]
      }
      getOptionLabel={(option) => {
        if (value && value[matchBy]) {
          return option?.[display] || initialValue?.[display] || ''
        } else {
          return option?.[display] || ""
        }
      }}
      options={options}
      loading={loading}
      onChange={(e, value) => handleChange({ name, value })}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          name={name}
          aria-label={placeholder}
          className="custom-form-input"
          placeholder={placeholder}
          InputLabelProps={{
            required,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          onChange={(ev) => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          validation={validation}
          {...rest}
        />
      )}
    />
  );
}

Asynchronous.defaultProps = {
  display: "value",
  matchBy: "id",
  required: false,
  label: "",
  handleChange: () => { },
  name: "async",
  options: [],
  validation: null,
  placeholder: "",
  apiCallback: () => { },
  value: {},
  initialValue: "",
  disabled:false
};

Asynchronous.propTypes = {
  display: PropTypes.string,
  matchBy: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  validation: PropTypes.any,
  placeholder: PropTypes.string,
  apiCallback: PropTypes.func,
  value: PropTypes.any,
  initialValue: PropTypes.any,
  disabled: PropTypes.any,
};

export const AsyncAutoComplete = styled(Asynchronous)(style);
