import React, { useState } from 'react';
import PropTypes from 'prop-types';

//  Import Components
import { Chip, Grid, styled, Popover } from '@mui/material';

//  Import Styles
import Style from './styles.jsx';

function StatusDropDownComponent(props) {

    // props
    const { value, onChange, statusOptions, priority, disable, disabled, className, chipClassName, data } = props;

    // state
    const [anchorEl, setAnchorEl] = useState(null);

    const onPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    /*** Handle Selection*/
    const handleOnChange = (value) => {
        onChange(value);
        onPopoverClose();
    };

    return (
        <Grid className={className}>
            <Chip
                className={`${chipClassName} approval_status_${data ? data.approval_status : ""} ${priority ? "priorityChip" : ""} statusChip`}
                label={value}
                onClick={(event) => !disable && onPopoverOpen(event)}
                disabled={disabled}
            />
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onPopoverClose}
                className={`${className} popoverComponent`}
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
            >
                <Grid className={"popoverDiv"}>
                    {
                        statusOptions && Object.keys(statusOptions).map((status, index) => {
                            return (
                                <Grid className={"popoverList"} key={`status_${index}`} onClick={() => handleOnChange(status)}>
                                    <Chip
                                        className={`${statusOptions[status] ? statusOptions[status].toLowerCase() : ""} statusChip`}
                                        label={statusOptions[status]}
                                    />
                                </Grid>
                            );
                        }
                        )
                    }
                </Grid>
            </Popover>
        </Grid>
    );

}

// default props
StatusDropDownComponent.defaultProps = {
    classes: {},
    value: "",
    onChange: () => { },
    statusOptions: [],
    priority: false,
    disable: false,
    disabled: false,
    noBackground: false,
    chipClassName: '',
    data:{}
};

// prop types
StatusDropDownComponent.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
    statusOptions: PropTypes.object,
    priority: PropTypes.bool,
    disable: PropTypes.bool,
    disabled: PropTypes.bool,
    noBackground: PropTypes.bool,
    chipClassName: PropTypes.string,
    data: PropTypes.object,
};

export const StatusDropDown = styled(StatusDropDownComponent)(Style);