import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import 'moment-timezone'

//style
import Styles from './style'

//Components
import { FilterPanel, GroupingSelect, MultiSelectAsync, Button, DatePicker } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import FilterDrawer from "./filterDrawer";
import Popover from "../../components/popover";

//reducer
import { requestDocumentRequest, updateWaitingListFilter, downloadWaitingListRequest, clearWaitingListFilter } from "../../redux/reducer/waitingListReducer";

//service
import { globalFilterService } from "../../redux/service";

//helpers
import { hasFilter, waitingListListViewSort } from "../../helpers";

// redux
import { errorMessage } from "../../redux/reducer/uiReducer";

const TopPanel = (props) => {
    // define dispatch
    const dispatch = useDispatch()

    // props
    const { className, IconsList, handleSorting, sortValue, handleFilter } = props;

    //redux
    const { listView: { pagination, pagination:{count} }, filter, tmpfilter, checkedValues, clearfilter } = useSelector(s => (s.waitingList))
    const { loggedUser } = useSelector(s => (s.profile))

    // variable
    const isCityStaff = loggedUser.user_group_id === 3;
    const tz = moment.tz.guess()

    // state
    const [openFilter, setOpenFilter] = useState(false)
    const [openDocPopup, setOpenDocPopup] = useState(false)
    const [subEndDate, setSubEndDate] = useState(null);

    // call api whenever clear filter is triggered
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
    }, [clearfilter])

    const getApplicantsFilter = async (value) => {
        const response = await globalFilterService.getApplicantsFilterList({ name: value || "", user_id: isCityStaff ? "" : loggedUser.id, waitinglist: true })
        return response
    }

    const getAddressFilter = async (value) => {
        const response = await globalFilterService.getApplicationsAddressFilterList({ address: value || "", user_id: isCityStaff ? "" : loggedUser.id, waitinglist: true })
        return response
    }

    const getStatusFilter = async (value) => {
        const response = await globalFilterService.getWaitingListStatusFilterList({ approval_status: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const handleOpenDocPopup = (e) => {
        if (checkedValues && checkedValues.length) {
            setOpenDocPopup(e.currentTarget)
        } else {
            dispatch(errorMessage("Please select applicants"))
        }
    }

    const handleRequestDocuments = () => {
        const daysPlus7 = moment().add(7, 'days').format('YYYY-MM-DD')
        if (!subEndDate) {
            dispatch(errorMessage("Please select submission end date"))
        } else if (subEndDate < daysPlus7) {
            dispatch(errorMessage("Please select submission end date greater than 7 days"))
        } else {
            dispatch(requestDocumentRequest({ sub_end_date: subEndDate, applicant_ids: checkedValues.map(m => { return m.id }) }))
            setOpenDocPopup(false);
            setSubEndDate(null);
        }
    }

    return (
        <FilterPanel
            IconsList={IconsList}
            handleFilter={(event) => setOpenFilter(event.currentTarget)}
            handleRequestDoc={(e) => { handleOpenDocPopup(e) }}
            isFilterApplied={hasFilter(tmpfilter)}
            handleClearFilter={() => { dispatch(clearWaitingListFilter()) }}
            handleDownload={() => { dispatch(downloadWaitingListRequest({ ...pagination, filter: filter, timezone: tz })) }}
            downloadDisabled={count===0}
        >
            <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"managerFilter"}
                        name={"applicantsFilter"}
                        placeholder={"Applicants"}
                        className={`sm`}
                        apiCallback={getApplicantsFilter}
                        value={filter.applicantsFilter}
                        handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                        initialValue={filter.applicantsFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        customWidth
                        variant={"outlined"}
                        id={"addressFilter"}
                        name={"addressFilter"}
                        placeholder={"Property Address or Name"}
                        className={`sm`}
                        apiCallback={getAddressFilter}
                        value={filter.addressFilter}
                        handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                        initialValue={filter.addressFilter}
                        matchBy={"id"}
                        display={"address_string"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"statusFilter"}
                        name={"statusFilter"}
                        placeholder={"Status"}
                        className={`sm`}
                        apiCallback={getStatusFilter}
                        value={filter.statusFilter}
                        handleChange={(e) => { dispatch(updateWaitingListFilter(e)) }}
                        initialValue={filter.statusFilter}
                        matchBy={"id"}
                        display={"ap_status"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0 selectBox">
                    <GroupingSelect
                        value={sortValue}
                        handleSorting={handleSorting}
                        placeholder={"sort by"}
                        options={waitingListListViewSort}
                    />
                </Grid>
            </Grid>
            {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}

            {openDocPopup &&
                <Popover
                    anchorEl={openDocPopup}
                    onClose={() => { setOpenDocPopup(false) }}
                    className={className}
                >
                    <Grid container className="" sx={{ maxWidth: '400px' }}>
                        <Grid item xs={12} className="reqDoc">
                            <Typography variant="h5" className="fs-18 pb-2">Request Documents</Typography>
                        </Grid>
                        <Grid item xs={12} className="reqDoc">
                            <DatePicker
                                className={`md ${subEndDate ? '' : ''} datePicker`}
                                name={"submission_end_date"}
                                id={"submission_end_date"}
                                value={subEndDate}
                                disablePast={true}
                                handleChange={(e) => { setSubEndDate(e.value) }}
                                placeholder={"Submission End Date"}
                            />
                        </Grid>
                        <Grid item xs={12} align="right" className="pt-2">
                            <Button variant="text" size="medium" className="mr-1" onClick={() => { setOpenDocPopup(false) }} aria-label="Cancel">Cancel</Button>
                            <Button variant="contained" size="medium" color="primary" onClick={() => { handleRequestDocuments() }} aria-label="Reject">Request</Button>
                        </Grid>
                    </Grid>
                </Popover>}
        </FilterPanel>);
}

// default props
TopPanel.defaultProps = {
    className: "",
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: ""
};

// prop types
TopPanel.propTypes = {
    className: PropTypes.string,
};

export default styled(TopPanel)(Styles);