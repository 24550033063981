const palette = {
	primary: {
		dark: '#343487',
		main: '#2E2E84',
		light: '#343487',
		light2: '#6464A3',
		contrastText: "#fff",
		btnBg: 'linear-gradient(66deg, #8F2F71 10%, #2E2E84 100%)',
		secondaryBtnBg:''
	},
	secondary: {
		dark: '#e5e5e5',
		main: '#DFB233',
		light: '#4C4C96',
		contrastText: "#000",
		btnBg: 'linear-gradient(242deg, #DFB233 0%, #CB3066 100%)'
	},
	greyshades: {
		main: '#999999',
		dark: '#C3C2D9',
		inputBg: '#F5F5F9',
		light1: '#9C9CB8',
		light2: '#EBEBF2',
		light3: '#EBEBF3',
		light4: '#FFFFFF',
	},
	colorShades: {
		red: '#F3685D',
		redBg: '#FFE7E5',
		green: '#02AE13',
		greenBg: '#EEFDF6',
		blue: '#2E2E84',
		blueBg: '#2E2E841A',
		grey: '#B1B1B1',
		greyBg: '#E5E4E4',
		yellow: '#FEF0DA',
		yellowBg: '#F9A8252B',
		lightGreyBg: '#F5F5F9'
	},
	primaryYellow: {
		main: "#DFB233",
		light: '#DFB233',
		contrastText: "#fff"
	},
	error: {
		main: "#d32f2f"
	},
	textPrimary: '#181840',
	text: {
		primary: '#181840',
		secondary: '#999999'
	}
}

export default palette;