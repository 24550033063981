import React, { memo } from 'react';
import PropTypes from "prop-types";
import { Grid, Typography, styled } from '@mui/material';
import _ from "lodash";

//components
import { Avatar } from '..';

// styles
import Styles from './style';

// helpers
import { gets3URL, globalDate } from '../../helpers';

const ReceiverComponent = (props) => {

    //props
    const { className, data } = props;

    return (
        <Grid className={`${className} receiverComp`}>
            <Avatar
                alt={data.from_first_name}
                src={gets3URL(data.from_profile_photo)}
            />
            <Grid className="messageDetail">
                <Typography variant="bod1" className="user">
                    <Grid className='name'>{`${data.from_first_name} ${data.from_last_name}`}</Grid>
                    <Grid className="commentsTime pl-1">{globalDate(data.sent_at)}</Grid>
                </Typography>
                <Typography variant="body1" className="message">{data.message}</Typography>
            </Grid>
        </Grid>

    );
}

//default types
ReceiverComponent.defaultProps = {
    className: "",
    data: {}
}

//prop types
ReceiverComponent.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
}

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const Receiver = memo(styled(ReceiverComponent)(Styles), areEqual);