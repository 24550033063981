import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import service
import { authService } from '../service';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    loginRequest, loginSuccess, loginFailure,
    forgetPasswordRequest, forgetPasswordSuccess, forgetPasswordFailure,
    signupRequest, signupSuccess, signupFailure,
    resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure,
    userActivationRequest, userActivationSuccess, userActivationFailure,
    passwordLinkVerifyRequest,passwordLinkVerifySuccess, passwordLinkVerifyFailure,
} from '../reducer/authReducer';
import { loggedUserRequest } from '../reducer/profileReducer';

/**Login */
const login = (action$) => action$.pipe(
    ofType(loginRequest),
    mergeMap(({ payload }) => {
        return from(authService.login(payload)).pipe(
            concatMap((res) => {
                return of(loginSuccess(res.data.data),loggedUserRequest(), successMessageApi(res))
            }),
            catchError((res) => of(loginFailure(res), errorMessageApi(res)))
        )
    })
)

/**forget password */
const forgetPassword = (action$) => action$.pipe(
    ofType(forgetPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.forgetPassword(payload)).pipe(
            concatMap((res) => of(forgetPasswordSuccess(res), successMessageApi(res))),
            catchError((res) => of(forgetPasswordFailure(), errorMessageApi(res))),
        )
    })
)

/**Signup */
const signup = (action$) => action$.pipe(
    ofType(signupRequest),
    mergeMap(({ payload }) => {
        return from(authService.signup(payload)).pipe(
            concatMap((res) => of(signupSuccess(), successMessageApi(res))),
            catchError((res) => of(signupFailure(), errorMessageApi(res))),
        )
    }),
)

/**reset password */
const resetPassword = (action$) => action$.pipe(
    ofType(resetPasswordRequest),
    mergeMap(({ payload }) => {
        return from(authService.resetPassword(payload)).pipe(
            concatMap((res) => of(resetPasswordSuccess(res), successMessageApi(res))),
            catchError((res) => of(resetPasswordFailure(), errorMessageApi(res))),
        )
    }),
)

/**User Activation */
const userActivation = (action$) => action$.pipe(
    ofType(userActivationRequest),
    mergeMap(({ payload }) => {
        return from(authService.userActivation(payload)).pipe(
            concatMap((res) => of(userActivationSuccess(res.data))),
            catchError((res) => of(userActivationFailure(res.response))),
        )
    }),
)

/**get password link verification */
const pwdLinkVerify = (action$) => action$.pipe(
    ofType(passwordLinkVerifyRequest),
    mergeMap(({ payload }) => {
        return from(authService.pwdLinkVerify(payload)).pipe(
            concatMap((res) => of(passwordLinkVerifySuccess(res.data.data))),
            catchError(() => of(passwordLinkVerifyFailure())),
        )
    }),
)

// Export All Auth Epic Functions
export const authEpic = [
    login,
    forgetPassword,
    signup,
    resetPassword,
    userActivation,
    pwdLinkVerify
];