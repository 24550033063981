import palette from "../../assets/themes/palette";

const styles = {
    '.titleContainer': {
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'space-between'
    },
    '.bodyContainer': {
        padding: 20,
        paddingTop: 0
    },
    '.bodyInner': {
        background: '#FFFFFF',
        border: '1px solid #EBEBF3',
        borderRadius: '6px',
    },
    '.detailContainer': {
        paddingBottom: '100px',
        '& .MuiInputBase-input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
    },
    '.profileImage': {
        width: '160px',
        height: '160px',
        margin: 'auto',
        position: 'relative',
        cursor: 'pointer',
        '&:hover .deletIcon': {
            opacity: 1,
            visibilty: 'visible',
            transition: "all 0.5s"
        },
        '& .MuiAvatar-root': {
            width: '160px',
            height: '160px',
            margin: 'auto'
        },
        '& .MuiButtonBase-root': {
            fontSize: 14
        }
    },
    '.deletIcon': {
        opacity: 0,
        visibilty: 'hidden',
        transition: "all 0.5s",
        height: 30,
        width: 30,
        padding: 5,
        background: `${palette.primary.main}99`,
        border: '1px solid #FFFFFF',
        position: 'absolute',
        right: '10px',
        top: '10px'
    },
    '.switchBrief': {
        color: palette.greyshades.main,
        fontStyle: 'italic',
        paddingTop: 8
    },
    '.switchLabel': {
        color: palette.primary.main,
        fontWeight: 'bold',
        paddingLeft: 8
    },
    '.commentsContainer': {
        '& .MuiAvatar-root': {
            backgroundColor: palette.greyshades.light3
        }
    },
    '.senderComp': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginLeft: 'auto',
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingRight: 15,
            textAlign: 'right'
        },
        ' .message': {
            background: palette.primary.light2,
            color: palette.primary.contrastText,
            padding: '12px',
            borderTopLeftRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        }
    },
    '.receiverComp': {
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginRight: 'auto',
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingLeft: 15
        },
        ' .message': {
            background: '#F5F5F9',
            padding: '12px',
            borderTopRightRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        }
    },
    '.user': {
        fontWeight: 600,
        fontSize: 14
    },
    '.commentsTime': {
        color: palette.greyshades.main,
        fontSize: 14
    },
    '.commentsList ': {
        height: 'calc(100vh - 324px)',
        overflowY: 'auto',
        paddingBottom: '20px',
        paddingRight: 30
    },
    '.commentType .MuiInputBase-root': {
        background: '#FCFCFC',
        borderRadius: 7
    },
    '.commentBox': {
        paddingRight: '0px',
        paddingTop: '30px',
    },
    '.detailsBox': {
        paddingTop: '45px',
    },
    '.commentType': {
        paddingRight: '30px'
    },
    '.changeBtn': {
        fontWeight: '600 !important',
        fontSize: '14px !important'
    }
}
export default styles;