import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//Components
import { Dialog, Table, FileUpload, Button } from "../../components";
import { UploadIcon } from "../../assets/svg";

// reducer
import { getApplicationsDocumentsRequest, uploadApplicationsDocumentsRequest, setCurrentDocuments } from "../../redux/reducer/applicationsReducer";

const Documents = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className } = props;

    const [fileUpload, setFileUpload] = useState(false)

    // reducer
    const { tenantDetail: { details: tenantDetail } } = useSelector(s => (s.tenants))
    const { applicationDocs, currentApplicationDocs } = useSelector(s => (s.applications))

    // get application docs
    useEffect(() => {
        dispatch(getApplicationsDocumentsRequest({ id: tenantDetail.application_id }))
    }, [dispatch, tenantDetail.application_id])

    const onUploadIconClick = () => {
        setFileUpload(true)
    }

    const handleFileUploadPopup = () => {
        if (currentApplicationDocs && currentApplicationDocs.length > 0) {
            dispatch(uploadApplicationsDocumentsRequest({
                id: tenantDetail.application_id,
                docs: [...currentApplicationDocs]
            }))
        }
        setFileUpload(false)
    }

    const handleFileUploadClose = () => {
        setFileUpload(false)
    }

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Grid className="dflex alignCenter flexEnd">
                    <Button variant="text" size="medium" onClick={onUploadIconClick} startIcon={<UploadIcon />} aria-label="Upload documents">Upload Document</Button>
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                <Grid className="bodyInner docTable">
                    <Table
                        header={["Document Name", "Size", "Uploaded Date", "Approval Status"]}
                        fields={["file_name", "file_size", "created_at", "ap_status"]}
                        linkFields={[]}
                        data={applicationDocs}
                        showpagination={false}
                        clipName={true}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={fileUpload}
                handleDialogClose={handleFileUploadClose}
                confirmHandle={handleFileUploadPopup}
                successButton="Save"
                failButton="Cancel"
                maxWidth={"sm"}
            >
                <Grid>
                    <FileUpload
                        multiple={true}
                        onChange={(files) => {
                            dispatch(setCurrentDocuments(files))
                        }}
                    />
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
Documents.defaultProps = {
    classes: {},
};

// prop types
Documents.propTypes = {
    classes: PropTypes.object,
};

export default styled(Documents)(Style);