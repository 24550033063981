import React from 'react';
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

const TabPanelComponent = (props) => {
    /**
     * Define Props
     */
    const { children, value, index, height, boxClass, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-label={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pl: 7 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

// default props
TabPanelComponent.defaultProps = {
    children: "",
    value: 0,
    index: 0,
    height: "",
    boxClass: ""
};

TabPanelComponent.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number,
    height: PropTypes.string,
    boxClass: PropTypes.string
};

export const VerticalTabPanel = TabPanelComponent;