import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

//style
import Style from './style'

//Components
import { NoResult, Table } from "../../components"
import TopPanel from "./topPanel";

// reducer
import { 
    getApplicationsCountRequest, updateApplicantsFilter, updateApplicationsViewType,
    getApplicationsListRequest, updateTempApplicationsFilter, updateDownloadCheckedValues, updateDownloadAllCheckedValues
} from "../../redux/reducer/applicationsReducer";

const ListView = (props) => {

    // props
    const { className } = props;

    //define dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();

    //redux
    const { viewType, listView: { data, pagination, pagination: { count, skip, limit, sortField, sortBy } }, filter, checkedValues } = useSelector(s => (s.applications))
    const { loggedUser } = useSelector(s => (s.profile))

    useEffect(() => {
        // get total count for pagination and then call the data in the epic
        dispatch(getApplicationsCountRequest({ ...pagination, skip: 0, filter: filter, viewType: viewType }))
    }, [dispatch])

    const handleFilter = () => {
        //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
        dispatch(updateTempApplicationsFilter())
        // get total count for pagination and then call the data in the epic
        dispatch(getApplicationsCountRequest({ ...pagination, skip: 0, filter: filter }))
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getApplicationsCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter: filter }))
    }

    const viewDetailSwitch = (data) => {
        dispatch(updateApplicationsViewType("panel"))
        dispatch(updateApplicantsFilter({ name: "applicationsFilter", value: [{ id: data.id, name: data.name }] }))
        navigate(`/applications`)
    }

    //variables
    const isCityStaff = loggedUser.user_group_id === 3 ? true : false

    return (
        <Grid container className={className}>
            <Grid item xs={12}>
                <TopPanel
                    IconsList={["VIEW_TYPE", "CHILDREN", "DOWNLOAD", "FILTER_LIST"]}
                    handleFilter={handleFilter}
                    sortValue={`${sortField}-${sortBy}`}
                    handleSorting={handleSorting}
                />
            </Grid>
            <Grid item xs={12}>
                {count > 0 ?
                    <Table
                        header={!isCityStaff ? ["Applicant", "Property Address", "Unit Type", "Affordability Level", "Submitted on", "Approval Status"] : ["Applicant", "Unit Type", "Affordability Level", "Submitted on", "Approval Status"]}
                        fields={!isCityStaff ? ["name", "property_address", "unit_type_name", "affordability_level", "submitted_at", "ap_status"] : ["name", "unit_type_name", "affordability_level", "submitted_at", "ap_status"]}
                        data={data}
                        skip={skip}
                        totalCount={count}
                        limit={limit}
                        showCheckBox={() => { return true }}
                        showHeaderCheckbox={true}
                        checkedValues={checkedValues}
                        handleCheckboxClick={(val, isHeader=false) => {
                            if(isHeader){
                                dispatch(updateDownloadAllCheckedValues(val))
                            }else{
                                dispatch(updateDownloadCheckedValues(val));
                            }
                        }}
                        showAvatar={true}
                        updatePage={(e, val) => {
                            dispatch(getApplicationsListRequest({ ...pagination, skip: val - 1, filter: filter }))
                        }}
                        handleLinkClick={(e) => { viewDetailSwitch(e) }}
                        linkFields={["name"]}
                    />
                    :
                    <NoResult text="No Application Found" />
                }
            </Grid>
        </Grid>
    );
}

// default props
ListView.defaultProps = {
    classes: {},
};

// prop types
ListView.propTypes = {
    classes: PropTypes.object,
};

export default styled(ListView)(Style);