import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap } from "rxjs/operators";
import { of, from } from "rxjs";

import { errorMessageApi, successMessageApi } from "../reducer/uiReducer";
import {
    getProfileRequest, getProfileSuccess, getProfileFailed,
    updateProfileRequest, updateProfileSuccess, updateProfileFailed,
    loggedUserRequest, loggedUserSuccess, loggedUserFailure,
    updateNotificationRequest,updateNotificationSuccess,updateNotificationFailure
} from "../reducer/profileReducer";

//import service
import { profileService } from "../service";

/** Get Profile */
const getProfile = (action$) =>
    action$.pipe(
        ofType(getProfileRequest),
        mergeMap(() => {
            return from(profileService.getProfile()).pipe(
                concatMap((res) => {
                    return of(getProfileSuccess(res.data));
                }),
                catchError((res) => of(getProfileFailed(), errorMessageApi(res)))
            );
        })
    );

/** Update Profile */
const updateProfile = (action$) =>
    action$.pipe(
        ofType(updateProfileRequest),
        mergeMap(({ payload }) => {
            return from(profileService.updateProfile(payload)).pipe(
                concatMap((res) => (of(updateProfileSuccess(res.data.data), loggedUserRequest(), successMessageApi(res)))),
                catchError((res) => of(updateProfileFailed(), errorMessageApi(res)))
            );
        })
    );

/**get logged user data */
const getLoggedUser = (action$) => action$.pipe(
    ofType(loggedUserRequest),
    mergeMap(({ payload }) => {
        return from(profileService.getLoggedUser(payload)).pipe(
            concatMap((res) => of(loggedUserSuccess(res.data.data))),
            catchError((res) => of(loggedUserFailure(), errorMessageApi(res))),
        )
    }),
)

/** Update Notification */
const updateNotification = (action$) =>
    action$.pipe(
        ofType(updateNotificationRequest),
        mergeMap(({ payload }) => {
            return from(profileService.updateNotification(payload)).pipe(
                concatMap((res) => (of(updateNotificationSuccess(res.data.data), successMessageApi(res)))),
                catchError((res) => of(updateNotificationFailure(), errorMessageApi(res)))
            );
        })
    );

export const profileEpic = [
    getProfile,
    updateProfile,
    getLoggedUser,
    updateNotification
];
