import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap, delay, toArray } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { saveAs } from 'file-saver';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    getWaitingListRequest, getWaitingListSuccess, getWaitingListFailure,
    getWaitingListCountRequest, getWaitingListCountSuccess, getWaitingListCountFailure,
    requestDocumentRequest, requestDocumentSuccess, requestDocumentFailure,
    downloadDocumentsRequest, downloadDocumentsSuccess, downloadDocumentsFailure,
    downloadWaitingListRequest, downloadWaitingListSuccess, downloadWaitingListFailure,
    approveDocumentsRequest, approveDocumentsSuccess, approveDocumentsFailure,
    rejectApplicationRequest, rejectApplicationSuccess, rejectApplicationFailure,
    propertyUnitDetailsRequest, propertyUnitDetailsSuccess, propertyUnitDetailsFailure,
    offerUnitRequest,offerUnitSuccess,offerUnitFailure,
    moveApplicantToWlRequest,moveApplicantToWlSuccess,moveApplicantToWlFailure,
    updateOverallWaitingListLoaders
} from "../reducer/waitingListReducer"

import { waitingListService } from '../service';

/**get waiting list */
const getWaitingList = (action$) => action$.pipe(
    ofType(getWaitingListRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.getWaitingList(payload)).pipe(
            concatMap((res) => of(getWaitingListSuccess(res.data), updateOverallWaitingListLoaders())),
            catchError((res) => of(getWaitingListFailure(), updateOverallWaitingListLoaders(), errorMessageApi(res))),
        )
    }),
)

/** get waiting list total count  */
const getWaitingListCount = (action$) => action$.pipe(
    ofType(getWaitingListCountRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.getWaitingListCount(payload)).pipe(
            concatMap((res) => (of(getWaitingListCountSuccess(res.data), getWaitingListRequest(payload), updateOverallWaitingListLoaders()))),
            catchError((res) => of(getWaitingListCountFailure(), updateOverallWaitingListLoaders(), errorMessageApi(res))),
        )
    }),
)

/**request Documents */
const requestDocuments = (action$) => action$.pipe(
    ofType(requestDocumentRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.requestDocuments(payload)).pipe(
            concatMap((res) => of(requestDocumentSuccess(payload), successMessageApi(res))),
            catchError((res) => of(requestDocumentFailure(), errorMessageApi(res))),
        )
    }),
)

/**download Documents */
const downloadDocuments = (action$) => action$.pipe(
    ofType(downloadDocumentsRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.downloadDocuments(payload)).pipe(
            concatMap((res) => {
                const docsList = res.data && res.data.data ? res.data.data : [];
                return from(docsList).pipe(
                    concatMap((m) =>
                        of(m).pipe(
                            delay(500),
                            concatMap(() => {
                                saveAs(m.document, m.file_name);
                                return of(m);
                            })
                        )
                    ),
                    toArray(),
                    concatMap((processedDocs) => of(downloadDocumentsSuccess(processedDocs)))
                );
            }),
            catchError((res) => of(downloadDocumentsFailure(), errorMessageApi(res))),
        )
    })
)

/**download Waiting List Report */
const downloadWaitingListReport = (action$) => action$.pipe(
    ofType(downloadWaitingListRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.downloadWaitingListReport(payload)).pipe(
            concatMap((res) => of(downloadWaitingListSuccess(res), successMessageApi({message: "The waiting list has been downloaded successfully"}))),
            catchError((res) => of(downloadWaitingListFailure(), errorMessageApi(res))),
        )
    }),
)

/**approve documents */
const approveDocuments = (action$) => action$.pipe(
    ofType(approveDocumentsRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.approveDocuments(payload)).pipe(
            concatMap((res) => of(approveDocumentsSuccess(payload), successMessageApi(res))),
            catchError((res) => of(approveDocumentsFailure(), errorMessageApi(res))),
        )
    }),
)

/**reject application */
const rejectApplication = (action$, state$) => action$.pipe(
    ofType(rejectApplicationRequest),
    mergeMap(({ payload }) => {
        const pagination = {...state$.value.waitingList.listView.pagination,
                            skip: state$.value.waitingList.listView.pagination.skip - 1}
        return from(waitingListService.rejectApplication(payload)).pipe(
            concatMap((res) => of(rejectApplicationSuccess(payload), successMessageApi(res),getWaitingListCountRequest({...pagination}))),
            catchError((res) => of(rejectApplicationFailure(), errorMessageApi(res))),
        )
    }),
)

/**get unit details */
const getPropertyUnitDetails = (action$) => action$.pipe(
    ofType(propertyUnitDetailsRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.getPropertyUnitDetails(payload)).pipe(
            concatMap((res) => of(propertyUnitDetailsSuccess(res.data.data))),
            catchError((res) => of(propertyUnitDetailsFailure(), errorMessageApi(res))),
        )
    }),
)

/**offer unit to applicant */
const offerUnit = (action$) => action$.pipe(
    ofType(offerUnitRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.offerUnit(payload)).pipe(
            concatMap((res) => of(offerUnitSuccess({payload : payload , data : res.data.data}), successMessageApi(res))),
            catchError((res) => of(offerUnitFailure(), errorMessageApi(res))),
        )
    }),
)

/**offer unit to applicant */
const moveToWaitingList = (action$) => action$.pipe(
    ofType(moveApplicantToWlRequest),
    mergeMap(({ payload }) => {
        return from(waitingListService.moveToWaitingList(payload)).pipe(
            concatMap((res) => of(moveApplicantToWlSuccess(payload), successMessageApi(res))),
            catchError((res) => of(moveApplicantToWlFailure(), errorMessageApi(res))),
        )
    }),
)


// Export All waitinglist Epic Functions
export const waitingListEpic = [
    getWaitingList,
    getWaitingListCount,
    requestDocuments,
    downloadDocuments,
    downloadWaitingListReport,
    approveDocuments,
    rejectApplication,
    getPropertyUnitDetails,
    offerUnit,
    moveToWaitingList
];