import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Switch as Core } from '@mui/material';

const Android12Switch = styled(Core)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));


const SwitchComponent = (props) => {

  //get props
  const { classes, className, label, handleChange, name, checked, disabled, ...rest } = props;

  return (
    <FormControlLabel
      name={name}
      checked={checked}
      control={<Android12Switch />}
      label={label}
      disabled={disabled}
      onChange={(e) => { handleChange({ name, value: e.target.checked }) }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleChange({ name, value: !e.target.checked })
        }
      }}
      {...rest}
    />
  );
}

// default props
SwitchComponent.defaultProps = {
  classes: {},
  className: "",
  label: "",
  name: "",
  handleChange: () => { },
  checked: false,
  disabled: false
};

// prop types
SwitchComponent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.any
};

/** Export Component */
export const Switch = SwitchComponent;
