import palette from "../../assets/themes/palette";

const style = {
    '&.headerBar': {
        height: 60,
        background: palette.primary.main,
        padding: '0 25px 0px 10px',
        boxShadow: 'none',
        position: 'relative',
        '& .MuiAvatar-root': {
            cursor: 'pointer'
        }
    },
    '.leftSection': {
        display: 'flex',
        alignItems: 'center'
    },
    '.logoImg': {
        height: 50
    },
    '.userIcon': {
        padding: '5px',
        '& svg, img': {
            height: '34px',
            width: '34px',
            objectFit: 'cover',
            borderRadius: '100%'
        }
    },
    '.menuOption': {
        height: 34,
        width: 34,
        padding: '5px',
        marginRight: 7,
        '& svg': {
            height: 20,
            width: 20,
            fill: palette.primary.contrastText
        },
        '& .MuiBadge-badge': {
            top: '6px',
            right: '6px',
            border: `2px solid ${palette.primary.main}`,
            height: '12px',
            borderRadius: '12px',
            minWidth: '12px'
        }
    },
    '.menuItems': {
        height: 60,
        minHeight: 60,
        paddingLeft: 20,
        '& .MuiButton-root': {
            minHeight: 60,
            color: palette.primary.contrastText,
            textTransform: 'capitalize',
            fontWeight: 400,
            paddingLeft: '38px',
            paddingRight: '38px',
            borderRadius: 0,
            fontSize: '16px',
            borderBottom: `4px solid transparent`,
            height: '60px',
            '&.active': {
                background: palette.secondary.light,
                fontWeight: 600,
                color: palette.primary.contrastText,
                borderBottom: `4px solid ${palette.secondary.main}`
            }
        }
    },
    '&.linkMenu': {
        '& .MuiMenuItem-root': {
            '&:hover': {
                color: palette.primary.dark
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: '32px'
        }
    }
}
export default style;