// Import API Helper
import { getRequest, putRequest, postRequest,
    deleteRequest, buildFormData 
} from "../../helpers";

/**
 * Export All global Service Functions
 */
export const globalService = {
    getMasterUnits,
    getMasterAmenities,
    createCityPolicyDocs,
    getCityPolicyDocs,
    getCityPolicyDocsCount,
    deleteCityPolicyDocs,
    createIncomeDocs,
    getIncomeRentDocsCount,
    getIncomeRentDocs,
    deleteIncomeRentsDocs,
};

/**
* get master units
*/
async function getMasterUnits() {
    const response = await getRequest(`global/units/`);
    return response;
}

/**
 * get master amenities
 */
async function getMasterAmenities() {
    const response = await getRequest(`global/amenities/`);
    return response;
}

/**
 * get City Policy Docs
 */
async function createCityPolicyDocs(params) {
    let formData = new FormData();
    formData = buildFormData(formData, params.docs);
    const response = await putRequest(`global/city-policy-docs/${params.year}/${params.user_id}/`, {
        body: formData,
        isFormData: true,
        responseType: "application/json",
        multipart: true
    });
    return response;
}

//get city policy docs
async function getCityPolicyDocs(params){
    const response = await postRequest(`global/city-policy/?skip=${params.skip}&limit=${params.limit}`, { "filter": params.filter })
    return response
}

//get city policy docs count
async function getCityPolicyDocsCount(params){
    const response = await postRequest(`global/city-policy-count/`, {"filter": params.filter})
    return response
}

/**
 * delete City Policy Doc
 */
async function deleteCityPolicyDocs({ id }) {
    const response = await deleteRequest(`global/city-policy-docs/delete/?id=${id}`);
    return response;
}

/**
 * create income Doc
 */
async function createIncomeDocs(params) {
    let formData = new FormData();
    formData = buildFormData(formData, params.docs);
    const response = await putRequest(`global/income-docs/${params.year}/${params.category}/${params.user_id}/`, {
        body: formData,
        isFormData: true,
        responseType: "application/json",
        multipart: true
    });
    return response;
}

//get income and rent docs
async function getIncomeRentDocs(params){
    const response = await postRequest(`global/income-rent/?skip=${params.skip}&limit=${params.limit}`, { "filter": params.filter })
    return response
}

//get income and rent docs count
async function getIncomeRentDocsCount(params){
    const response = await postRequest(`global/income-rent-count/`, {"filter": params.filter})
    return response
}

//delete income and rent docs
async function deleteIncomeRentsDocs(params){
    const response = await deleteRequest(`global/income-rent/delete/?id=${params.id}`)
    return response
}