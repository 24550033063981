// Import API Helper
import { getRequest } from "../../helpers";

//Get Cities
async function getCities(param, regionId) {
  const response = await getRequest(`location/city/?q=${param||""}&region_id=${regionId || 0}`);
  return response;
}

//Get States
 async function getStates(param,cityId) {
    const response = await getRequest(`location/state/?q=${param||""}&city_id=${cityId || 0}`);
    return response;
}

// export service
export const locationService = {
    getCities,
    getStates,
};
