import palette from "./palette";
import typography from "./typography";

const components =  {
        MuiButton: {
            styleOverrides: {
                root: {
                    // fontSize: '15px',
                    borderRadius: 4,
                    textTransform: 'none'
                },
                textPrimary: {
                    color: palette.primary.dark
                },
                containedSizeLarge: {
                    height: 47
                },
                containedSizeMeium: {
                    height: 35
                },
                containedSizeSmall: {
                    height: 29
                },
                containedPrimary: {
                    background: palette.primary.btnBg,
                    '&:hover': {
                        // backgroundColor: palette.secondary.main
                    },
                    '&.Mui-disabled': {
                        background: `rgba(0, 0, 0, 0.12) !important`,
                        color: '#ccc'
                    }
                },
                containedSecondary: {
                    background: palette.secondary.btnBg,
                    '&:hover': {
                        // backgroundColor: palette.secondary.main
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    fontFamily: typography.body1.fontFamily,
                    fontSize: '14px',
                    textAlign: 'center'
                }
            }
        },
        MuiTextField: {

        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                },
            notchedOutline: {
                borderColor: '#EAEAF3'
            }
        }
    },
    MuiSwitch: {
        styleOverrides: {
            root: {
                '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: `${palette.secondary.main} !important`,
                    opacity: '1 !important'
                },
                '& .MuiSwitch-thumb': {
                    background: palette.primary.contrastText
                },
                '& .MuiSwitch-track': {
                    opacity: 0.18
                }
            },
        }
    },
    MuiTable: {
        styleOverrides: {
            root: {
                '& .MuiTableCell-head': {
                    background: '#EBEBF3 ',
                    color: '#181840 !important',
                    height: 52,
                    fontSize: 14,
                    fontWeight: 600
                },
                '& .MuiTableCell-root': {
                    borderBottom: '1px solid #E5E4E4',
                    fontSize: 14,
                    color: '#000'
                },
                '& .MuiTableRow-root:last-child td': {
                    borderBottom: '1px solid #E5E4E4',
                }
            }
        }
    },
    MuiTableContainer: {
        styleOverrides: {
            root: {
                boxShadow: 'none'
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            root: {
                color: palette.textPrimary
            }
        }
    }
}

export default components;