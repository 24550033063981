import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'

//Components
import { DatePicker, Button, MultiSelectAsync } from "../../components";
import Popover from "../../components/popover";

// reducer
import { updateReportsFilter, clearReportsFilter, revertReportsFilter } from "../../redux/reducer/reportsReducer";

//service
import { globalFilterService } from "../../redux/service";

const FilterDrawer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { openFilter, setOpenFilter, handleFilter, className } = props;

    // redux
    const { filter, clearfilter } = useSelector(s => (s.reports))
    const { loggedUser } = useSelector(s => (s.profile))

    //variable
    const isCityStaff = loggedUser.user_group_id === 3;

    //set temporary filters to existing filter value
    useEffect(() => {
        dispatch(revertReportsFilter())
    }, [])

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
            setOpenFilter(false)
        }
    }, [clearfilter])

    // get names of report documents
    const getReportNameFilter = async (value) => {
        const response = await globalFilterService.getReportsNameFilterList({ name: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    return (
        <Popover
            anchorEl={openFilter}
            onClose={() => { setOpenFilter(false) }}
            className={className}
        >
            <Grid item xs={12} className="filterContainer">
                <Grid container>
                    <Grid container justifyContent={"space-between"}>
                        <Typography variant="h5" color="" className="fs-18">Filters</Typography>
                        <Typography
                            variant="body1"
                            color={"primary.dark"}
                            className="fw-500 pointer"
                            onClick={() => { dispatch(clearReportsFilter()) }}
                        >
                            Clear Filter
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={6} sx={{ zIndex: 1 }}>
                                <Typography variant="caption" color="textSecondary">Report Name</Typography>
                                <MultiSelectAsync
                                    variant={"outlined"}
                                    id={"reportNameFilter"}
                                    name={"reportNameFilter"}
                                    placeholder={"Report"}
                                    className={`sm`}
                                    apiCallback={getReportNameFilter}
                                    value={filter.reportNameFilter}
                                    handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                                    initialValue={filter.reportNameFilter}
                                    matchBy={"id"}
                                    display={"report_name"}
                                    limitTags={1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" color="textSecondary">Submitted - Start Date</Typography>
                                <DatePicker
                                    id={"startDate"}
                                    className={` sm datePicker ${filter.startDate ? '' : ''}`}
                                    name="startDate"
                                    value={filter.startDate}
                                    handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Grid container flexWrap={"wrap"} spacing={4}>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="textSecondary">End Date</Typography>
                                        <DatePicker
                                            id={"endDate"}
                                            className={` sm datePicker ${filter.endDate ? '' : ''}`}
                                            name="endDate"
                                            value={filter.endDate}
                                            handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button variant="text" size="medium" className="mr-1" onClick={() => { setOpenFilter(false); dispatch(revertReportsFilter()) }} aria-label="Close">Close</Button>
                                <Button variant="contained" size="medium" color="primary" onClick={() => { handleFilter(); setOpenFilter(false) }} aria-label="Apply">Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    );
}

// default props
FilterDrawer.defaultProps = {
    handleFilter: () => { },
    openFilter: false,
    setOpenFilter: () => { },
    className: ""
};

// prop types
FilterDrawer.propTypes = {
    handleFilter: PropTypes.func,
    openFilter: PropTypes.any,
    setOpenFilter: PropTypes.func,
    className: PropTypes.string
};

export default styled(FilterDrawer)(Styles);