import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    webNotificationList: {
        today: [],
        older: []
    },
    newWebNotification: 0,
}

const webNotificationSlice = createSlice({
    name: 'webNotification',
    initialState: initialState,
    reducers: {
        //get Web Notification list
        webNotificationListRequest(state) {
            state.isLoading = true
        },
        webNotificationListSuccess(state, action) {
            state.webNotificationList = action.payload
            state.isLoading = false
        },
        webNotificationListFailure(state) {
            state.webNotificationList = {}
            state.isLoading = false
        },
        //get Unread Web Notification list
        newWebNotificationRequest(state) {
            state.isLoading = true
            state.newWebNotification = 0
        },
        newWebNotificationSuccess(state, action) {
            state.newWebNotification = action.payload.count
            state.isLoading = false
        },
        newWebNotificationFailure(state) {
            state.newWebNotification = 0
            state.isLoading = false
        },
        // Update Web notification list by socket
        updateNotificationBySocket(state, action) {
            state.newWebNotification = (state.newWebNotification + 1)
        },

        markAsReadNotificationRequest(state) {
            state.isLoading = true
        },
        markAsReadNotificationSuccess(state, action) {
            state.newWebNotification = (state.newWebNotification > 0 ? (state.newWebNotification - 1) : 0)
            
            const id = action.payload.payload.id;
            const type = action.payload.payload.type;
            const elementIndex = state.webNotificationList[type].findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.webNotificationList[type][elementIndex];
                indexData.is_read = true
                state.webNotificationList[type][elementIndex] = indexData
            }
            state.isLoading = false
        },
        markAsReadNotificationFailure(state) {
            state.isLoading = false
        },

        markAllAsReadNotificationRequest(state) {
            state.isLoading = true
        },
        markAllAsReadNotificationSuccess(state, action) {
            state.newWebNotification = 0
            state.isLoading = false
        },
        markAllAsReadNotificationFailure(state) {
            state.isLoading = false
        },

    }
})

export const {
    webNotificationListRequest, webNotificationListSuccess, webNotificationListFailure,
    newWebNotificationRequest, newWebNotificationSuccess, newWebNotificationFailure,
    updateNotificationBySocket,
    markAsReadNotificationRequest, markAsReadNotificationSuccess, markAsReadNotificationFailure,
    markAllAsReadNotificationRequest, markAllAsReadNotificationSuccess, markAllAsReadNotificationFailure
} = webNotificationSlice.actions;
export default webNotificationSlice.reducer;