import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";

// images
import { LinkIcon } from "../../assets/svg";

const CopyToClipboardComponent = (props) => {

  // props
  const { text } = props;

  return (
    <IconButton onClick={() => {navigator.clipboard.writeText(text);}} aria-label="Copy Link">
      <LinkIcon />
    </IconButton>
  );
};

//default types
CopyToClipboardComponent.defaultProps = {
  text: ""
}

//prop types
CopyToClipboardComponent.propTypes = {
  text: PropTypes.string,
}


export const CopyToClipboard = CopyToClipboardComponent;
