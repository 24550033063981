// Import API Helper
import { postRequest, getRequest } from "../../helpers";

//Get all waiting list
async function getWaitingList(params) {
    const response = await postRequest(`waiting-list/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter });
    return response;
}

/** get waiting list total count */
async function getWaitingListCount(params) {
    const response = await postRequest(`waiting-list/count/`, { "filter": params.filter });
    return response;
}

/** Request Documents */
async function requestDocuments(params) {
    const response = await postRequest(`waiting-list/request-documents/`, params);
    return response;
}

/** download Documents */
async function downloadDocuments({ id }) {
    const response = await getRequest(`waiting-list/download-documents/${id}/`);
    return response;
}

/**
* download waiting list reports
*/
async function downloadWaitingListReport(params) {
    const response = await postRequest(`waiting-list/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter, "timezone": params.timezone }, false, "arraybuffer");
    return response;
}

/** approve documents */
async function approveDocuments(params) {
    const response = await postRequest(`waiting-list/approve-docs/${params.id}/`, params);
    return response;
}

/** reject applications */
async function rejectApplication(params) {
    const response = await postRequest(`waiting-list/reject/${params.id}/`, params);
    return response;
}

/** get Property Unit Details */
async function getPropertyUnitDetails({ id }) {
    const response = await getRequest(`waiting-list/unit-details/${id}/`);
    return response;
}

/** offer unit to applicant */
async function offerUnit(params) {
    const response = await postRequest(`waiting-list/offer-unit/${params.applicationId}/`, params);
    return response;
}

/** offer unit to applicant */
async function moveToWaitingList(params) {
    const response = await postRequest(`waiting-list/move-to-wl/${params.applicationId}/`, params);
    return response;
}

/**
 * Export All waiting list Service Functions
 */
export const waitingListService = {
    getWaitingList,
    getWaitingListCount,
    requestDocuments,
    downloadDocuments,
    downloadWaitingListReport,
    approveDocuments,
    rejectApplication,
    getPropertyUnitDetails,
    offerUnit,
    moveToWaitingList
};