import { createSlice } from '@reduxjs/toolkit';
import { getErrorMessageRecursion, removeToken } from '../../helpers';

const initialState = {
    isLoading: false,
    alertMessage: {
        type: "",
        message: ""
    },
    serverCommunicationError: false,
    overRideAndStopLoader: false
}

const uiSlice = createSlice({
    name: 'loader',
    initialState: initialState,
    reducers: {
        //set loading true
        loadingTrue(state) {
            state.isLoading = true
        },

        //set loading false
        loadingFalse(state) {
            state.isLoading = false
        },

        //succces message
        successMessage(state, action) {
            state.alertMessage = {
                type: "SUCCESS",
                message: action.payload || "success"
            }
        },

        //error message
        errorMessage(state, action) {
            state.alertMessage = {
                type: "ERROR",
                message: action.payload || "error"
            }
        },

        resetServerCommunicationError(state, action){
            state.serverCommunicationError = false
        },

        //succces message
        successMessageApi(state, action) {
            const message = action.payload && action.payload.message ? action.payload.message : action.payload.data && action.payload.data.message ? action.payload.data.message : action.payload.data && action.payload.data[0] && action.payload.data[0]["message"] ? action.payload.data[0]["message"] : null
            state.alertMessage = {
                type: "SUCCESS",
                message: message || "success"
            }
        },

        //error message
        errorMessageApi(state, action) {
            if (action?.payload?.response) {
                //show alert on api 404 errors
                const response = action.payload.response
                const status = response.status || null;
                let message = response.statusText || null;
                const msgData = response.data && response.data.message ? response.data.message : response.data ? response.data : ""
                message = getErrorMessageRecursion(msgData)

                if (status === 401) {
                    removeToken('user');
                    window?.location?.replace('/');
                }

                state.alertMessage = {
                    type: "ERROR",
                    message: message || "Something went wrong"
                }
            }
            else {
                state.serverCommunicationError = true
            }
        },

        //clear message
        clearMessage(state, action) {
            state.alertMessage = {
                type: "",
                message: ""
            }
        },

        updateOverrideLoader(state){
            state.overRideAndStopLoader = true
        }
    },
})

export const {
    loadingTrue, loadingFalse, clearMessage,
    successMessage, errorMessage,
    errorMessageApi, successMessageApi,
    resetServerCommunicationError, updateOverrideLoader
} = uiSlice.actions;
export default uiSlice.reducer;