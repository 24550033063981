import React from "react";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import { Grid, MenuItem, Select, Typography, Pagination } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

//style
import Style from './style'

//components
import { NoResult,Searchbar } from "../../components";

//reducer
import { getCityPolicyDocsCountRequest, updateCityPolicyFilter} from "../../redux/reducer/globalReducer";

//helpers
import { referenceOptions, gets3URL } from "../../helpers";
import { InsuranceIcon } from "../../assets/svg";

const CityPolicy = (props) => {

    //define navigate
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    // props
    const { className } = props;

    // define dispatch
    const dispatch = useDispatch()

    //redux
    const {cityPolicyDocs: {data, pagination, pagination:{skip, limit, count}, filter}} = useSelector((s)=> s.global)

    useEffect(()=>{
        dispatch(getCityPolicyDocsCountRequest({ ...pagination, skip: 0, limit: 12, filter: filter }))
    }, [])

    const onSearch = (key) => {
        dispatch(updateCityPolicyFilter({ 'name': 'search', 'value': key }))
        dispatch(getCityPolicyDocsCountRequest({ ...pagination, skip: 0, limit: 12, filter: { ...filter, search: key } }))
    }

    const handleNextData = (pageIncrement) => {
        dispatch(getCityPolicyDocsCountRequest({ ...pagination, skip: pageIncrement-1, filter: {...filter}}))
    }

    const handlePageChange = (value) => {
        if(value===2){
            navigate("/references/income-rent")
        }
        else if(value===3){
            navigate("/references/resources")
        }
    }

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Typography variant="h3" className="fw-500">Obligation and City Policy</Typography>
                <Grid className="dflex alignCenter">
                    <Searchbar
                        onEnter={onSearch}
                    />
                    <Select className="ml-2" value={1} onChange={(e)=>{handlePageChange(e.target.value)}}>
                        {referenceOptions.map((item)=>(
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                {count > 0 ?
                    <Grid className="bodyInner">
                        <Grid container spacing={2}>
                            {data && data.map((item)=>(
                                <Grid item xs={4}>
                                    <Grid onClick={()=>{window.open(gets3URL(item.document), "_blank")}} className="card pointer">
                                        <InsuranceIcon />
                                        <Typography variant="h6" className="pl-15">{item.file_name}</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid className="pt-5" item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                                defaultPage={6} siblingCount={0}
                                count={Math.ceil((count || 0) / (limit ? limit : 15))}
                                shape="rounded"
                                page={Number(skip)}
                                color="primary"
                                onChange={(e, page) => { handleNextData(page) }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <NoResult text="No City policy document found" />
                }
            </Grid>
        </Grid>
    )
}

// default props
CityPolicy.defaultProps = {
    classes: {},
};

// prop types
CityPolicy.propTypes = {
    classes: PropTypes.object,
};

export default styled(CityPolicy)(Style);