import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//Components
import { Sender, Receiver, TextField } from "../../components";

//redux
import {
    getPropertiesCommentsRequest, updatePropertiesCurrentComment,
    updatePropertiesCommentsRequest, deletePropertiesCommentRequest
} from "../../redux/reducer/propertiesReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";
import { SendIcon } from "../../assets/svg";
function Comments() {
    //define dispatch
    const dispatch = useDispatch();

    const { currentProperty: { general: propertiesDetail }, currentComment, comments } = useSelector(s => (s.properties));
    const { loggedUser } = useSelector(s => (s.profile))

    // get selected manager comments
    useEffect(() => {
        dispatch(getPropertiesCommentsRequest({ id: propertiesDetail.id }))
    }, [dispatch, propertiesDetail.id])

    const updateMessage = (data) => {
        if (data.message) {
            dispatch(updatePropertiesCommentsRequest({
                message: data.message,
                user: data.user_id,
                property: data.property_id,
                id: data.id
            }))
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    }

    // send new messages
    const sendMessage = () =>{
        if (currentComment.message) {
            dispatch(updatePropertiesCommentsRequest({
                ...currentComment,
                user: loggedUser.id,
                property: propertiesDetail.id,
                id: null,
            }))
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    
    }

    return (
        <Grid className="commentsContainer">
            <Grid className="commentsList">
                {comments && comments.map((item, index) => (
                    <>
                        {item.user_id === loggedUser.id ?
                            <Sender
                                key={`sender-${index}`}
                                data={item}
                                loggedUser={loggedUser}
                                updateMessage={updateMessage}
                                handleDelete={() => { dispatch(deletePropertiesCommentRequest(item)) }}
                            />
                            :
                            <Receiver
                                key={`receiver-${index}`}
                                data={item}
                            />
                        }
                    </>
                ))}
            </Grid>
            <Grid className="commentType">
                <TextField
                    placeholder={"Write a comment here"}
                    aria-label="Write a comment here"
                    value={currentComment.message}
                    handleChange={(e) => { dispatch(updatePropertiesCurrentComment(e.value)) }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Tooltip arrow title="Send">
                                <IconButton
                                    onClick={() => {sendMessage()}}
                                    aria-label="Send"
                                >
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                    }}
                    onKeyPress={(e) => {
                        if (e.charCode == 13 || e.keyCode == 13) {
                            sendMessage();
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

// default props
Comments.defaultProps = {
    classes: {},
};

// prop types
Comments.propTypes = {
    classes: PropTypes.object,
};

export default styled(Comments)(Style);