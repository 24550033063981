import React from "react";
import { Routes, Route } from "react-router-dom";

// components
import ListView from "./listView";
import PanelView from "./panelView";

// Property Manager Routes
const PropertyManagerRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<PanelView/>} />
            <Route exact path="/list" element={<ListView/>} />
        </Routes>
    );
};

export default PropertyManagerRoutes;
