import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType, globalDate } from '../../helpers';


const initialState = {
    isLoading: false,
    reportsFileName: "",
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
        }
    },
    filter: {
        reportNameFilter: [],
        addressFilter:[],
        approvalStatusFilter: [],
        yearFilter: [],
        periodFilter:[],
        startDate: "",
        endDate: "",
        managerFilter:[],
    },

    tmpfilter: {
        reportNameFilter: [],
        addressFilter:[],
        approvalStatusFilter: [],
        yearFilter: [],
        periodFilter:[],
        startDate: "",
        endDate: "",
        managerFilter:[],
    },
    docslist: [],
    reportDocument:[],
    reportLoaders: {
        getCountLoader: false,
        getListLoader: false
    },
    overallReportLoader: false
}

const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialState,
    reducers: {

        downloadReportsModelRequest(state) {
            state.reportsFileName = `reportsmodel_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadReportsModelSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.reportsFileName || "reportsmodel.xlsx");
            state.reportsFileName = ''
        },
        downloadReportsModelFailure(state) {
            state.isLoading = false
            state.reportsFileName = ''
        },
        
        // get reports list
        getReportsListRequest(state) {
            state.reportLoaders.getListLoader = true
        },
        getReportsListSuccess(state, action) {
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            };
            state.reportLoaders.getListLoader = false
        },
        getReportsListFailure(state) {
            state.reportLoaders.getListLoader = false
        },

        // get reports list total count
        getReportsListCountRequest(state) {
            state.reportLoaders.getCountLoader = true
        },
        getReportsListCountSuccess(state, action) {
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.reportLoaders.getCountLoader = false
        },
        getReportsListCountFailure(state) {
            state.reportLoaders.getCountLoader = false
        },

        // update reports filter
        updateReportsFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertReportsFilter(state, action){
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempReportsFilter(state, action){
            state.tmpfilter = {
                ...state.filter
            }
        },

        // update reports approval status
        reportsApprovalRequest(state) {
            state.isLoading = true
        },
        reportsApprovalSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.report_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                if (status === 2){
                    indexData.approval_status = 2
                    indexData.ap_status = 'Approved'
                }
                else if(status === 3){
                    indexData.approval_status = 3
                    indexData.ap_status = 'Rejected'
                }
                else if(status === 1){
                    indexData.approval_status = 1
                    indexData.ap_status = 'Review Pending'
                    indexData.submitted_at = globalDate(action.payload.data.submitted_at)
                }
                state.listView.data[elementIndex] = indexData
            }
        },
        reportsApprovalFailure(state) {
            state.isLoading = false
        },

        downloadReportDocumentsRequest(state) {
            state.isLoading = true
            state.docsList = initialState.docsList
        },
        downloadReportDocumentsSuccess(state, action) {
            state.isLoading = false
            state.docsList = action.payload.data
            const id = action.payload.payload.id;
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                if (indexData.button_flags === 0){
                    indexData.button_flags = 1
                }
                state.listView.data[elementIndex] = indexData
            }
        },
        downloadReportDocumentsFailure(state) {
            state.isLoading = false
            state.docsList = initialState.docsList
        },

        // upload report
        uploadReportRequest(state) {
            state.isLoading = true
        },
        uploadReportSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.reportId;
            const elementIndex = state.listView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.listView.data[elementIndex];
                indexData.button_flags = 2
                state.listView.data[elementIndex] = indexData
            }
            state.reportDocument = []
        },
        uploadReportFailure(state) {
            state.isLoading = false
        },

        setReportDocument(state, action){
            state.reportDocument = action.payload
        },

        //clear reports filter
        clearReportsFilter(state) {
            state.filter = {
                reportNameFilter: [],
                addressFilter:[],
                approvalStatusFilter: [],
                yearFilter: [],
                periodFilter:[],
                startDate: "",
                endDate: "",
                managerFilter:[],
            }
            state.clearfilter = true
        },
        updateOverallReportLoaders(state){
            state.overallReportLoader = Object.values(state.reportLoaders).some(loaderValue => loaderValue)
        }

    }
})

export const {
    downloadReportsModelRequest, downloadReportsModelSuccess, downloadReportsModelFailure,
    getReportsListRequest, getReportsListSuccess, getReportsListFailure, 
    getReportsListCountRequest, getReportsListCountSuccess, getReportsListCountFailure,
    updateReportsFilter, clearReportsFilter, revertReportsFilter, updateTempReportsFilter,
    reportsApprovalRequest, reportsApprovalSuccess, reportsApprovalFailure,
    downloadReportDocumentsRequest, downloadReportDocumentsSuccess, downloadReportDocumentsFailure,
    uploadReportRequest,uploadReportSuccess,uploadReportFailure,
    setReportDocument,
    updateOverallReportLoaders,
} = reportsSlice.actions;
export default reportsSlice.reducer;