import palette from "../../assets/themes/palette";

const styles = {
    '&.welcomeContainer': {
        height: 'calc(100vh - 60px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-h1 ': {
            fontSize: 32,
            '&.secondary': {
                color: '#C3C2D9'
            }
        }
    },
    '.welcomeLogo': {
        width: 135,
        height: 135,
        borderRadius: '100px',
        background: '#E4E4EE',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '18px',
        '& svg': {
            fill: '#fff',
        }
    },
    '.titleContainer': {
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'space-between'
    },
    '.filterFields': {
        background: 'red',
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important',
                },
                '& .MuiFormControl-root': {
                    border: '2px solid #2e2e84  !important',
                    borderRadius: '4px',
                    '& fieldset': {
                        border: 'none !important',
                        borderWidth: '0px !important'
                    }
                }
            }
        },
        '& .MuiInputBase-root': {
            maxHeight: '250px',
            overflowY: 'auto',
        },
        '& .MuiFormControl-root': {
            position: 'absolute',
            background: '#fff',
            zIndex: 9
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            padding: '0px'
        },
        '& .MuiChip-root': {
            height: '24px'
        },
        '& .MuiChip-deleteIcon': {
            fontSize: '20px'
        },
        '& .selectBox': {
            '& .MuiFormControl-root': {
                margin: '0px'
            },
            '& .MuiSelect-select': {
                height: '34px',
                padding: '0.5px 6px',
                lineHeight: '32px',
                paddingRight: '34px'
            },
            '& .MuiCheckbox-root': {
                padding: '6px',
                marginRight: '4px'
            }
        }
    },
    '.createBodyContainer': {
        paddingTop: '0px !important'
    },
    '.bodyContainer': {
        paddingTop: 15,
        // paddingTop: 0
    },
    '.bodyInner': {
        background: '#FFFFFF',
        border: '1px solid #EBEBF3',
        borderRadius: '6px',
    },
    '.detailContainer, .createContainer, .accordionHeader': {
        '& .warningIcon': {
            fontSize: '18px',
            marginLeft: '6px',
            color: '#DFB233',
            position: 'absolute',
            right: '-23px',
            top: '-2px'
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500,
            '&.Mui-disabled': {
                WebkitTextFillColor: 'inherit',
                background: 'transparent'
            }
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
    },
    '.createContainer': {
        // '@media (min-width: 1200px)': {
        // '& .MuiGrid-grid-xs-3': {
        //     maxWidth: '20%',
        //     flexBasis: '20%'
        // },
        // '& .MuiGrid-grid-xs-4': {
        //     maxWidth: '40%',
        //     flexBasis: '40%'
        // },
        '& .rangeHypen': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
        // }
    },
    '.map': {
        height: 250
    },
    '.photos': {
        background: '#F5F5F9',
        border: '1px dashed #D1D1D1',
        height: 250,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&.imagesSelected': {
            border: 'none !important'
        }
    },
    '.insertPhotos': {
        color: '#999999'
    },
    '.insertMorePhotos': {
        display: 'none'
    },
    '.amenties': {
        '& .MuiChip-icon': {
            marginLeft: '0px'
        },
        '& .MuiChip-root': {
            height: 27,
            paddingRight: '10px',
            paddingLeft: '10px',
            color: '#BCBCBC',
            border: '1px solid #E5E5E5',
            '.MuiSvgIcon-root': {
                fontSize: '20px',
                fill: '#BCBCBC',
            }
        },
        '& .MuiChip-root.selected': {
            border: `1px solid ${palette.primary.main}`,
            color: palette.primary.main,
            background: '#E6E6F080',
            '& .MuiSvgIcon-root': {
                fill: palette.primary.main
            }
        },
    },
    // units and rents
    '.unitsRents': {
        '& .MuiAccordion-root': {
            boxShadow: 'none',
            '&:before': {
                background: 'transparent !important'
            }
        },
        '& .MuiAccordionSummary-root': {
            background: '#FFFFFF',
            boxShadow: '0px 2px 4px #0000000F',
            border: '1px solid #E9E9EF',
            borderRadius: '6px 6px 0px 0px',
            padding: '20px 30px'
        },
        '& .MuiAccordionSummary-content': {
            margin: '0px auto'
        },
        '& .MuiAccordionDetails-root': {
            background: '#FAFAFC',
            boxShadow: '0px 2px 4px #0000000F',
            border: '1px solid #E9E9EF',
            borderRadius: '0px 0px 6px 6px',
            padding: '0px 20px 15px'
        },
        '& .tableImg': {
            height: 50,
            width: 65,
            objectFit: 'cover',
            borderRadius: 6
        },
        '& .unitmoreImage': {
            position: 'relative'
        },
        '& .photosCount': {
            position: 'absolute',
            top: '36px',
            left: '28px',
            height: '23px',
            width: '42px',
            borderRadius: 6,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontWeight: 400,
            fontSize: '12px',
            background:'#00000040',
            '& .GalleryIcon': {
                marginRight: '5px'
            }
        },
        '& .MuiTableCell-head': {
            background: 'transparent',
            color: '#181840 !important',
            lineHeight: '18px'
        },
        '& .inlineEdit .MuiOutlinedInput-root:hover .MuiOutlinedInput-input': {
            paddingLeft: '0px !important'
        }
    },
    '.createPageTab': {
        '& input': {
            // width: 'inherit'
        },
        '& .MuiGrid-grid-xs-4': {
            maxWidth: '25%'
        },
        '& .MuiGrid-grid-xs-8': {
            maxWidth: '50%'
        }
    },

    // Detail Contaner
    '.detailView': {
    },
    ' .managerDetail': {
        background: '#fff',
        padding: '10px',
        ' .header': {
            // display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 24,
            paddingBottom: 15
        }
    },
    '.propertyLogo': {
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#EBEBF3',
        borderRadius: 30
    },
    ' .managerTitle': {
        display: 'flex',
        alignItems: 'center '
    },
    // Detail Contaner

    //  Comments
    '.commentsContainer': {
        '& .MuiAvatar-root': {
            backgroundColor: palette.greyshades.light3
        }
    },
    '.senderComp': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginLeft: 'auto',
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingRight: 15,
            textAlign: 'right'
        },
        ' .message': {
            background: palette.primary.light2,
            color: palette.primary.contrastText,
            padding: '12px',
            borderTopLeftRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        }
    },
    '.receiverComp': {
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginRight: 'auto',
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingLeft: 15
        },
        ' .message': {
            background: '#F5F5F9',
            padding: '12px',
            borderTopRightRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        }
    },
    '.user': {
        fontWeight: 800,
        fontSize: 14
    },
    '.commentsTime': {
        color: palette.greyshades.main,
        fontSize: 14
    },
    '.commentsList ': {
        height: 'calc(100vh - 424px)',
        overflowY: 'auto',
        paddingBottom: '20px',
        paddingRight: 30
    },
    '.commentType .MuiInputBase-root': {
        background: '#FCFCFC',
        borderRadius: 7
    },
    '.commentBox': {
        paddingRight: '0px',
        paddingTop: '30px',
        '&.commentsSection': {
            paddingBottom: '5px'
        }
    },
    '.commentType': {
        paddingRight: '30px'
    },
    // Comments
    '.sideBarList': {
        '& .sidebarInner': {
            height: 'calc(100vh - 133px)',
            overflowY: 'auto',
        }
    },

    //table
    '.tableContainer': {
        height: 'calc(100vh - 212px)'
    },
    '.pagination': {
        '&  .MuiButtonBase-root': {
            minWidth: '26px',
            height: '26px',
            background: '#fff',
        },
        '& .Mui-selected': {
            background: palette.primary.main
        }
    },
    '.photosList': {
        display: 'flex',
    },
    '.singleimgList': {
        width: '100%',
        '& .listImg': {
            maxWidth: '100%',
            flexBasis: '100%',
            '& img': {
                width: '100%',
                height: '250px',
                objectFit: 'cover'
            },
        },
        '& .moreimgCount': {
            display: 'block !important'
        },
        '& span.photoview': {
            display: 'none'
        },
        '& span.insert': {
            background: '#0000007a',
            padding: '5px 10px',
            borderRadius: '4px',
            display: 'block !important'
        }
    },
    '.listImg ': {
        maxWidth: '50%',
        flexBasis: '50%',
        position: 'relative',
        cursor: 'pointer',
        '&:first-of-type': {
            paddingRight: '5px',
        },
        '&:nth-of-type(1) .moreimgCount': {
            display: 'none'
        },
        '&:nth-of-type(2) .MuiGrid-root': {
            paddingLeft: '10px'
        },
        '& img': {
            maxWidth: '100%',
            width: '100%',
            height: '250px',
            objectFit: 'cover'
        },
        '& .moreimgCount': {
            position: 'absolute',
            bottom: '18px',
            left: '18px',
            color: '#fff',
            '& .photoview': {
                borderRadius: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 500,
                fontSize: '14px',
                background:'#00000080',
                cursor: 'pointer',
                padding: '5px 10px',
                '& .GalleryIcon': {
                    marginRight: '8px'
                }
            },
            '& span.insert': {
                display: 'none'
            }
        },
    },
    // Gallery
    '& .galleryContainer': {
        padding: '30px 0px 40px 40px',
        '& img': {
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            borderRadius: '10px'
        },
        '& .galleryBody': {
            height: 'calc(100vh - 215px)',
            overflowY: 'auto',
            paddingRight: 40,
            paddingTop: '0px',
            alignContent: 'baseline'
        }
    },
    '&.dialogLottery': {
        '& .tableContainer': {
            // maxHeight: 'calc(100vh - 540px)',
            height: 'auto'
        }
    },
    '.lotteryContainer': {
        padding: '40px 100px 50px',
        '& .MuiTable-root': {
            border: `1px solid #ebebf3`
        }
    },
    '.rangeValues': {
        display: 'flex',
        // alignItems: 'center'
    },
    '.adorementBlock': {
        '& .MuiInputAdornment-positionStart': {
            width: '5px',
        },
        '& .MuiTypography-root': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500,
        }
    },
    '.unitsFields': {
        '& .MuiTableCell-root': {
            padding: '12px 8px'
        },
        '& .MuiInputBase-input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500
        },
        '& .nodata .MuiInputBase-root': {
            background: '#fff'
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none',
            width: '5px',
            '& .MuiTypography-root': {
                fontSize: '16px',
                color: '#000',
                fontWeight: 500,
            }
        },
        '& .MuiInputAdornment-positionEnd': {
            '& .MuiTypography-root': {
                fontSize: '16px',
                color: '#000',
                fontWeight: 500,
            }
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input': {
            WebkitTextFillColor: '#222 !important'
        }
    },
    '.galleryItem': {
        position: 'relative',
        '&:hover .deletImage': {
            visibility: 'visible',
            opacity: 1,
            transition: 'all 0.5s'
        }
    },
    '.deletImage': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.5s',
        position: 'absolute',
        right: '15px',
        top: '43px',
        background: '#ffffff7d',
        padding: '2px',
        '& svg': {
            fill: '#fff'
        }
    },
    '.filterContainer': {
        // padding: '30px',
        maxWidth: 800,
        '& .MuiInputBase-root': {
            minHeight: '34px'
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
        // for auto complete
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important'
                }
            },
            '& .MuiFormControl-root': {
                position: 'absolute',
                background: '#fff',
                // zIndex: 9
            },
            '& .MuiOutlinedInput-root': {
                padding: '0px'
            },
            '& .MuiChip-root': {
                height: '24px'
            },
            '& .MuiChip-deleteIcon': {
                fontSize: '20px'
            },
        },
        '& .MuiGrid-grid-md-4': {
            height: '90px'
        }
    },
    ' .detailAction': {
        display: 'flex',
        alignItems: 'center',
        ' .approve': {
            background: palette.colorShades.greenBg,
            borderRadius: 6,
            border: `1px solid ${palette.colorShades.green}`,
            color: `${palette.colorShades.green}`,
            lineHeight: '10px',
            '&:hover': {
                boxShadow: '3px 5px 11px #adadad !important'
            }
        },
        ' .reject': {
            background: palette.colorShades.redBg,
            borderRadius: 6,
            border: `1px solid ${palette.colorShades.red}`,
            color: `${palette.colorShades.red}`,
            lineHeight: '10px',
            '&:hover': {
                boxShadow: '3px 5px 11px #adadad !important'
            }
        }
    },
    '.sqFtRange': {
        '& .sm .MuiInputBase-input': {
            padding: '7.5px 4px 7.5px 6px'
        }
    },

}
export default styles;