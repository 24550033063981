import { Routes, Route } from "react-router-dom";

// components
import CityPolicy from "./cityPolicy";
import IncomeRent from "./incomeRent";
import Resources from "./resources";

// Property Manager Routes
const ReferenceRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<CityPolicy/>} />
            <Route exact path="/income-rent" element={<IncomeRent/>} />
            <Route exact path="/resources" element={<Resources/>} />
        </Routes>   
    );
};

export default ReferenceRoutes;