import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

//style
import Styles from './style'

//Components
import { Button } from "../../components";

function Success(props) {
    // route
    const navigate = useNavigate();

    // props
    const { className } = props;

    return (
        <Grid className={className} container justifyContent={"center"} alignItems="center">
            <Grid align="center" className="successContainer">
                <CheckCircleOutlineIcon className="successIcon" />
                <Typography variant="h1" className="pb-2">The registration details have been submitted successfully</Typography>
                <Typography variant="body1" className="pb-4">The verification email has been sent to your registration email address. Please verify and setup password for profile submission for city staff approval.</Typography>
                <Button variant="contained" size="large" onClick={()=>{navigate("/")}} className={"loginBtn"} aria-label="Click here and check the email">OK</Button>
            </Grid>
        </Grid>
    );
}

// default props
Success.defaultProps = {
    classes: {},
};

// prop types
Success.propTypes = {
    classes: PropTypes.object,
};

export default styled(Success)(Styles)