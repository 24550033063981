import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, styled } from "@mui/material";
import _ from 'lodash';


// import Styles
import Style from './style.jsx';


const SimpleTabHeaderComponent = (props) => {

    // props
    const { tabList, onTabChange, tabIndex, className } = props;

    /**
     * Get Tab List
     */
    const list = useMemo(() => tabList, [tabList]);


    /**
     * Handle Change Event
     * @param {*} event
     * @param {*} newValue
     */
    const handleChange = (event, newValue) => {
        onTabChange(newValue);
    };


    return (
        <Tabs
            value={tabIndex}
            onChange={(event, value) => handleChange(event, value)}
            aria-label="defaultTab"
            className={className}
        >
            {
                list.map((data, index) => (
                    <Tab tabIndex={"0"} aria-label={data} label={data} key={index} disableRipple />
                ))
            }
        </Tabs>
    );
};


// Define Prop Type
SimpleTabHeaderComponent.propTypes = {
    tabList: PropTypes.array,
    onTabChange: PropTypes.func,
    tabIndex: PropTypes.number,
    className: PropTypes.string
};


// Default Props
SimpleTabHeaderComponent.defaultProps = {
    tabList: [],
    onTabChange: () => { },
    tabIndex: 0,
    className: ""
};

/**
 * Compare Prev and Current Prev
 * @param {*} prevProps
 * @param {*} nextProps
 * @returns
 */

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}


/**
 * Export As Memo Component
 */
export const TabHeader = memo(styled(SimpleTabHeaderComponent)(Style), areEqual);