import React, { useState, memo, useEffect } from 'react';
import { Grid, IconButton, InputAdornment, Typography, styled, Tooltip } from '@mui/material';
import PropTypes from "prop-types";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Close } from '@mui/icons-material';
import _ from "lodash";
import moment from 'moment';

//components
import { Avatar, TextField } from '..';

// styles
import Styles from './style';

// helpers
import { gets3URL, globalDate } from '../../helpers';
import { SendIcon } from '../../assets/svg';

const SenderComponent = (props) => {

    const { className, data, updateMessage, loggedUser, handleDelete } = props;

    const [editable, setEdiatble] = useState(false);
    const [editMessage, setEditMessage] = useState(data.message);

    useEffect(() => {
        if (!editable) {
            setEditMessage(data.message)
        }
    }, [editable])

    //variable
    const dataTimeCheck = moment(data.sent_at).diff(moment(), 'minutes')

    return (
        <Grid className={`${className} senderComp`}>
            {
                editable ?
                    <Grid className="editField">
                        <Grid className="commentType">
                            <TextField
                                aria-label={"Write a comment here"}
                                placeholder={"Write a comment here"}
                                value={editMessage}
                                handleChange={(e) => { setEditMessage(e.value); }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Tooltip arrow title="Cancel">
                                            <IconButton
                                                onClick={() => { setEdiatble(false); }}
                                                aria-label="Clear the edited text"
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow title="Send">
                                            <IconButton
                                                onClick={() => { updateMessage({ ...data, message: editMessage }) }}
                                                aria-label="Send the edited text"
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>,
                                }}
                                onKeyPress={(e) => {
                                    if (e.charCode == 13 || e.keyCode == 13) {
                                        updateMessage({ ...data, message: editMessage });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid> :
                    <>
                        <Grid className="messageDetail">
                            {
                                !editable && dataTimeCheck > -60 && <Grid className="editOptions">
                                    <Tooltip arrow title="Edit"><IconButton className="p5" onClick={() => { setEdiatble(true) }} aria-label="Edit"><EditIcon /></IconButton></Tooltip>
                                    <Tooltip arrow title="Delete"><IconButton className="p5" onClick={() => { handleDelete(data) }} aria-label="Delete"><DeleteOutlineIcon /></IconButton></Tooltip>
                                </Grid>
                            }
                            <Typography variant="bod1" className="user">
                                <Grid className="pr-1 name">{`${loggedUser.first_name} ${loggedUser.last_name}`}</Grid>
                                <Grid className="commentsTime">{globalDate(data.sent_at)}</Grid>
                            </Typography>
                            <Typography variant="body1" className="message">{data.message}</Typography>
                        </Grid>
                        <Avatar
                            alt={loggedUser.first_name}
                            src={gets3URL(loggedUser.profile_photo)}
                        />
                    </>
            }
        </Grid>
    );
}

//default types
SenderComponent.defaultProps = {
    data: {},
    loggedUser: {},
    updateMessage: () => { },
    handleDelete: () => { },
    className: ""
}

//prop types
SenderComponent.propTypes = {
    data: PropTypes.object,
    loggedUser: PropTypes.object,
    updateMessage: PropTypes.func,
    handleDelete: PropTypes.func,
    className: PropTypes.string
}

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const Sender = memo(styled(SenderComponent)(Styles), areEqual);