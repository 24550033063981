const Styles = {
    '.MuiPaper-root': {
        padding: 30
    },
    '.actions': {
        marginTop: 20,
        '& button': {
            fontWeight: '500'
        }
    }
}

export default Styles;