import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//style
import Styles from './style'

//Components
import { Avatar, Button, Dialog, LeftPanel, NoResult, TabHeader, TabPanel, TextField } from "../../components";
import Detail from "./detail";
import Comments from "./comments";
import TopPanel from "./topPanel";

// reducer
import {
    getPMDetailRequest, updatePMFlagRequest, managerApprovalRequest,
    getPMCountRequest, getPMLeftPanelListRequest, updatePMCommentsRequest,
    updateTempPMFilter,
} from "../../redux/reducer/propertyManagerReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";

// helpers
import { propertyManagerFieldMap, propertyManagerStatusOptions, propertyManagerStatusPlOptions, jsonParser, gets3URL } from "../../helpers";

function PanelView(props) {
    //define dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();
    const { search } = useLocation()
    const [searchParams] = useSearchParams()

    // props
    const { className } = props;

    // state
    const [tabIndex, setTabIndex] = useState(0);
    const [rejectDialog, setRejectDialog] = useState(false);
    const [comment, setComment] = useState("")

    //redux
    const { panelView: { data, pagination, pagination: { count, sortField, sortBy } }, managerDetail, filter, isLazyLoading } = useSelector(s => (s.propertyManager))
    const { loggedUser } = useSelector(s => (s.profile))

    // get left panel data
    useEffect(() => {
        let searchQuery = {}
        let redirect = false
        if (search && search.includes("?id=")) {
            searchQuery = {
                pm_id: searchParams.get("id")
            }
            redirect = true
        }
        if(search && search.includes("comment")){
            setTabIndex(1)
            redirect = true
        }

        //clear search query if any
        if(redirect){
            navigate("/property-manager")
        }

        // get total count for pagination and then call the data in the epic
        dispatch(getPMCountRequest({ ...pagination, skip: 0, isFirst: true, filter: { ...filter, ...searchQuery } }))
    }, [dispatch])

    // approve or reject manager
    const handleApproval = (approval) => {
        dispatch(managerApprovalRequest({
            approval_status: approval,
            user_id: managerDetail.id
        }))
    }

    // show hide prev button
    const hasPrev = () => {
        return managerDetail && managerDetail.id && data && data[0] && data[0].id !== managerDetail.id ? true : false
    }

    const hasNext = () => {
        const curIndex = data.findIndex(f => (f.id === managerDetail.id))
        return curIndex < data.length - 1
    }

    const handleFilter = () => {
        //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
        dispatch(updateTempPMFilter())
        dispatch(getPMCountRequest({ ...pagination, skip: 0, isFirst: true, filter }))
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getPMCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter }))
    }

    const handleRejection = () => {
        if (comment) {
            dispatch(managerApprovalRequest({
                approval_status: "3",
                user_id: managerDetail.id
            }))
            dispatch(updatePMCommentsRequest({
                message: comment,
                message_from: loggedUser.id,
                message_to: managerDetail.id,
                id: null
            }))
            setComment("");
            setRejectDialog(false)
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    }

    // remove reject from the status dropdown if the application is rejected
    const getStatusOptions = (data) => {
        if(data.approval_status != 3){
            return propertyManagerStatusOptions
        }
        else{
            let filteredStatuses = {...jsonParser(propertyManagerStatusOptions)}
            delete filteredStatuses['3']
            return filteredStatuses
        }
    }

    return (
        <Grid container spacing={2} className={className}>
            <Grid item xs={12}>
                <TopPanel
                    handleFilter={handleFilter}
                    sortValue={`${sortField}-${sortBy}`}
                    handleSorting={handleSorting}
                    IconsList={["VIEW_TYPE", "ADD", "CHILDREN", "DOWNLOAD", "FILTER_LIST", hasPrev() ? "PREV" : null, hasNext() ? "NEXT" : null]}
                />
            </Grid>
            <Grid item xs={12} className="bodyContainer">
                {(count > 0 && managerDetail && managerDetail.id) ?

                    <Grid container spacing={2}>
                        <Grid item xs={4} lg={3} className={"sideBarList"}>
                            <LeftPanel
                                viewId={managerDetail.id || null}
                                fieldMap={propertyManagerFieldMap}
                                handleStatusChange={(name, value, data) => {
                                    if (Number(value) === 3) {
                                        setRejectDialog(true)
                                    } else {
                                        dispatch(managerApprovalRequest({
                                            approval_status: value,
                                            user_id: data.id
                                        }))
                                    }
                                }}
                                data={data || []}
                                disableStatusChange={(data) => {
                                    return data.approval_status === 2 ? true : false
                                }}
                                totalCount={count}
                                apicallback={() => {
                                    dispatch(getPMLeftPanelListRequest({ ...pagination, filter: filter }))
                                }}
                                statusOptions={getStatusOptions}
                                statusViewOption={propertyManagerStatusPlOptions}
                                handleView={(data) => {
                                    dispatch(getPMDetailRequest(data))
                                    setTabIndex(0)
                                }}
                                handleFlag={(data) => {
                                    dispatch(updatePMFlagRequest({ ...data, is_flag: data.is_flag ? 0 : 1 }))
                                }}
                                isLoading={isLazyLoading}
                            />
                        </Grid>
                        <Grid item xs={8} lg={9} className={"detailView"}>
                            <Grid className="managerDetail">
                                <Grid className="header">
                                    <Grid className="managerTitle">
                                        <Avatar
                                            src={gets3URL(managerDetail.profile_photo)}
                                            alt={managerDetail.name}
                                        />
                                        <Grid className="pl-1">
                                            <Typography variant="h2" className="fw-500">{managerDetail.name || ""}</Typography>
                                        </Grid>
                                    </Grid>
                                    {managerDetail.approval_status !== 2 &&
                                        <Grid className="detailAction">
                                            {managerDetail.approval_status !== 3 && <Button size={"small"} variant={"contained reject mr-1"}
                                                onClick={() => { setRejectDialog(true) }}
                                                aria-label="Reject"
                                            >Reject</Button>}
                                            <Button size={"small"} variant={"contained approve"} onClick={() => { handleApproval("2") }} aria-label="Approve">Approve</Button>
                                        </Grid>
                                    }
                                </Grid>

                                <Grid
                                    container
                                    wrap="nowrap"
                                    className={'tabHeaderContainer'}
                                >
                                    <TabHeader
                                        tabStyle={'inline'}
                                        tabIndex={tabIndex}
                                        tabList={['Details', 'Comments']}
                                        onTabChange={(newValue) => setTabIndex(newValue)}
                                    />
                                </Grid>
                                <Grid className={'tabsBodySection'}>
                                    <Grid className={"tabsBody"}>
                                        {
                                            tabIndex === 0 &&
                                            <TabPanel value={0} index={tabIndex} height="calc(100vh - 294px)" sx={{ overflowY: 'auto' }}>
                                                <Detail />
                                            </TabPanel>
                                        }
                                        {
                                            tabIndex === 1 &&
                                            <TabPanel boxClass="commentBox" value={1} index={tabIndex} height="calc(100vh - 294px)">
                                                <Comments />
                                            </TabPanel>
                                        }
                                    </Grid>
                                </Grid>

                                <Dialog
                                    open={rejectDialog}
                                    handleDialogClose={() => { setRejectDialog(false); setComment("") }}
                                    confirmHandle={() => {
                                        handleRejection()
                                    }}
                                >
                                    <Typography variant='h6' className="pb-1">Please provide the rejection reason</Typography>
                                    <TextField
                                        placeholder={"Rejection reason"}
                                        aria-label="Enter the reject reason"
                                        multiline
                                        value={comment}
                                        handleChange={e => { setComment(e.value) }}
                                    />
                                </Dialog>

                            </Grid>

                        </Grid>
                    </Grid>
                    :
                    <>{!isLazyLoading && <NoResult text="No Property Manager Found" />}</>
                }
            </Grid>
        </Grid>
    );
}

// default props
PanelView.defaultProps = {
    classes: {},
};

// prop types
PanelView.propTypes = {
    classes: PropTypes.object,
};

export default styled(PanelView)(Styles);