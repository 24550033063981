import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

// Import Styles and images
import Styles from "./style";

function PublicLayoutComponent({ component: Component, className, classes, ...props }) {

    return (
        <Box className={className}>
            <Component {...props} />
        </Box>
    );
}

// prop types
PublicLayoutComponent.propTypes = {
    classes: PropTypes.object,
};

export const PublicLayout = styled(PublicLayoutComponent)(Styles)