import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//utils
import { convertPhoneformat } from "../../helpers";

const Detail = () => {

    //redux
    const { managerDetail } = useSelector(s=>(s.propertyManager))

    return (
        <Grid container className="detailContainer">
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">COMPANY NAME</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.company_name || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">STATUS</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.status || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">Email</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.email || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">PHONE</Typography>
                        <Typography variant="h5" className="pt-1">{convertPhoneformat(managerDetail.phone) || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ALTERNATE PHONE</Typography>
                        <Typography variant="h5" className="pt-1">{convertPhoneformat(managerDetail.alt_phone) || ""}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" color="textSecondary">STREET ADDRESS</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.address || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">STATE</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.state || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">CITY</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.city || ""}</Typography>
                    </Grid>
                    
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ZIP CODE</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.zipcode || ""}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">GENDER</Typography>
                        <Typography variant="h5" className="pt-1">{managerDetail.gender || ""}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
Detail.defaultProps = {
    classes: {},
};

// prop types
Detail.propTypes = {
    classes: PropTypes.object,
};

export default styled(Detail)(Style);