// Import API Helper
import { getRequest } from "../../helpers";

/*** get property manager filter list*/
async function getPMFilterList(params) {
    const response = await getRequest(`global-filter/property-manager/?name=${params.name}`);
    return response;
}

/*** get properties filter list*/
async function getPropertiesFilterList(params) {
    const response = await getRequest(`global-filter/properties/?name=${params.name}&user_id=${params.user_id || ""}`);
    return response;
}

/*** get applicants filter list*/
async function getApplicantsFilterList(params) {
    const response = await getRequest(`global-filter/applicants/?name=${params.name}&user_id=${params.user_id || ""}&waitinglist=${params.waitinglist}&tenants=${params.tenants}`);
    return response;
}

//get applicants status list
async function getStatusFilterList(params) {
    const response = await getRequest(`global-filter/status/?approval_status=${params.approval_status}&user_id=${params.user_id || ""}`)
    return response
}
//get properties address list
async function getAddressFilterList(params) {
    const response = await getRequest(`global-filter/address/?address=${params.address}&user_id=${params.user_id || ""}`)
    return response
}

//get applications address filter list
async function getApplicationsAddressFilterList(params) {
    const response = await getRequest(`global-filter/applications-address/?address=${params.address}&user_id=${params.user_id || ""}&waitinglist=${params.waitinglist}`)
    return response
}

//get reports address filter list
async function getReportsAddressFilterList(params) {
    const response = await getRequest(`global-filter/reports-address/?address=${params.address}&user_id=${params.user_id || ""}`)
    return response
}

//get reports year filter list
async function getReportsYearFilterList(params) {
    const response = await getRequest(`global-filter/reports-year/?address=${params.year}&user_id=${params.user_id || ""}`)
    return response
}

//get tenants address filter list
async function getTenantsAddressFilterList(params) {
    const response = await getRequest(`global-filter/tenants-address/?address=${params.address}&user_id=${params.user_id || ""}`)
    return response
}

//get applications status filter lisyt
async function getApplicationsStatusFilterList(params) {
    const response = await getRequest(`global-filter/applications-status/?approval_status=${params.approval_status}&user_id=${params.user_id || ""}`)
    return response
}

//get waiting list status filter list
async function getWaitingListStatusFilterList(params) {
    const response = await getRequest(`global-filter/waitinglist-status/?approval_status=${params.approval_status}&user_id=${params.user_id || ""}`)
    return response
}

//get tenants status filter list
async function getTenantsStatusFilterList(params) {
    const response = await getRequest(`global-filter/tenants-status/?approval_status=${params.approval_status}&user_id=${params.user_id || ""}`)
    return response
}

//get document name filter for city staff
async function getIncomeDocNameFilter(params) {
    const response = await getRequest(`global-filter/income-docname/?name=${params.name}`)
    return response
}

//get names of city staff who uploaded the documents
async function getIncomeDocumentUploaderFilter(params) {
    const response = await getRequest(`global-filter/income-csname/?name=${params.name}`)
    return response
}

//get document name filter for city staff
async function getPolicyDocNameFilter(params) {
    const response = await getRequest(`global-filter/policy-docname/?name=${params.name}`)
    return response
}

//get names of city staff who uploaded the documents
async function getPolicyDocumentUploaderFilter(params) {
    const response = await getRequest(`global-filter/policy-csname/?name=${params.name}`)
    return response
}

//get reports names filter list
async function getReportsNameFilterList(params) {
    const response = await getRequest(`global-filter/reports-names/?address=${params.year}&user_id=${params.user_id || ""}`)
    return response
}

//get reports status filter list
async function getReportsStatusFilterList(params) {
    const response = await getRequest(`global-filter/reports-status/?approval_status=${params.approval_status}&user_id=${params.user_id || ""}`)
    return response
}

//get reports manager filter list
async function getReportsManagerFilterList(params) {
    const response = await getRequest(`global-filter/reports-managers/?name=${params.manager}`)
    return response
}

//get report period filter list
async function getReportPeriodFilterList(params) {
    const response = await getRequest(`global-filter/report-period/?period=${params.period}&user_id=${params.user_id || ""}`)
    return response
}

async function getAllPMFilterList(params) {
    const response = await getRequest(`global-filter/property-all-manager/?name=${params.name}`);
    return response;
}

/**
 * Export All global Filter Service Functions
 */
export const globalFilterService = {
    getPMFilterList,
    getPropertiesFilterList,
    getApplicantsFilterList,
    getStatusFilterList,
    getAddressFilterList,
    getApplicationsAddressFilterList,
    getApplicationsStatusFilterList,
    getWaitingListStatusFilterList,
    getTenantsStatusFilterList,
    getIncomeDocNameFilter,
    getIncomeDocumentUploaderFilter,
    getPolicyDocNameFilter,
    getPolicyDocumentUploaderFilter,
    getTenantsAddressFilterList,
    getReportsAddressFilterList,
    getReportsYearFilterList,
    getReportsNameFilterList,
    getReportsStatusFilterList,
    getReportsManagerFilterList,
    getReportPeriodFilterList,
    getAllPMFilterList
};