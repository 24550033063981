import palette from "./palette";

const typography = {
	h1: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 24,
		fontWeight: 700,
		textTransform: 'unset',
	},
	h2: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 22,
		fontWeight: 700,
		textTransform: 'unset',
	},
	h3: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 20,
		fontWeight: 700,
		textTransform: 'unset',
	},
	h4: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 18,
		fontWeight: 700,
		textTransform: 'unset',
	},
	h5: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 16,
		fontWeight: 500,
	},
	h6: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 15,
		fontWeight: 500,
	},
	subtitle1: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 14,
		fontWeight: 500,
	},
	subtitle2: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 13,
		fontWeight: 500,
	},
	body1: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 14,
		fontWeight: 400,
	},
	body2: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 13,
		fontWeight: 400,
	},
	button: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 16,
		fontWeight: 600,
	},
	caption: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 14,
		fontWeight: 400,
		textTransform: 'uppercase',
		color: `${palette.greyshades.main} !important`,
		paddingBottom: 5
	},
	overline: {
		fontFamily: 'Public Sans, sans-serif',
		fontSize: 14,
		fontWeight: 500,
		textTransform: 'uppercase',
	},
};

export default typography;