import palette from "../../../assets/themes/palette";

const style = {
    '.titleContainer': {
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'space-between',
        '& .SearchIcon': {
            width: '16px',
            height: '16px',
            '& path': {
                fill: '#343487',
            }
        },
        '& .MuiToggleButton-root': {
            height: '30px',
            textTransform: 'capitalize',
            background: '#fff',
            '& .MuiTypography-root': {
                color: '#979797'
            },
            '&.Mui-selected': {
                background: '#DFB233',
                border: 'none !important',
                '& .MuiTypography-root': {
                    fontWeight: '500',
                    color: '#fff'
                }
            }
        }
    },
    '.bodyContainer': {
        padding: 20,
        paddingTop: 0
    },
    '.bodyInner': {
        // background: '#FFFFFF',
        // border: '1px solid #EBEBF3',
        borderRadius: '6px',
    },
    '.uploadDocuments': {
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
    },
    '.filterContainer': {
        // padding: '30px',
        maxWidth: 800,
        '& .MuiInputBase-root': {
            minHeight: '34px'
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
        '& .MuiGrid-grid-md-4': {
            height: '90px'
        },
        // for auto complete
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important'
                }
            },
            '& .MuiFormControl-root': {
                position: 'absolute',
                background: '#fff',
                // zIndex: 9
            },
            '& .MuiOutlinedInput-root': {
                padding: '0px'
            },
            '& .MuiChip-root': {
                height: '24px'
            },
            '& .MuiChip-deleteIcon': {
                fontSize: '20px'
            },
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EAEAF3'
        }
    },
}
export default style;