// Import API Helper
import { getRequest, postRequest } from "../../helpers";

/**
 * Export All notification Service Functions
 */
export const webNotificationService = {
    webNotificationList,
    newWebNotification,
    markAsReadNotification,
    markAllAsReadNotification
};


/**
* Web Notification List
*/
async function webNotificationList(payload) {
    const response = await getRequest(`notifications/${payload.notificationType || 0}/`);
    return response;
}

/**
* New Web Notification List
*/
async function newWebNotification() {
    const response = await getRequest(`notifications/new/`);
    return response;
}

/**
* mark notification as read
*/
async function markAsReadNotification({ id }) {
    const response = await postRequest(`notifications/read/${id}/`);
    return response;
}

/**
* mark notification as read
*/
async function markAllAsReadNotification({ id }) {
    const response = await postRequest(`notifications/read-all/`);
    return response;
}