import * as Sentry from "@sentry/react";

if (["QA", "UAT", "PROD"].includes(process.env.REACT_APP_ENVIRONMENT)) {
  Sentry.init({
    dsn: "https://0da7c77501130a06b22958fef5bcc1ae@o4507486045536256.ingest.us.sentry.io/4507486048485376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
