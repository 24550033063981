import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

//style
import Style from "./style";

//Components
import { NoResult, Table } from "../../components";
import TopPanel from "./topPanel";

// reducer
import {
  getPropertiesCountRequest,
  getPropertiesListRequest,
  updatePropertiesViewType,
  updatePropertiesFilter,
  updateTempPropertiesFilter,
} from "../../redux/reducer/propertiesReducer";

const ListView = (props) => {
  // props
  const { className } = props;

  //define navigate
  const navigate = useNavigate()

  //define dispatch
  const dispatch = useDispatch();

  //redux
  const {
    listView: {
      data,
      pagination,
      pagination: { count, skip, limit, sortField, sortBy },
    },
    viewType,
    filter,
  } = useSelector((s) => s.properties);
  const { loggedUser } = useSelector((s) => s.profile);

  const isCityStaff = loggedUser.user_group_id === 3;

  useEffect(() => {
    // get total count for pagination and then call the data in the epic
    dispatch(
      getPropertiesCountRequest({
        ...pagination,
        skip: 0,
        viewType: viewType,
        filter: filter,
      })
    );
  }, [dispatch]);

  const handleFilter = () => {
    //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
    dispatch(updateTempPropertiesFilter())
    dispatch(getPropertiesCountRequest({ ...pagination, skip: 0, isFirst: true, filter: filter }))
  }

  const handleSorting = ({ sortField, sortBy }) => {
    dispatch(
      getPropertiesCountRequest({
        ...pagination,
        sortField,
        sortBy,
        skip: 0,
        isFirst: true,
        filter: filter,
      })
    );
  };

  const viewDetailSwitch = (data) => {
    dispatch(updatePropertiesViewType("panel"))
    dispatch(updatePropertiesFilter({ name: "propertiesFilter", value: [{ id: data.id, name: data.name }] }))
    navigate(`/properties`)
  }

  return (
    <Grid container className={className}>
      <Grid item xs={12}>
        <TopPanel
          IconsList={[
            "VIEW_TYPE",
            isCityStaff ? null : "ADD",
            "CHILDREN",
            "FILTER_LIST",
            "DOWNLOAD",
          ]}
          sortValue={`${sortField}-${sortBy}`}
          handleSorting={handleSorting}
          handleFilter={handleFilter}
        />
      </Grid>
      <Grid item xs={12} className="bodyContainer pt-2">
        {count > 0 ?
          <Table
            header={
              isCityStaff
                ? [
                  "Property Name",
                  "Property Address",
                  "Property Manager",
                  "Units",
                  "Applications",
                  "Vacant Units",
                  "Vacancy",
                  "Approval Status",
                ]
                : ["Property Name", "Property Address", "Units", "Applications", "Vacant Units", "Vacancy", "Approval Status"]
            }
            fields={
              isCityStaff
                ? [
                  "name",
                  "address",
                  "manager_name",
                  "units",
                  "applications",
                  "vacant_units",
                  "vacancy_percentage",
                  "ap_status",
                ]
                : ["name", "address", "units", "applications", "vacant_units", "vacancy_percentage", "ap_status"]
            }
            data={data}
            skip={skip}
            totalCount={count}
            limit={limit}
            showAvatar={true}
            updatePage={(e, val) => {
              dispatch(
                getPropertiesListRequest({ ...pagination, skip: val - 1, filter: filter })
              );
            }}
            handleLinkClick={(e) => { viewDetailSwitch(e) }}
            linkFields={["name"]}
          />
          :
          <NoResult text="No Property Found" />
        }
      </Grid>
    </Grid>
  );
};

// default props
ListView.defaultProps = {
  classes: {},
};

// prop types
ListView.propTypes = {
  classes: PropTypes.object,
};

export default styled(ListView)(Style);
