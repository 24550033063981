import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, IconButton, List, ListItem, Typography, Grid, Tooltip } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDropzone } from 'react-dropzone';

import "./style.css";

// helper
import { formatBytesToSize, getFileNameWithTime } from "../../helpers";

// reducer
import { errorMessage } from "../../redux/reducer/uiReducer";

export function DragandDrop({ selectedFiles, setSelectedFiles, accept }) {
  //define dispatch
  const dispatch = useDispatch();

  // dropzone
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: true, accept: "application/pdf" });

  useEffect(() => {
    if (accept) {
      let resFiles = []
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i].type === accept) {
          const newFile = new File([acceptedFiles[i]], getFileNameWithTime(acceptedFiles[i]),{
            type: "application/pdf"
          });
          resFiles.push(newFile)
        }
      }
      if (acceptedFiles.length === resFiles.length) {
        setSelectedFiles([...selectedFiles, ...resFiles])
      } else {
        dispatch(errorMessage(`Please upload only ${accept} type files`))
      }
    } else {
      let resFiles = []
      for (let i = 0; i < acceptedFiles.length; i++) {
        const newFile = new File([acceptedFiles[i]], getFileNameWithTime(acceptedFiles[i]),{
          type: "application/pdf"
        });
        resFiles.push(newFile)
      }
      setSelectedFiles([...selectedFiles, ...resFiles])
    }
  }, [acceptedFiles])

  const deleteFile = (i) => {
    let tmpFiles = [...selectedFiles]
    tmpFiles.splice(i, 1)
    setSelectedFiles(tmpFiles)
  }

  const renderFileList = () => {
    const listItems = []
    for (let i = 0; i < selectedFiles.length; i++) {
      let file = selectedFiles[i]
      listItems.push(
        <ListItem sx={{ pl: 0 }}
          key={`obligation-file-upload-key-${i}`}
        >
          <Tooltip title="Delete" arrow>
            <IconButton
              edge="end"
              aria-label="delete"
              className="mr-1"
              sx={{ marginLeft: '-9px' }}
              onClick={() => {
                deleteFile(i)
              }}
            >
              <DeleteOutlineIcon sx={{ color: '#343487' }} />
            </IconButton>
          </Tooltip>
          {(i + 1)}. {file.name} ({formatBytesToSize(file.size)})
        </ListItem>
      )
    }
    return listItems
  }

  return (
    <Box>
      <Box
        color="primary"
        aria-label="add-image"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid className="uploadBox container" {...getRootProps({ className: 'dropzone' })} startIcon={<CloudUploadIcon />}>
          <Grid align="center">
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fill: "#C3C2D9", width: '60px', height: '60px' }} />
            <Typography variant="body1" color={"textSecondary"}>Drag and Drop or Browse file</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ display: 'flex' }}>
        <List>{renderFileList()}</List>
      </Box>
    </Box>
  );
}

DragandDrop.defaultProps = {
  name: "file-input",
  multiple: false,
  accept: '.pdf',
  selectedFiles: [],
  setSelectedFiles: () => { },
  accept: ""
};
