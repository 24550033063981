import palette from "../../assets/themes/palette";

const Styles = {
    padding: '13px 23px',
    height: 60,
    background: '#fff',
    boxShadow: '0px 1px 4px #00000014',
    borderRadius: '0px 0px 6px 6px',
    '& .MuiToggleButton-root': {
        padding: '5px 10px',
        '&.Mui-selected': {
            background: palette.secondary.main,
            '& svg path': {
                fill: '#fff'
            }
        }
    },
    '.addBtn': {
        background: palette.primary.main,
        color: palette.primary.contrastText,
        ' svg path#Path_3176': {
            fill: palette.primary.contrastText
        },
        '&:hover': {
            background: palette.primary.main,
            boxShadow: '3px 5px 11px #adadad !important'
        }
    },
    '.filterRtActions': {
        '& .MuiButton-textPrimary': {
            color: '#181840 !important',
            fontWeight: '400',
            fontSize: '14px'
        },
        '& .dotBtn': {
            height: '8px',
            width: '8px',
            borderRadius: '30px',
            background: '#DFB233',
            position: 'absolute',
            right: '0px',
            top: '2px'
        }
    }
}

export default Styles;