import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    runLotteryRequest, runLotterySuccess, runLotteryFailure,
    downloadLotteryResultRequest,downloadLotteryResultSuccess,downloadLotteryResultFailure
} from "../reducer/runLotteryReducer"
import { getPropertiesDetailRequest } from '../reducer/propertiesReducer';

import { runLotteryService } from '../service';

/** run Lottery Process */
const runLotteryProcess = (action$) => action$.pipe(
    ofType(runLotteryRequest),
    mergeMap(({ payload }) => {
        return from(runLotteryService.runLotteryProcess(payload)).pipe(
            concatMap((res) => (of(
                runLotterySuccess(res.data.data),
                getPropertiesDetailRequest({id : payload.property_id }),
                successMessageApi(res)))),
            catchError((res) => of(runLotteryFailure(), errorMessageApi(res))),
        )
    }),
)

/**download lottery Report */
const downloadLotteryResult = (action$) => action$.pipe(
    ofType(downloadLotteryResultRequest),
    mergeMap(({ payload }) => {
        return from(runLotteryService.downloadLotteryResult(payload)).pipe(
            concatMap((res) => of(downloadLotteryResultSuccess(res), successMessageApi(res))),
            catchError((res) => of(downloadLotteryResultFailure(), errorMessageApi(res))),
        )
    }),
)

// Export All global Epic Functions
export const runLotteryEpic = [
    runLotteryProcess,
    downloadLotteryResult
];