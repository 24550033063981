import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    getTenantsLeftPanelListRequest, getTenantsLeftPanelListSuccess, getTenantsLeftPanelListFailure,
    getTenantsCountRequest, getTenantsCountSuccess, getTenantsCountFailure,
    getTenantsListRequest, getTenantsListSuccess, getTenantsListFailure,
    getTenantsDetailRequest, getTenantsDetailSuccess, getTenantsDetailFailure,
    updateTenantStatusRequest, updateTenantStatusSuccess, updateTenantStatusFailure,
    updateTenantsFlagRequest, updateTenantsFlagSuccess, updateTenantsFlagFailure,
    downloadTenantsRequest, downloadTenantsSuccess, downloadTenantsFailure,
    updateOverallTenantsLoaders
} from "../reducer/tenantsReducer"

import { tenantsService } from '../service';

/**get tenants left Panel List */
const getTenantsLeftPanelList = (action$) => action$.pipe(
    ofType(getTenantsLeftPanelListRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.getTenantsLeftPanelList(payload)).pipe(
            concatMap((res) => {
                const data = res.data && res.data.data ? res.data.data : []
                if (payload.isFirst && data && data[0]) {
                    return of(getTenantsLeftPanelListSuccess(res.data), getTenantsDetailRequest(data[0]), updateOverallTenantsLoaders())
                } else {
                    return of(getTenantsLeftPanelListSuccess(res.data), updateOverallTenantsLoaders())
                }
            }),
            catchError((res) => of(getTenantsLeftPanelListFailure(), errorMessageApi(res), updateOverallTenantsLoaders())),
        )
    })
)

// get total count of tenants
const getTenantsCount = (action$, state$) => action$.pipe(
    ofType(getTenantsCountRequest),
    mergeMap(({ payload }) => {
        const viewType = state$.value.tenants.viewType
        return from(tenantsService.getTenantsCount(payload)).pipe(
            concatMap((res) => {
                if (viewType === "panel") {
                    return of(getTenantsCountSuccess(res.data), getTenantsLeftPanelListRequest(payload), updateOverallTenantsLoaders())
                } else {
                    return of(getTenantsCountSuccess(res.data), getTenantsListRequest(payload), updateOverallTenantsLoaders())
                }
            }),
            catchError((res) => of(getTenantsCountFailure(), updateOverallTenantsLoaders(), errorMessageApi(res))),
        )
    }),
)

// get Tenants list
const getTenantsList = (action$) => action$.pipe(
    ofType(getTenantsListRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.getTenantsList(payload)).pipe(
            concatMap((res) => of(getTenantsListSuccess(res.data), updateOverallTenantsLoaders())),
            catchError((res) => of(getTenantsListFailure(), updateOverallTenantsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**get Tenants data */
const getTenantsDetail = (action$) => action$.pipe(
    ofType(getTenantsDetailRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.getTenantsDetail(payload)).pipe(
            concatMap((res) => of(getTenantsDetailSuccess(res.data.data), updateOverallTenantsLoaders())),
            catchError((res) => of(getTenantsDetailFailure(), updateOverallTenantsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**update Tenants status */
const updateTenantStatus = (action$) => action$.pipe(
    ofType(updateTenantStatusRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.updateTenantStatus(payload)).pipe(
            concatMap((res) => of(
                updateTenantStatusSuccess({ data: res.data.data, payload: payload }),
                getTenantsDetailRequest({ id: payload.tenant_id }),
                updateOverallTenantsLoaders(),
                successMessageApi(res))
            ),
            catchError((res) => of(updateTenantStatusFailure(), updateOverallTenantsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**update tenants flag*/
const updateTenantsFlag = (action$) => action$.pipe(
    ofType(updateTenantsFlagRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.updateTenantsFlag(payload)).pipe(
            concatMap((res) => of(updateTenantsFlagSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(updateTenantsFlagFailure(), errorMessageApi(res))),
        )
    })
)

/**download tenants Report */
const downloadTenantsReport = (action$) => action$.pipe(
    ofType(downloadTenantsRequest),
    mergeMap(({ payload }) => {
        return from(tenantsService.downloadTenantsReport(payload)).pipe(
            concatMap((res) => of(downloadTenantsSuccess(res), successMessageApi({message: "The tenant list has been downloaded successfully"}))),
            catchError((res) => of(downloadTenantsFailure(), errorMessageApi(res))),
        )
    }),
)

// Export All tenants Epic Functions
export const tenantsEpic = [
    getTenantsLeftPanelList,
    getTenantsCount,
    getTenantsList,
    getTenantsDetail,
    updateTenantStatus,
    updateTenantsFlag,
    downloadTenantsReport
];