import React, { useState } from 'react';
import { Autocomplete } from '@mui/material';
import PropTypes from "prop-types";

//components
import { TextField } from '../';

// helpers
import { isEmail } from '../../helpers';

const ChipInputComponent = (props) => {

    // props
    const { classes, id, name, value, handleChange, ...rest } = props;

    //state
    const [inputValue, setInputValue] = useState('');

    return (
        <>
            <Autocomplete
                freeSolo
                multiple
                classes={classes}
                // limitTags={2}
                id={id}
                options={[]}
                value={value}
                inputValue={inputValue}
                onInputChange={(e, v) => setInputValue(v)}
                onBlur={(e) => {
                    const val = e.target.value
                    if (val && isEmail(val)) {
                        handleChange({ name, value: [...value, val] })
                        setInputValue("")
                    }
                }}
                onChange={(e, newval) => { handleChange({ name, value: newval }); setInputValue("") }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...rest}
                    />
                )}
                {...rest}
            />
        </>
    );
}

//default types
ChipInputComponent.defaultProps = {
    classes: {},
    id: "",
    name: "",
    value: [],
    handleChange: () => { },
}

//prop types
ChipInputComponent.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.array,
    handleChange: PropTypes.func,
}

export const ChipInput = ChipInputComponent