import React, { useEffect } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";

//import actions
import { userActivationRequest } from "../../redux/reducer/authReducer";

//import components
import { Button } from "../../components";

//style
import Styles from './style'

const UserActivation = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    const { className } = props;

    // navigator
    const navigate = useNavigate();

    // get route data
    const urlParams = useParams();

    // reducer
    const { activationMessage, redirectToResetPwd } = useSelector(s => s.auth)

    // activate user
    useEffect(() => {
        if (urlParams.hash) {
            dispatch(userActivationRequest(urlParams))
        }
    }, [])

    useEffect(()=>{
        if(redirectToResetPwd === true){
            navigate(`/create-password/${urlParams.uidb64}/${urlParams.hash}`)
        }
    },[redirectToResetPwd])

    return (
        <Grid className={className} container justifyContent={"center"} alignItems="center">
            <Grid align="center" className="successContainer">
                {
                    !activationMessage==="Invalid Url" && <CheckCircleOutlineIcon className="successIcon" />
                }
                <Typography variant="h1" className="pb-2">{activationMessage}</Typography>
                <Typography variant="body1" className="pb-4">Click here to login and update the profile details for the City Staff approval</Typography>
                <Button variant="contained" size="large" className={"loginBtn"} href="#/login"  aria-label="Login">Login</Button>
            </Grid>
        </Grid>
    )
}

// default props
UserActivation.defaultProps = {
    classes: {},
};

// prop types
UserActivation.propTypes = {
    classes: PropTypes.object,
};

export default styled(UserActivation)(Styles);