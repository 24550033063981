import palette from "../../assets/themes/palette";

const Styles = {
    height: 'calc(100vh - 138px)',
    overflowY:"auto",
    alignContent: 'baseline',
    '.statusChip': {
        borderRadius: 6,
        height: 22,
        fontSize: '12px',
        '& .MuiTypography-root': {
            fontSize: 12
        },
        '&.pending': {
            background: palette.colorShades.yellow
        },
        '&.approved': {
            background: palette.colorShades.greenBg,
            color: palette.colorShades.green
        },
        '&.rejected': {
            background: palette.colorShades.redBg,
            color: palette.colorShades.red
        },
        '&.preapproved': {
            background: palette.colorShades.blueBg,
            color: palette.colorShades.blue
        },
        '&.movedin': {
            background: palette.colorShades.blueBg,
            color: palette.colorShades.blue
        },
        '&.movedout': {
            background: '#E5E4E4',
            color: '#B1B1B1'
        }
    },
    '.sideBarList': {
        overflowY: 'auto'
    },
    '.listItem': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 44,
        paddingRight: 12,
        borderBottom: `1px solid ${palette.colorShades.greyBg}`,
        background: '#fff',
        ' .flags': {
            marginLeft: '10px',
            '& svg': {
                fill: '#999999',
                width: '18px',
                height: '18px',
            }
        },
        '& .FilledFlagIcon': {
            fill: '#F3685D !important'
        },
        // '&.active': {
        //     ' .dot': {
        //         display: 'none !important'
        //     },
        // },
        '&.active': {
            background: `${palette.greyshades.light2} !important`,
            ' .leftActiveSection': {
                borderLeft: `4px solid ${palette.secondary.main}`,
                width: '18px',
                flexShrink: 0
            },
            ' .name': {
                color: palette.primary.main
            },
            ' .dot': {
                display: 'none !important'
            },
            ' .MuiAvatar-root': {
                background: '#fff !important',
                color: '#EBEBF3 !important',
                ' .MuiSvgIcon-root': {
                    fill: '#EBEBF3'
                }
            },
            ' .flags svg': {
                fill: '#fff'
            }
        },
        '&.completed': {
            background: '#F8F8FB',
            ' .name': {
                color: '#9C9CB8'
            },
            ' .dot': {
                background: '#EBEBF3 !important'
            }
        },
        '.avatarItem': {
            background: '#EBEBF3',
            ' .MuiSvgIcon-root': {
                fill: '#fff'
            }
        },
        ' .leftActiveSection': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '18px',
            height: '44px',
            flexShrink: 0,
            ' .dot': {
                width: '4px',
                height: '4px',
                borderRadius: '30px',
                display: 'block',
                background: '#2E2E84'
            }
        },
        '.approved': {
            ' .dot': {
                background: palette.colorShades.green
            }
        },
        '.rejected': {
            ' .dot': {
                background: palette.colorShades.red
            }
        },
        '.pending': {
            ' .dot': {
                background: palette.primary.main
            }
        },
        '& .leftSection': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            flex: 1,
            '& .MuiAvatar-root': {
                height: '20px',
                width: '20px',
                fontSize: '11px',
                marginRight: '6px',
                background: '#EBEBF3'
            }
        },
    }
}

export default Styles;