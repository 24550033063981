import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

//style
import Style from "./style";

//Components
import { AutoComplete, Button, TextField } from "../../components";
import Popover from "../../components/popover";

// reducer
import { errorMessage } from "../../redux/reducer/uiReducer";
import { offerUnitRequest } from "../../redux/reducer/waitingListReducer";

// helpers
import { affordabilityLevelOptions, unitTypesOptions } from "../../helpers";

const OfferUnit = (props) => {
  //define dispatch
  const dispatch = useDispatch();

  // props
  const { className, openOfferPopup, setOpenOfferPopup } = props;

  const { unitDetails } = useSelector(s => (s.waitingList))

  // state
  const [offerDetails, setOfferDetails] = useState({
    unit_type: "",
    unit_no: "",
    affordability_level: "",
    offered_rent: "",
    unit_id: ""
  })

  const handleOfferUnit = () => {
    if(!offerDetails.unit_id){
      dispatch(errorMessage("Please select unit details"))
    }else if(!offerDetails.offered_rent || (offerDetails.offered_rent && Number(offerDetails.offered_rent) === 0)){
      dispatch(errorMessage("Please enter rent amount"))
    }else{
      dispatch(offerUnitRequest({
        ...offerDetails,
        approval_status : 9,
        unit_type_id : offerDetails.unit_type,
        applicationId: openOfferPopup && openOfferPopup.data ? openOfferPopup.data.id : 0
      }))
      setOpenOfferPopup(false)
    }
  }

  const handleChange = (e) => {
    if (e.name === "unit_no") {
      const val = e.value && e.value.id ? e.value.id : null;
      const selectedUnitNo = unitDetails.find(f => (f.unit_no === val));
      if (selectedUnitNo) {
        setOfferDetails({
          ...offerDetails,
          unit_no: selectedUnitNo.unit_no,
          unit_type: selectedUnitNo.unit_type_id,
          affordability_level: selectedUnitNo.affordability_level,
          unit_id: selectedUnitNo.unit_id,
          offered_unit_detail_id : selectedUnitNo.unit_detail_id
        })
      }
    } else {
      setOfferDetails({ ...offerDetails, [e.name]: e.value && e.value.id ? e.value.id : null })
    }
  }

  const getUnitNoOptions = () => {
    const options = unitDetails.map(m => {
      return {
        id: m.unit_no,
        value: m.unit_no,
      }
    })
    return options
  }

  const getAffordabilityOptions = () => {
    const selAffLevel = unitDetails.filter(f => (f.unit_no === offerDetails.unit_no)).map(m => { return m.affordability_level })
    return affordabilityLevelOptions.filter(f => (selAffLevel.includes(f.id)))
  }

  const getUnitTypeOptions = () => {
    const selUnitType = unitDetails.filter(f => (f.unit_no === offerDetails.unit_no)).map(m => { return m.unit_type_id })
    return unitTypesOptions.filter(f => (selUnitType.includes(f.id)))
  }

  return (
    <Popover
      anchorEl={openOfferPopup && openOfferPopup.target ? openOfferPopup.target : false}
      onClose={() => { setOpenOfferPopup(false) }}
      className={className}
    >
      <Grid container sx={{ maxWidth: 400 }}>
        <Grid item xs={12}>
          <Typography variant="h5" className="pb-2 fs-18">Select the unit you want to offer</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <AutoComplete
                id={"unit_no"}
                name={"unit_no"}
                placeholder="Unit No."
                className="sm"
                value={{ id: offerDetails.unit_no }}
                handleChange={(e) => { handleChange(e) }}
                options={getUnitNoOptions()}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoComplete
                className="sm"
                id={"affordability_level"}
                name={"affordability_level"}
                placeholder="Affordability Level"
                value={{ id: offerDetails.affordability_level }}
                handleChange={(e) => { handleChange(e) }}
                options={getAffordabilityOptions()}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoComplete
                className="sm"
                id={"unit_type"}
                name={"unit_type"}
                placeholder="Unit Type"
                value={{ id: offerDetails.unit_type }}
                handleChange={(e) => { handleChange(e) }}
                options={getUnitTypeOptions()}
                display={"name"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="sm"
                id={"offered_rent"}
                name={"offered_rent"}
                placeholder="Fixed Rent Price"
                aria-label="Enter Fixed Rent Price"
                value={offerDetails.offered_rent || ""}
                type={"number"}
                handleChange={(e) => { setOfferDetails({ ...offerDetails, [e.name]: e.value }) }}
              />
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12} align="right" className="pt-3">
          <Button variant="text" size="medium" className="mr-1" onClick={() => { setOpenOfferPopup(false) }} aria-label="Close">Close</Button>
          <Button variant="contained" size="medium" color="primary" onClick={() => { handleOfferUnit() }} aria-label="Offer">Offer</Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

// default props
OfferUnit.defaultProps = {
  className: {},
  openOfferPopup: false,
  setOpenOfferPopup: () => { }
};

// prop types
OfferUnit.propTypes = {
  className: PropTypes.string,
  openOfferPopup: PropTypes.any,
  setOpenOfferPopup: PropTypes.func,
};

export default styled(OfferUnit)(Style);
