const Styles = {
    '.MuiPaper-root': {
        padding: 30
    },
    '.actions': {
        marginTop: 20,
        '& button': {
            width: '120px'
        }
    },
    'span.statusText': {
        paddingRight: '8px'
    },
    '.rejected': {
        '& .MuiTimelineDot-root': {
            backgroundColor: '#F3685D'
        }
    },
    '& .MuiTimelineDot-root': {
        margin: '0px',
        backgroundColor: '#C3C2D9',
        boxShadow: 'none'
    },
    '& .MuiTimelineConnector-root': {
        width: '6px',
        backgroundColor: '#F5F5F9'
    },
    '& .MuiTimelineContent-root': {
        paddingTop: 0,
        marginTop: '-4px'
    },
    '& .logIcon': {
        backgroundColor: '#EBEBF3',
        borderRadius: '30px',
        width: '28px',
        height: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}

export default Styles;