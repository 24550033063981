import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'
import { WelcomeIcon } from "../../assets/svg";

//Components
import { Button } from "../../components";

// reducer
import { getWelcomePageRequest } from "../../redux/reducer/propertiesReducer";

const Welcome = (props) => {
    // define dispatch
    const dispatch = useDispatch()
    
    // navigator
    const navigate = useNavigate();

    // props
    const { className } = props;

    // redux
    const { loggedUser } = useSelector(s => (s.profile));
    const { welcomeData } = useSelector(s => (s.properties));

    // redirect to properties page if count available
    useEffect(()=>{
        if(welcomeData && welcomeData.count>0){
            navigate("/properties")
        }
    },[welcomeData])

    // using again here for getting details on page refreshing
    useEffect(() => {
        // get property manager approval status
        if(!welcomeData || (welcomeData && !Object.keys(welcomeData).length)){
            dispatch(getWelcomePageRequest())
        }
    }, [dispatch])

    return (
        <Grid className={`${className} welcomeContainer`}>
            <Grid align="center">
                <Grid className="welcomeLogo">
                    <WelcomeIcon />
                </Grid>
                <Typography variant="h1" className="pb-2">{`Welcome, ${loggedUser.first_name || ""} ${loggedUser.last_name || ""}!`}</Typography>
                {welcomeData && welcomeData.isApproved ?
                    <>
                        {(welcomeData.count === 0) &&
                            <>
                                <Typography variant="h1" color={"textSecondary"} className="pb-2 fw-400 secondary">No Properties created yet</Typography>
                                <Typography variant="body1" className="pb-3">Click the button below to create a property</Typography>
                                <Button variant={"contained"} size="large" onClick={() => { navigate("/properties/create") }} aria-label="Add property">+ Add Property</Button>
                            </>
                        }
                    </>
                    :
                    <>
                        <Typography variant="body1" className="pb-3">Your profile is not approved, please review or update your profile details</Typography>
                        <Button variant={"contained"} size="large" onClick={() => { navigate("/profile") }} aria-label="Profile">Profile</Button>
                    </>
                }
            </Grid>
        </Grid>
    );
}

// default props
Welcome.defaultProps = {
    classes: {},
};

// prop types
Welcome.propTypes = {
    classes: PropTypes.object,
};

export default styled(Welcome)(Styles);