import React, { memo } from "react";
import PropTypes from "prop-types";
import { InputAdornment, styled, TextField as Core } from "@mui/material";
import _ from "lodash";

// Imports Styles
import Styles from "./style";

const TextFieldComponent = (props) => {
  //get props
  const {
    id,
    name,
    type,
    autoComplete,
    placeholder,
    multiline,
    margin,
    autoFocus,
    rows,
    value,
    variant,
    fullWidth,
    handleChange,
    adornment,
    validation,
    onStartAdormentClick,
    onEndAdormentClick,
    adormentIcons,
    startIcon,
    size,
    ...rest
  } = props;


  //field validations
  let fieldErr = {};
  if (validation && validation.constructor === Array && validation.length > 0) {
    fieldErr.error = true
    fieldErr.helperText = validation[0]
  } else if (validation) {
    fieldErr.error = true;
    fieldErr.helperText = validation;
  }

  return (
    <Core
      id={id || name}
      name={name}
      type={type}
      autoComplete={autoComplete}
      placeholder={placeholder}
      multiline={multiline}
      margin={margin}
      autoFocus={autoFocus}
      size={size}
      rows={rows}
      value={value || value===0 ? value : ""}
      variant={variant}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {startIcon}
          </InputAdornment>
        ),
      }}
      onWheel={(e) => {
        if (type === "number") {
          e.target.blur()
        }
      }}
      onChange={(e) => {
        handleChange({ name: name, value: e.target.value });
      }}
      {...rest}
      {...fieldErr}
    />
  );
};

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "new-password",
  placeholder: "",
  multiline: false,
  rows: "0",
  value: "",
  margin: "none",
  variant: "outlined",
  fullWidth: true,
  autoFocus: false,
  handleChange: () => { },
  className: "",
  adornment: "",
  validation: "",
  onStartAdormentClick: () => { },
  onEndAdormentClick: () => { },
  adormentIcons: {}
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  adornment: PropTypes.string,
  size: PropTypes.string
};

function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps, nextProps);
}

// export component
export const TextField = memo(styled(TextFieldComponent)(Styles), areEqual);
