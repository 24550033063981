import palette from "../../assets/themes/palette";

const Styles = {
    '&.senderComp': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginLeft: 'auto',
        position: 'relative',
        // '&:hover': {
        //     '& .editOptions': {
        //         visibility: 'visible',
        //         opacity: 1,
        //         transition: '0.5s'
        //     }
        // },
        '& .editOptions': {
            visibility: 'hidden',
            opacity: 0,
            transition: '0.5s',
            position: 'absolute',
            left: '0px',
            background: '#fff',
            borderRadius: '10px',
            border: '1px solid #e5e5e5',
            padding: '0px 5px'
        },
        ' .editField': {
            width: '100%',
            paddingTop: '5px',
            '& .MuiOutlinedInput-root': {
                padding: '12.5px 14px'
            },
            '& .MuiSvgIcon-root': {
                fontSize: '2rem'
            }
        },
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingRight: 15,
            textAlign: 'right',
            position: 'relative',
            '&:hover': {
                '& .editOptions': {
                    visibility: 'visible',
                    opacity: 1,
                    transition: '0.5s'
                }
            },
        },
        ' .message': {
            background: palette.primary.light2,
            color: palette.primary.contrastText,
            padding: '12px',
            borderTopLeftRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        },
        ' .user': {
            justifyContent: 'flex-end'
        }
    },
    ' .user': {
        display: 'flex',
        alignItems: 'baseline'
    },
    '.commentsTime': {
        color: palette.greyshades.main,
        fontSize: 14,
        fontWeight: 500
    },
    '.name': {
        fontSize: '15px',
        fontWeight: 500
    },
    '&.receiverComp': {
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: '20px',
        maxWidth: '80%',
        marginRight: 'auto',
        ' .MuiSvgIcon-root': {
            padding: 4
        },
        ' .messageDetail': {
            paddingLeft: 15
        },
        ' .message': {
            background: '#F5F5F9',
            padding: '12px',
            borderTopRightRadius: '7px',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
            marginTop: '5px'
        }
    },
}

export default Styles;