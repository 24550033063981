// Import API Helper
import { postRequest } from "../../helpers";

/**
 * run Lottery Process
 */
async function runLotteryProcess(params) {
    const response = await postRequest(`lottery/run-lottery/${params.property_id || 0}/`);
    return response;
}

/**
* download lottery reports
*/
async function downloadLotteryResult(params) {
    const response = await postRequest(`lottery/download/`, params, false, "arraybuffer");
    return response;
}

/**
 * Export All RunLottery Service Functions
 */
export const runLotteryService = {
    runLotteryProcess,
    downloadLotteryResult
};
