import React from "react";
import PropTypes from "prop-types";
import { Link as Core } from "@mui/material";

const LinkComponent = (props) => {

    //get props
    const { component, variant, classes, color, href, children, className, underline, ...rest } = props;

    return (
        <Core
            variant={variant}
            component={component}
            classes={classes}
            href={href}
            className={className}
            underline={underline}
            {...rest}
        >
            {children}
        </Core>
    );
}

// default props
LinkComponent.defaultProps = {
    classes: {},
    children: "",
    color: "inherit",
    href: "#",
    component: "a",
    variant: "h6",
    className: "",
    underline: "none"
};

// prop types
LinkComponent.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    component: PropTypes.string,
    href: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    underline: PropTypes.string
};

/** Export Component */
export const Link = LinkComponent;
