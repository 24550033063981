import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

//style
import Style from './style'

//Components
import { NoResult, Table } from "../../components"
import TopPanel from "./topPanel";

// reducer
import { getPMCountRequest, getPMListRequest, updatePMFilter, updatePMViewType, updateTempPMFilter } from "../../redux/reducer/propertyManagerReducer";

const ListView = (props) => {

    // props
    const { className } = props;

    //define dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();

    //redux
    const { listView: { data, pagination, pagination: { count, skip, limit, sortField, sortBy } }, filter } = useSelector(s => (s.propertyManager))

    useEffect(() => {
        // get total count for pagination and then call the data in the epic
        dispatch(getPMCountRequest({ ...pagination, skip: 0, filter }))
    }, [dispatch])

    const handleFilter = () => {
        //update temporary filters on applying filters. This can be used to reset them on opening the filter drawer again
        dispatch(updateTempPMFilter())
        // get total count for pagination and then call the data in the epic
        dispatch(getPMCountRequest({ ...pagination, skip: 0, filter }))
    }

    const handleSorting = ({ sortField, sortBy }) => {
        dispatch(getPMCountRequest({ ...pagination, sortField, sortBy, skip: 0, isFirst: true, filter }))
    }

    const viewDetailSwitch = (data) => {
        dispatch(updatePMViewType("panel"))
        dispatch(updatePMFilter({ name: "managerFilter", value: [{ id: data.id, name: data.name }] }))
        navigate(`/property-manager`)
    }

    return (
        <Grid container className={className}>
            <Grid item xs={12}>
                <TopPanel
                    IconsList={["VIEW_TYPE", "ADD", "CHILDREN", "DOWNLOAD", "FILTER_LIST"]}
                    handleFilter={handleFilter}
                    sortValue={`${sortField}-${sortBy}`}
                    handleSorting={handleSorting}
                />
            </Grid>
            <Grid item xs={12}>
                {count > 0 ?
                    <Table
                        header={["Property Manager", "Property Count", "Approval Status", "Submitted Date"]}
                        fields={["name", "properties", "approval_status", "created_at"]}
                        data={data}
                        skip={skip}
                        totalCount={count}
                        limit={limit}
                        showAvatar={true}
                        updatePage={(e, val) => {
                            dispatch(getPMListRequest({ ...pagination, skip: val - 1, filter:filter }))
                        }}
                        handleLinkClick={(e) => { viewDetailSwitch(e) }}
                        linkFields={["name"]}
                    />
                    :
                    <NoResult text="No Property Manager Found" />
                }
            </Grid>
        </Grid>
    );
}

// default props
ListView.defaultProps = {
    classes: {},
};

// prop types
ListView.propTypes = {
    classes: PropTypes.object,
};

export default styled(ListView)(Style);