import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Grid, IconButton, InputAdornment, Typography, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

//style
import Styles from './style'
import { KeyIcon } from "../../assets/svg";

//Components
import { Button, TextField } from "../../components";

// helpers
import { commonValidation, isPasswordValid } from "../../helpers";

//import actions
import { resetPasswordRequest, passwordLinkVerifyRequest, resetPasswordData } from "../../redux/reducer/authReducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ResetPassword(props) {
    //define navigator
    const navigate = useNavigate()

    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { className } = props;

    const urlParams = useParams();
    const { pathname } = useLocation()

    //set state
    const [validate, setValidate] = useState(false);
    const [password1, setPassword1] = useState(false);
    const [password2, setPassword2] = useState(false);
    const [resetPwdForm, setResetForm] = useState({
        password: "",
        confirm_password: ""
    })

    const { passwordLinkValid, passwordSubmit } = useSelector(s => s.auth)

    useEffect(() => {
        dispatch(passwordLinkVerifyRequest(urlParams))
    }, [])

    useEffect(()=>{
        if(passwordSubmit){
            navigate(`/`)
            dispatch(resetPasswordData())
        }
    },[passwordSubmit])

    //update signup form data
    const handleChange = (e) => {
        setResetForm({ ...resetPwdForm, [e.name]: e.value })
    }

    //submit form
    const handleSubmit = () => {
        setValidate(true);
        //get validations
        const validation = commonValidation(resetPwdForm, "resetPassword");

        if (!validation) {
            dispatch(resetPasswordRequest({ ...resetPwdForm, ...urlParams }))
        }
    }

    return (
        <Grid className={className}>
            <Grid container justifyContent="space-between" className={"passwordContainer"}>
                <Grid item xs={12} className="forgotPassBtnContainer">
                    <Button sx={{ fontWeight: 500 }} href="#/" className={"backLoginBtn flexStart dinlineflex"} variant={"text"} startIcon={<ChevronLeftIcon />} aria-label="Back to Login page">Back to Login page</Button>
                </Grid>
                {passwordLinkValid && <Grid>
                    <Typography variant="h1" align="center" className={"pt-3 pb-6"}>
                        {`${pathname.indexOf("create-password") ? "Set up Password" : "Reset Password"}`}
                    </Typography>
                    <TextField
                        id={"password"}
                        name={"password"}
                        value={resetPwdForm.password}
                        type={password1 ? "text" : "password"}
                        handleChange={(e) => { handleChange(e) }}
                        className={"pt-3"}
                        aria-label="Enter New Password"
                        placeholder={"New Password"}
                        startIcon={<KeyIcon />}
                        validation={isPasswordValid(validate, resetPwdForm.password)}
                        InputProps={
                            {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setPassword1(!password1) }}
                                            edge="end"
                                        >
                                            {password1 ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <TextField
                        id={"confirm_password"}
                        name={"confirm_password"}
                        value={resetPwdForm.confirm_password}
                        handleChange={(e) => { handleChange(e) }}
                        className={"pt-3"}
                        type={password2 ? "text" : "password"}
                        aria-label="Enter Confirm New Password"
                        placeholder={"Confirm New Password"}
                        startIcon={<KeyIcon />}
                        validation={isPasswordValid(validate, resetPwdForm.confirm_password)}
                        InputProps={
                            {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <KeyIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { setPassword2(!password2) }}
                                            edge="end"
                                        >
                                            {password2 ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    <Grid align="center" className="pt-4">
                        <Button variant="contained" size="large" className={"loginBtn"} onClick={() => { handleSubmit() }}  aria-label="Save">Save</Button>
                    </Grid>
                </Grid>}
                {!passwordLinkValid &&
                    <Typography variant="h1" align="center" className={"pt-3 pb-6 w-100"}>
                        The verification link has been expired
                    </Typography>}
            </Grid>
        </Grid>
    );
}

// default props
ResetPassword.defaultProps = {
    classes: {},
};

// prop types
ResetPassword.propTypes = {
    classes: PropTypes.object,
};

export default styled(ResetPassword)(Styles)