import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';

//style
import Styles from '../style'

// reducer
import { updateAmenities } from "../../../redux/reducer/propertiesReducer";

const Amenities = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { isDisabled } = props;

    //reducer
    const { masterAmenities } = useSelector(s => (s.global))
    const { currentProperty: { amenities: selectedAmenities } } = useSelector(s => (s.properties))

    // update amenities reducer data
    const updateAmenenitesList = (item) => {
        let tmpAmenities = [...selectedAmenities]
        const amenityIndex = tmpAmenities.findIndex(f => (f.master_amenity === item.id))
        if (amenityIndex > -1) {
            tmpAmenities.splice(amenityIndex, 1)
        } else {
            tmpAmenities.push({ master_amenity: item.id })
        }
        dispatch(updateAmenities(tmpAmenities))
    }

    // bind amenities chip
    const bindChip = (item, indx) => {
        const isFound = (selectedAmenities.findIndex(f => (f.master_amenity === item.id))) >= 0
        if (isDisabled && !isFound) {
            return null
        } else {
            return (
                <Grid item className="amenties" key={`master-am-${indx}`}>
                    <Chip
                        onClick={isDisabled ? () => { } : () => { updateAmenenitesList(item) }}
                        className={isFound ? 'selected' : ''}
                        icon={isFound ? <DoneIcon /> : <AddIcon />}
                        label={<Typography variant="body2">{item.name || ""}</Typography>}
                        variant="outlined"
                    />
                </Grid>
            )
        }
    }

    return (
        <Grid item xs={12}>
            {(!isDisabled || (isDisabled && selectedAmenities && selectedAmenities.length>0)) && <Typography variant="caption" color="textSecondary">AMENITIES</Typography>}
            <Grid container spacing={2} style={{ marginTop: '1px' }}>
                {masterAmenities && masterAmenities.map((itm, indx) => (
                    <Fragment key={`sdd-werwq-${indx}`}>
                       {bindChip(itm, indx)} 
                    </Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

// default props
Amenities.defaultProps = {
    isDisabled: false,
};

// prop types
Amenities.propTypes = {
    isDisabled: PropTypes.any,
};

export default styled(Amenities)(Styles);