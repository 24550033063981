// Import API Helper
import { getRequest, postRequest } from "../../helpers";

/**
* user login
*/
async function login(params) {
    const response = await postRequest(`auth/login/`, params);
    return response;
}

/**
 * forget password
 */
 async function forgetPassword(params) {
    const response = await postRequest(`auth/forgot-password/`, params);
    return response;
}

/**
* user signup
*/
async function signup(params) {
    const response = await postRequest(`auth/register/`, params);
    return response;
}

/**
 * user activation
 */
 async function userActivation(params) {
    const response = await getRequest(`auth/activate/${params.uidb64}/${params.hash}/`);
    return response;
}

/**
 * get password link verification 
 */
 async function pwdLinkVerify(params) {
    const response = await getRequest(`auth/password-link-verify/${params.uidb64}/${params.hash}/`);
    return response;
}

/**
 * reset password
 */
 async function resetPassword(params) {
    const response = await postRequest(`auth/reset-password/`, params);
    return response;
}

/**
 * Export All Authentication Service Functions
 */
 export const authService = {
    login,
    forgetPassword,
    signup,
    userActivation,
    pwdLinkVerify,
    resetPassword
};