import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

// Import Styles and images
import Styles from "./style";
import BannerImg from "../../assets/images/signupBg.png"
import LogoImg from "../../assets/images/svg/logo.svg"
import { ServerCommunicationError } from "../../components";
import { updateOverrideLoader } from "../../redux/reducer/uiReducer";
import cityStaffRegisterImage from "../../assets/images/city_staff_register_wall.png";


const myUrl = process.env.REACT_APP_S3_URL;
const path = `${myUrl}assets/`
function SignupLayoutComponent({ component: Component, className, srcImage, classes, ...props }) {

    const { serverCommunicationError } = useSelector(s => s.ui)

    const dispatch = useDispatch();

    useEffect(() => {
        if (serverCommunicationError) {
            dispatch(updateOverrideLoader())
        }
    }, [serverCommunicationError])

    return (
        <Box className={className}>
            <Box className={"bannerCont"}>
                <Box className={"imageCont"}>
                    <Grid container className="">
                        <Grid item xs={3} className="">
                            <Box className={"imBgCont"}>
                                <img
                                    width="100%"
                                    height={"100%"}
                                    alt={"BannerImg"}
                                    src={srcImage}
                                />
                                <img src={LogoImg} className="appLogo" alt={"Signup Background"} />
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Box className="mainCont">
                                {!serverCommunicationError ?
                                    <Grid className="topContainer">
                                        <Component {...props} />
                                    </Grid> :
                                    <Grid className="topContainer">
                                        <ServerCommunicationError />
                                    </Grid>
                                }
                                <Grid align="center" className="pt-2 pb-2">
                                    <Typography variant="body2" className="copyRightText">Copyright © City of Jersey City, 2024</Typography>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>


                </Box>
            </Box>
        </Box>
    );
}

// prop types
SignupLayoutComponent.defaultProps = {
    srcImage: cityStaffRegisterImage
};
SignupLayoutComponent.propTypes = {
    classes: PropTypes.object,
};

export const SignupLayout = styled(SignupLayoutComponent)(Styles)