const style = {
    '.titleContainer': {
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'space-between',
    },
    '.bodyContainer': {
        padding: 20,
        paddingTop: 0
    },
    '.bodyInner': {
        background: '#FFFFFF',
        border: '1px solid #EBEBF3',
        padding: '26px 30px',
        borderRadius: '6px',
        paddingRight: '0px'
    },
    '.notificationTabsBody': {
        height: 'calc(100vh - 226px)',
        overflowY: 'auto',
        paddingRight: '30px'
    },
    '.tabsRoot': {
        width: '155px',
        '& .MuiTab-root': {
            alignItems: 'flex-start',
            minHeight: '40px',
            '& svg': {
                marginRight: '20px'
            },
            '&.Mui-selected': {
                background: '#EBEBF2',
                borderRadius: '6px',
                '& .MuiTypography-root': {
                    color: '#343487',
                },
                '& svg, .fillPath': {
                    fill: '#DFB233'
                }
            },
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '500',
                color: '#181840',
              
            }
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        },
        
    },
    '.notificationCard': {
        display: 'flex',
        alignItems: 'center',
        background: '#FFFFFF',
        padding: '18px 0px 18px',
        borderBottom: `1px solid #E5E5E5`,
        '& a': {
            textAlign: 'left'
        },
        '& svg, & .svg': {
            flexShrink: '0'
        },
        '& .notificationAlert': {
            height: '50px',
            width: '50px',
            flexShrink: '0',
            borderRadius: '30px',
            // background: '#EBEBF3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiAvatar-root ': {
                height: '50px',
                width: '50px',
            },
        },
        '& .fileName': {
            fontWeight: '600',
            paddingBottom: '8px',
            whiteSpace: 'pre-wrap',
            '& sup': {
                // display: 'none',
                fontSize: '12px',
                fontPalette: 'normal',
                color: '#DFB233',
                fontWeight: 500,
                paddingLeft: '8px',
                marginTop: '-2px'
            }
        },
        '& .notificationTime': {
            color: '#C9C8C8 !important'
        },
        '&.newItem': {
            '& .fileName': {
                color: '#343487 !important',
                display: 'flex',
                '& sup': {
                    // display: 'block',
                }
            }
        }
    },
    '.headerTitle': {
        borderBottom: `2px solid #F5F5F5`,
        paddingBottom: '10px',
        marginBottom: '10px'
    }
}
export default style;