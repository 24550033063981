import axios from "axios";

//import helpers
import { getLoggedInUser } from ".";

const apiBaseUrl = process.env.REACT_APP_SERVER_URL;

/**
 * Prepares headers for the request
 */
const getHeaders = (isFormData, multipart) => {
  const token = getLoggedInUser()?.token;
  let headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (!isFormData && multipart) {
    headers["Content-Type"] = "application/json";
  }
  if (multipart) {
    headers["Content-Type"] = "application/form-data";
  }
  return headers;
};

/**
 * api make request
 */
const makeRequest = async ( 
  endpoint,
  verb,
  data,
  isFormData = false,
  responseType,
  multipart
) => {
  const requestOptions = {
    method: verb,
    url: endpoint,
    headers: getHeaders(isFormData, multipart),
    responseType: responseType,
    data,
  };
  return axios(requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * GET request
 */
export const getRequest = async (endpoint) => {
  return makeRequest(`${apiBaseUrl}/${endpoint}`, "GET", {});
};

/**
 * PUT requests
 */
export const putRequest = async (
  endpoint,
  { body, isFormData, responseType, multipart }
) => {
  return makeRequest(`${apiBaseUrl}/${endpoint}`, "PUT", body, isFormData, responseType, multipart);
};

/**
 * POST request
 */
export const postRequest = async (endpoint, body, isFormData, responseType) => {
  return makeRequest(
    `${apiBaseUrl}/${endpoint}`,
    "POST",
    body,
    isFormData,
    responseType
  );
};

/**
 * DELETE request
 */
export const deleteRequest = async (endpoint, body) => {
  return makeRequest(`${apiBaseUrl}/${endpoint}`, "DELETE", body);
};
