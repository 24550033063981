import { getRequest, putRequest, postRequest, buildFormData, deleteRequest } from "../../helpers";

// get applications left panel list
async function getApplicationsLeftPanelList(params) {
    const response = await postRequest(`manage-applications/left-panel/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter })
    return response
  }

//get total count of applications for list and panel view
async function getApplicationsCount(params) {
    const response = await postRequest(`manage-applications/count/`, { "filter": params.filter });
    return response;
  }

//Get applications detail
async function getApplicationsDetail(params) {
  const response = await getRequest(`manage-applications/detail/${params.id}/`);
  return response
}

//applications Approval
async function applicationsApproval(params) {
  const response = await postRequest(`manage-applications/approval/`, params);
  return response;
}

//update applications flag
async function updateApplicationsFlag(params) {
  const response = await postRequest(`manage-applications/update-flag/${params.id}/${params.is_flag}/`);
  return response;
}

// get applications list
async function getApplicationsList(params) {
  const response = await postRequest(`manage-applications/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter })
  return response
}

/**
* download applications reports
*/
async function downloadApplicationsReport(params) {
  const response = await postRequest(`manage-applications/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter, applicationIds : (params.applicationIds || []), "timezone": params.timezone}, false, "arraybuffer");
  return response;
}

/**
 Applications Comments
 */
async function getApplicationsComments(params) {
  const response = await getRequest(`manage-applications/comments/${params.id}/`);
  return response;
}

/**
 * update Applications Comments
 */
async function updateApplicationsComments(params) {
  const response = await postRequest(`manage-applications/update-comments/`, params);
  return response;
}

/**
 * delete Applications Comments
 */
async function deleteApplicationsComments(params) {
  const response = await deleteRequest(`manage-applications/delete-comments/${params.id}/`);
  return response;
}

//get application documents
async function getApplicationsDocuments(params){
  const response = await getRequest(`manage-applications/get-docs/${params.id}/`)
  return response
}

//upload application documents
async function uploadApplicationsDocuments(params) {
  let formData = new FormData();
  formData = buildFormData(formData, params.docs);
  const response = await putRequest(`manage-applications/upload-docs/${params.id}/`, {
      body: formData,
      isFormData: true,
      responseType: "json",
      multipart: true
  });
  return response;
}

// get application logs
async function getApplicationLogs({ application_id }){
  const response = await getRequest(`application/logs/${application_id}/`)
  return response
}

/** reject applications */
async function rejectApplication(params) {
  const response = await postRequest(`manage-applications/reject/${params.application_id}/`, params);
  return response;
}

// export all applications functions
export const applicationsService = {
    getApplicationsLeftPanelList,
    getApplicationsCount,
    getApplicationsDetail,
    applicationsApproval,
    updateApplicationsFlag,
    getApplicationsList,
    downloadApplicationsReport,
    getApplicationsComments,
    updateApplicationsComments,
    deleteApplicationsComments,
    getApplicationsDocuments,
    uploadApplicationsDocuments,
    getApplicationLogs,
    rejectApplication
  };