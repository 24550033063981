import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Popover, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

//style
import Styles from "./style";

//Components
import { Button, ChipInput } from "../../components";

//redux
import { errorMessage } from "../../redux/reducer/uiReducer";
import {
  getPMInviteRequest,
  updateEmailList,
  clearEmailList
} from "../../redux/reducer/propertyManagerReducer";

//helpers
import { isEmail } from "../../helpers";

const PropertyManagerInvite = (props) => {
  // define dispatch
  const dispatch = useDispatch();

  // props
  const { invitePopup, setInvitePopup } = props;

  // state
  const { managerInviteEmails, isLoading } = useSelector((s) => s.propertyManager);

  const open = Boolean(invitePopup);
  const id = open ? "simple-popover" : undefined;

  // close popup on complete
  useEffect(()=>{
    if(invitePopup && isLoading===false){
      setInvitePopup(null)
    }
  },[isLoading])

  // update the list of emails in the invite box
  const handleChange = ({name, value}) => {
    if(value.length>0 && !isEmail(value[value.length-1])){
      dispatch(errorMessage("Enter a valid email"))
    }else{
      dispatch(updateEmailList(value));
    }
  };

  const handleInviteClose = () => {
    dispatch(clearEmailList())
    setInvitePopup(null);
  };

  // submit the emails for invites to be sent
  const handleInviteSubmit = () => {
    if (managerInviteEmails.length === 0){
      dispatch(errorMessage("Please enter email addresses to send invites"))
    }
    else{
      dispatch(getPMInviteRequest({ emails: managerInviteEmails }));
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={invitePopup}
      onClose={() => {
        handleInviteClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box sx={{ p: 2, width: 350 }}>
        <Typography variant="h4" className="pb-2">
          Invite Property Manager(s)
        </Typography>
        <ChipInput
          sx={{
            "& .MuiInputBase-root": { paddingLeft: 0 },
            "& .MuiInputAdornment-root": { display: "none" },
          }}
          className={"md"}
          placeholder={"Enter Email ID"}
          id={"emails"}
          name={"emails"}
          value={managerInviteEmails}
          handleChange={(e) => {
            handleChange(e);
          }}
        />
        <Grid align="right" className="dflex flexEnd pt-2">
          <Button
            variant={"text"}
            size="medium"
            className={"mr-1"}
            onClick={() => handleInviteClose()}
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color="primary"
            size="medium"
            onClick={() => handleInviteSubmit()}
            aria-label="Send Invite"
          >
            Send Invite
          </Button>
        </Grid>
      </Box>
    </Popover>
  );
};

// default props
PropertyManagerInvite.defaultProps = {
  classes: {},
};

// prop types
PropertyManagerInvite.propTypes = {
  classes: PropTypes.object,
};

export default styled(PropertyManagerInvite)(Styles);
