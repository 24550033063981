export const propertyManagerFieldMap = {
    "NAME":"name",
    "PHOTO":"profile_photo",
    "VIEWED":"is_viewed",
    "FLAG":"is_flag",
    "STATUS":"approval_status"
}

export const propertiesFieldMap = {
    "NAME": "name",
    "VIEWED": "is_viewed",
    "FLAG": "is_flag",
    "STATUS":"approval_status",
    "PHOTO":"photo"
}

export const applicationsFieldMap = {
    "NAME": "name",
    "VIEWED": "is_viewed",
    "FLAG": "is_flag",
    "STATUS":"approval_status",
    "PHOTO": "profile_photo"
}

export const tenantsFieldMap = {
    "NAME": "name",
    "VIEWED": "is_viewed",
    "FLAG": "is_flag",
    "STATUS":"approval_status",
    "PHOTO": "profile_photo"
}