import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    webNotificationListRequest, webNotificationListSuccess, webNotificationListFailure,
    newWebNotificationRequest, newWebNotificationSuccess, newWebNotificationFailure,
    markAsReadNotificationRequest, markAsReadNotificationSuccess, markAsReadNotificationFailure,
    markAllAsReadNotificationRequest, markAllAsReadNotificationSuccess, markAllAsReadNotificationFailure
} from "../reducer/webNotificationReducer"

import { webNotificationService } from '../service';

/** Web notification list */
const webNotificationList = (action$) => action$.pipe(
    ofType(webNotificationListRequest),
    mergeMap(({ payload }) => {
        return from(webNotificationService.webNotificationList(payload)).pipe(
            concatMap((res) => of(webNotificationListSuccess(res.data.data))),
            catchError((res) => of(webNotificationListFailure(), errorMessageApi(res))),
        )
    }),
)

/** Unread Web notification */
const newWebNotification = (action$) => action$.pipe(
    ofType(newWebNotificationRequest),
    mergeMap(() => {
        return from(webNotificationService.newWebNotification()).pipe(
            concatMap((res) => of(newWebNotificationSuccess(res.data.data))),
            catchError((res) => of(newWebNotificationFailure(), errorMessageApi(res))),
        )
    }),
)

/** mark notification as read*/
const markAsReadNotification = (action$) => action$.pipe(
    ofType(markAsReadNotificationRequest),
    mergeMap(({ payload }) => {
        return from(webNotificationService.markAsReadNotification(payload)).pipe(
            concatMap((res) => of(markAsReadNotificationSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(markAsReadNotificationFailure(), errorMessageApi(res))),
        )
    }),
)

/** mark all notification as read*/
const markAllAsReadNotification = (action$) => action$.pipe(
    ofType(markAllAsReadNotificationRequest),
    mergeMap(({ payload }) => {
        return from(webNotificationService.markAllAsReadNotification(payload)).pipe(
            concatMap((res) => of(markAllAsReadNotificationSuccess({ data: res.data.data, payload: payload }), webNotificationListRequest({ notificationType: payload.notificationType}), successMessageApi(res))),
            catchError((res) => of(markAllAsReadNotificationFailure(), errorMessageApi(res))),
        )
    }),
)

// Export All notification Epic Functions
export const webNotificationEpic = [
    webNotificationList,
    newWebNotification,
    markAsReadNotification,
    markAllAsReadNotification
];