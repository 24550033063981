import React from "react";
import PropTypes from "prop-types";

// Import Auth Layout
import { AuthLayout } from "../layouts";

// Routes the page without any authentication by the user
const AuthRoute = ({ component, ...props }) => (
  <AuthLayout component={component} {...props} />
);

// Define Props Types
AuthRoute.propTypes = {
  component: PropTypes.elementType
};

export default AuthRoute;
