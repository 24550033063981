const style = {
    '& .MuiInputBase-root': {
        paddingRight: '28px'
    },
    '& .listCheckBox': {
        padding: '5px 10px !important',
        background: 'red'
    }
}

export default style;