import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, Link, paginationClasses, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

//style
import Style from './style'

//Components
import { NoResult, Table, Searchbar, AutoComplete, Button, MultiSelectAsync, DatePicker, MultiSelect, Dialog } from "../../../components";
import { FilterIcon, UploadIcon, DownloadIcon } from "../../../assets/svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

//reducer
import {
    getIncomeRentsDocsCountRequest, getIncomeRentsDocsRequest, deleteIncomeRentsDocsRequest,
    updateIncomeRentFilter, clearIncomeRentFilter, updateIncomeRentTmpFilter, revertIncomeRentFilter
} from "../../../redux/reducer/globalReducer";

//service
import { globalFilterService } from "../../../redux/service";

//helpers
import { hasFilter, incomeRentDocsCategoryOptions, incomeRentDocsYearOptions, globalDate } from "../../../helpers";

import UploadFiles from "./uploadFiles";
import Popover from "../../../components/popover";

function IncomeAndRent(props) {
    //define navigate
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    // props
    const { className } = props;

    // reducer data
    const { incomeRentDocs: { data, pagination, pagination: { skip, limit, count }, filter, clearfilter } } = useSelector(s => s.global)

    //State
    const [fileUpload, setFileUpload] = useState(false);
    const [filterDraw, setFilterDraw] = useState(false);
    const [deleteDocument, setDeleteDocument] = useState(null);

    //define dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getIncomeRentsDocsCountRequest({ ...pagination, skip: 0 }))
    }, [])

    useEffect(() => {
        dispatch(revertIncomeRentFilter())
    }, [filterDraw])

    const handleDownload = ({ document, file_name }) => {
        saveAs(document, file_name)
    }

    // Table custom cell components
    const DocLink = ({ file_name, document }) => {
        return (
            <Link
                sx={{ display: { xs: 'block', textAlign: "left", maxWidth: "300px",
                 textOverflow: "ellipsis", whiteSpace: "nowrap",
                  overflow: "hidden" } }}
                onClick={() => handleDownload({ document, file_name })}
                aria-label={file_name}
            >
                {file_name}
            </Link>
        )
    }

    const ActionsComponent = ({ id, document, file_name }) => {
        return (
            <>  <Tooltip arrow title="Download"><IconButton onClick={() => handleDownload({ document, file_name })} aria-label="Download">
                <DownloadIcon color={"primary.dark"} sx={{ color: '#343487' }} />
            </IconButton>
            </Tooltip>
                <Tooltip title="Delete" arrow>
                    <IconButton onClick={() => setDeleteDocument(id)} aria-label="Delete">
                        <DeleteOutlineIcon color={"primary.dark"} sx={{ color: '#343487' }} />
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    const convertTimeZone = ({created_at}) => {
        console.log("created_at", created_at)
        return globalDate(created_at)
    }


    const handleClose = () => {
        setDeleteDocument(null)
    }

    const onConfirmDelete = () => {
        dispatch(deleteIncomeRentsDocsRequest({ id: deleteDocument }))
        handleClose()
    }

    const onSearch = (key) => {
        dispatch(updateIncomeRentFilter({ 'name': 'search', 'value': key }))
        dispatch(getIncomeRentsDocsCountRequest({ ...pagination, skip: 0, filter: { ...filter, search: key } }))
    }

    const handleFilter = () => {
        dispatch(getIncomeRentsDocsCountRequest({ ...pagination, skip: 0, filter: filter }))
        dispatch(updateIncomeRentTmpFilter())
        setFilterDraw(false)
    }

    const getDocumentNameFilter = async (value) => {
        const response = await globalFilterService.getIncomeDocNameFilter({ name: value || "" })
        return response
    }

    const getDocumentUploaderFilter = async (value) => {
        const response = await globalFilterService.getIncomeDocumentUploaderFilter({ name: value || "" })
        return response
    }

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
            setFilterDraw(false)
        }
    }, [clearfilter])

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Typography variant="h3" className="fw-500">Manage Policies & Income and Rent Limit</Typography>
                <Grid className="dflex alignCenter">
                    <Searchbar onEnter={onSearch} />
                    <Tooltip arrow title="Filter">
                        <IconButton onClick={(event) => { setFilterDraw(true); setFilterDraw(event.currentTarget) }} aria-label="Open Filter"><FilterIcon /></IconButton>
                    </Tooltip>
                    {hasFilter(filter) && <Tooltip arrow title="Clear Filter">
                        <IconButton onClick={() => { dispatch(clearIncomeRentFilter()) }} aria-label="Clear Filter"><FilterAltOffIcon sx={{ width: '20px', fill: "#DFB233" }} /></IconButton>
                    </Tooltip>}
                    <Tooltip arrow title="Upload">
                        <IconButton onClick={() => { setFileUpload(true) }} aria-label="Upload"><UploadIcon /></IconButton>
                    </Tooltip>
                    <ToggleButtonGroup
                        className="ml-1"
                        value={pathname.includes("income-rent") ? "income" : ""}
                        exclusive
                        onChange={(e, newVal) => {
                            if (newVal === "policy") {
                                navigate("/obligation")
                            }
                        }}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="income" aria-label="left aligned">
                            <Typography variant="body1">Income & Rent</Typography>
                        </ToggleButton>
                        <ToggleButton value="policy" aria-label="centered">
                            <Typography variant="body1">City Policy</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                {count > 0 ?
                    <Grid className="bodyInner">
                        <Table
                            header={["Document Name", "Category", "Year", "Size", "Uploaded Date", "Uploaded By", "Actions"]}
                            fields={["file_name", "category", "year", "file_size", "created_at", "uploaded_by", "actions"]}
                            linkFields={[]}
                            data={data}
                            componentMap={{
                                "file_name": DocLink,
                                "actions": ActionsComponent,
                                "created_at": convertTimeZone
                            }}
                            skip={skip}
                            totalCount={count}
                            limit={limit}
                            updatePage={(e, val) => {
                                dispatch(
                                    getIncomeRentsDocsRequest({ ...pagination, skip: val - 1, filter: filter })
                                )
                            }}
                        />
                    </Grid>
                    :
                    <NoResult text="No Income and Rent Document Found" />
                }
            </Grid>
            {fileUpload &&
                <UploadFiles
                    fileUpload={fileUpload}
                    setFileUpload={setFileUpload}
                />
            }
            {/* Filter Drawer */}
            <Popover
                anchorEl={filterDraw}
                className={className}
                onClose={() => { setFilterDraw(false) }}>
                <Grid item xs={12} className="filterContainer">
                    <Grid container>
                        <Grid container justifyContent={"space-between"}>
                            <Typography variant="h5" color="" className="fs-18">Filters</Typography>
                            <Typography
                                variant="body1"
                                size={"medium"}
                                color={"primary.dark"}
                                className="fw-500 pointer"
                                onClick={() => { dispatch(clearIncomeRentFilter()) }}
                            >
                                Clear Filter
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="pt-3">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                    <Typography variant="caption" color="textSecondary">Document Name</Typography>
                                    <MultiSelectAsync
                                        variant={"outlined"}
                                        id={"documentNameFilter"}
                                        name={"documentNameFilter"}
                                        placeholder={"Doc Name"}
                                        className={`sm`}
                                        apiCallback={getDocumentNameFilter}
                                        value={filter.documentNameFilter}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                        initialValue={filter.documentNameFilter}
                                        matchBy={"id"}
                                        display={"file_name"}
                                        limitTags={1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                    <Typography variant="caption" color="textSecondary">Category</Typography>
                                    <MultiSelect
                                        variant={"outlined"}
                                        id={"categoryFilter"}
                                        name={"categoryFilter"}
                                        placeholder={"Category"}
                                        className={`sm`}
                                        options={incomeRentDocsCategoryOptions}
                                        value={filter.categoryFilter}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                        initialValue={filter.categoryFilter}
                                        matchBy={"id"}
                                        display={"name"}
                                        limitTags={1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} sx={{ zIndex: 1 }}>
                                    <Typography variant="caption" color="textSecondary">Year</Typography>
                                    <MultiSelect
                                        variant={"outlined"}
                                        id={"yearFilter"}
                                        name={"yearFilter"}
                                        placeholder={"Year"}
                                        className={`sm`}
                                        value={filter.yearFilter}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                        initialValue={filter.yearFilter}
                                        options={incomeRentDocsYearOptions}
                                        matchBy={"id"}
                                        display={"name"}
                                        limitTags={1}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="caption" color="textSecondary">Uploaded by</Typography>
                                    <MultiSelectAsync
                                        variant={"outlined"}
                                        id={"uploadedByFilter"}
                                        name={"uploadedByFilter"}
                                        placeholder={"Uploaded By"}
                                        className={`sm`}
                                        apiCallback={getDocumentUploaderFilter}
                                        value={filter.uploadedByFilter}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                        initialValue={filter.uploadedByFilter}
                                        matchBy={"id"}
                                        display={"name"}
                                        limitTags={1}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="caption" color="textSecondary">Uploaded - Start Date</Typography>
                                    <DatePicker
                                        id={"startDate"}
                                        className={`sm datePicker ${filter.startDate ? '' : ''}`}
                                        name="startDate"
                                        value={filter.startDate}
                                        disableFuture={true}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="caption" color="textSecondary">End Date</Typography>
                                    <DatePicker
                                        id={"endDate"}
                                        className={`sm datePicker ${filter.endDate ? '' : ''}`}
                                        name="endDate"
                                        value={filter.endDate}
                                        disableFuture={true}
                                        handleChange={(e) => { dispatch(updateIncomeRentFilter(e)) }}
                                    />
                                </Grid>
                                <Grid item xs={12} align="right">
                                    <Button variant="text" size="medium" className="mr-1" onClick={() => { setFilterDraw(false) }} aria-label="Close">Close</Button>
                                    <Button variant="contained" size="medium" color="primary" onClick={() => { setFilterDraw(false); handleFilter() }} aria-label="Apply">Apply</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
            <Dialog
                open={deleteDocument !== null}
                handleDialogClose={handleClose}
                confirmHandle={onConfirmDelete}
            >
                <Grid>
                    Are you sure to delete this document?
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
IncomeAndRent.defaultProps = {
    classes: {},
};

// prop types
IncomeAndRent.propTypes = {
    classes: PropTypes.object,
};

export default styled(IncomeAndRent)(Style);