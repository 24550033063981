import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//Components
import { TextField, Sender, Receiver } from "../../components";

// reducer
import { getApplicationsCommentsRequest, updateApplicationsCommentsRequest, updateApplicationsCurrentComment, deleteApplicationsCommentRequest } from "../../redux/reducer/applicationsReducer";
import { errorMessage } from "../../redux/reducer/uiReducer";
import { SendIcon } from "../../assets/svg";

const Comments = () => {
    //define dispatch
    const dispatch = useDispatch();

    // reducer
    const { currentApplication: { general: applicationsDetail }, currentComment, comments } = useSelector(s => (s.applications))
    const { loggedUser } = useSelector(s => (s.profile))

    //variables
    const isCityStaff = loggedUser.user_group_id === 3 ? true : false

    // get selected manager comments
    useEffect(() => {
        dispatch(getApplicationsCommentsRequest({ id: applicationsDetail.id }))
    }, [dispatch, applicationsDetail.id])

    // update existing messages
    const updateMessage = (data) => {
        if (data.message) {
            dispatch(updateApplicationsCommentsRequest({
                message: data.message,
                message_from: data.message_from_id,
                message_to: data.message_to_id,
                application: data.application_id,
                id: data.id
            }))
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    }

    // send new messages
    const sendMessage = () =>{
        if (currentComment.message) {
            dispatch(updateApplicationsCommentsRequest({
                ...currentComment,
                message_from: loggedUser.id,
                message_to: applicationsDetail.applicant_id,
                application: applicationsDetail.id,
                id: null
            }))
        } else {
            dispatch(errorMessage("Please enter comments"))
        }
    }

    return (
        <Grid className="commentsContainer">
            <Grid className="commentsList">
                {comments && comments.map((item, index) => (
                    <>
                        {item.message_from_id === loggedUser.id ?
                            <Sender
                                key={`sender-${index}`}
                                data={item}
                                loggedUser={loggedUser}
                                updateMessage={updateMessage}
                                handleDelete={() => { dispatch(deleteApplicationsCommentRequest(item)) }}
                            />
                            :
                            <Receiver
                                key={`receiver-${index}`}
                                data={item}
                            />
                        }
                    </>
                ))}
            </Grid>
            {!isCityStaff && <Grid className="commentType">
                <TextField
                    placeholder={"Write a comment here"}
                    value={currentComment.message}
                    handleChange={(e) => { dispatch(updateApplicationsCurrentComment(e.value)) }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <Tooltip arrow title="Send">
                                <IconButton
                                    onClick={() => {sendMessage()}}
                                    aria-label="Send the comment"
                                >
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>,
                    }}
                    onKeyPress={(e) => {
                        if (e.charCode == 13 || e.keyCode == 13) {
                            sendMessage();
                        }
                    }}
                />
            </Grid>}
        </Grid>
    );
}

// default props
Comments.defaultProps = {
    classes: {},
};

// prop types
Comments.propTypes = {
    classes: PropTypes.object,
};

export default styled(Comments)(Style);