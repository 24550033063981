import React from "react"
import { Avatar as Core } from "@mui/material";
import PropTypes from "prop-types";

// helpers
import { gets3URL } from "../../helpers";

//avatar
const AvatarComponent = (props) => {

    // props
    const { onClick, src, alt, loadS3Url, ...rest } = props;

    let imageSrc = src
    // if(imageSrc && (imageSrc+"").includes("amazonaws") === false && loadS3Url){
    //     imageSrc = gets3URL(imageSrc)
    // }
    return (
        <Core
            onClick={onClick}
            src={imageSrc || "1.png"}
            alt={alt ? alt.toUpperCase() : ""}
            {...rest}
        />
    )
}

AvatarComponent.defaultProps = {
    onClick: () => { },
    src: "1.png",
    alt: "img",
    loadS3Url: true
}

AvatarComponent.propTypes = {
    onClick: PropTypes.func,
    src: PropTypes.string,
    alt: PropTypes.string,
    loadS3Url: PropTypes.bool,
}

export const Avatar = AvatarComponent