import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'
import { DeleteIcon } from "../../assets/svg";

//Components
import { Avatar, AutoComplete, Button, TextField, Switch, AsyncAutoComplete, Dialog, InputMaskField } from "../../components";

// epic
import { getProfileRequest, updateProfileDetails, updateNotificationDetails, updateNotificationRequest } from "../../redux/reducer/profileReducer";

// service
import { locationService } from "../../redux/service";

// helpers
import { genderOptions, getFileNameWithTime, isEmailValid, isPasswordValid, isPhoneValid, isRequired, isZipCodeValid, gets3URL } from "../../helpers";

// constants
import { commonConstants } from "../../config/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//redux
import { errorMessage } from "../../redux/reducer/uiReducer";

function Detail(props) {
    //define dispatch
    const dispatch = useDispatch();

    // get props
    const { validate } = props;

    // state
    const [pwdToggle, setPwdToggle] = useState(false);
    const [password1, setPassword1] = useState(false);
    const [password2, setPassword2] = useState(false);
    const [password3, setPassword3] = useState(false);
    const [emailConfirmDialog, setEmailConfirmDialog] = useState({ flag: false, data: {} });
    const [webConfirmDialog, setWebConfirmDialog] = useState({ flag: false, data: {} });
    const [removeProfilePhoto, setRemoveProfilePhoto] = useState(false);


    // reducer
    const { userProfile, loggedUser: { user_group_id } } = useSelector(s => (s.profile));

    // variables
    const isPropertyManager = user_group_id === 2

    // get profile details
    useEffect(() => {
        dispatch(getProfileRequest())
    }, [dispatch])

    // handle profile
    const hiddenFileInput = useRef(null);

    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    };

    // update profile details
    const handleProfileChange = (data) => {
        dispatch(updateProfileDetails(data))
    }

    // update profile details
    const handleNotificationChange = (data) => {
        dispatch(updateNotificationDetails(data))
    }

    // get city options
    async function getCityOptions(key) {
        const regionId = userProfile.profile.state && userProfile.profile.state.id ? userProfile.profile.state.id : ""
        const cities = await locationService.getCities(key, regionId);
        return cities;
    }

    // get state options
    async function getStateOptions(key) {
        const cityId = userProfile.profile.city && userProfile.profile.city.id ? userProfile.profile.city.id : ""
        const states = await locationService.getStates(key, cityId);
        return states;
    }

    const getProfilePhoto = () => {
        if (userProfile.profile.profile_photo && !removeProfilePhoto) {
            return typeof userProfile.profile.profile_photo === "string" ?
            gets3URL(userProfile.profile.profile_image) : URL.createObjectURL(userProfile.profile.profile_photo);
        }
        return "";
    };

    // update notification data in DB
    const updateNotification = (data) => {
        dispatch(updateNotificationRequest({ ...data }))
    }

    return (
        <Grid container className="detailContainer">

            <Grid item xs={4} xl={3}>
                <Grid className="profileImage">
                    <Tooltip arrow title="Change Photo">
                        <Grid>
                            <Avatar
                                onClick={() => { handleUploadClick() }}
                                src={getProfilePhoto()}
                                alt={userProfile.first_name}
                                loadS3Url={false}
                            />
                        </Grid>
                    </Tooltip>
                    {userProfile.profile && userProfile.profile.profile_photo &&
                        <Tooltip arrow title="Delete">
                            <IconButton className="deletIcon" onClick={() => {
                                setRemoveProfilePhoto(true);
                                handleProfileChange({
                                    ...userProfile,
                                    profile: { ...userProfile.profile, profile_photo: "None" }
                                })
                                hiddenFileInput.current.value = null; // Reset the file input
                            }}
                                aria-label="Delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => {
                            const newFile = new File([e.target.files[0]], getFileNameWithTime(e.target.files[0]));
                            const extArr = newFile && newFile.name ? newFile.name.split(".") : []
                            const extension = extArr.length ? extArr[extArr.length-1] : ''
                            if(['jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi','png','gif'].includes(extension)){
                                setRemoveProfilePhoto(false);
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, profile_photo: newFile } })
                            }else{
                                dispatch(errorMessage("Please upload image files"))
                            }
                        }}
                        style={{ display: 'none' }}
                    />
                </Grid>
                <Grid align="center" className="pt-5">
                    <Button variant={"text"} className="changeBtn" onClick={() => { setPwdToggle(!pwdToggle) }} aria-label="Change password">Change Password</Button>
                </Grid>
                <Collapse in={pwdToggle}>
                    <Grid item xs={12} className="pl-5 pr-5 pt-4">
                        <Typography variant="caption" color="textSecondary">Old Password</Typography>
                        <TextField
                            id={"old_password"}
                            name={"old_password"}
                            aria-label="Enter Old Password"
                            value={userProfile.old_password}
                            className={`inlineEdit sm ${userProfile.old_password ? '' : 'nodata'} mb-2`}
                            type={password1 ? "text" : "password"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isRequired(validate && (userProfile.password || userProfile.confirm_password), userProfile.old_password)}
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setPassword1(!password1) }}
                                                edge="end"
                                            >
                                                {password1 ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                        <Typography variant="caption" color="textSecondary">New Password</Typography>
                        <TextField
                            id={"password"}
                            name={"password"}
                            aria-label="Enter New Password"
                            value={userProfile.password}
                            className={`inlineEdit sm ${userProfile.password ? '' : 'nodata'} mb-2`}
                            type={password2 ? "text" : "password"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isPasswordValid(validate && (userProfile.password || userProfile.confirm_password), userProfile.password)}
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setPassword2(!password2) }}
                                                edge="end"
                                            >
                                                {password2 ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                        <Typography variant="caption" color="textSecondary">Confirm Password</Typography>
                        <TextField
                            id={"confirm_password"}
                            name={"confirm_password"}
                            aria-label="Enter Confirm Password"
                            value={userProfile.confirm_password}
                            className={`inlineEdit sm ${userProfile.confirm_password ? '' : 'nodata'}`}
                            type={password3 ? "text" : "password"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isPasswordValid(validate && (userProfile.password || userProfile.confirm_password), userProfile.confirm_password)}
                            InputProps={
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setPassword3(!password3) }}
                                                edge="end"
                                            >
                                                {password3 ? <Tooltip arrow title="Hide Password"><Visibility /></Tooltip> : <Tooltip arrow title="Show Password"><VisibilityOff /></Tooltip>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            }
                        />
                    </Grid>
                </Collapse>
            </Grid>
            <Grid item xs={8} xl={9}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">FIRST NAME</Typography>
                        <TextField
                            id={"first_name"}
                            name={"first_name"}
                            aria-label="Enter First Name"
                            value={userProfile.first_name}
                            className={`inlineEdit sm ${userProfile.first_name ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isRequired(validate, userProfile.first_name)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">Last NAME</Typography>
                        <TextField
                            id={"last_name"}
                            name={"last_name"}
                            aria-label="Enter Last Name"
                            value={userProfile.last_name}
                            className={`inlineEdit sm ${userProfile.last_name ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isRequired(validate, userProfile.last_name)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {isPropertyManager &&
                            <>
                                <Typography variant="caption" color="textSecondary">Company name</Typography>
                                <TextField
                                    id={"company_name"}
                                    name={"company_name"}
                                    aria-label="Enter Company Name"
                                    value={userProfile.profile.company_name}
                                    className={`inlineEdit sm ${userProfile.profile.company_name ? '' : 'nodata'}`}
                                    type={"text"}
                                    handleChange={(e) => {
                                        handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                                    }}
                                    validation={isRequired(validate, userProfile.profile.company_name)}
                                />
                            </>}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">Email ID</Typography>
                        <TextField
                            id={"email"}
                            name={"email"}
                            aria-label="Enter Email id"
                            value={userProfile.email}
                            className={`inlineEdit sm ${userProfile.email ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, [e.name]: e.value })
                            }}
                            validation={isEmailValid(validate, userProfile.email)}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">Phone Number</Typography>
                        <InputMaskField
                            id={"phone"}
                            name={"phone"}
                            aria-label="Enter Phone Number"
                            value={userProfile.profile.phone}
                            className={`inlineEdit sm ${userProfile.profile.phone ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                const extractNumbers = e.value.replace(/[^0-9]/g, '')
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: extractNumbers } })
                            }}
                            validation={isPhoneValid(validate, userProfile.profile.phone)}
                            mask={'(999) 999-9999'}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ALTERNATE PHONE NUMBER</Typography>
                        <InputMaskField
                            id={"alt_phone"}
                            name={"alt_phone"}
                            aria-label="Enter Phone Number"
                            value={userProfile.profile.alt_phone}
                            className={`inlineEdit sm ${userProfile.profile.alt_phone ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                const extractNumbers = e.value.replace(/[^0-9]/g, '')
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: extractNumbers } })
                            }}
                            validation={isPhoneValid(validate && userProfile.profile.alt_phone, userProfile.profile.alt_phone, commonConstants.PHONE_INVALID)}
                            mask={'(999) 999-9999'}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" color="textSecondary">ADDRESS</Typography>
                        <TextField
                            id={"address"}
                            name={"address"}
                            aria-label="Enter Address"
                            value={userProfile.profile.address}
                            className={`inlineEdit sm ${userProfile.profile.address ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">CITY</Typography>
                        <AsyncAutoComplete
                            id={"city"}
                            name={"city"}
                            aria-label="Select city"
                            value={userProfile.profile.city}
                            className={`inlineEdit sm ${userProfile.profile.city ? '' : 'nodata'}`}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                            }}
                            initialValue={userProfile.profile.city}
                            apiCallback={getCityOptions}
                            matchBy="id"
                            display="name"
                            validation={isRequired(validate, userProfile.profile.city && userProfile.profile.city.id ? userProfile.profile.city.id : '')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">STATE</Typography>
                        <AsyncAutoComplete
                            id={"state"}
                            name={"state"}
                            aria-label="Select state"
                            className={`inlineEdit sm ${userProfile.profile.state && userProfile.profile.state.id ? '' : 'nodata'}`}
                            initialValue={userProfile.profile.state}
                            value={userProfile.profile.state}
                            handleChange={(e) =>
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                            }
                            apiCallback={getStateOptions}
                            matchBy="id"
                            display="name"
                            validation={isRequired(validate, userProfile.profile.state && userProfile.profile.state.id ? userProfile.profile.state.id : '')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">ZIP CODE</Typography>
                        <TextField
                            id={"zipcode"}
                            name={"zipcode"}
                            aria-label="Enter Zip code"
                            value={userProfile.profile.zipcode}
                            className={`inlineEdit sm ${userProfile.profile.zipcode ? '' : 'nodata'}`}
                            type={"text"}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                            }}
                            validation={isZipCodeValid(validate, userProfile.profile.zipcode)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="caption" color="textSecondary">GENDER</Typography>
                        <AutoComplete
                            id={"gender"}
                            name={"gender"}
                            aria-label="Select Gender"
                            value={{ id: userProfile.profile.gender }}
                            className={`inlineEdit sm ${userProfile.profile.gender ? '' : 'nodata'}`}
                            handleChange={(e) => {
                                handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, other_gender: "", [e.name]: e.value && e.value.id ? e.value.id : '' } })
                            }}
                            options={genderOptions}
                            validation={isRequired(validate, userProfile.profile.gender)}
                        />
                    </Grid>
                    {(userProfile.profile.gender && (userProfile.profile.gender + "" === "6")) &&
                        <Grid item xs={4}>
                            <Typography variant="caption" color="textSecondary">OTHER GENDER</Typography>
                            <TextField
                                id={"other_gender"}
                                name={"other_gender"}
                                aria-label={"Enter other gender"}
                                value={userProfile.profile.other_gender}
                                className={`inlineEdit sm ${userProfile.profile.other_gender ? '' : 'nodata'}`}
                                type={"text"}
                                handleChange={(e) => {
                                    handleProfileChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } })
                                }}
                                validation={isRequired(validate, userProfile.profile.other_gender)}
                            />
                        </Grid>}
                    <Grid item xs={12}>
                        <Tooltip arrow placement="bottom-start" title={userProfile.profile.email_notification ? "Disable" : "Enable"}>
                            <Grid>
                                <Switch
                                    id={"email_notification"}
                                    name={"email_notification"}
                                    aria-label="Enable/ Disable Email Notification"
                                    checked={userProfile.profile.email_notification}
                                    label={<Typography variant="h6" className="switchLabel">EMAIL NOTIFICATION</Typography>}
                                    handleChange={(e) => {
                                        if (e.value === true) {
                                            handleNotificationChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } });
                                            updateNotification({ notification: "email_notification", value: e.value })
                                        } else {
                                            setEmailConfirmDialog({ flag: true, data: { [e.name]: e.value } });
                                        }
                                    }}
                                />
                            </Grid>
                        </Tooltip>
                        <Typography variant="body1" className="switchBrief">
                            You can choose email and/or web notifications.  Select your preference by switching the toggle button on or off. This can be changed at any time.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip arrow placement="bottom-start" title={userProfile.profile.web_notification ? "Disable" : "Enable"}>
                            <Grid>
                                <Switch
                                    id={"web_notification"}
                                    name={"web_notification"}
                                    aria-label="Enable/ Disable Web Notification"
                                    checked={userProfile.profile.web_notification}
                                    label={<Typography variant="h6" className="switchLabel">WEB NOTIFICATION</Typography>}
                                    handleChange={(e) => {
                                        if (e.value === true) {
                                            handleNotificationChange({ ...userProfile, profile: { ...userProfile.profile, [e.name]: e.value } });
                                            updateNotification({ notification: "web_notification", value: e.value })
                                        } else {
                                            setWebConfirmDialog({ flag: true, data: { [e.name]: e.value } });
                                        }
                                    }}
                                />
                            </Grid>
                        </Tooltip>
                        <Typography variant="body1" className="switchBrief">
                            You can choose email and/or web notifications.  Select your preference by switching the toggle button on or off. This can be changed at any time.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {
                emailConfirmDialog && emailConfirmDialog.flag &&
                <Dialog
                    open={emailConfirmDialog.flag}
                    handleDialogClose={() => { setEmailConfirmDialog({ flag: false, data: {} }) }}
                    confirmHandle={() => {
                        handleNotificationChange({ ...userProfile, profile: { ...userProfile.profile, email_notification: emailConfirmDialog.data && emailConfirmDialog.data.email_notification ? emailConfirmDialog.data.email_notification : false } });
                        updateNotification({ notification: "email_notification", value: emailConfirmDialog.data && emailConfirmDialog.data.email_notification ? emailConfirmDialog.data.email_notification : false })
                        setEmailConfirmDialog({ flag: false, data: {} })
                    }}
                    showSuccessBtn={userProfile.profile && userProfile.profile.web_notification}
                >
                    <Typography variant='h6'>{userProfile.profile && userProfile.profile.web_notification ? `Are you sure, you want to ${emailConfirmDialog.data && emailConfirmDialog.data.email_notification ? "unmute" : "mute"} email notification?` : `You cannot disable both email and web notification`}</Typography>
                </Dialog>
            }

            {
                webConfirmDialog && webConfirmDialog.flag &&
                <Dialog
                    open={webConfirmDialog.flag}
                    handleDialogClose={() => { setWebConfirmDialog({ flag: false, data: {} }) }}
                    confirmHandle={() => {
                        handleNotificationChange({ ...userProfile, profile: { ...userProfile.profile, web_notification: webConfirmDialog.data && webConfirmDialog.data.web_notification ? webConfirmDialog.data.web_notification : false } });
                        updateNotification({ notification: "web_notification", value: webConfirmDialog.data && webConfirmDialog.data.web_notification ? webConfirmDialog.data.web_notification : false })
                        setWebConfirmDialog({ flag: false, data: {} })
                    }}
                    showSuccessBtn={userProfile.profile && userProfile.profile.email_notification}
                >
                    <Typography variant='h6'>{userProfile.profile && userProfile.profile.email_notification ? `Are you sure, you want to ${webConfirmDialog.data && webConfirmDialog.data.web_notification ? "unmute" : "mute"} web notification?` : `You cannot disable both email and web notification`}</Typography>
                </Dialog>
            }
        </Grid >
    );
}

// default props
Detail.defaultProps = {
    classes: {},
};

// prop types
Detail.propTypes = {
    classes: PropTypes.object,
    confirmHandle: PropTypes.func,
    cancelEdit: PropTypes.bool
};

export default styled(Detail)(Style);