import React from "react";
import PropTypes from "prop-types";
import { Typography as Core } from "@mui/material"
import { styled } from '@mui/material/styles';

// Imports Styles
import Styles from "./style";

const TypographyComponent = (props) => {

    //get props
    const { classes, color, variant, children, align, onClick, noWrap, title, className, ...rest } = props;

    return (
        <Core
            variant={variant}
            classes={classes}
            align={align}
            color={color}
            onClick={onClick}
            noWrap={noWrap}
            className={className}
            {...rest}
        >
            {title}
            {children}
        </Core>
    );
}

//default props
TypographyComponent.defaultProps = {
    variant: "h5",
    classes: {},
    children: "",
    color: "textPrimary",
    align: "left",
    noWrap: false,
    onClick: () => { },
    className: ""
}

//prop types 
TypographyComponent.propTypes = {
    variant: PropTypes.string.isRequired,
    classes: PropTypes.object,
    children: PropTypes.any,
    color: PropTypes.string,
    align: PropTypes.string,
    noWrap: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
}

/** Export Component */
export const Typography = styled(TypographyComponent)(Styles)