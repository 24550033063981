import React from "react";
import PropTypes from "prop-types";

// Import Auth Layout
import { SignupLayout } from "../layouts";

// Routes the page without any authentication by the user
const SignupRoute = ({ component, ...props }) => (
  <SignupLayout component={component} {...props} />
);

// Define Props Types
SignupRoute.propTypes = {
  component: PropTypes.elementType
};

export default SignupRoute;
