import React, { memo } from "react";
import PropTypes from "prop-types";
import { styled, Grid, ToggleButtonGroup, Box, ToggleButton, Button, IconButton, Tooltip } from "@mui/material";
import _ from "lodash";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

// Imports Styles
import Styles from "./style";
import { DocumentIcon, DownloadIcon, FilterIcon, GridIcon, NextIcon, PlusIcon, PrevIcon, TableIcon } from "../../assets/svg";
import palette from "../../assets/themes/palette";

const FilterBoxComponent = (props) => {

    //get props
    const { className, children, setViewType, handleAdd, handleFilter, handleClearFilter, handleDownload, handlePrevious, handleNext, viewType, IconsList, handleRequestDoc, isFilterApplied, downloadDisabled } = props;

    return (
        <Grid item xs={12} className={className}>
            <Grid container justifyContent={"space-between"}>
                <Grid className="dflex alignCenter flexOne">
                    {IconsList.includes("VIEW_TYPE") &&
                        <Box sx={{ pr: 2 }}>
                            <ToggleButtonGroup
                                value={viewType}
                                exclusive
                                onChange={(e, value) => setViewType(value)}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="panel" aria-label="grid">
                                    <GridIcon />
                                </ToggleButton>
                                <ToggleButton value="list" aria-label="list">
                                    <TableIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    }
                    {IconsList.includes("ADD") &&
                        <Box sx={{ pr: 2, pl: 2, borderLeft: `1px solid ${palette.greyshades.light2}`, borderRight: `1px solid ${palette.greyshades.light2}` }}>
                            <Button className="addBtn" onClick={(event) => handleAdd(event)} variant="text" size="small" startIcon={<PlusIcon />} aria-label="Add">Add</Button>
                        </Box>}
                    {IconsList.includes("CHILDREN") &&
                        <Box sx={{ flex: 1 }}>
                            {children}
                        </Box>}
                </Grid>
                <Grid className="dflex alignCenter filterRtActions">
                    {IconsList.includes("FILTER_LIST") &&
                        <Box sx={{ pr: 2, pl: 2 }}>
                            <>
                                {/* {isFilterApplied && <Tooltip title="Clear Filter" arrow><IconButton sx={{padding: '0px', marginRight: '5px'}} onClick={() => handleClearFilter()}><FilterAltOffIcon sx={{width: '20px', fill: `${isFilterApplied ? "#DFB233" : "#2e2e84"}`}} /></IconButton></Tooltip>} */}
                                {isFilterApplied && <Button onClick={() => handleClearFilter()} className="mr-1" variant="text" size="small" startIcon={<FilterAltOffIcon sx={{width: '20px', fill: `${isFilterApplied ? "#DFB233" : "#2e2e84"}`}} />} aria-label="Clear Filter ">Clear Filter</Button>}
                                <Button sx={{position: 'relative'}} onClick={(event) => handleFilter(event)} variant="text" size="small" startIcon={<FilterIcon />} aria-label="Filter List">Filter List{isFilterApplied &&<Grid class="dotBtn"></Grid>}</Button>
                            </>
                        </Box>
                    }
                    {IconsList.includes("DOWNLOAD") &&
                        <Box sx={{ pr: 2, pl: 2, borderLeft: `1px solid ${palette.greyshades.light2}`, borderRight: `1px solid ${palette.greyshades.light2}`, '& .Mui-disabled svg path': { fill: '#ddd' } }}>
                            <Button onClick={() => handleDownload()} variant="text" size="small" startIcon={<DownloadIcon />} disabled={downloadDisabled} aria-label="Download">Download</Button>
                        </Box>
                    }
                    {IconsList.includes("PREV") &&
                        <>
                            <Box sx={{ pr: 2, pl: 2 }}>
                                <Button onClick={() => handlePrevious()} variant="text" size="small" startIcon={<PrevIcon />} aria-label="Previous">Previous</Button>
                            </Box>
                        </>
                    }
                    {IconsList.includes("NEXT") &&
                        <>
                            <Box sx={{ pr: 2, pl: 2 }}>
                                <Button onClick={() => handleNext()} variant="text" size="small" endIcon={<NextIcon />} aria-label="Next">Next</Button>
                            </Box>
                        </>
                    }
                    {IconsList.includes("REQUEST_DOC") &&
                        <>
                            <Box sx={{ pr: 2, pl: 2, borderLeft: `1px solid ${palette.greyshades.light2}`, borderRight: `1px solid ${palette.greyshades.light2}` }}>
                                <Button onClick={(e) => handleRequestDoc(e)} variant="text" size="small" startIcon={<DocumentIcon />} aria-label="Request Documents">Request Documents</Button>
                            </Box>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid >
    );
};

// default props
FilterBoxComponent.defaultProps = {
    className: "",
    children: "",
    viewType: "panel",
    setViewType: () => { },
    handleAdd: () => { },
    IconsList: [],
    handleFilter: () => { },
    handleClearFilter: () => { },
    handleDownload: () => { },
    handlePrevious: () => { },
    handleNext: () => { },
    handleRequestDoc: () => { },
    isFilterApplied: false
};

// prop types
FilterBoxComponent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    viewType: PropTypes.string,
    setViewType: PropTypes.func,
    handleAdd: PropTypes.func,
    IconsList: PropTypes.array,
    handleFilter: PropTypes.func,
    handleClearFilter: PropTypes.func,
    handleDownload: PropTypes.func,
    handlePrevious: PropTypes.func,
    handleNext: PropTypes.func,
    handleRequestDoc: PropTypes.func,
    isFilterApplied: PropTypes.bool,
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const FilterPanel = memo(styled(FilterBoxComponent)(Styles), areEqual);
