// Import API Helper
import { getRequest, postRequest, deleteRequest } from "../../helpers";

//Get left panel property manager details
async function getPMLeftPanelList(params) {
  const response = await postRequest(`property-manager/left-panel/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter});
  return response;
}

//Get property manager detail
async function getPMDetail(params) {
  const response = await getRequest(`property-manager/detail/${params.id}/`);
  return response;
}

//update property manager flag
async function updatePMFlag(params) {
  const response = await postRequest(`property-manager/update-flag/${params.id}/${params.is_flag}/`);
  return response;
}

//property Manager Approval
async function propertyManagerApproval(params) {
  const response = await postRequest(`property-manager/approval/`, params);
  return response;
}

//Get property manager list
async function getPMList(params) {
  const response = await postRequest(`property-manager/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter});
  return response;
}

/** get property manager total count */
async function getPMCount(params) {
  const response = await postRequest(`property-manager/count/`, {"filter" : params.filter});
  return response;
}

//Send invite emails to property managers
async function sendPMInvites(params) {
  const response = await postRequest('property-manager/invite-managers/', params);
  return response;
}

/**
 * get Manager Comments
 */
async function getPMComments(params) {
  const response = await getRequest(`property-manager/comments/${params.id}/`);
  return response;
}

/**
 * update Manager Comments
 */
async function updatePMComments(params) {
  const response = await postRequest(`property-manager/update-comments/`, params);
  return response;
}

/**
 * delete Manager Comments
 */
async function deletePMComments(params) {
  const response = await deleteRequest(`property-manager/delete-comments/${params.id}/`);
  return response;
}

/**
* download property manager reports
*/
async function downloadPMReport(params) {
  const response = await postRequest(`property-manager/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter, 'timezone': params.timezone}, false, "arraybuffer");
  return response;
}

// export all property Manager functions
export const propertyManagerService = {
  getPMLeftPanelList,
  getPMDetail,
  updatePMFlag,
  propertyManagerApproval,
  getPMList,
  getPMCount,
  sendPMInvites,
  getPMComments,
  updatePMComments,
  deletePMComments,
  downloadPMReport
};