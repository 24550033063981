import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";

//style
import Style from './style'

// components
import { TimeLine } from "../../components";

// reducer
import { getApplicationsLogsRequest } from "../../redux/reducer/applicationsReducer";

const Logs = () => {
    //define dispatch
    const dispatch = useDispatch();

    const { logs } = useSelector(s => (s.applications))
    const { tenantDetail: { details: tenantDetail } } = useSelector(s => (s.tenants))

    // get selected manager comments
    useEffect(() => {
        dispatch(getApplicationsLogsRequest({ application_id: tenantDetail.application_id }))
    }, [dispatch, tenantDetail.application_id])

    return (
        <Grid className="commentsContainer">
            <TimeLine
                logs={logs}
            />
        </Grid>
    );
}

// default props
Logs.defaultProps = {
    classes: {},
};

// prop types
Logs.propTypes = {
    classes: PropTypes.object,
};

export default styled(Logs)(Style);