import palette from "../../assets/themes/palette";

const styles = {
    '.detailView': {
    },
    ' .managerDetail': {
        background: '#fff',
        padding: '10px',
        height: 'calc(100vh - 136px)',
        overflowY: 'auto',
        ' .header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 24
        }
    },
    '.bodyContainer': {
        paddingTop: '15px'
    },
    ' .managerTitle': {
        display: 'flex',
        alignItems: 'center '
    },
    ' .detailAction': {
        display: 'flex',
        alignItems: 'center',
        ' .approve': {
            background: palette.colorShades.blueBg,
            borderRadius: 6,
            border: `1px solid ${palette.colorShades.blue}`,
            color: `${palette.colorShades.blue}`,
            lineHeight: '10px',
            '&:hover': {
                boxShadow: '3px 5px 11px #adadad !important'
            }
        },
        ' .reject': {
            background: palette.colorShades.redBg,
            borderRadius: 6,
            border: `1px solid ${palette.colorShades.red}`,
            color: `${palette.colorShades.red}`,
            lineHeight: '10px',
            '&:hover': {
                boxShadow: '3px 5px 11px #adadad !important'
            }
        }
    },
    '.detailContainer': {
        paddingBottom: 20,
        '& .MuiInputBase-input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
        '& .tableContainer': {
            height: 'auto !important'
        }
    },
    '.commentsContainer': {
        '& .MuiAvatar-root': {
            backgroundColor: palette.greyshades.light3
        }
    },

    '.commentsTime': {
        color: palette.greyshades.main,
        fontSize: 14
    },
    '.commentsList ': {
        height: 'calc(100vh - 390px)',
        overflowY: 'auto',
        paddingBottom: '20px',
        paddingRight: 30
    },
    '.commentType .MuiInputBase-root': {
        background: '#FCFCFC',
        borderRadius: 7
    },
    '.commentType': {
        paddingRight: '30px'
    },
    '.propertiesList': {
        padding: '20px',
        paddingTop: '4px !important'
    },
    '.filterFields': {
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important',
                },
                '& .MuiFormControl-root': {
                    border: '2px solid #2e2e84  !important',
                    borderRadius: '4px',
                    '& fieldset': {
                        border: 'none !important',
                        borderWidth: '0px !important'
                    }
                }
            }
        },
        '& .MuiInputBase-root': {
            maxHeight: '250px',
            overflowY: 'auto',
        },
        '& .MuiFormControl-root': {
            position: 'absolute',
            background: '#fff',
            zIndex: 9
        },
        '& .MuiOutlinedInput-root': {
            padding: '0px'
        },
        '& .MuiChip-root': {
            height: '24px'
        },
        '& .MuiChip-deleteIcon': {
            fontSize: '20px'
        },
        '& .selectBox': {
            '& .MuiFormControl-root': {
                margin: '0px'
            },
            '& .MuiSelect-select': {
                height: '34px',
                padding: '0.5px 6px',
                lineHeight: '32px',
                paddingRight: '34px'
            },
            '& .MuiCheckbox-root': {
                padding: '6px',
                marginRight: '4px'
            }
        }
    },
    '.tableContainer': {
        height: 'calc(100vh - 212px)'
    },
    '.pagination': {
        '&  .MuiButtonBase-root': {
            minWidth: '26px',
            height: '26px',
            background: '#fff',
        },
        '& .Mui-selected': {
            background: palette.primary.main
        }
    },
    '.sideBarList': {
        '& .sidebarInner': {
            height: 'calc(100vh - 133px)',
            overflowY: 'auto',
        }
    },
    '.commentBox': {
        paddingRight: '0px',
        paddingTop: '30px',
        '&.commentsSection': {
            paddingBottom: '0px'
        }
    },
    '.filterContainer': {
        // padding: '30px',
        maxWidth: 800,
        '& .MuiInputBase-root': {
            minHeight: '34px'
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
        // for auto complete
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important'
                }
            },
            '& .MuiFormControl-root': {
                position: 'absolute',
                background: '#fff',
                // zIndex: 9
            },
            '& .MuiOutlinedInput-root': {
                padding: '0px'
            },
            '& .MuiChip-root': {
                height: '24px'
            },
            '& .MuiChip-deleteIcon': {
                fontSize: '20px'
            },
        },
        '& .MuiGrid-grid-md-4': {
            height: '90px'
        }
    },
    '.applicationDetail': {
        padding: '20px 30px 30px'
    },
    '.docTable': {
        '& .tableContainer': {
            height: 'auto'
        }
    }
}
export default styles;