// Import API Helper
import { getRequest, putRequest, postRequest, buildFormData, deleteRequest } from "../../helpers";

//get properties count/
async function getPropertiesCount(params) {
  const response = await postRequest(`properties/count/`, { "filter": params.filter });
  return response;
}

//get welcome page/
async function getWelcomePage() {
  const response = await getRequest(`properties/welcome/`);
  return response;
}

// get properties list
async function getPropertiesList(params) {
  const response = await postRequest(`properties/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter })
  return response
}

/** create or update general tab */
async function updateGeneral(params) {
  const response = await postRequest(`properties/update-general/`, params);
  return response;
}

/*** create/update amenities tab data*/
async function updateAmenities(params) {
  const response = await postRequest(`properties/update-amenities/${params.propertyId}/`, params.amenities);
  return response;
}

/*** create or update photos*/
async function updatePhotos(params) {
  let formData = new FormData();
  formData = buildFormData(formData, params.photos);

  const response = await putRequest(`properties/upload-photos/${params.propertyId}/`, {
    body: formData,
    isFormData: true,
    responseType: "json",
    multipart: true
  });
  return response;
}

// get properties left panel list
async function getPropertiesLeftPanelList(params) {
  const response = await postRequest(`properties/left-panel/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter })
  return response
}

//update properties flag
async function updatePropertiesFlag(params) {
  const response = await postRequest(`properties/update-flag/${params.id}/${params.is_flag}/`);
  return response;
}

//properties Approval
async function propertiesApproval(params) {
  const response = await postRequest(`properties/approval/`, params);
  return response;
}

//Get properties detail
async function getPropertiesDetail(params) {
  const response = await getRequest(`properties/detail/${params.id}/`);
  return response
}

//Get property report
async function getPropertiesReport(params) {
  const response = await postRequest(`properties/get-report/`)
  return response
}

//delete property
async function deleteProperties(params) {
  const response = await postRequest(`properties/delete/${params.id}/`)
  return response
}

/**
 * get Manager Comments
 */
async function getPropertiesComments(params) {
  const response = await getRequest(`properties/comments/${params.id}/`);
  return response;
}

/**
 * update Manager Comments
 */
async function updatePropertiesComments(params) {
  const response = await postRequest(`properties/update-comments/`, params);
  return response;
}

/**
 * delete Manager Comments
 */
async function deletePropertiesComments(params) {
  const response = await deleteRequest(`properties/delete-comments/${params.id}/`);
  return response;
}

/** create or update units tab */
async function updateUnits(params) {
  const response = await postRequest(`properties/update-units/${params.propertyId}/`, params);
  return response;
}

/**
* download property manager reports
*/
async function downloadPropertiesReport(params) {
  const response = await postRequest(`properties/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, {"filter" : params.filter, "timezone": params.timezone}, false, "arraybuffer");
  return response;
}

/*** update photos*/
async function uploadUnitPhotos(params) {
  let formData = new FormData();
  formData = buildFormData(formData, params);

  const response = await putRequest(`properties/units/upload-photos/${params.propertyId}/`, {
    body: formData,
    isFormData: true,
    responseType: "application/json",
    multipart: true
  });
  return response;
}

/**
 * get properties units
 */
async function getUnitDetails(params) {
  const response = await getRequest(`properties/units/${params.id}/`);
  return response;
}

/**
 * get properties photos
 */
async function getPropertiesPhotos(params) {
  const response = await getRequest(`properties/photos/${params.id}/`);
  return response;
}

/**
 * get properties amenities
 */
async function getPropertiesAmenities(params) {
  const response = await getRequest(`properties/amenities/${params.id}/`);
  return response;
}


/**
 * update property status
 */
async function updatePropertyStatus(params) {
  const response = await postRequest(`properties/update-status/${params.id}/${params.status}/`);
  return response;
}

/**
 * update property unit status
 */
async function updatePropertyUnitStatus(params) {
  const response = await postRequest(`properties/update-unit-status/`, params);
  return response;
}

// export all properties functions
export const propertiesService = {
  getPropertiesCount,
  getWelcomePage,
  getPropertiesList,
  updatePhotos,
  updateAmenities,
  updateGeneral,
  getPropertiesLeftPanelList,
  updatePropertiesFlag,
  propertiesApproval,
  getPropertiesDetail,
  getPropertiesReport,
  deleteProperties,
  getPropertiesComments,
  updatePropertiesComments,
  deletePropertiesComments,
  updateUnits,
  downloadPropertiesReport,
  uploadUnitPhotos,
  getUnitDetails,
  getPropertiesPhotos,
  getPropertiesAmenities,
  updatePropertyStatus,
  updatePropertyUnitStatus
};