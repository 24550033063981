import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

//style
import Style from "./style";

//Components
import { Button, RadioButton, TextField, AutoComplete } from "../../components";
import Popover from "../../components/popover";

// reducer
import { errorMessage } from "../../redux/reducer/uiReducer";
import { rejectApplicationRequest } from "../../redux/reducer/waitingListReducer";

// helpers
import { rejectionReasonOptions } from "../../helpers";

const RejectApplication = (props) => {
  // props
  const { className, openRejectPopup, setOpenRejectPopup } = props;

  //define dispatch
  const dispatch = useDispatch();

  // redux
  const { loggedUser } = useSelector(s => (s.profile));

  // state
  const [rejectOption, setRejectOption] = useState("");
  const [rejectMsg, setRejectMsg] = useState("");
  const [rejectReason, setRejectReason] = useState('')

  const handleRejection = () => {
    if (!rejectOption) {
      dispatch(errorMessage("Please select reject option"))
    } else if (!rejectReason) {
      dispatch(errorMessage("Please select reject reason"))
    } else {
      const applicationDetails = openRejectPopup.data || {}
      const rejects = {
        message: rejectReason ? rejectReason.trim() : "",
        message_from_id: loggedUser.id,
        message_to_id: applicationDetails.user_id,
        application_id: applicationDetails.id,
        is_log: true
      }
      const comments = {
        message: rejectMsg ? rejectMsg.trim() : "",
        message_from_id: loggedUser.id,
        message_to_id: applicationDetails.user_id,
        application_id: applicationDetails.id,
        is_log: false
      }
      dispatch(rejectApplicationRequest({ approval_status: rejectOption, rejects: rejects, comments: comments, id: applicationDetails.id }))
      setOpenRejectPopup(false)
      setRejectOption("")
      setRejectMsg("")
    }
  }

  return (
    <Popover
      anchorEl={openRejectPopup && openRejectPopup.target ? openRejectPopup.target : false}
      onClose={() => { setOpenRejectPopup(false) }}
      className={className}
    >
      <Grid container sx={{ maxWidth: 400 }}>
        <Typography variant="h5" className="pb-1 fs-18">Are you sure you want to reject ?</Typography>
        <Grid item xs={12} className="radioGroup">
          <RadioButton
            value={rejectOption}
            handleChange={e => { setRejectOption(e.value) }}
            options={[
              { "label": "Reject Application", "value": "14" },
              { "label": "Reject Document", "value": "8", "disabled": openRejectPopup.data && openRejectPopup.data.doc_count && openRejectPopup.data.approval_status === 6 ? false : true }]}
          />
          <Grid item xs={12} className="pb-2 pt-1">
            <AutoComplete
              id={"unitTypeFilter"}
              name={"unitTypeFilter"}
              placeholder="Select a reason"
              className="sm"
              fullWidth
              display={"name"}
              value={{ name: rejectReason }}
              handleChange={(e) => { setRejectReason(e.value && e.value.name ? e.value.name : "") }}
              options={rejectionReasonOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id={"rejectMsg"}
              name={"rejectMsg"}
              aria-label="Enter rejected message"
              className="sm"
              multiline
              value={rejectMsg}
              handleChange={(e) => { setRejectMsg(e.value) }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} align="right" className="pt-3">
          <Button variant="text" size="medium" className="mr-1" onClick={() => { setOpenRejectPopup(false) }} aria-label="Close">Close</Button>
          <Button variant="contained" size="medium" color="primary" onClick={() => { handleRejection() }} aria-label="Reject">Reject</Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

// default props
RejectApplication.defaultProps = {
  className: {},
  openRejectPopup: false,
  setOpenRejectPopup: () => { }
};

// prop types
RejectApplication.propTypes = {
  className: PropTypes.string,
  openRejectPopup: PropTypes.any,
  setOpenRejectPopup: PropTypes.func,
};

export default styled(RejectApplication)(Style);
