import palette from "../../assets/themes/palette";

const Style = {
    '.statusChip': {
        borderRadius: 6,
        height: 22,
        cursor: 'pointer',
        '& .MuiTypography-root': {
            fontSize: 12
        },
    },
    //  (0, "Draft")
    '& .approval_status_0': {
        // background: palette.colorShades.lightGreyBg,
        background: '#dddddd'
    },
    // (1, "Review Pending"),, (12, "Application Withdrawn"),
    '& .approval_status_1, .approval_status_12': {
        background: palette.colorShades.yellowBg,
    },
    // (2, "Pre-Approved"), // (4, "Waiting List"), (6, "Document Verification"), (10, "Tenant"), (5, "Document Requested")
    '& .approval_status_2, .approval_status_4, .approval_status_6, .approval_status_10, .approval_status_5': {
        background: palette.colorShades.blueBg,
        color: palette.colorShades.blue,
    },
    // (3, "Application Rejected"), (8, "Document Rejected"), (11, "Offer Denied"), (13, "Offer Declined"),  (14, "Application Rejected after lottery"),
    '& .approval_status_3, .approval_status_8, .approval_status_11, .approval_status_13, .approval_status_14': {
        color: palette.colorShades.red,
        background: palette.colorShades.redBg,
    },
    // (7, "Document Approved"),  (9, "Offered"),
    '& .approval_status_7, .approval_status_9': {
        background: palette.colorShades.greenBg,
        color: palette.colorShades.green,
    },
    '& .popoverDiv': {
        padding: '8px 0px',
    },
    '& .popoverList': {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '6px 16px',
    }
    // popoverDiv: {
    //     padding: '8px 0px',
    //     '& svg': {
    //         marginRight: '7px !important'
    //     },
    //     '& .DoubleArrowUp path': {
    //         fill: theme.palette.colorThemes.high
    //     },
    //     '& .ArrowUp path': {
    //         fill: theme.palette.colorThemes.medium
    //     },
    //     '& .ArrowDown path': {
    //         fill: `${theme.palette.colorThemes.low}`
    //     }
    // },
    // chips: {
    //     height: '26px !important',
    //     borderRadius: '4px !important',
    //     padding: '0px 10px !important',
    //     '& .MuiChip-label': {
    //         paddingLeft: '0px',
    //         paddingRight: '0px',
    //         fontSize: '14px'
    //     },
    //     '& svg': {
    //         marginRight: '7px !important',
    //         marginLeft: '0px !important'
    //     },
    //     '& .DoubleArrowUp path': {
    //         fill: theme.palette.colorThemes.high
    //     },
    //     '& .ArrowUp path': {
    //         fill: theme.palette.colorThemes.medium
    //     },
    //     '& .ArrowDown path': {
    //         fill: `${theme.palette.colorThemes.low}`
    //     }
    // },
    // verifiedChip: {
    //     background: `${theme.palette.secondary.main}21 !important`,
    //     color: `${theme.palette.secondary.main} !important`,
    //     '& svg': {
    //         fill: `${theme.palette.secondary.main} !important`
    //     }
    // },
    // pendingChip: {
    //     background: `${theme.palette.colorThemes.medium}40 !important`,
    //     color: `${theme.palette.colorThemes.medium} !important`,
    //     '& svg': {
    //         fill: `${theme.palette.colorThemes.medium} !important`
    //     }
    // },
    // highChip: {
    //     background: `${theme.palette.colorThemes.high}40 !important`,
    //     '& svg': {
    //         fill: `${theme.palette.colorThemes.high} !important`
    //     },
    //     '&.newChip': {
    //         color: `${theme.palette.colorThemes.high} !important`
    //     }
    // },
    // mediumChip: {
    //     background: `${theme.palette.colorThemes.medium}40 !important`,
    //     '& svg': {
    //         fill: `${theme.palette.colorThemes.medium} !important`
    //     },
    //     '&.inprogressChip': {
    //         color: `${theme.palette.colorThemes.medium} !important`
    //     }
    // },
    // lowChip: {
    //     background: `${theme.palette.colorThemes.low}21 !important`,
    //     '& svg': {
    //         fill: `${theme.palette.colorThemes.low} !important`
    //     },
    //     '&.resolvedChip': {
    //         color: `${theme.palette.colorThemes.low} !important`
    //     }
    // },
    // popoverList: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     cursor: 'pointer',
    //     padding: '6px 16px',
    //     '& svg': {
    //         marginRight: '7px'
    //     },
    //     '&:first-child': {
    //     },
    //     '&:hover': {
    //         background: '#f5f7f8'
    //     },
    //     '& .PendingIcon': {
    //         fill: `${theme.palette.colorThemes.medium} !important`
    //     },
    //     '& .VerfiedIcon': {
    //         fill: `${theme.palette.secondary.main} !important`
    //     },
    //     '& .New': {
    //         background: `${theme.palette.colorThemes.high}40 !important`,
    //         color: `${theme.palette.colorThemes.high} !important`,
    //         padding: '2px 8px',
    //         borderRadius: 4
    //     },
    //     '& .Inprogress': {
    //         background: `${theme.palette.colorThemes.medium}40 !important`,
    //         color: `${theme.palette.colorThemes.medium} !important`,
    //         padding: '2px 8px',
    //         borderRadius: 4
    //     },
    //     '& .Resolved': {
    //         background: `${theme.palette.colorThemes.low}40 !important`,
    //         color: `${theme.palette.colorThemes.low} !important`,
    //         padding: '2px 8px',
    //         borderRadius: 4
    //     }
    // },
    // noBackground: {
    //     background: 'transparent !important'
    // }
};
export default Style;