import palette from "../../../assets/themes/palette";

const Style = () => ({
    width: '100%',
    padding: '0px 10px',
    minHeight: 38,
    '& .MuiTab-root.Mui-selected': {
        // borderBottom: `3px solid ${palette.secondary.main}`,
        // color: `${palette.secondary.main} !important`
        fontWeight: 'bold',
        color: `${palette.primary.main}`
    },
    '& .MuiTabs-flexContainer': {
        borderBottom: `1px solid ${palette.greyshades.light3}`
    },
    '& .MuiTab-root': {
        textTransform: 'capitalize',
        color: '#000',
        fontWeight: 400,
        fontSize: '14px',
        minHeight: 38,
        padding: '12px 20px',
        '& .MuiTypography-root': {
            fontWeight: '400'
        },
        '&.Mui-selected .MuiTypography-root': {
            color: `${palette.primary.main} !important`,
            fontWeight: 'bold'
        }
    },
    '& .MuiTabs-indicator': {
        backgroundColor: palette.secondary.main,
        height: '3px'
    }
});
export default Style;