import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import actions
import { errorMessageApi } from "../reducer/uiReducer";
import {
    getMasterAmenitiesRequest, getMasterAmenitiesSuccess, getMasterAmenitiesFailure,
    getMasterUnitsRequest, getMasterUnitsSuccess, getMasterUnitsFailure,
    getCityPolicyDocsRequest, getCityPolicyDocsSuccess, getCityPolicyDocsFailure,
    createCityPolicyDocsRequest, createCityPolicyDocsSuccess, createCityPolicyDocsFailure,
    getCityPolicyDocsCountRequest, getCityPolicyDocsCountSuccess, getCityPolicyDocsCountFailure,
    handleDeleteCityPolicyDocsRequest, handleDeleteCityPolicyDocsSuccess, 
    handleDeleteCityPolicyDocsFailure,
    getIncomeRentsDocsRequest, getIncomeRentsDocsSuccess, getIncomeRentsDocsFailure,
    getIncomeRentsDocsCountRequest, getIncomeRentsDocsCountSuccess, getIncomeRentsDocsCountFailure,
    createInconeDocsRequest, createInconeDocsSuccess, createInconeDocsFailure,
    deleteIncomeRentsDocsRequest, deleteIncomeRentsDocsSuccess, deleteIncomeRentsDocsFailure,
    updateOverallGlobalLoaders
} from '../reducer/globalReducer';

//import service
import { globalService } from '../service';

/**get master units */
const getMasterUnits = (action$) => action$.pipe(
    ofType(getMasterUnitsRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getMasterUnits(payload)).pipe(
            concatMap((res) => of(getMasterUnitsSuccess(res.data.data))),
            catchError((res) => of(getMasterUnitsFailure(), errorMessageApi(res))),
        )
    }),
)

/**get master amenities */
const getMasterAmenities = (action$) => action$.pipe(
    ofType(getMasterAmenitiesRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getMasterAmenities(payload)).pipe(
            concatMap((res) => of(getMasterAmenitiesSuccess(res.data.data))),
            catchError((res) => of(getMasterAmenitiesFailure(), errorMessageApi(res))),
        )
    }),
)

/**get city policy Docs*/
const getCityPolicyDocs = (action$) => action$.pipe(
    ofType(getCityPolicyDocsRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getCityPolicyDocs(payload)).pipe(
            concatMap((res) => of(getCityPolicyDocsSuccess(res.data), updateOverallGlobalLoaders())),
            catchError((res) => of(getCityPolicyDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

//get city policy docs count
const getCityPolicyDocsCount = (action$) => action$.pipe(
    ofType(getCityPolicyDocsCountRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getCityPolicyDocsCount(payload)).pipe(
            concatMap((res) => of(getCityPolicyDocsCountSuccess(res.data), getCityPolicyDocsRequest(payload), updateOverallGlobalLoaders())),
            catchError((res) => of(getCityPolicyDocsCountFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

/**create City Policy Docs*/
const createCityPolicyDocs = (action$, state$) => action$.pipe(
    ofType(createCityPolicyDocsRequest),
    mergeMap(({ payload }) => {
        const paginationState = state$.value.global.cityPolicyDocs.pagination
        const filterState = state$.value.global.cityPolicyDocs.filter
        return from(globalService.createCityPolicyDocs(payload)).pipe(
            concatMap((res) => of(createCityPolicyDocsSuccess(res.data),getCityPolicyDocsCountRequest({ ...paginationState, skip:0, filter: filterState }), updateOverallGlobalLoaders())),
            catchError((res) => of(createCityPolicyDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

/**delete City Policy Docs*/
const deleteCityPolicyDocs = (action$, state$) => action$.pipe(
    ofType(handleDeleteCityPolicyDocsRequest),
    mergeMap(({ payload }) => {
        const paginationState = state$.value.global.cityPolicyDocs.pagination
        const filterState = state$.value.global.cityPolicyDocs.filter
        return from(globalService.deleteCityPolicyDocs(payload)).pipe(
            concatMap((res) => of(handleDeleteCityPolicyDocsSuccess(res.data), getCityPolicyDocsCountRequest({ ...paginationState, skip:0, filter: filterState }), updateOverallGlobalLoaders())),
            catchError((res) => of(handleDeleteCityPolicyDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

/**create income Docs*/
const createIncomeDocs = (action$, state$) => action$.pipe(
    ofType(createInconeDocsRequest),
    mergeMap(({ payload }) => {
        const paginationState = state$.value.global.incomeRentDocs.pagination
        const filterState = state$.value.global.incomeRentDocs.filter
        return from(globalService.createIncomeDocs(payload)).pipe(
            concatMap((res) => of(createInconeDocsSuccess(res.data), getIncomeRentsDocsCountRequest({...paginationState, skip:0, filter: filterState}), updateOverallGlobalLoaders())),
            catchError((res) => of(createInconeDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
            )
        }),
    )

/**get Income and Rent Docs*/
const getIncomeRentDocs = (action$) => action$.pipe(
    ofType(getIncomeRentsDocsRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getIncomeRentDocs(payload)).pipe(
            concatMap((res) => of(getIncomeRentsDocsSuccess(res.data), updateOverallGlobalLoaders())),
            catchError((res) => of(getIncomeRentsDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

//get income and rent docs count
const getIncomeRentDocsCount = (action$) => action$.pipe(
    ofType(getIncomeRentsDocsCountRequest),
    mergeMap(({ payload }) => {
        return from(globalService.getIncomeRentDocsCount(payload)).pipe(
            concatMap((res) => of(getIncomeRentsDocsCountSuccess(res.data), getIncomeRentsDocsRequest(payload), updateOverallGlobalLoaders())),
            catchError((res) => of(getIncomeRentsDocsCountFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

/**delte Income Rent Docs*/
const deleteIncomeRentsDocs = (action$, state$) => action$.pipe(
    ofType(deleteIncomeRentsDocsRequest),
    mergeMap(({ payload }) => {
        const paginationState = state$.value.global.incomeRentDocs.pagination
        const filterState = state$.value.global.incomeRentDocs.filter
        return from(globalService.deleteIncomeRentsDocs(payload)).pipe(
            concatMap((res) => of(deleteIncomeRentsDocsSuccess({data: res.data, payload: payload}), getIncomeRentsDocsCountRequest({...paginationState, skip:0, filter: filterState}), updateOverallGlobalLoaders())),
            catchError((res) => of(deleteIncomeRentsDocsFailure(), updateOverallGlobalLoaders(), errorMessageApi(res))),
        )
    }),
)

// Export All global Epic Functions
export const globalEpic = [
    getMasterUnits,
    getMasterAmenities,
    getCityPolicyDocs,
    getCityPolicyDocsCount,
    createCityPolicyDocs,
    deleteCityPolicyDocs,
    createIncomeDocs,
    getIncomeRentDocs,
    getIncomeRentDocsCount,
    deleteIncomeRentsDocs,
];

