import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading: false,
    lotteryResult: []
}

const runLotterySlice = createSlice({
    name: 'runLottery',
    initialState: initialState,
    reducers: {
        runLotteryRequest(state) {
            state.isLoading = true
            state.lotteryResult = initialState.lotteryResult
        },
        runLotterySuccess(state, action) {
            state.isLoading = false
            state.lotteryResult = action.payload
        },
        runLotteryFailure(state) {
            state.isLoading = false
            state.lotteryResult = initialState.lotteryResult
        },

        downloadLotteryResultRequest(state) {
            state.isLoading = true
        },
        downloadLotteryResultSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, `lotteryResult_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`);
        },
        downloadLotteryResultFailure(state) {
            state.isLoading = false
        },

        resetLotteryResult(state){
            state.lotteryResult = initialState.lotteryResult
        }
    }
})

export const {
    runLotteryRequest, runLotterySuccess, runLotteryFailure,
    downloadLotteryResultRequest,downloadLotteryResultSuccess,downloadLotteryResultFailure,
    resetLotteryResult
} = runLotterySlice.actions;
export default runLotterySlice.reducer;