import palette from "../../assets/themes/palette";

const style = {
    '.titleContainer': {
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'space-between',
        '& .SearchIcon': {
            width: '16px',
            height: '16px',
            '& path': {
                fill: '#343487',
            }
        },
        '& .MuiSelect-select': {
            padding: '8.5px 32px 10px 14px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#dcdcdd'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2E2E84 !important'
        }
    },
    '.bodyContainer': {
        padding: 20,
        paddingTop: 0
    },
    '.bodyInner': {
        // background: '#FFFFFF',
        // border: '1px solid #EBEBF3',
        borderRadius: '6px',
    },
    '& .card': {
        background: 'rgb(255, 255, 255)',
        boxShadow: 'rgba(0, 85, 129, 0.12) 0px 0px 16px',
        borderRadius: '6px',
        padding: '30px 20px',
        height: '100%',
        wordBreak: 'break-word',
        display: 'flex',
        alignItems: 'center',
    },
    '& .resource': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& img':{
            height: 80,
            marginBottom: 30,
            maxWidth: "100%",
            objectFit: "contain"
        }
    }

}
export default style;