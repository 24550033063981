import React, { memo } from "react";
import PropTypes from "prop-types";
import { styled, Button as Core } from "@mui/material";
import _ from "lodash";

// Imports Styles
import Styles from "./style";

const ButtonComponent = (props) => {
    //get props
    const {
        className,
        variant,
        fullWidth,
        size,
        ...rest
    } = props;

    return (
        <Core
            className={className}
            variant={variant}
            fullWidth={fullWidth}
            size={size}
            disableElevation
            {...rest}
        />
    );
};

// default props
ButtonComponent.defaultProps = {
    variant: "outlined",
    fullWidth: false,
    className: "",
    size: "small"
};

// prop types
ButtonComponent.propTypes = {
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.string
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const Button = memo(styled(ButtonComponent)(Styles), areEqual);
