//import constants
import { isEmpty, isEmail, isPassword, isZipCode, isPhoneNumber, isDate, isSSN, isNumber, isNjZipCode } from "."
import moment from "moment";

//import constants
import { commonConstants } from "../config/constants";

const validationFields = {
    login: ["email", "password"],
    forgetPassword: ["email"],
    signup: ["email", "first_name", "last_name"],
    resetPassword: ["password", "confirm_password"],
    PROPERTY_MANAGER: ["first_name", "last_name", "email", "company_name", "phone", "city", "state", "gender"],
    CITY_STAFF: ["first_name", "last_name", "email", "phone", "city", "state", "gender"],
}

/**check whether the field has value */
export const isRequired = (validate, value, message = commonConstants.FIELD_REQUIRED) => {
    return validate && isEmpty(value) ? message : null
}

/**check email is valid */
export const isEmailValid = (validate, value, message = commonConstants.EMAIL_INVALID) => {
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : !isEmail(value) ? message : null : null;
}

/**check password is satisfied with password criteria */
export const isPasswordValid = (validate, value, message = null) => {
    const isValid = isPassword(value)
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : isValid !== true ? isValid : null : null;
}

/**check password is satisfied with password criteria */
export const isPhoneValid = (validate, value, message = commonConstants.PHONE_INVALID) => {
    const isValid = isPhoneNumber(value)
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : isValid !== true ? message : null : null;
}

// ** zip code validatons */
export const isZipCodeValid = (validate, value, message = commonConstants.INVALID_ZIP) => {
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : !isZipCode(value) ? message : null : null;
}

// **NJ zip code validatons */
export const isNjZipCodeValid = (validate, value, message = commonConstants.INVALID_ZIP) => {
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : !isNjZipCode(value) ? message : null : null;
}

// ** date validatons */
export const isDateValid = (validate, value, message = commonConstants.FIELD_REQUIRED, disableFuture = false, disablePast = false) => {
    let validation = validate ? !isDate(value) ? message || commonConstants.INVALID_DATE : null : null
    if (validate && !validation && (disableFuture || disablePast)) {
        validation = disableFuture && value > moment().format("YYYY-MM-DD") ? "Please enter past date" : disablePast && value < moment().format("YYYY-MM-DD") ? "Please enter future date" : null
    }
    return validation;
}

// ** ssn validatons */
export const isSsnValid = (validate, value, message = commonConstants.INVALID_SSN) => {
    return validate ? (!value || value === "___-__-____") ? commonConstants.FIELD_REQUIRED : !isSSN(value) ? message : null : null;
}

export const isNumberValid = (validate, value, message = commonConstants.NUMBER_INVALID) => {
    return validate ? isEmpty(value) ? commonConstants.FIELD_REQUIRED : isNaN(value) === true || (value + "").includes("e") ? message : null : null
}

// password validation
export const ResetPasswordValidation = (params = {}) => {
    let errMsg = "";

    if (params.hasOwnProperty("old_password") && (params.confirm_password || params.password) && !params.old_password) {
        errMsg = commonConstants.OLD_PWD_REQ
    } else if (params.password && isPassword(params.password) !== true) {
        errMsg = isPassword(params.password)
    } else if (params.confirm_password && isPassword(params.confirm_password) !== true) {
        errMsg = isPassword(params.confirm_password)
    } else if ((params.confirm_password && !params.password) || (!params.confirm_password && params.password)) {
        errMsg = commonConstants.PWD_REQUIRED
    } else if (params.confirm_password && params.password && (params.confirm_password !== params.password)) {
        errMsg = commonConstants.PWD_NOT_MATCH
    }
    return errMsg;
}


/** profile validation*/
export const profileValidation = (params, formType) => {
    let errMessage = "";
    const validateFields = validationFields[formType] || [];

    validateFields.map((item) => {
        if (!errMessage) {
            if (isEmpty(params[item])) {
                errMessage = `Please fill required fields`;
            } else if (item === "email" && !isEmail(params[item])) {
                errMessage = commonConstants.EMAIL_INVALID;
            } else if (item.includes("phone") && isPhoneNumber(params[item]) !== true) {
                errMessage = commonConstants.PHONE_INVALID;
            } else if (item === "dob" && !isDate(params[item])) {
                errMessage = commonConstants.INVALID_DATE;
            } else if (item === "ssn" && !isSSN(params[item])) {
                errMessage = commonConstants.INVALID_SSN;
            } else if (item === "gender" && (params[item] + "" === "6") && !params["other_gender"]) {
                errMessage = "Please enter gender";
            }
        }
    })

    if (!errMessage && params.alt_phone && isPhoneNumber(params.alt_phone) !== true) {
        return commonConstants.PHONE_INVALID;
    }

    if (!errMessage) {
        return ResetPasswordValidation(params)
    }

    return errMessage;
}

/**
 * common validation
 */
export const commonValidation = (params, formType) => {
    let errMessage = "";
    const validateFields = validationFields[formType] || [];

    validateFields.map((item) => {
        if (!errMessage) {
            if (isEmpty(params[item])) {
                errMessage = commonConstants.FIELD_REQUIRED;
            } else if (item === "email" && !isEmail(params[item])) {
                errMessage = commonConstants.EMAIL_INVALID;
            } else if (item.includes("password") && isPassword(params[item]) !== true && formType !== "login") {
                errMessage = isPassword(params[item]);
            }
        }
    })
    return errMessage;
}

/**
 * properties validation
 */
export const propertiesValidation = (params, photos, approval_status = 1) => {
    let errMessage = "";
    const validateFields = approval_status === 0 ? ["address"] : ["desc", "address", "city_id", "state_id", "zipcode", "lat_long", "built_year", "offer_valid_at", "available_at", "application_date", "application_deadline", "lottery_date", "preference", "is_ada", "report_period", "ami_rmi"];
    const numberFields = ["zipcode"]
    const futureDates = ["available_at", "application_date", "application_deadline", "lottery_date"]
    const dateFields = ["available_at", "application_date", "application_deadline", "lottery_date", "built_year"]
    const pastDates = ["built_year"]
    const displayFields = {
        "desc": "Description",
        "address": "Address",
        "city_id": "City",
        "state_id": "State",
        "zipcode": "Zipcode",
        "lat_long": "Latitude and Longitude",
        "built_year": "Year built",
        "offer_valid_at": "Document submission period",
        "available_at": "Earliest move-in date",
        "application_date": "Application start date",
        "application_deadline": "Application end date",
        "lottery_date": "Lottery date",
        "preference": "Preference",
        "is_ada": "Ada",
        "report_period": "Report period",
        "ami_rmi": "Standard"
    }

    validateFields.map((item) => {
        if (!errMessage) {
            if (isEmpty(params[item])) {
                // date picker returns null if the date is typed insted of being selected in the datepicker
                if(dateFields.includes(item)){
                    errMessage = `${displayFields[item] || item} field should be a valid date`;
                }
                else{
                    errMessage = `${displayFields[item] || item} field is required`;
                }  
            } else if (item === "lat_long") {
                let latLong = params[item]
                if (!latLong || (latLong && (!latLong[0] || !latLong[1]))) {
                    errMessage = `${displayFields[item] || item} field is required`;
                }
            } else if (numberFields.includes(item) && !isNumber(params[item])) {
                errMessage = `${displayFields[item] || item} field should be valid number`;
            } else if (item == "zipcode" && !isNjZipCode(params[item])) {
                errMessage = `Zipcode should be a valid`
            } else if (params.id == null && futureDates.includes(item) && (params[item] < moment().format("YYYY-MM-DD"))) {
                errMessage = `${displayFields[item] || item} should be future date`
                
            } else if (item === "built_year") {
                const inputYear = moment(params[item]).year();
                if (inputYear === 0 ) {
                    errMessage = "Please enter a valid Year";
                } 


            } else if (pastDates.includes(item) && (params[item] > moment().format("YYYY-MM-DD"))) {
                errMessage = `${displayFields[item] || item} should be a past date`
            }

            // preference and ami_rmi
            if(!errMessage && ["preference","ami_rmi","report_period"].includes(item) && params[item]==="0"){
                errMessage = `${displayFields[item] || item} field is required`;
            }
        }
    })

    if ((params.application_date && params.application_deadline && new Date(params.application_deadline) <= new Date(params.application_date))) {
        errMessage = "Application deadline should be greater than application start date"
    }

    if ((params.lottery_date && params.application_deadline && new Date(params.lottery_date) <= new Date(params.application_deadline))) {
        errMessage = "Lottery date should be greater than application deadline date"
    }

    if ((params.available_at && params.lottery_date && new Date(params.available_at) <= new Date(params.lottery_date))) {
        errMessage = "Earliest move-in date should be greater than lottery date"
    }

    if (approval_status !== 0 && !errMessage && (photos && photos.length === 0)) {
        errMessage = "please add property photos"
    }
    return errMessage;
}

// units validation
export const unitsValidation = (params = [], unitPhotos = {}, approval_status = 1) => {
    let errMessage = "";
    const validateUnitFields = approval_status === 0 ? [] : ["unit_type", "unit_count", "min_household_size", "max_household_size"];
    const validateUnitDetailFields = approval_status === 0 ? [] : ["unit_no", "affordability_level", "min_ami", "max_ami", "min_rent_amt", "max_rent_amt", "lease_length", "sqft", "household_min_income", "household_max_income"];
    const numberFields = ["min_household_size", "max_household_size", "unit_count", "lease_length", "sqft", "min_rent_amt", "max_rent_amt", "household_min_income", "household_max_income"];
    const displayFields = {
        "unit_type": "Unit Type",
        "unit_count": "Unit Count",
        "min_household_size": "Minimum Household Size",
        "max_household_size": "Maximum Household Size",
        "unit_no": "Unit Number",
        "affordability_level": "Affordability Level",
        "min_ami": "Minimum AMI",
        "max_ami": "Maximum AMI",
        "min_rent_amt": "Minimum Rent Amount",
        "max_rent_amt": "Maximum Rent Amount",
        "lease_length": "Lease Length",
        "sqft": "Square Feet",
        "household_min_income": "Minimum Household Income",
        "household_max_income": "Maximum Household Income",
    }

    if (approval_status !== 0 && params.length === 0) {
        errMessage = `Please enter atleast one unit`;
    }

    params.forEach((itm, index) => {
        if (!errMessage) {
            validateUnitFields.forEach((item) => {
                if (!errMessage) {
                    if (isEmpty(itm[item])) {
                        errMessage = `${displayFields[item]} field is required`;
                    } else if (numberFields.includes(item) && !isNumber(itm[item])) {
                        errMessage = `${displayFields[item]} field should be valid number`;
                    }
                }
            });

            const unitDetails = itm.unitDetails || [];

            if (unitDetails.length === 0) {
                errMessage = `Please enter unit details`;
            }

            unitDetails.forEach((itm1, index1) => {
                if (!errMessage) {
                    validateUnitDetailFields.forEach((item1) => {
                        if (!errMessage) {
                            if (isEmpty(itm1[item1])) {
                                errMessage = `${displayFields[item1]} field is required`;
                            } else if (numberFields.includes(item1) && !isNumber(itm1[item1])) {
                                errMessage = `${displayFields[item1]} field should be valid number`;
                            }
                        }
                    })
                }

                if (approval_status !== 0 && !errMessage) {
                    const unitIndex = `${index}_${index1}`
                    if (!unitPhotos[unitIndex] || (unitPhotos[unitIndex] && unitPhotos[unitIndex].length === 0)) {
                        errMessage = "Please add photos for each units"
                    }
                }
            })
            unitDetails.forEach((itm1, index1) => {
                if (!errMessage) {
                    if (itm1.min_rent_amt && itm1.max_rent_amt && (parseFloat(itm1.min_rent_amt) >= parseFloat(itm1.max_rent_amt))) {
                        errMessage = "Maximum rent amount should be greater than minimum rent amount"
                    }
                    else if (itm1.household_min_income && itm1.household_max_income && (parseFloat(itm1.household_min_income) >= parseFloat(itm1.household_max_income))) {
                        errMessage = "Maximum household income should be greater than minimum household income"
                    }
                }
            })
        }
    })
    return errMessage;
}