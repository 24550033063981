
import palette from '../../assets/themes/palette';

const style = theme => ({
  '&.SUCCESS': {
    '& .MuiSnackbarContent-root': {
      backgroundColor: `#4caf50 !important`,
      fontWeight: "500"
    },
  },
  '&.ERROR': {
    '& .MuiSnackbarContent-root': {
      backgroundColor: `#d32f2f !important`,
      fontWeight: "500"
    },
  },
//   ALERT_INFO: {
//     backgroundColor: palette.primary.light,
//     fontWeight: "500"
//   },
//   ALERT_WARNING: {
//     backgroundColor: palette.greyshades.main,
//     fontWeight: "500"
//   },
//   ALERT_CLOSE: {
//     padding: theme.spacing(0.5)
//   }
});

export default style;
