import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    pmFilterList: [],
    selectedPMFilter: []
}

const globalFilterSlice = createSlice({
    name: 'globalFilter',
    initialState: initialState,
    reducers: {
        // update property manager filters
        updatePMFilter(state, action) {
            state.selectedPMFilter = action.payload
        }
    }
})

export const {
    getPMFilterListRequest, getPMFilterListSuccess, getPMFilterListFailure,
    updatePMFilter
} = globalFilterSlice.actions;
export default globalFilterSlice.reducer;