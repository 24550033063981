import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

//style
import Style from './style'

// components
import { Avatar } from "../../components"

// reducer
import { webNotificationListRequest, markAsReadNotificationRequest } from "../../redux/reducer/webNotificationReducer";

// helpers
import { gets3URL, globalDate } from "../../helpers";

function NotificationList(props) {
    //define navigate
    const navigate = useNavigate()

    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className, notificationType } = props;

    // reducer
    const { webNotificationList: { today, older }, newWebNotification } = useSelector(s => (s.webNotification));

    useEffect(() => {
        dispatch(webNotificationListRequest({ notificationType: notificationType }))
    }, [dispatch, newWebNotification])

    const handleNotificationClick = (item, type) => {
        // make notification as read
        dispatch(markAsReadNotificationRequest({ id: item.id, type: type }))

        // navigate if available
        if (item.navigation) {
            navigate(item.navigation)
        }
    }

    const getMessageList = (data, type) => {
        return (
            <>
                {(data && data.length) ?
                    <>
                        {data.map((item) => (
                            <Grid item xs={12} key={item.name} className={className} onClick={() => { handleNotificationClick(item, type) }}>
                                <Grid className={`${item.is_read === false ? 'newItem' : ''} notificationCard`}>
                                    <Grid className="notificationAlert">
                                        <Avatar src={gets3URL(item.profile_photo)} alt={item.title} />
                                    </Grid>
                                    <Grid className="pl15 mw-100">
                                        <Typography variant="body1" className="fileName fs-18" noWrap>
                                            {item.title}
                                            {item.is_read === false && <sup>New</sup>}
                                        </Typography>
                                        <Typography variant="body1" color={"primary"} className="notificationTime">
                                            {globalDate(item.created_at)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                    :
                    <Grid sx={{ height: 70 }} className="dflex alignCenter justifyCenter">
                        <Typography variant='body1' sx={{ color: '#999' }}>No notifications found</Typography>
                    </Grid>
                }
            </>)
    }

    return (
        <>
            <Grid className='headerTitle'>
                <Typography variant='body1' color={"textSecondary"}>TODAY</Typography>
            </Grid>
            {getMessageList(today, 'today')}
            <Grid className='headerTitle'>
                <Typography variant='body1' color={"textSecondary"}>OLDER</Typography>
            </Grid>
            {getMessageList(older, 'older')}
        </>
    );
}

// default props
NotificationList.defaultProps = {
    classes: {},
    notificationType: 0
};

// prop types
NotificationList.propTypes = {
    classes: PropTypes.object,
    notificationType: PropTypes.any
};

export default styled(NotificationList)(Style);