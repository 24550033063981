import moment from "moment";

// get logggedin user details
export const getLoggedInUser = () => {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null
}

//Set Token
export const setToken = (key, value) => {
    localStorage.setItem(key, value);
};

//Get Token
export const getToken = (key) => {
    try{
        return (localStorage.getItem(key) || null);
    }catch(err){
        return null
    }
};

// Remove Token
export const removeToken = (key) => {
    localStorage.removeItem(key);
};

// is empty
export const isEmpty = (input) => {
    return (input === 0 || input )  ? typeof input === 'string' ? !Boolean(input.trim()) : false : true;
}

// Email validation
export const isEmail = (input) => {
    if (input) {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = regex.test(String(input).toLowerCase());
        return result ? true : false
    } else {
        return false
    }
}

// zip code validation
export const isZipCode = (input) => {
    if (input) {
        const regex = /^[0-9]{5}$/;
        const result = regex.test(input);
        return result ? true : false
    } else {
        return false
    }
}

// New jersey zipcode validation
export const isNjZipCode = (input) => {
    if (input) {
        const regex = /^073[0-9]{2}$/;
        const result = regex.test(input);
        return result ? true : false
    } else {
        return false
    }
}

// phone number validation
export function isPhoneNumber(val) {
    if (val) {
        // const regex = /^\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^\d{10}$|^1\s\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^(1\s?)?\(\d{3}\)(\s|\-)?\d{3}\-\d{4}$/;
        const regex = /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        const result = regex.test(String(val).toLowerCase());
        return result;
    }
    return false;
}

// password validation
export const isPassword = (value = "") => {
    if (value.length < 8) {
        return "password length should be greater than 8";
    } else if (value.length > 50) {
        return "password length should be less than 50";
    } else if (value.search(/[!@#$^&*()_]/) === -1) {
        return "password should contain atleast one special character";
    } else if (value.search(/[a-z]/) === -1) {
        return "password should contain one lower case";
    } else if (value.search(/\d/) === -1) {
        return "password should contain one number";
    } else if (value.search(/[A-Z]/) === -1) {
        return "password should contain one upper case";
    } else { return true; }
}

//date validation
export const isDate = (value) => {
    return (!value || (value && new Date(value) == "Invalid Date") ? false : true)
}

//SSN validation
export const isSSN = (value) => {
    return (value && value.indexOf("_") === -1 ? true : false)
}

//Number validation
export const isNumber = (value) => {
    return (value && ((isNaN(value) === true) || (value + "").includes("e") || (Number(value) < 0)) ? false : true)
}

// convert email to lower case
export const emailToLower = (email) => {
    return email ? email.toLowerCase() : ""
}

// recursive function to de parse the error messages
export const getErrorMessageRecursion = (message = "") => {
    let msg = ""
    if (!message || typeof message === "string") {
        msg = message
    } else if (message.constructor === Object) {
        const keys = Object.keys(message)
        if (keys.length) {
            msg = getErrorMessageRecursion(message[keys[0]])
        } else {
            msg = getErrorMessageRecursion("")
        }
    } else if (message.constructor === Array) {
        if (message.length) {
            msg = getErrorMessageRecursion(message[0])
        } else {
            msg = getErrorMessageRecursion("")
        }
    }
    return msg;
}

/** generate form builder data */
export function buildFormData(formData, data, parentKey, preserveEmpty = false) {
    if (data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key, preserveEmpty);
        });
    } else {
        const value = data == null ? "" : data;
        if (!preserveEmpty && value !== "") {
            formData.append(parentKey, value);
        }
        else if(preserveEmpty){
            formData.append(parentKey, value)
        }
    }
    return formData;
}

// get user Group
export const getUserType = (user_group_id) => {
    switch (user_group_id) {
        case 1:
            return "APPLICANT"
        case 2:
            return "PROPERTY_MANAGER"
        case 3:
            return "CITY_STAFF"
        default:
            return "GUEST"
    }
}

// get s3 url
export const gets3URL = (url) => {
    return url ? `${process.env.REACT_APP_S3_URL}${url}` : "/static/images/avatar/1.jpg"
}

// get image object url
export const getImage = (input) => {
    try {
        if (input) {
            return URL.createObjectURL(input);
        } else {
            return ""
        }
    } catch (err) {
        return ""
    }
}

// json parser
export const jsonParser = (data) => {
    try {
        return JSON.parse(JSON.stringify(data))
    } catch (err) {
        return data
    }
}

export const getFileName = (file) => {
    if (typeof file == "object") {
        if (file.name) {
            return file.name
        } else if (file.document) {
            const doc = file.document.split("/")
            return doc[doc.length - 1]
        } else {
            return ""
        }
    } else if (file) {
        file = file.split("/")
        return file[file.length - 1]
    } else {
        return ""
    }
}
// Applicant property slug url
export const getPropertiesSlugUrl = (data) => {
    return `${process.env.REACT_APP_APPLICANT_URL}property/${data}?copy=true`
}

export function formatBytesToSize(bytes) {
    if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
        bytes = bytes + " bytes";
    } else if (bytes === 1) {
        bytes = bytes + " byte";
    } else {
        bytes = "0 bytes";
    }
    return bytes;
}

export const getNotificationData = (event) => {
    try {
        const data = event.data;
        return JSON.parse(data)
    } catch (err) {
        return null
    }
}

// date formatter
export const globalDate = (date) => {
    return date ? moment(date).utc().local().format("LLL") : ""
}

// adding date to file to make it unique
export const getFileNameWithTime = (file) => {
    try {
        const lastDot = file.name.lastIndexOf('.');
        const fileName = file.name.substring(0, lastDot);
        const ext = file.name.substring(lastDot + 1);
        return `${fileName}_${new Date().getTime()}.${ext}`
    } catch (err) {
        return file.name
    }
}

//check whehther the filter is applied
export const hasFilter = (filterObj = {}) => {
    try {
        let filterAppplied = false
        for (let obj in filterObj) {
            if (Array.isArray(filterObj[obj]) && filterObj[obj].length) {
                filterAppplied = true
            } else if (filterObj[obj] && typeof filterObj[obj] === "string") {
                filterAppplied = true
            }
        }
        return filterAppplied
    } catch (err) {
        return false
    }
}

//Set session
export const setSession = (key, value) => {
    try {
        sessionStorage.setItem(key, value);
    } catch (err) {
        console.log(err)
    }
};

//Get session
export const getSession = (key) => {
    try {
        return (sessionStorage.getItem(key) || null);
    } catch (err) {
        return false
    }
};

//Remove session
export const removeSession = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (err) {
        console.log(err)
    }
};

// convert phone number to US format
export const convertPhoneformat = (phone) =>{
    try{
        if(phone){
            let convertPhone = "(" + phone.substring(0,3) + ") " + phone.substring(3,6) + "-" + phone.substring(6,10)
            return convertPhone
        }
        return null
    }
    catch(err){
        console.log(err)
    }
}