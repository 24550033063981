import * as React from 'react';
import { Grid, Popover as Core } from '@mui/material';
import PropTypes from 'prop-types';

export default function Popover(props) {

    /**
     * Define Props
     */
    const { anchorEl, onClose, children, className, ...rest } = props;

    /**
     * Define Values
     */
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Core
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            className={className}
            anchorOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }
            {...rest}
        >
            <Grid className="p-3">
                {children}
            </Grid>
        </Core>
    );
}

// default props
Popover.defaultProps = {
    anchorEl: {},
    onClose: () => { },
    children: "",
    className: ""
};

// prop types
Popover.propTypes = {
    anchorEl: PropTypes.object,
    onClose: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};