
import React from "react";
import { ThemeProvider } from "@mui/material/styles";

// import themes
import theme from "../assets/themes";

// import routes
import Routes from "./routes";

// components
import { SnackBar, Loader } from "../components";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes />
        <SnackBar sx={{ background: '#2E2E84' }} />
        <Loader />
      </ThemeProvider>
    </>
  );
}

export default App;