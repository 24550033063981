import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import 'moment-timezone'

//style
import Styles from './style'

//Components
import { Dialog, FilterPanel, GroupingSelect, MultiSelectAsync } from "../../components";
import { Typography } from "../../components/typography";
import FilterDrawer from "./filterDrawer";

//reducer
import { updatePropertiesViewType, downloadPropertiesRequest, updatePropertiesFilter, getPropertiesDetailRequest, getPropertiesLeftPanelListRequest, getWelcomePageRequest, resetPropertyData, getPropertiesUnitsRequest, getPropertiesPhotosRequest, getPropertiesAmenitiesRequest, clearPropertiesFilter } from "../../redux/reducer/propertiesReducer";

//service
import { globalFilterService } from "../../redux/service";

//helpers
import { propertiesPanelViewSort, propertiesListViewSort, hasFilter } from "../../helpers";

const TopPanel = (props) => {
    // define dispatch
    const dispatch = useDispatch()

    // navigator
    const navigate = useNavigate();
    const { pathname } = useLocation()

    // props
    const { IconsList, handleSorting, sortValue, handleFilter } = props;

    //redux
    const {
        welcomeData, viewType, panelView: { data, pagination, pagination: { count } }, currentProperty: { general: propertiesDetail }, filter, tmpfilter,
        isDetailEdited, isUnitsEdited, hasGeneralChanges, hasAmenityChanges, hasPhotosChange, hasUnitChanges, hasUnitPhotosChanges, clearfilter
    } = useSelector(s => (s.properties))
    const { loggedUser } = useSelector(s => (s.profile))

    // state
    const [openFilter, setOpenFilter] = useState(false);
    const [editAlert, setEditAlert] = useState(false);

    // variable
    const isCityStaff = loggedUser.user_group_id === 3;
    const isPM = loggedUser.user_group_id === 2;
    const hasFieldChanges = ((isDetailEdited && (hasGeneralChanges || hasAmenityChanges || hasPhotosChange)) || (isUnitsEdited && (hasUnitChanges || hasUnitPhotosChanges)))
    const tz = moment.tz.guess()

    useEffect(() => {
        // get property manager approval status
        dispatch(getWelcomePageRequest())
    }, [dispatch])

    // redirect to welcome page if count = 0 
    useEffect(() => {
        if (isPM && welcomeData && welcomeData.count === 0) {
            navigate("/properties/welcome")
        }
    }, [dispatch, welcomeData, loggedUser])

    // handle page changes
    useEffect(() => {
        if (viewType === "list" && pathname === "/properties") {
            navigate(`/properties/list`)
        } else if (viewType === "panel" && pathname === "/properties/list") {
            navigate(`/properties`)
        }
    }, [dispatch, pathname])

       // call api whenever clear filter is triggered
       useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
        }, [clearfilter])

    // global function
    const getPropertyDetails = (id) => {
        dispatch(getPropertiesDetailRequest(id))
        dispatch(getPropertiesUnitsRequest(id))
        dispatch(getPropertiesPhotosRequest(id))
        dispatch(getPropertiesAmenitiesRequest(id))
    }

    //Previous
    const handlePrevious = () => {
        const currentPropertyIndex = data.findIndex(f => (f.id === propertiesDetail.id))
        if (currentPropertyIndex > 0) {
            getPropertyDetails(data[currentPropertyIndex - 1])
        }
    };

    //Next
    const handleNext = () => {
        const currentPropertyIndex = data.findIndex(f => (f.id === propertiesDetail.id))
        if (currentPropertyIndex < data.length - 1) {
            getPropertyDetails(data[currentPropertyIndex + 1])
        }
        if ((currentPropertyIndex === data.length - 2) && (data.length < count)) {
            dispatch(getPropertiesLeftPanelListRequest({ ...pagination, filter: filter }))
        }
    };

    //update view type
    const updateView = (value) => {
        dispatch(updatePropertiesViewType(value))
        if (value === "panel") {
            navigate(`/properties`)
        } else {
            navigate(`/properties/list`)
        }
    }

    const getPropertyManagerFilter = async (value) => {
        const response = await globalFilterService.getPMFilterList({ name: value || "" })
        return response
    }

    const getStatusFilter = async (value) => {
        const response = await globalFilterService.getStatusFilterList({ approval_status: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getAddressFilter = async (value) => {
        const response = await globalFilterService.getAddressFilterList({ address: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    return (
        <>
            <FilterPanel
                IconsList={IconsList}
                viewType={viewType}
                setViewType={(value) => { if (value !== null) { updateView(value) } }}
                handleAdd={() => { dispatch(resetPropertyData()); navigate(`/properties/create`) }}
                handleFilter={(event) => setOpenFilter(event.currentTarget)}
                isFilterApplied = {hasFilter(tmpfilter)}
                handleDownload={() => { dispatch(downloadPropertiesRequest({ ...pagination, filter: filter, timezone: tz })) }}
                handlePrevious={() => { hasFieldChanges ? setEditAlert("PREV") : handlePrevious() }}
                handleNext={() => { hasFieldChanges ? setEditAlert("NEXT") : handleNext() }}
                handleClearFilter={() => { dispatch(clearPropertiesFilter()) }}
                downloadDisabled={count === 0}
            >
                <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                    <Grid item xs={3} className="pt-0">
                        <MultiSelectAsync
                            customWidth
                            variant={"outlined"}
                            id={"addressFilter"}
                            name={"addressFilter"}
                            placeholder={"Property Address or Name"}
                            className={`sm`}
                            apiCallback={getAddressFilter}
                            value={filter.addressFilter}
                            handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                            initialValue={filter.addressFilter}
                            matchBy={"id"}
                            display={"address_string"}
                            limitTags={1}
                            handleClose={() => { handleFilter() }}
                        />
                    </Grid>
                    {isCityStaff && <Grid item xs={3} className="pt-0">
                        <MultiSelectAsync
                            variant={"outlined"}
                            id={"managerFilter"}
                            name={"managerFilter"}
                            placeholder={"Property Manager"}
                            className={`sm`}
                            apiCallback={getPropertyManagerFilter}
                            value={filter.managerFilter}
                            handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                            initialValue={filter.managerFilter}
                            matchBy={"id"}
                            display={"name"}
                            limitTags={1}
                            handleClose={() => { handleFilter() }}
                        />
                    </Grid>}
                    <Grid item xs={3} className="pt-0">
                        <MultiSelectAsync
                            variant={"outlined"}
                            id={"statusFilter"}
                            name={"statusFilter"}
                            placeholder={"Status"}
                            className={`sm`}
                            apiCallback={getStatusFilter}
                            value={filter.statusFilter}
                            handleChange={(e) => { dispatch(updatePropertiesFilter(e)) }}
                            initialValue={filter.statusFilter}
                            matchBy={"id"}
                            display={"ap_status"}
                            limitTags={1}
                            handleClose={() => { handleFilter() }}
                        />
                    </Grid>

                    <Grid item xs={3} className="pt-0 selectBox">
                        <GroupingSelect
                            value={sortValue}
                            handleSorting={handleSorting}
                            placeholder={"sort by"}
                            options={viewType === "panel" ? propertiesPanelViewSort : propertiesListViewSort}
                        />
                    </Grid>
                </Grid>
                {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}
            </FilterPanel>
            {editAlert &&
                <Dialog
                    open={editAlert}
                    handleDialogClose={() => { setEditAlert(false); }}
                    confirmHandle={() => {
                        if (editAlert === "PREV") {
                            handlePrevious()
                            setEditAlert(false);
                        } else {
                            handleNext()
                            setEditAlert(false);
                        }
                    }}
                    successButton={"Yes"}
                    failButton={"Cancel"}
                >
                    <Typography variant='h6'>All your changes will be lost, Are you sure you want to proceed?</Typography>
                </Dialog>
            }
        </>
    );
}

// default props
TopPanel.defaultProps = {
    classes: {},
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: "",
};

// prop types
TopPanel.propTypes = {
    classes: PropTypes.object,
    IconsList: PropTypes.array,
    handleFilter: PropTypes.func,
    handleSorting: PropTypes.func,
    sortValue: PropTypes.string,
};

export default styled(TopPanel)(Styles);