const styles = {
    '& .galleryContainer': {
        padding: '30px 0px 40px 40px',
        '& img': {
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            borderRadius: '10px'
        },
        '& .galleryBody': {
            maxHeight: 'calc(100vh - 168px)',
            overflowY: 'auto',
            paddingRight: 40,
            paddingTop: '0px',
            alignContent: 'baseline'
        }
    },
    '.galleryItem': {
        position: 'relative',
        '&:hover .deletImage': {
            visibility: 'visible',
            opacity: 1,
            transition: 'all 0.5s'
        }
    },
    '.deletImage': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.5s',
        position: 'absolute',
        right: '15px',
        top: '43px',
        background: '#00000066',
        padding: '2px',
        '& svg': {
            fill: '#fff'
        }
    },
    '.': {
        background: '#E5E5E5',
        color: '#343487'
    }
}

export default styles;