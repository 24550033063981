import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { AppBar as Core, Badge, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

// Imports Styles
import Style from "./style";

//images
import Logo from '../../assets/images/svg/logo.svg';
import { CityPolicy, HelpIcon, LogoutIcon, MyProfileIcon, NotificationBellIcon } from '../../assets/svg';

//Components
import { MenuTabs } from './menu';
import { Dialog } from '../dialog';
import { Avatar } from '../avatar';

// helpers
import { gets3URL } from '../../helpers';

const AppBarComponent = (props) => {
    // navigator
    const navigate = useNavigate()

    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className, onLogout, loggedUser } = props;

    // reducer
    const { newWebNotification } = useSelector(s => (s.webNotification))

    // state
    const [profileMenu, setProfileMenu] = useState(null);
    const [helpMenu, setHelpMenu] = useState(null);
    const [logoutPopup, setLogoutPopup] = useState(false);


    // handle profile menu close
    const handleMenuClose = () => {
        setProfileMenu(null);
    };

    //handle logout popup
    const hanleConfirm = () => {
        setLogoutPopup(!logoutPopup);
        handleMenuClose();
    };

    const myUrl = process.env.REACT_APP_S3_URL;
    const path = `${myUrl}assets/`

    return (
        <Core className={`${className} headerBar`}>
            <Grid container justifyContent={"space-between"} className="widthFit h-100">
                <Grid className='leftSection'>
                    <img src={Logo} alt="Logo Image" className='logoImg' />
                    <MenuTabs loggedUser={loggedUser} />
                </Grid>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip arrow title="Help">
                        <IconButton className='menuOption' color="inherit" aria-label="Help" onClick={()=>{window.open(`${path}Portal+Guide+for+PM+_V2+(06.06.24).pdf`, "_blank")}}>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="Notification">
                        <IconButton
                            className='menuOption'
                            aria-label="show notifications"
                            color="inherit"
                            onClick={() => { navigate('/notification') }}
                        >
                            <Badge color="secondary" invisible={newWebNotification ? false : true} variant="dot">
                                <NotificationBellIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Grid tabIndex="0"
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                setProfileMenu(event.currentTarget);
                            }
                        }}>
                        <Avatar
                            alt={loggedUser.first_name}
                            onClick={(e) => { setProfileMenu(e.currentTarget) }}
                            src={gets3URL(loggedUser.profile_photo)}
                            sx={{ width: 34, height: 34 }}
                        />
                    </Grid>
                </Box>
            </Grid>
            <Menu
                anchorEl={profileMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(profileMenu)}
                onClose={handleMenuClose}
                className={`${className} linkMenu`}
            >
                <MenuItem onClick={() => { navigate("/profile"); setProfileMenu(null) }}>
                    <ListItemIcon>
                        <MyProfileIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
                {loggedUser.user_group_id === 3 &&
                    <MenuItem onClick={() => { navigate("/obligation"); setProfileMenu(null) }}>
                        <ListItemIcon>
                            <CityPolicy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Obligation & City Policy</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => { hanleConfirm() }}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                open={logoutPopup}
                handleDialogClose={() => { hanleConfirm() }}
                confirmHandle={() => { onLogout() }}
            >
                <Typography variant='h6'>Are you sure, you want to Logout?</Typography>
            </Dialog>
        </Core>
    )
}

// default props
AppBarComponent.defaultProps = {
    className: "",
    onLogout: () => { },
    loggedUser: {}
};

// prop types
AppBarComponent.propTypes = {
    className: PropTypes.string,
    loggedUser: PropTypes.object,
    onLogout: PropTypes.func
};

// export component
export const AppBar = styled(AppBarComponent)(Style);
