import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'

//Components
import { FilterPanel, MultiSelectAsync, GroupingSelect } from "../../components";
import FilterDrawer from "./filterDrawer";

// reducer
import {
    updateTenantsFilter, updateTenantsViewType, getTenantsDetailRequest,
    getTenantsLeftPanelListRequest, downloadTenantsRequest, clearTenantsFilter
} from "../../redux/reducer/tenantsReducer";

// service
import { globalFilterService } from "../../redux/service";

// helpers
import { applicationsListViewSort, applicationsPanelViewSort, hasFilter } from "../../helpers";

const TopPanel = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { IconsList, handleFilter, handleSorting, sortValue } = props;

    // navigator
    const navigate = useNavigate();
    const { pathname } = useLocation()

    // redux
    const { viewType, panelView: { data, pagination, pagination: { count } }, tenantDetail: { details: tenantDetail }, filter, clearfilter } = useSelector(s => s.tenants)
    const { loggedUser } = useSelector(s => (s.profile))

    // state
    const [openFilter, setOpenFilter] = useState(false)

    //variable
    const isCityStaff = loggedUser.user_group_id === 3;

    // handle page changes
    useEffect(() => {
        if (viewType === "list" && pathname === "/tenants") {
            navigate(`/tenants/list`)
        } else if (viewType === "panel" && pathname === "/tenants/list") {
            navigate(`/tenants`)
        }
    }, [dispatch, pathname])

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
    }, [clearfilter])

    //Previous
    const handlePrevious = () => {
        const currentApplicationIndex = data.findIndex(f => (f.id === tenantDetail.id))
        if (currentApplicationIndex > 0) {
            dispatch(getTenantsDetailRequest(data[currentApplicationIndex - 1]))
        }
    };

    //Next
    const handleNext = () => {
        const currentApplicationIndex = data.findIndex(f => (f.id === tenantDetail.id))
        if (currentApplicationIndex < data.length - 1) {
            dispatch(getTenantsDetailRequest(data[currentApplicationIndex + 1]))
        }
        if ((currentApplicationIndex === data.length - 2) && (data.length < count)) {
            dispatch(getTenantsLeftPanelListRequest({ ...pagination, filter: filter }))
        }
    };

    const updateView = (value) => {
        dispatch(updateTenantsViewType(value))
        if (value === "panel") {
            navigate(`/tenants`)
        } else {
            navigate(`/tenants/list`)
        }
    }

    const getUserFilter = async (value) => {
        const response = await globalFilterService.getApplicantsFilterList({ name: value || "", user_id: isCityStaff ? "" : loggedUser.id, tenants: true })
        return response
    }

    const getAddressFilter = async (value) => {
        const response = await globalFilterService.getTenantsAddressFilterList({ address: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getStatusFilter = async (value) => {
        const response = await globalFilterService.getTenantsStatusFilterList({ approval_status: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    return (
        <FilterPanel
            IconsList={IconsList}
            viewType={viewType}
            isFilterApplied={hasFilter(filter)}
            setViewType={(value) => { if (value !== null) { updateView(value) } }}
            handleFilter={(event) => { setOpenFilter(event.currentTarget) }}
            handleDownload={() => { dispatch(downloadTenantsRequest({ ...pagination, filter: filter })) }}
            handlePrevious={() => { handlePrevious() }}
            handleNext={() => { handleNext() }}
            handleClearFilter={() => { dispatch(clearTenantsFilter()) }}
            downloadDisabled={count===0}
        >
            <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"userFilter"}
                        name={"userFilter"}
                        placeholder={"Tenants"}
                        className={`sm`}
                        apiCallback={getUserFilter}
                        value={filter.userFilter}
                        handleChange={(e) => { dispatch(updateTenantsFilter(e)) }}
                        initialValue={filter.userFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        customWidth
                        variant={"outlined"}
                        id={"addressFilter"}
                        name={"addressFilter"}
                        placeholder={"Property Address or Name"}
                        className={`sm`}
                        apiCallback={getAddressFilter}
                        value={filter.addressFilter}
                        handleChange={(e) => { dispatch(updateTenantsFilter(e)) }}
                        initialValue={filter.addressFilter}
                        matchBy={"id"}
                        display={"address_string"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"statusFilter"}
                        name={"statusFilter"}
                        placeholder={"Status"}
                        className={`sm`}
                        apiCallback={getStatusFilter}
                        value={filter.statusFilter}
                        handleChange={(e) => { dispatch(updateTenantsFilter(e)) }}
                        initialValue={filter.statusFilter}
                        matchBy={"id"}
                        display={"ap_status"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0 selectBox">
                    <GroupingSelect
                        value={sortValue}
                        handleSorting={handleSorting}
                        placeholder={"sort by"}
                        options={viewType === "panel" ? applicationsPanelViewSort : applicationsListViewSort}
                    />
                </Grid>
            </Grid>
            {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}
        </FilterPanel>
    );
}

// default props
TopPanel.defaultProps = {
    classes: {},
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: ""
};

// prop types
TopPanel.propTypes = {
    classes: PropTypes.object,
    handleFilter: PropTypes.func
};

export default styled(TopPanel)(Styles);