import palette from "../../assets/themes/palette";

const Style = {
    '& .clickableLink': {
        cursor: "pointer",
        '& .MuiTypography-root': {
            fontSize: '15px',
            fontWeight: '500',
            color: '#343487'
        }
    },
    '& .MuiAvatar-root': {
        height: '28px',
        width: '28px',
        fontSize: '14px',
        marginRight: 8
    },
    '.tableBody': {
        '& .MuiTableCell-body': {
            // padding: '8px 16px'
        }
    },
    '& .statusChip': {

        height: '26px'
    },
    //  (0, "Draft")
    '& .approval_status_0': {
        background: palette.colorShades.lightGreyBg,
        padding: '3px 14px',
        borderRadius: '6px',
    },
    // (1, "Review Pending"),, (12, "Application Withdrawn"),
    '& .approval_status_1, .approval_status_12': {
        background: palette.colorShades.yellowBg,
        padding: '3px 14px',
        borderRadius: '6px',
    },
    // (2, "Pre-Approved"), // (4, "Waiting List"), (6, "Document Verification"), (10, "Tenant"), (5, "Document Requested")
    '& .approval_status_2, .approval_status_4, .approval_status_6, .approval_status_10, .approval_status_5': {
        background: palette.colorShades.blueBg,
        color: palette.colorShades.blue,
        padding: '3px 14px',
        borderRadius: '6px',
    },
    // (3, "Application Rejected"), (8, "Document Rejected"), (11, "Offer Denied"), (13, "Offer Declined"),  (14, "Application Rejected after lottery"),
    '& .approval_status_3, .approval_status_8, .approval_status_11, .approval_status_13, .approval_status_14': {
        color: palette.colorShades.red,
        background: palette.colorShades.redBg,
        padding: '3px 14px',
        borderRadius: '6px',
    },
    // (7, "Document Approved"),  (9, "Offered"),
    '& .approval_status_7, .approval_status_9': {
        background: palette.colorShades.greenBg,
        color: palette.colorShades.green,
        padding: '3px 14px',
        borderRadius: '6px',
    },
};
export default Style;