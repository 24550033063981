import React from "react";
import PropTypes from "prop-types";
import {
    Table as Tbl, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Grid, Pagination, Typography, Checkbox, IconButton, Tooltip, styled
} from '@mui/material';
import { DeleteOutline } from "@mui/icons-material";

// components
import { Avatar } from "../avatar";
import Style from "./style";

// images
import { DoubleTick, DoubleTickCross, DownloadIcon, OfferHome, OfferDeclined, UploadIcon, SendIcon } from "../../assets/svg";

// helpers
import { gets3URL, globalDate } from "../../helpers";

const TableComponent = (props) => {

    // get props
    const { header, data, fields, linkFields, handleLinkClick, skip, updatePage, totalCount,
        limit, showpagination, showCheckBox, componentMap, checkedValues, handleCheckboxClick,
        actions, handleActionClick, showHideIcon, showAvatar, className, showHeaderCheckbox,
        clipName
    } = props;

    // render table header
    const tableHeader = () => (
        <TableRow>
            {header && header.map((i, ix) => (
                <TableCell key={`tb-head-${ix}`}>
                    <Grid
                        className="alignCenter dflex"
                        sx={{ '& .MuiCheckbox-root': { padding: '0px', marginRight: '10px' } }}
                    >
                        {(ix === 0 && showHeaderCheckbox) &&
                            <Checkbox
                                checked={checkedValues.length === data.length}
                                onClick={() => { handleCheckboxClick(data,true) }}
                            />
                        }
                        {i}
                    </Grid>
                </TableCell>
            ))}
            {(actions && actions.length > 0) && <TableCell key={`tb-head-asdc`} sx={{textAlign: 'center'}}>Actions</TableCell>}
        </TableRow>
    )

    const renderComponentMap = (CellComponent, item, index, ix) => {
        return (
            <TableCell key={`tb-row-${index}-${ix}`}>
                <CellComponent {...item} />
            </TableCell>
        )
    }

    const getActionIcon = (action, isDisabled) => {
        const iconsList = {
            "DOWNLOAD": { icon: DownloadIcon, title: "Download" },
            "APPROVE": { icon: DoubleTick, title: "Approve" },
            "REJECT": { icon: DoubleTickCross, title: "Reject" },
            "OFFER": { icon: OfferHome, title: "Offer" },
            "MOVE_TO_WAITINGLIST": { icon: OfferDeclined, title: "Move to waiting list" },
            "DEFAULT": { icon: DeleteOutline, title: "Delete" },
            "UPLOAD": { icon: UploadIcon, title: "Upload"},
            "SEND": { icon: SendIcon, title: "Send"},
        }
        const Component = (iconsList[action] && iconsList[action]["icon"]) || (iconsList["DEFAULT"] && iconsList["DEFAULT"]["icon"])
        return <Tooltip arrow aria-label={iconsList[action] && iconsList[action]["title"] ? iconsList[action]["title"] : ''} title={iconsList[action] && iconsList[action]["title"] ? iconsList[action]["title"] : ''}><Grid sx={{ width: 24, height: 24 }}><Component fill={isDisabled ? "gray" : ""} /></Grid></Tooltip>
    }

    const ActionsList = (item) => {
        return (
            <Grid className="dflex justifyCenter">
                {actions.map((it, ix) => {
                    const isDisabled = showHideIcon(it, item)
                    return (
                        <IconButton className="tableActionIcon" key={`sd-3we${ix}`} disabled={isDisabled} onClick={(e) => { handleActionClick(it, item, e) }}>
                            {getActionIcon(it, isDisabled)}
                        </IconButton>
                    )
                })}
            </Grid>
        )
    }

    const bindData = (item, field) =>{
        if(["created_at","submitted_at"].includes(field) && item){
            return globalDate(item)
        }else{
            return item
        }
    }

    const tableBody = () => (
        <>
            {data.map((item, index) => (
                <TableRow key={`tb-row-${index}`}>
                    {fields.map((field, ix) => {
                        if (componentMap[field]) {
                            return renderComponentMap(componentMap[field], item, index, ix)
                        } else {
                            return (
                                <TableCell className={`${linkFields.includes(field) ? 'clickableLink' : 'notClickable'}`} key={`tb-row-${index}-${ix}`}>
                                    <Grid
                                        className="alignCenter dflex"
                                        sx={{ '& .MuiCheckbox-root': { padding: '0px', marginRight: '10px' } }}
                                    >
                                        {ix === 0 &&
                                            <>
                                                {showCheckBox(item) &&
                                                    <Checkbox
                                                        checked={checkedValues.findIndex(f => (f.id === item.id)) > -1}
                                                        onClick={() => { handleCheckboxClick(item) }}
                                                    />
                                                }
                                                {showAvatar && <Avatar src={gets3URL(item.photo)} alt={item.name} />}
                                            </>
                                        }
                                        {clipName?
                                        <Typography sx={{maxWidth: "300px"}} noWrap className={["approval_status", "ap_status"].includes(field) ? `approval_status_${isNaN(item.approval_status) ? item.ap_status : item.approval_status}` : ""} onClick={linkFields.includes(field) ? () => { handleLinkClick(item) } : () => { }}>{bindData(item[field], field)}</Typography>
                                        :
                                        <Typography className={["approval_status", "ap_status"].includes(field) ? `approval_status_${isNaN(item.approval_status) ? item.ap_status : item.approval_status}` : ""} onClick={linkFields.includes(field) ? () => { handleLinkClick(item) } : () => { }}>{bindData(item[field], field)}</Typography>
                                        }
                                    </Grid>
                                </TableCell>
                            )
                        }
                    })}
                    {(actions && actions.length > 0) && <TableCell key={`tb-rrow-asdc`}>{ActionsList(item)}</TableCell>}
                </TableRow>
            ))}
        </>
    )

    return (
        <>
            <TableContainer className={`${className} tableContainer`} component={Paper}>
                <Tbl sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        {tableHeader()}
                    </TableHead>
                    <TableBody className="tableBody">
                        {tableBody()}
                    </TableBody>
                </Tbl>
            </TableContainer>
            {
                showpagination &&
                <Grid container justifyContent={"space-between"} className="pt-2 pl-1 pr-1 pb-2">
                    <Typography variant="body1">
                        {`Displaying ${((skip - 1) * limit) + 1} to ${(skip - 1) * limit + data.length} of ${totalCount} records`}
                    </Typography>
                    <Pagination
                        className="pagination"
                        color="primary"
                        shape="rounded"
                        count={Math.ceil(totalCount / limit)}
                        page={skip}
                        onChange={(e, val) => { updatePage(e, val) }}
                    />
                </Grid>
            }

        </>
    );

}

//props types
TableComponent.propTypes = {
    header: PropTypes.array,
    data: PropTypes.array,
    fields: PropTypes.array,
    skip: PropTypes.number,
    updatePage: PropTypes.func,
    totalCount: PropTypes.number,
    limit: PropTypes.number,
    linkFields: PropTypes.array,
    handleLinkClick: PropTypes.func,
    showpagination: PropTypes.bool,
    checkedValues: PropTypes.array,
    handleCheckboxClick: PropTypes.func,
    actions: PropTypes.array,
    handleActionClick: PropTypes.func,
    showHideIcon: PropTypes.func,
    showAvatar: PropTypes.bool,
    showCheckBox: PropTypes.func,
    showHeaderCheckbox: PropTypes.bool,
}

//default props
TableComponent.defaultProps = {
    header: [],
    data: [],
    fields: [],
    skip: 0,
    updatePage: () => { },
    totalCount: 0,
    limit: 15,
    handleLinkClick: () => { },
    componentMap: {},
    linkFields: [],
    showpagination: true,
    showCheckBox: () => { return false },
    checkedValues: [],
    handleCheckboxClick: () => { },
    actions: [],
    handleActionClick: () => { },
    showHideIcon: () => { return true },
    showAvatar: false,
    showHeaderCheckbox: false
}

export const Table = styled(TableComponent)(Style);