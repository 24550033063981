import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import moment from 'moment';

// helpers
import { defaultExcelType } from '../../helpers';

const initialState = {
    isLoading:false,
    isLazyLoading:false,
    viewType: "panel",
    panelView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 30,
            count: 0,
            sortField: "viewed",
            sortBy: 'asc'
        }
    },
    filter: {
        managerFilter: [],
        propertiesFilter: [],
        flagFilter: [],
        propertyCountFilter: [],
        statusFilter: [],
        startDate: '',
        endDate: ''
    },
    tmpfilter: {
        managerFilter: [],
        propertiesFilter: [],
        flagFilter: [],
        propertyCountFilter: [],
        statusFilter: [],
        startDate: '',
        endDate: ''
    },
    clearfilter:false,
    listView: {
        data: [],
        pagination: {
            skip: 0,
            limit: 9,
            count: 0,
            sortField: "name",
            sortBy: 'asc'
        }
    },
    managerDetail: {},
    managerInviteEmails: [],
    comments: [],
    currentComment: {
        message: ""
    },
    managerFileName: "",
    propertyManagerLoaders: {
        getPMCountLoader: false,
        getPMLeftPanelLoader: false,
        getPMListLoader: false,
        getPMDetailLoader: false,
        approvalLoader: false
    },
    overallPMLoaders: false
}

const propertyManagerSlice = createSlice({
    name: 'propertyManager',
    initialState: initialState,
    reducers: {

        // get list of manager details for left panel
        getPMLeftPanelListRequest(state) {
            state.isLazyLoading = true;
            state.propertyManagerLoaders.getPMLeftPanelLoader = true
        },
        getPMLeftPanelListSuccess(state, action) {
            const isFirst = action.payload.pagination && action.payload.pagination.skip === 1 ? true : false
            state.panelView = {
                data: isFirst ? [...(action.payload.data || [])] : [...(state.panelView.data || []), ...(action.payload.data || [])],
                pagination: {
                    ...state.panelView.pagination,
                    ...action.payload.pagination
                }
            };
            state.isLazyLoading = false;
            state.propertyManagerLoaders.getPMLeftPanelLoader = false
        },
        getPMLeftPanelListFailure(state) {
            state.isLazyLoading = false;
            state.propertyManagerLoaders.getPMLeftPanelLoader = false
            state.leftPanelList = initialState.leftPanelList;
        },

        // get property manager details
        getPMDetailRequest(state) {
            state.propertyManagerLoaders.getPMCount = true
        },
        getPMDetailSuccess(state, action) {
            state.propertyManagerLoaders.getPMCount = false
            state.managerDetail = action.payload
            const id = action.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = action.payload ? action.payload.approval_status : indexData.approval_status
                state.panelView.data[elementIndex] = indexData
            }
        },
        getPMDetailFailure(state) {
            state.propertyManagerLoaders.getPMCount = false
            state.managerDetails = initialState.managerDetails;
        },

        // update property manager flag
        updatePMFlagRequest(state) {
            state.isLoading = true;
        },
        updatePMFlagSuccess(state, action) {
            state.isLoading = false;
            const id = action.payload.payload.id;
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.is_flag = indexData.is_flag ? 0 : 1
                state.panelView.data[elementIndex] = indexData
            }
        },
        updatePMFlagFailure(state) {
            state.isLoading = false;
        },

        // update property manager approval status
        managerApprovalRequest(state) {
            state.propertyManagerLoaders.approvalLoader = true
        },
        managerApprovalSuccess(state, action) {
            const id = action.payload.payload.user_id;
            const status = Number(action.payload.payload.approval_status);
            const elementIndex = state.panelView.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                let indexData = state.panelView.data[elementIndex];
                indexData.is_viewed = true
                indexData.approval_status = status
                state.panelView.data[elementIndex] = indexData
            }
            state.managerDetail.approval_status = status
            state.propertyManagerLoaders.approvalLoader = false
        },
        managerApprovalFailure(state) {
            state.propertyManagerLoaders.approvalLoader = false
        },

        // get property manager list
        getPMListRequest(state) {
            state.propertyManagerLoaders.getPMListLoader = true
        },
        getPMListSuccess(state, action) {
            state.listView = {
                data: action.payload.data || [],
                pagination: {
                    ...state.listView.pagination,
                    ...action.payload.pagination
                }
            };
            state.propertyManagerLoaders.getPMListLoader = false
        },
        getPMListFailure(state) {
            state.propertyManagerLoaders.getPMListLoader = false
        },

        // get property manager total count
        getPMCountRequest(state) {
            state.propertyManagerLoaders.getPMCountLoader = true
        },
        getPMCountSuccess(state, action) {
            state.clearfilter = false
            state.listView.pagination = {
                ...state.listView.pagination,
                count: action.payload.data.count
            };
            state.panelView.pagination = {
                ...state.panelView.pagination,
                count: action.payload.data.count
            };
            state.propertyManagerLoaders.getPMCountLoader = false
        },
        getPMCountFailure(state) {
            state.propertyManagerLoaders.getPMCountLoader = false
        },

        // update property manager view type
        updatePMViewType(state, action) {
            state.viewType = action.payload
        },

        // get PM invite email response 
        getPMInviteRequest(state) {
            state.isLoading = true;
        },
        getPMInviteSuccess(state) {
            state.isLoading = false;
            state.managerInviteEmails = initialState.managerInviteEmails
        },
        getPMInviteFailure(state) {
            state.isLoading = false;
            state.managerInviteEmails = initialState.managerInviteEmails
        },

        //get property manager comments
        getPMCommentsRequest(state) {
            state.isLoading = true
            state.comments = []
        },
        getPMCommentsSuccess(state, action) {
            state.isLoading = false
            state.comments = action.payload || []
        },
        getPMCommentsFailure(state) {
            state.isLoading = false
            state.comments = []
        },

        //update property manager comments
        updatePMCommentsRequest(state) {
            state.isLoading = true
        },
        updatePMCommentsSuccess(state) {
            state.isLoading = false
            state.currentComment = {
                message: ""
            }
        },
        updatePMCommentsFailure(state) {
            state.isLoading = false
        },

        // update comment text
        updateCurrentComment(state, action) {
            state.currentComment = {
                message: action.payload
            }
        },

        //delete property manager comments
        deletePMCommentRequest(state) {
            state.isLoading = true
        },
        deletePMCommentSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id;
            const elementIndex = state.comments.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.comments.splice(elementIndex, 1)
            }

        },
        deletePMCommentFailure(state) {
            state.isLoading = false
        },

        // update list of invite emails
        updateEmailList(state, action) {
            state.managerInviteEmails = action.payload
        },

        clearEmailList(state){
            state.managerInviteEmails = []
        },

        downloadPMRequest(state, action) {
            state.managerFileName = `propertymanagers_${moment().format("YYYY-MM-DD_h-mm-ssA")}.xlsx`
            state.isLoading = true
        },
        downloadPMSuccess(state, action) {
            state.isLoading = false
            const blob = new Blob([action.payload.data], { type: defaultExcelType });
            saveAs(blob, state.managerFileName || "report.xlsx");
            state.managerFileName = ''
        },
        downloadPMFailure(state) {
            state.isLoading = false
            state.managerFileName = ''
        },

        // update property manager filter
        updatePMFilter(state, action) {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value
            }
            state.clearfilter = false
        },

        // Revert filter to temporary filter values
        revertPMFilter(state, action){
            state.filter = {
                ...state.tmpfilter
            }
        },

        //update temporary filter
        updateTempPMFilter(state, action){
            state.tmpfilter = {
                ...state.filter
            }
        },

        clearPMFilter(state) {
            state.filter = {
                managerFilter: [],
                propertiesFilter: [],
                flagFilter: [],
                propertyCountFilter: [],
                statusFilter: [],
                startDate: '',
                endDate: ''
            }
            state.clearfilter = true
        },
        updateOverallPMLoaders(state){
            state.overallPMLoaders = Object.values(state.propertyManagerLoaders).some(loaderValue => loaderValue)
        }
    }
})

export const {
    getPMLeftPanelListRequest, getPMLeftPanelListSuccess, getPMLeftPanelListFailure,
    getPMDetailRequest, getPMDetailSuccess, getPMDetailFailure,
    updatePMFlagRequest, updatePMFlagSuccess, updatePMFlagFailure,
    managerApprovalRequest, managerApprovalSuccess, managerApprovalFailure,
    getPMInviteRequest, getPMInviteSuccess, getPMInviteFailure, updateEmailList,
    getPMListRequest, getPMListSuccess, getPMListFailure,
    getPMCountRequest, getPMCountSuccess, getPMCountFailure,
    updatePMViewType, clearEmailList,
    getPMCommentsRequest, getPMCommentsSuccess, getPMCommentsFailure,
    updatePMCommentsRequest, updatePMCommentsSuccess, updatePMCommentsFailure,
    updateCurrentComment,
    deletePMCommentRequest, deletePMCommentSuccess, deletePMCommentFailure,
    downloadPMRequest, downloadPMSuccess, downloadPMFailure,
    updatePMFilter, clearPMFilter, updateTempPMFilter, revertPMFilter,
    updateOverallPMLoaders
} = propertyManagerSlice.actions;
export default propertyManagerSlice.reducer;