import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

//style
import Styles from './style'
import { EmailIcon } from "../../assets/svg";

//Components
import { Button, TextField } from "../../components";

// helpers
import { commonValidation, emailToLower, isEmailValid } from "../../helpers";

// epic
import { forgetPasswordRequest } from "../../redux/reducer/authReducer";

function ForgotPassword(props) {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className } = props;

    //set state
    const [email, setEmail] = useState("");
    const [validate, setValidate] = useState(false);

    //get validations
    const validation = commonValidation({ email }, "forgetPassword");

    // submit form
    const handleSubmit = () => {
        setValidate(true);
        if (!validation) {
            dispatch(forgetPasswordRequest({ email: emailToLower(email), user_group_id: ["2", "3"] }))
        }
    }

    return (
        <Grid className={`${className} h-100 w-100`}>
            <Grid container justifyContent="space-between" className={"passwordContainer"}>
                <Grid item xs={12} className="forgotPassBtnContainer">
                    <Button sx={{ fontWeight: 500 }} href="#/" className={"backLoginBtn flexStart dinlineflex"} variant={"text"} startIcon={<ChevronLeftIcon />}  aria-label="Back to login page">Back to Login page</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h1" align="center" className={"pt-3 pb-5"}>
                        Forgot Password
                    </Typography>
                    <TextField
                        id={"email"}
                        name={"email"}
                        placeholder={"Enter email"}
                        aria-label="Enter email id"
                        startIcon={<EmailIcon />}
                        value={email}
                        handleChange={(e) => { setEmail(e.value ? e.value.trim() : "") }}
                        validation={isEmailValid(validate, email)}
                    />
                    <Grid align="center" className="pt-4">
                        <Button variant="contained" size="large" className={"loginBtn"} onClick={() => { handleSubmit() }}  aria-label="Send">Send</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
ForgotPassword.defaultProps = {
    classes: {},
};

// prop types
ForgotPassword.propTypes = {
    classes: PropTypes.object,
};

export default styled(ForgotPassword)(Styles)