import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";

//style
import Style from './style'

//Components
import { Dialog, AutoComplete, DragandDrop } from "../../../components";

// reducer
import { createCityPolicyDocsRequest, createInconeDocsRequest } from "../../../redux/reducer/globalReducer";
import { errorMessage } from "../../../redux/reducer/uiReducer";

const UploadFiles = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { className, fileUpload, setFileUpload } = props;

    // reducer
    const { loggedUser } = useSelector(s=>s.profile) 

    //State
    const [selectedFiles, setSelectedFiles] = useState([])
    const [fileType, setFileType] = useState("");
    const [category, setCategory] = useState("");
    const [year, setYear] = useState("");

    const handleFileUploadClose = () => {
        setSelectedFiles([])
        setFileUpload(false)
    }

    const isIncome = fileType === "income";

    const handleFileUploadPopup = () => {
        if (selectedFiles && selectedFiles.length > 0) {
            if (fileType === "income") {
                if (!year) {
                    dispatch(errorMessage("Please choose year"))
                } else if (!category) {
                    dispatch(errorMessage("Please choose category"))
                } else {
                    dispatch(createInconeDocsRequest({
                        docs: [...selectedFiles],
                        category,
                        year,
                        user_id : loggedUser.id
                    }))
                    setFileUpload(false)
                }
            } else if (fileType === "policy") {
                if(!year){
                    dispatch(errorMessage("Please choose year"))
                }else{
                dispatch(createCityPolicyDocsRequest({
                    docs: [...selectedFiles],
                    year,
                    user_id: loggedUser.id
                }))
                setFileUpload(false)
            }
            } else {
                dispatch(errorMessage("Please choose type"))
            }
        } else {
            dispatch(errorMessage("Please choose files to upload"))
        }
    }

    return (
        <Dialog
            open={fileUpload}
            handleDialogClose={handleFileUploadClose}
            confirmHandle={() => { handleFileUploadPopup(selectedFiles) }}
            successButton="Upload"
            failButton="Cancel"
            fullScreen
            className={className}
        >
            <Grid container spacing={4} className="uploadDocuments">
                <Grid item xs={12}>
                    <Typography variant="h3" className="fw-500">Upload Documents</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="body1" color={"textSecondary"}>TYPE</Typography>
                            <AutoComplete
                                id={"fileType"}
                                name={"fileType"}
                                value={{ id: fileType }}
                                className={`inlineEdit sm ${fileType ? '' : 'nodata'}`}
                                handleChange={(e) => { setFileType(e.value && e.value.id ? e.value.id : "") }}
                                options={[
                                    { "id": "income", "value": "Income and Rent Limit" },
                                    { "id": "policy", "value": "Obligation and City Policy" },
                                ]}
                            />
                        </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="body1" color={"textSecondary"}>YEAR</Typography>
                                <AutoComplete
                                    id={"year"}
                                    name={"year"}
                                    value={{ id: year }}
                                    className={`inlineEdit sm  ${year ? '' : 'nodata'}`}
                                    handleChange={(e) => { setYear(e.value && e.value.id ? e.value.id : "") }}
                                    options={[
                                        { "id": "2019", "value": "2019" },
                                        { "id": "2020", "value": "2020" },
                                        { "id": "2021", "value": "2021" },
                                        { "id": "2022", "value": "2022" },
                                        { "id": "2023", "value": "2023" },
                                        { "id": "2024", "value": "2024" },
                                    ]}
                                />
                            </Grid>
                        {isIncome &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="body1" color={"textSecondary"}>CATEGORY</Typography>
                                <AutoComplete
                                    id={"category"}
                                    name={"category"}
                                    value={{ id: category }}
                                    className={`inlineEdit sm  ${category ? '' : 'nodata'}`}
                                    handleChange={(e) => { setCategory(e.value && e.value.id ? e.value.id : "") }}
                                    options={[
                                        { "id": "1", "value": "UHAC" },
                                        { "id": "2", "value": "NJHMFA"},
                                        { "id": "3", "value": "Other"},
                                    ]}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className="mt-1">
                    <DragandDrop
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        accept={"application/pdf"}
                    />
                </Grid>
            </Grid>
        </Dialog>);
}

// default props
UploadFiles.defaultProps = {
    classes: {},
    fileUpload: false,
    setFileUpload: () => { },
};

// prop types
UploadFiles.propTypes = {
    classes: PropTypes.object,
    fileUpload: PropTypes.object,
    setFileUpload: PropTypes.func,
};

export default styled(UploadFiles)(Style);