// Import API Helper
import { getRequest, postRequest, putRequest } from "../../helpers";

//Get logged in user profile
async function getProfile() {
  const response = await getRequest(`profile/getProfile/`);
  return response;
}

//Update the user profile
async function updateProfile(params) {
  const response = await putRequest(`profile/updateProfile/`, {
    body: params,
    isFormData: true,
    responseType: "json",
    multipart: true
  });
  return response;
}

//get Logged User/
async function getLoggedUser() {
  const response = await getRequest(`profile/detail/`);
  return response;
}

// update notification
async function updateNotification(params) {
  const response = await postRequest(`profile/update-notification/`, params);
  return response;
}

// export all profile functions
export const profileService = {
  getProfile,
  updateProfile,
  getLoggedUser,
  updateNotification
};