import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Dialog, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { Close } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

import Style from './style'

// helpers
import { getFileNameWithTime, getImage, gets3URL } from "../../helpers";

// components
import { Button } from "../button";

// reducer
import { errorMessage } from "../../redux/reducer/uiReducer";

const ImageUploadComponent = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { open, handleClose, closeGallery, className, data, onDelete, onAdd, acceptFiles, isDisabled, multiple } = props;

    const hiddenFileInput = useRef(null);

    //State
    const [uploadedPhotos, setUploadedPhotos] = useState([]);

    //File Upload
    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    };
    const handleFileChange = event => {
        const fileUploaded = event.target.files;
        let hasMaxSize = false
        let hasInvalidFile = false

        let resFiles = []
        for (let i = 0; i < fileUploaded.length; i++) {
            if (fileUploaded[i].size > (10 * 1024 * 1024)) {
                hasMaxSize = true
            } else if (acceptFiles === "image" && fileUploaded[i].type.indexOf("image") === -1) {
                hasInvalidFile = true
            }

            // for changing file name
            const newFile = new File([fileUploaded[i]], getFileNameWithTime(fileUploaded[i]));
            resFiles.push(newFile)
        }

        if (hasMaxSize) {
            dispatch(errorMessage("File Size should be less than 5MB"))
        } else if (hasInvalidFile) {
            dispatch(errorMessage("Please upload only image Fields"));
        } else {
            setUploadedPhotos([...uploadedPhotos, ...resFiles])
            // onAdd(multiple ? resFiles : resFiles[0]);
        }
    };

    const handleSave = () => {
        setUploadedPhotos([])
        onAdd(uploadedPhotos);
        handleClose();
    }

    const handleCancel = () => {
        setUploadedPhotos([])
        handleClose();
    }

    const handleDelete = (item, index) => {
        const stateIndex = uploadedPhotos.findIndex((elem) => elem.name === item.name)
        if (stateIndex > -1) {
            const tempPhotos = [...uploadedPhotos];
            tempPhotos.splice(stateIndex, 1)
            setUploadedPhotos(tempPhotos);
        } else {
            onDelete(index)
        }
    }

    return (
        <>
            <Dialog
                className={className}
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleCancel}
            >
                <Grid container className="galleryContainer">
                    <Grid item xs={12} className="pr-4">
                        <Grid container justifyContent={"space-between"} className="pb-2">
                            <Typography variant="h2" className="fw-500">{isDisabled ? "View Images" : "Upload Images"}</Typography>
                            <Grid className="dflex alignCenter">
                                {/* <Grid item xs={12} align={`${data.length > 1 ? "left" : "center"}`}> */}
                                {!isDisabled && <Button className={"uploadBtn"} variant="text" onClick={handleUploadClick} size="medium" startIcon={<CloudUploadIcon />} aria-label="Upload Images">Upload Images</Button>}
                                <input
                                    type="file"
                                    name="inputImage"
                                    ref={hiddenFileInput}
                                    multiple={multiple}
                                    value={""}
                                    accept={acceptFiles === "image" ? "image/png, image/jpeg" : "*"}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                {/* </Grid> */}
                                {isDisabled &&
                                    <Tooltip title="Close" arrow>
                                        <IconButton onClick={handleCancel} className="ml-1" aria-label="Close"><Close /></IconButton>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} className="galleryBody" sx={{ maxHeight: 'calc(100vh - 215px) !important' }}>
                        {!data.length > 0 && !uploadedPhotos.length > 0 ?
                            <Grid alignItems={"center"} justifyContent="center" container sx={{ height: '200px' }}>
                                <Typography variant="body1" align="center">No images selected</Typography>
                            </Grid> :
                            <>
                                {
                                    [...data, ...uploadedPhotos].map((item, index) => (
                                        <Grid item xs={6} key={`image-df-${index}`} className={"galleryItem"}>
                                            {!isDisabled && <Tooltip title="Remove" arrow><IconButton className="deletImage" onClick={() => { handleDelete(item, index) }} aria-label="Close">
                                                <CloseIcon />
                                            </IconButton></Tooltip>}
                                            <img src={typeof item === "object" &&  item.photo ? gets3URL(item.photo) : getImage(item, index)} alt={"Property Image"} />
                                        </Grid>
                                    ))
                                }
                            </>
                        }
                    </Grid>
                    {!isDisabled &&
                        <Grid container className="mt-2 pr-3 pl-3" justifyContent={"flex-end"}>
                            <Button variant={"text"} color="secondary" className={"mr-1"} size={"medium"} sx={{ color: '#343487' }} onClick={handleCancel}>Cancel</Button>
                            <Button variant={"contained"} color="primary" size={"medium"} onClick={handleSave}>Save</Button>
                        </Grid>
                    }

                </Grid>
            </Dialog>
        </>
    );
}

// default props
ImageUploadComponent.defaultProps = {
    classes: {},
    open: false,
    handleClose: () => { },
    data: [],
    acceptFiles: "*",
    isDisabled: false,
    closeGallery: () => { },
    className: "",
    onDelete: () => { },
    onAdd: () => { },
    multiple: false
};

// prop types
ImageUploadComponent.propTypes = {
    classes: PropTypes.object,
    handleClose: PropTypes.func,
    closeGallery: PropTypes.func,
    open: PropTypes.bool,
    data: PropTypes.array,
    isDisabled: PropTypes.any,
    className: PropTypes.string,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    acceptFiles: PropTypes.string,
    multiple: PropTypes.bool,
};

export const ImageUpload = styled(ImageUploadComponent)(Style);