import React from "react";
import PropTypes from "prop-types";

// Import Private Layout
import { PrivateLayout } from "../layouts";

// Routes the page with authentication by the user
const PrivateRoute = ({ component: component, srcImage, ...props }) => {

  return (<PrivateLayout srcImage={srcImage} component={component} {...props} />);
};

// Define Props Types
PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  srcImage: PropTypes.string
};

export default PrivateRoute;
