import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { Tabs, Tab, Grid, Typography, Button } from '@mui/material';

//style
import Style from './style'

// components
import { VerticalTabPanel } from "../../components/tabs/tabPanel/verticalTabs";
import NotificationList from './notificationList';
import { ApplicationIcon, NotificationAll, PropertyIcon, OfferHomeNotifications } from "../../assets/svg";

//reducer
import { markAllAsReadNotificationRequest } from "../../redux/reducer/webNotificationReducer";

function Notification(props) {

    // props
    const { className } = props;

    // define dispatch
    const dispatch = useDispatch();

    // state
    const [value, setValue] = useState(0);

    
    const handleReadAll = () => {
        dispatch(markAllAsReadNotificationRequest({ notificationType: 0}))
        setValue(0)
    }

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h3" className="fw-500">My Notification</Typography>
                <Button variant="text" size="medium" onClick={handleReadAll}>Read All</Button>
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                <Grid className="bodyInner">
                    <Grid container>
                        <Grid item>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={(event, newValue) => { setValue(newValue) }}
                                aria-label="Vertical tabs example"
                                className='tabsRoot'
                            >
                                <Tab label={<Typography variant="body1"><NotificationAll /> All</Typography>} />
                                <Tab label={<Typography variant="body1"><PropertyIcon/>Properties</Typography>} />
                                <Tab label={<Typography variant="body1"><ApplicationIcon/>Applications</Typography>} />
                                <Tab label={<Typography variant="body1"><OfferHomeNotifications/>Offer</Typography>} />
                            </Tabs>
                        </Grid>
                        <Grid item sx={{ flex: 1 }} className="notificationTabsBody">
                            <VerticalTabPanel value={value} index={0}>
                                <NotificationList notificationType={0} />
                            </VerticalTabPanel>
                            <VerticalTabPanel value={value} index={1}>
                                <NotificationList notificationType={1} />
                            </VerticalTabPanel>
                            <VerticalTabPanel value={value} index={2}>
                                <NotificationList notificationType={2} />
                            </VerticalTabPanel>
                            <VerticalTabPanel value={value} index={3}>
                                <NotificationList notificationType={5} />
                            </VerticalTabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
}

// default props
Notification.defaultProps = {
    classes: {},
};

// prop types
Notification.propTypes = {
    classes: PropTypes.object,
};

export default styled(Notification)(Style);