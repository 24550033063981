import { Typography, Grid } from "@mui/material"

import ServerErrorImage from "../../assets/images/servererror.png"

const ServerCommunicationErrorComponent = (props) => {
    return (
        <Grid container alignItems="center" justifyContent="center" sx={{height: "100%", backgroundColor: 'white' }}>
            <Grid item xs={12} align="center">
                {
                    ServerErrorImage && <img src={ServerErrorImage} height={200} alt="No property image" />
                }
                <Typography paddingTop={"30px"} paddingBottom={"20px"} textAlign={"center"} variant="h2">{"Taking a Quick Breather!"}</Typography>
                <Typography textAlign={"center"} variant="p">{"Sorry for the hiccup, but we're tweaking things behind the scenes."}</Typography>
                <br></br>
                <Typography textAlign={"center"} variant="p">{"We'll be back before you know it. Thanks for hanging in there."}</Typography>
            </Grid>
        </Grid>
    )
}

export const ServerCommunicationError = ServerCommunicationErrorComponent;
