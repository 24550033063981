import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

// Import Styles and images
import Styles from "./style";
import { AppBar, SessionTimeOut, ServerCommunicationError } from "../../components";

// epic
import { loggedUserRequest } from "../../redux/reducer/profileReducer";
import { logoutRequest, resetSession } from "../../redux/reducer/authReducer";
import { newWebNotificationRequest } from "../../redux/reducer/webNotificationReducer";
import { updateOverrideLoader } from "../../redux/reducer/uiReducer";

import { getSession, getToken } from "../../helpers";

function PrivateLayoutComponent({ component: Component, className, classes, ...props }) {
    //dispatch
    const dispatch = useDispatch();

    // navigator
    const navigate = useNavigate();

    const { loggedUser } = useSelector(s => (s.profile))
    const { serverCommunicationError } = useSelector(s => s.ui)

    useEffect(() => {
        // get logged user profiles details
        dispatch(loggedUserRequest());
        dispatch(newWebNotificationRequest())
    }, [dispatch])

    useEffect(() => {
        try {
            window.addEventListener('storage', (event) => {
                if (event && event.key === "user" && event.newValue) {
                    const sessionId = getSession("sessionIdd")
                    const localStorageId = getToken("sessionIdd")
                    console.log(sessionId + "  " + localStorageId)
                    if (localStorageId && sessionId && localStorageId !== sessionId) {
                        console.log("DUPLICATE SESSION LOGOUT")
                        duplicateSessionOut()
                    }
                } else if (event && event.key === "user" && !event.newValue) {
                    onLogout()
                }
            });
        } catch (err) {
            console.log(err)
        }
    }, [dispatch])

    useEffect(() => {
        if(serverCommunicationError){
            dispatch(updateOverrideLoader())
        }
    }, [serverCommunicationError])

    const duplicateSessionOut = () => {
        dispatch(resetSession());
        navigate("/");
    }

    // user logout
    const onLogout = () => {
        dispatch(logoutRequest());
        navigate("/")
    }

    return (
        <Box className={className}>
            <AppBar onLogout={onLogout} loggedUser={loggedUser} />
            {!serverCommunicationError ?
                <Grid item xs={12} className={"LayoutContainer"}>
                    <Component {...props} />
                </Grid> :
                <Grid item xs={12} className={"LayoutContainer"}>
                    <ServerCommunicationError />
                </Grid>
            }
            {/* session timeout  3o Mins*/}
            <SessionTimeOut onTimeout={onLogout} timeOut={60000 * 30} />
        </Box>
    );
}

// prop types
PrivateLayoutComponent.propTypes = {
    classes: PropTypes.object,
};

export const PrivateLayout = styled(PrivateLayoutComponent)(Styles)