import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    masterAmenities: [],
    masterUnits: [],
    cityPolicyDocs: {
        data: [],
        pagination: {
            skip:0,
            limit:5,
            count:0,
        },
        filter: {
            documentNameFilter:[],
            yearFilter: [],
            startDate:"",
            endDate:"",
            uploadedByFilter: [],
        },
        tmpfilter: {
            documentNameFilter:[],
            yearFilter: [],
            startDate:"",
            endDate:"",
            uploadedByFilter: [],
        },
        clearfilter: false,
    },
    isDocsUpdated: false, 
    incomeRentDocs:{
        data: [],
        pagination: {
            skip:0,
            limit:5,
            count:0,
        },
        filter: {
            search: "",
            documentNameFilter:[],
            yearFilter: [],
            categoryFilter: [],
            startDate:"",
            endDate:"",
            uploadedByFilter: [],
        },
        tmpfilter: {
            search: "",
            documentNameFilter:[],
            yearFilter: [],
            categoryFilter: [],
            startDate:"",
            endDate:"",
            uploadedByFilter: [],
        },
        clearfilter: false
    },
    currentIncomeRentsDocs: [],
    globalLoader: {
        getCityPolicyCount: false,
        getIncomeRentCount: false,
        getCityPolicyDocs: false,
        getIncomeRentDocs: false
    },
    overallGlobalLoader: false
}

const globalSlice = createSlice({
    name: 'global',
    initialState: initialState,
    reducers: {
        //get all units
        getMasterUnitsRequest(state) {
            state.isLoading = true
        },
        getMasterUnitsSuccess(state, action) {
            state.masterUnits = action.payload
            state.isLoading = false
        },
        getMasterUnitsFailure(state) {
            state.isLoading = false
        },

        //get all amenities
        getMasterAmenitiesRequest(state) {
            state.isLoading = true
        },
        getMasterAmenitiesSuccess(state, action) {
            state.masterAmenities = action.payload
            state.isLoading = false
        },
        getMasterAmenitiesFailure(state) {
            state.isLoading = false
        },

        //get city policy docs
        getCityPolicyDocsRequest(state){
            state.globalLoader.getCityPolicyDocs = true
        },
        getCityPolicyDocsSuccess(state, action) {
            state.globalLoader.getCityPolicyDocs = false
            state.cityPolicyDocs = {...state.cityPolicyDocs,
                data: action.payload.data || [],
                pagination: {
                    ...state.cityPolicyDocs.pagination,
                    ...action.payload.pagination
                }
            }
        },
        getCityPolicyDocsFailure(state){
            state.globalLoader.getCityPolicyDocs = false
        },

        //get city policy docs count
        getCityPolicyDocsCountRequest(state) {
            state.globalLoader.getCityPolicyCount = true
        },
        getCityPolicyDocsCountSuccess(state, action) {
            state.cityPolicyDocs.pagination = {
                ...state.cityPolicyDocs.pagination,
                count: action.payload.data.count
            };
            state.cityPolicyDocs.clearfilter = false
            state.globalLoader.getCityPolicyCount = false
        },
        getCityPolicyDocsCountFailure(state) {
            state.globalLoader.getCityPolicyCount = false
        },

        // create city policy
        createCityPolicyDocsRequest(state) {
            state.isLoading = true
        },
        createCityPolicyDocsSuccess(state) {
            state.isLoading = false
            state.isDocsUpdated = false
        },
        createCityPolicyDocsFailure(state) {
            state.isLoading = false
        },

        createInconeDocsRequest(state) {
            state.isLoading = true
        },
        createInconeDocsSuccess(state) {
            state.isLoading = false
        },
        createInconeDocsFailure(state) {
            state.isLoading = false
        },

        // delete city policy documents
        handleDeleteCityPolicyDocsRequest(state) {
            state.isLoading = true
        },
        handleDeleteCityPolicyDocsSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id
            const elementIndex = state.cityPolicyDocs.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.cityPolicyDocs.data.splice(elementIndex, 1)
            }
            state.cityPolicyDocs.pagination.count = state.cityPolicyDocs.pagination.count -1
        },
        handleDeleteCityPolicyDocsFailure(state) {
            state.isLoading = false
        },

        //get income and rents docs
        getIncomeRentsDocsRequest(state){
            state.globalLoader.getIncomeRentDocs = true
        },
        getIncomeRentsDocsSuccess(state, action) {
            state.globalLoader.getIncomeRentDocs = false
            state.incomeRentDocs = {...state.incomeRentDocs,
                data: action.payload.data || [],
                pagination: {
                    ...state.incomeRentDocs.pagination,
                    ...action.payload.pagination
                }
            }
        },
        getIncomeRentsDocsFailure(state){
            state.globalLoader.getIncomeRentDocs = false
        },

        //get income and rent docs count
        getIncomeRentsDocsCountRequest(state) {
            state.globalLoader.getIncomeRentCount = true
        },
        getIncomeRentsDocsCountSuccess(state, action) {
            state.globalLoader.getIncomeRentCount = false
            state.incomeRentDocs.pagination = {
                ...state.incomeRentDocs.pagination,
                count: action.payload.data.count
            };
            state.incomeRentDocs.clearfilter = false
        },
        getIncomeRentsDocsCountFailure(state) {
            state.globalLoader.getIncomeRentCount = false
        },

        //upload income and rents documents
        uploadIncomeRentsDocumentsRequest(state){
            state.isLoading = true
        },
        uploadIncomeRentsDocumentsSuccess(state){
            state.isLoading = false
            state.currentIncomeRentsDocs = []
        },
        uploadIncomeRentsDocumentsFailure(state){
            state.isLoading = false
        },

        // delete income and rents documents
        deleteIncomeRentsDocsRequest(state) {
            state.isLoading = true
        },
        deleteIncomeRentsDocsSuccess(state, action) {
            state.isLoading = false
            const id = action.payload.payload.id
            const elementIndex = state.incomeRentDocs.data.findIndex(f => (f.id === id))
            if (elementIndex >= 0) {
                state.incomeRentDocs.data.splice(elementIndex, 1)
            }
            state.incomeRentDocs.pagination.count = state.incomeRentDocs.pagination.count -1
        },
        deleteIncomeRentsDocsFailure(state) {
            state.isLoading = false
        },

        //update filter list
        updateIncomeRentFilter(state, action){
            state.incomeRentDocs.filter = {
                ...state.incomeRentDocs.filter,
                [action.payload.name]: action.payload.value
            }
        },

        //clear filter list
        clearIncomeRentFilter(state, action){
            state.incomeRentDocs.filter = {
                search: "",
                documentNameFilter:[],
                yearFilter: [],
                categoryFilter: [],
                startDate:"",
                endDate:"",
                uploadedByFilter: [],
            }
            state.incomeRentDocs.clearfilter = true
        },

        //update filter list
        updateCityPolicyFilter(state, action){
            state.cityPolicyDocs.filter = {
                ...state.cityPolicyDocs.filter,
                [action.payload.name]: action.payload.value
            }
        },

        //revert city policy filter
        revertCityPolicyFilter(state){
            state.cityPolicyDocs.filter = {
                ...state.cityPolicyDocs.tmpfilter
            }
        },

         //update city policy tmp filter list
         updateCityPolicyTmpFilter(state, action){
            state.cityPolicyDocs.tmpfilter = {
                ...state.cityPolicyDocs.filter
            }
        },

        //revert income and rent filter
        revertIncomeRentFilter(state){
            state.incomeRentDocs.filter = {
                ...state.incomeRentDocs.tmpfilter
            }
        },

        //update income and rent tmp filter list
        updateIncomeRentTmpFilter(state, action){
            state.incomeRentDocs.tmpfilter = {
                ...state.incomeRentDocs.filter
            }
        },
        
        //clear filter list
        clearCityPolicyFilter(state, action){
            state.cityPolicyDocs.filter = {
                documentNameFilter:[],
                yearFilter: [],
                startDate:"",
                endDate:"",
                uploadedByFilter: [],
            }
            state.cityPolicyDocs.clearfilter = true
        },

        updateOverallGlobalLoaders(state){
            state.overallGlobalLoader = Object.values(state.globalLoader).some(loaderValue => loaderValue)
        }
    }
})

export const {
    getMasterAmenitiesRequest, getMasterAmenitiesSuccess, getMasterAmenitiesFailure,
    getMasterUnitsRequest, getMasterUnitsSuccess, getMasterUnitsFailure,
    handleDeleteCityPolicyDocs,
    getCityPolicyDocsRequest, getCityPolicyDocsSuccess, getCityPolicyDocsFailure,
    getCityPolicyDocsCountRequest, getCityPolicyDocsCountSuccess, getCityPolicyDocsCountFailure,
    createCityPolicyDocsRequest, createCityPolicyDocsSuccess, createCityPolicyDocsFailure,
    handleDeleteCityPolicyDocsRequest, handleDeleteCityPolicyDocsSuccess, handleDeleteCityPolicyDocsFailure,
    createInconeDocsRequest, createInconeDocsSuccess, createInconeDocsFailure,
    getIncomeRentsDocsRequest, getIncomeRentsDocsSuccess, getIncomeRentsDocsFailure,
    getIncomeRentsDocsCountRequest, getIncomeRentsDocsCountSuccess, getIncomeRentsDocsCountFailure,
    deleteIncomeRentsDocsRequest, deleteIncomeRentsDocsSuccess, deleteIncomeRentsDocsFailure,
    updateIncomeRentFilter, clearIncomeRentFilter, updateIncomeRentTmpFilter, revertIncomeRentFilter,
    updateCityPolicyFilter, clearCityPolicyFilter, updateCityPolicyTmpFilter, revertCityPolicyFilter,
    updateOverallGlobalLoaders
} = globalSlice.actions;
export default globalSlice.reducer;