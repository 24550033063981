import palette from "../../assets/themes/palette";

const styles = {
    height: '100%',
    '.backLoginBtn': {
        fontWeight: '500',
        '& .MuiButton-startIcon': {
            background: palette.primary.main,
            borderRadius: '30px',
            '& svg': {
                fill: `${palette.primary.contrastText} !important`
            }
        }
    },
    '.loginBtn': {
        paddingLeft: 40,
        paddingRight: 40
    },
    '.successIcon': {
        width: 75,
        height: 75,
        fill: palette.primary.main,
        marginBottom: 30
    },
    '.successContainer': {
        maxWidth: '80%'
    },
    '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px'
    },
    '.forgotPassBtnContainer': {
        position: 'relative',
        '& .MuiButton-root': {
            position: 'absolute',
            top: '-24px',
            left: '-60px'
        }
    },
    '@media only screen and (max-width: 1500px)': {
        '& .forgotPassBtnContainer': {
            '& .MuiButton-root': {
                left: '-36px'
            }
        },
    },
    '@media only screen and (max-width: 1400px)': {
        '.pt-4': {
            paddingTop: '30px !important'
        }
    },
    '.passwordContainer': {
        height: '100%',
        padding: '35px 0px'
    },
    '&.loginpageCont': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    '.zipcodeField': {
        '& .MuiInputAdornment-root': {
            display: 'none'
        },
        '& input': {
            paddingLeft: '2px'
        }
    }
}
export default styles;