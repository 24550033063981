import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import 'moment-timezone'

//style
import Styles from './style'

//Components
import { FilterPanel, MultiSelectAsync, GroupingSelect } from "../../components";
import FilterDrawer from "./filterDrawer";

// reducer
import { updateApplicantsFilter, getApplicationsLeftPanelListRequest, getApplicationsDetailRequest, updateApplicationsViewType, downloadApplicationsRequest, clearApplicationsFilter } from "../../redux/reducer/applicationsReducer"

// service
import { globalFilterService } from "../../redux/service";

// helpers
import { applicationsListViewSort, applicationsPanelViewSort, hasFilter } from "../../helpers";

const TopPanel = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { IconsList, handleFilter, handleSorting, sortValue } = props;

    // navigator
    const navigate = useNavigate();
    const { pathname } = useLocation()

    // redux
    const { viewType, panelView: { data, pagination, pagination: { count } }, currentApplication: { general: applicationsDetail }, filter, tmpfilter, checkedValues, clearfilter } = useSelector(s => s.applications)
    const { loggedUser } = useSelector(s => (s.profile))

    // state
    const [openFilter, setOpenFilter] = useState(false)

    //variable
    const isCityStaff = loggedUser.user_group_id === 3;
    const tz = moment.tz.guess()

    // handle page changes
    useEffect(() => {
        if (viewType === "list" && pathname === "/applications") {
            navigate(`/applications/list`)
        } else if (viewType === "panel" && pathname === "/applications/list") {
            navigate(`/applications`)
        }
    }, [dispatch, pathname])

    // call api whenever clear filter is triggered
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
        }, [clearfilter])

    //Previous
    const handlePrevious = () => {
        const currentApplicationIndex = data.findIndex(f => (f.id === applicationsDetail.id))
        if (currentApplicationIndex > 0) {
            dispatch(getApplicationsDetailRequest(data[currentApplicationIndex - 1]))
        }
    };

    //Next
    const handleNext = () => {
        const currentApplicationIndex = data.findIndex(f => (f.id === applicationsDetail.id))
        if (currentApplicationIndex < data.length - 1) {
            dispatch(getApplicationsDetailRequest(data[currentApplicationIndex + 1]))
        }
        if ((currentApplicationIndex === data.length - 2) && (data.length < count)) {
            dispatch(getApplicationsLeftPanelListRequest({ ...pagination, filter: filter }))
        }
    };

    const updateView = (value) => {
        dispatch(updateApplicationsViewType(value))
        if (value === "panel") {
            navigate(`/applications`)
        } else {
            navigate(`/applications/list`)
        }
    }
    
    const handleClearFilter = () => {
        dispatch(clearApplicationsFilter())
    }

    const getApplicantsFilter = async (value) => {
        const response = await globalFilterService.getApplicantsFilterList({ name: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getAddressFilter = async (value) => {
        const response = await globalFilterService.getApplicationsAddressFilterList({ address: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getStatusFilter = async (value) => {
        const response = await globalFilterService.getApplicationsStatusFilterList({ approval_status: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    return (
        <FilterPanel
            IconsList={IconsList}
            viewType={viewType}
            isFilterApplied = {hasFilter(tmpfilter)}
            setViewType={(value) => { if (value !== null) {updateView(value)} }}
            handleFilter={(event) => { setOpenFilter(event.currentTarget) }}
            handleDownload={() => { dispatch(downloadApplicationsRequest({...pagination, filter: filter, timezone: tz, applicationIds : (checkedValues || []).map(m=>{ return m.id }) })) }}
            handlePrevious={() => { handlePrevious() }}
            handleNext={() => { handleNext() }}
            handleClearFilter={ () => { handleClearFilter() }}
            downloadDisabled={count===0}
        >
            <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"managerFilter"}
                        name={"applicantsFilter"}
                        placeholder={"Applicants"}
                        className={`sm`}
                        apiCallback={getApplicantsFilter}
                        value={filter.applicantsFilter}
                        handleChange={(e) => { dispatch(updateApplicantsFilter(e)) }}
                        initialValue={filter.applicantsFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        customWidth
                        variant={"outlined"}
                        id={"addressFilter"}
                        name={"addressFilter"}
                        placeholder={"Property Address or Name"}
                        className={`sm`}
                        apiCallback={getAddressFilter}
                        value={filter.addressFilter}
                        handleChange={(e) => { dispatch(updateApplicantsFilter(e)) }}
                        initialValue={filter.addressFilter}
                        matchBy={"id"}
                        display={"address_string"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"statusFilter"}
                        name={"statusFilter"}
                        placeholder={"Status"}
                        className={`sm`}
                        apiCallback={getStatusFilter}
                        value={filter.statusFilter}
                        handleChange={(e) => { dispatch(updateApplicantsFilter(e)) }}
                        initialValue={filter.statusFilter}
                        matchBy={"id"}
                        display={"ap_status"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={3} className="pt-0 selectBox">
                    <GroupingSelect
                        value={sortValue}
                        handleSorting={handleSorting}
                        placeholder={"sort by"}
                        options={viewType === "panel" ? applicationsPanelViewSort : applicationsListViewSort}
                    />
                </Grid>
            </Grid>
            {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}
        </FilterPanel>
    );
}

// default props
TopPanel.defaultProps = {
    classes: {},
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: ""
};

// prop types
TopPanel.propTypes = {
    classes: PropTypes.object,
    handleFilter: PropTypes.func
};

export default styled(TopPanel)(Styles);