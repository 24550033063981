import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const RadioComponent = (props) => {

    // props
    const { options, value, handleChange, name } = props;

    return (
        <FormControl>
            <FormLabel id="radio-label-id">{name}</FormLabel>
            <RadioGroup
                row
                aria-label="radio-label"
                name={name}
                onChange={e => { handleChange({ name: name, value: e.target.value }) }}
                value={value}
            >
                {options.map((item, index) => (
                    <FormControlLabel key={`sdwq-${index}`} disabled={item.disabled || false} value={item.value} control={<Radio />} label={item.label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

// default props
RadioComponent.defaultProps = {
    className: "",
    name: "",
    options: [],
    value: "",
    handleChange: () => { }
};

// prop types
RadioComponent.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    handleChange: PropTypes.func
};

export const RadioButton = RadioComponent