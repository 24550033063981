// Import API Helper
import { getRequest, postRequest, deleteRequest } from "../../helpers";

//Get left panel tenants details
async function getTenantsLeftPanelList(params) {
    const response = await postRequest(`tenants/left-panel/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter });
    return response;
}

// get tenants list
async function getTenantsList(params) {
    const response = await postRequest(`tenants/list/?skip=${params.skip}&limit=${params.limit}&sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter })
    return response
}

//get tenants count/
async function getTenantsCount(params) {
    const response = await postRequest(`tenants/count/`, { "filter": params.filter });
    return response;
}

//Get tenant detail
async function getTenantsDetail(params) {
    const response = await getRequest(`tenants/detail/${params.id}/`);
    return response;
}

//update tenants flag
async function updateTenantsFlag(params) {
    const response = await postRequest(`tenants/update-flag/${params.id}/${params.is_flag}/`);
    return response;
}

//tenants status update
async function updateTenantStatus(params) {
    const response = await postRequest(`tenants/approval/`, params);
    return response;
}

/**
* download tenants reports
*/
async function downloadTenantsReport(params) {
    const response = await postRequest(`tenants/download/?sortField=${params.sortField}&sortBy=${params.sortBy}`, { "filter": params.filter }, false, "arraybuffer");
    return response;
}

/**
 * Export All tenants Service Functions
 */
export const tenantsService = {
    getTenantsLeftPanelList,
    getTenantsList,
    getTenantsCount,
    getTenantsDetail,
    updateTenantStatus,
    updateTenantsFlag,
    downloadTenantsReport
};
