import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

import './style.css'

const loaderWrapperStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0px",
  width: "100%",
  height: "100%",
  zIndex: 2000,
  color: "#222",
  background: '#ffffff9c'
};

const loaderTextStyle = {
  color: "rgb(255, 255, 255)",
  height: "200px",
  position: "absolute",
  width: "200px",
  margin: "auto",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const LoaderComponent = () => {

  // reducer state
  const {
    auth: { isLoading: authLoading },
    profile: { isLoading: profileLoading },
    propertyManager: { isLoading: propertyManagerLoading, overallPMLoaders },
    global: { isLoading: globalLoading, overallGlobalLoader },
    properties: { isLoading: propertiesLoading, overallPropertiesLoaders },
    waitingList: { isLoading: waitingListLoading, overallWaitingListLoader },
    runLottery: { isLoading: runLotteryLoading },
    applications: { isLoading: applicationsLoading, overallApplicationsLoader },
    globalFilter: { isLoading: globalFilterLoading },
    reports: { isLoading: reportsLoader, overallReportLoader },
    tenants: {isLoading: tenantsLoader, overallTenantsLoader},
    ui: { overRideAndStopLoader }
  } = useSelector((s) => s);

  return ((globalFilterLoading || applicationsLoading || runLotteryLoading || waitingListLoading || authLoading || profileLoading || propertyManagerLoading || globalLoading || propertiesLoading || reportsLoader || tenantsLoader || overallPropertiesLoaders || overallPMLoaders || overallApplicationsLoader || overallTenantsLoader || overallWaitingListLoader || overallGlobalLoader || overallReportLoader) && !overRideAndStopLoader) ? (
    <div style={loaderWrapperStyle}>
      <div style={loaderTextStyle}>
        {/* <CircularProgress /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 746.953 766.558">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
              <stop offset="0%" stop-color="#043664" />
              <stop offset="20%" stop-color="#8F2F71" />
              <stop offset="100%" stop-color="#043664" />
              <animate attributeName="y1" values="100%; 0%" dur="1s" repeatCount="indefinite" />
              <animate attributeName="y2" values="0%; -100%" dur="1s" repeatCount="indefinite" />
            </linearGradient>
          </defs>
          <g id="Group_1" data-name="Group 1" transform="translate(-2050.405 -516.901)">
            <circle id="Ellipse_1" data-name="Ellipse 1" cx="298" cy="298" r="298" transform="translate(2126 635)" fill="none" />
            <g class="outerline">
              <path id="Subtraction_1" data-name="Subtraction 1" d="M473.143,629.126v0h0V603.741a277.686,277.686,0,0,0,0-431.483V146.874a299.373,299.373,0,0,1,89.35,103.707A297.676,297.676,0,0,1,523.75,582.534,300.059,300.059,0,0,1,473.143,629.126ZM122,628.5h0A299.372,299.372,0,0,1,33.262,524.945a297.672,297.672,0,0,1,38.47-330.876A300.082,300.082,0,0,1,122,147.5v25.458a277.68,277.68,0,0,0,0,430.083V628.5Z" transform="matrix(-0.616, -0.788, 0.788, -0.616, 2301.6, 1373.884)" fill="#043664" />
            </g>
            <path class="home" id="Path_2" data-name="Path 2" d="M3.683,295.692s-.37,9.294,8.709,9.294c11.3,0,104.812-.124,104.812-.124l.154-85.878s-1.478-14.158,12.264-14.158h43.5c16.251,0,15.25,14.158,15.25,14.158l-.2,85.6h102.6c11.525,0,10.992-11.572,10.992-11.572V134.679L157.17,6.052,3.669,134.679Z" transform="translate(2271.014 716.392)" fill="url(#gradient)" />
          </g>
        </svg>

      </div>
    </div>
  ) : null;
};

/**export component */
export const Loader = LoaderComponent;
