
import React from "react";
import { Typography } from "../components/typography";

const NotFound = () => {

    return (
        <>
            <Typography className={"pt-6"} align={"center"}>404 Page Not Found</Typography>
        </>
    );
}

export default NotFound;