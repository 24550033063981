import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

//import service
import { propertyManagerService } from '../service';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";
import {
    getPMLeftPanelListRequest, getPMLeftPanelListSuccess, getPMLeftPanelListFailure,
    getPMDetailRequest, getPMDetailSuccess, getPMDetailFailure,
    updatePMFlagRequest, updatePMFlagSuccess, updatePMFlagFailure,
    managerApprovalRequest, managerApprovalSuccess, managerApprovalFailure,
    getPMListRequest, getPMListSuccess, getPMListFailure,
    getPMCountRequest, getPMCountSuccess, getPMCountFailure,
    getPMInviteRequest, getPMInviteSuccess, getPMInviteFailure,
    getPMCommentsRequest, getPMCommentsSuccess, getPMCommentsFailure,
    updatePMCommentsRequest, updatePMCommentsSuccess, updatePMCommentsFailure,
    deletePMCommentRequest, deletePMCommentSuccess, deletePMCommentFailure,
    downloadPMRequest, downloadPMSuccess, downloadPMFailure,
    updateOverallPMLoaders
} from "../reducer/propertyManagerReducer";

/**get PM left Panel List */
const getPMleftPanelList = (action$) => action$.pipe(
    ofType(getPMLeftPanelListRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.getPMLeftPanelList(payload)).pipe(
            concatMap((res) => {
                const data = res.data && res.data.data ? res.data.data : []
                if (payload.isFirst && data && data[0]) {
                    return of(getPMLeftPanelListSuccess(res.data), getPMDetailRequest(data[0], updateOverallPMLoaders()))
                } else {
                    return of(getPMLeftPanelListSuccess(res.data), updateOverallPMLoaders())
                }
            }),
            catchError((res) => of(getPMLeftPanelListFailure(), updateOverallPMLoaders(), errorMessageApi(res))),
        )
    })
)

/**get Property manager data */
const getPMDetail = (action$) => action$.pipe(
    ofType(getPMDetailRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.getPMDetail(payload)).pipe(
            concatMap((res) => of(getPMDetailSuccess(res.data.data), updateOverallPMLoaders())),
            catchError((res) => of(getPMDetailFailure(), updateOverallPMLoaders(), errorMessageApi(res))),
        )
    }),
)

/**update property manager flag*/
const updatePMFlag = (action$) => action$.pipe(
    ofType(updatePMFlagRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.updatePMFlag(payload)).pipe(
            concatMap((res) => of(updatePMFlagSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(updatePMFlagFailure(), errorMessageApi(res))),
        )
    })
)

/**property manager Approval */
const propertyManagerApproval = (action$) => action$.pipe(
    ofType(managerApprovalRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.propertyManagerApproval(payload)).pipe(
            concatMap((res) => of(managerApprovalSuccess({ data: res.data.data, payload: payload }),getPMDetailRequest({id: payload.user_id}), updateOverallPMLoaders(), successMessageApi(res))),
            catchError((res) => of(managerApprovalFailure(), updateOverallPMLoaders(), errorMessageApi(res))),
        )
    }),
)

/**property manager list */
const getPMList = (action$) => action$.pipe(
    ofType(getPMListRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.getPMList(payload)).pipe(
            concatMap((res) => of(getPMListSuccess(res.data), updateOverallPMLoaders())),
            catchError((res) => of(getPMListFailure(), updateOverallPMLoaders(), errorMessageApi(res))),
        )
    }),
)

/** get property manager total count  */
const getPMCount = (action$, state$) => action$.pipe(
    ofType(getPMCountRequest),
    mergeMap(({ payload }) => {
        const viewType = state$.value.propertyManager.viewType
        return from(propertyManagerService.getPMCount(payload)).pipe(
            concatMap((res) => {
                if (viewType === "panel") {
                    return of(getPMCountSuccess(res.data), getPMLeftPanelListRequest(payload), updateOverallPMLoaders())
                } else {
                    return of(getPMCountSuccess(res.data), getPMListRequest(payload), updateOverallPMLoaders())
                }
            }),
            catchError((res) => of(getPMCountFailure(), updateOverallPMLoaders(), errorMessageApi(res))),
        )
    }),
)

// send invite emails to Property Managers
const invitePropertyManager = (action$) => action$.pipe(
    ofType(getPMInviteRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.sendPMInvites(payload)).pipe(
            concatMap((res) => of(getPMInviteSuccess(res),successMessageApi(res))),
            catchError((res) => of(getPMInviteFailure(), errorMessageApi(res)))
        )
    })
)

/*get proeprty Manager Comments*/
const getPMComments = (action$) => action$.pipe(
    ofType(getPMCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.getPMComments(payload)).pipe(
            concatMap((res) => of(getPMCommentsSuccess(res.data.data))),
            catchError((res) => of(getPMCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*update property Manager Comments*/
const updatePMComments = (action$) => action$.pipe(
    ofType(updatePMCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.updatePMComments(payload)).pipe(
            concatMap((res) => of(updatePMCommentsSuccess(res.data.data), getPMCommentsRequest({ id: payload.message_to }))),
            catchError((res) => of(updatePMCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*delete property Manager Comments*/
const deletePMComments = (action$) => action$.pipe(
    ofType(deletePMCommentRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.deletePMComments(payload)).pipe(
            concatMap((res) => of(deletePMCommentSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(deletePMCommentFailure(), errorMessageApi(res))),
        )
    })
)

/**download PM Report */
const downloadPMReport = (action$) => action$.pipe(
    ofType(downloadPMRequest),
    mergeMap(({ payload }) => {
        return from(propertyManagerService.downloadPMReport(payload)).pipe(
            concatMap((res) => of(downloadPMSuccess(res), successMessageApi({message: "The property manager list has been downloaded successfully"}))),
            catchError((res) => of(downloadPMFailure(), errorMessageApi(res))),
        )
    }),
)

// Export All property Manager Epic Functions
export const propertyManagerEpic = [
    getPMleftPanelList,
    getPMDetail,
    updatePMFlag,
    propertyManagerApproval,
    getPMList,
    getPMCount,
    invitePropertyManager,
    getPMComments,
    updatePMComments,
    deletePMComments,
    downloadPMReport
];