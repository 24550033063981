import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import moment from "moment";

//style
import Style from "./style";

//Components
import { NoResult, Table, TextField, Dialog, FileUpload } from "../../components";
import TopPanel from "./topPanel";

//helpers
import { errorMessage } from "../../redux/reducer/uiReducer";

// reducer
import { getReportsListCountRequest, getReportsListRequest, reportsApprovalRequest, downloadReportDocumentsRequest, setReportDocument, uploadReportRequest, updateTempReportsFilter } from "../../redux/reducer/reportsReducer";
import { updatePropertiesCommentsRequest, updatePropertiesFilter } from "../../redux/reducer/propertiesReducer";

const ListView = (props) => {
  // navigator
  const navigate = useNavigate();
  const { search } = useLocation()
  let [searchParams] = useSearchParams();

  // props
  const { className } = props;

  //define dispatch
  const dispatch = useDispatch();

  // state
  const [rejectDialog, setRejectDialog] = useState(false)
  const [rejectionComment, setRejectionComment] = useState("")
  const [currentReport, setCurrentReport] = useState("")
  const [fileUpload, setFileUpload] = useState(false);

  //redux
  const { listView: { data, pagination, pagination: { count, skip, limit } }, filter, reportDocument } = useSelector((s) => s.reports)
  const { loggedUser } = useSelector(s => (s.profile))

  useEffect(() => {
    // get total count for pagination and then call the data in the epic
    let searchQuery = {}
    let redirect = false
    if (search && search.includes("?id=")) {
        searchQuery = {
          report_id: searchParams.get("id")
        }
        redirect = true
    }
    //clear search query if any
    if (redirect) {
        navigate("/reports")
    }
    dispatch(
      getReportsListCountRequest({ ...pagination, skip: 0, filter: { ...filter, ...searchQuery }}));
  }, [dispatch]);

  // handle filter change
  const handleFilter = () => {
    dispatch(updateTempReportsFilter())
    dispatch(getReportsListCountRequest({ ...pagination, skip: 0, isFirst: true, filter: filter }))
  }

  // reject report
  const handleRejection = () => {
    if (rejectionComment) {
      dispatch(reportsApprovalRequest({ approval_status: "3", report_id: currentReport.id }))
      dispatch(updatePropertiesCommentsRequest({
        message: rejectionComment,
        user: loggedUser.id,
        property: currentReport.property_id,
        id: null
      }))
      setRejectionComment("");
      setRejectDialog(false)
    } else {
      dispatch(errorMessage("Please enter comments"))
    }
  }

  // upload documents
  const handleFileUpload = () => {
    if (reportDocument && reportDocument.length === 1) {
      dispatch(uploadReportRequest({ reportId: fileUpload, document: [...reportDocument] }))
      setFileUpload(false)
    } else {
      dispatch(errorMessage("Please upload file"))
    }
  }

  // handle action click
  const handleActionClick = (action, data, event) => {
    switch (action) {
      case "DOWNLOAD":
        dispatch(downloadReportDocumentsRequest({ id: data.id }))
        break;
      case "UPLOAD":
        setFileUpload(data.id)
        break;
      case "SEND":
        dispatch(reportsApprovalRequest({ approval_status: "1", report_id: data.id }))
        break
      case "APPROVE":
        dispatch(reportsApprovalRequest({ approval_status: "2", report_id: data.id }))
        break
      case "REJECT":
        setRejectDialog(true)
        setCurrentReport(data)
        break
      default:
        break;
    }
  }

  const showHideIcon = (action, data) => {
    switch (action) {
      case "DOWNLOAD":
        return false
      case "UPLOAD":
        return (((moment(data.due_date, 'YYYY-MM-DD').toDate()) >= (moment().toDate())) && ((data.approval_status === 0 && [1,2].includes(data.button_flags)) || (data.approval_status ===3 && [1,2].includes(data.button_flags)))) ? false: true
      case "SEND":
        return ((data.approval_status === 0 && data.button_flags === 2) || (data.button_flags === 2 && data.approval_status === 3)) ? false : true
      case "APPROVE":
        return !(data.approval_status === 2) ? false : true
      case "REJECT":
        return !([2,3].includes(data.approval_status)) ? false : true
      default:
        return false
    }
  }

  const viewDetailSwitch = (e) => {
    dispatch(updatePropertiesFilter({ name: "propertiesFilter", value: [{ id: e.property_id, name: e.name }] }))
    navigate(`/properties`);
  }

  const isCityStaff = loggedUser.user_group_id === 3 ? true : false

  return (
    <>
      <Grid container className={className}>
        <Grid item xs={12}>
          <TopPanel
            IconsList={["CHILDREN", "FILTER_LIST", "DOWNLOAD"]}
            handleFilter={handleFilter}
          />
        </Grid>
        <Grid item xs={12} className="bodyContainer pt-2">
          {count > 0 ?
            <Table
              header={isCityStaff? ["Property Name", "Property Manager", "Year", "Period", "Report Name", "Submitted Date", "Status"] : ["Property Name", "Year", "Period", "Report Name", "Submitted Date", "Status"]}
              fields={isCityStaff? ["property_name", "pm_name", "report_year", "rep_type", "report_name", "submitted_at", "ap_status"]: ["property_name", "report_year", "rep_type", "report_name", "submitted_at", "ap_status"]}
              data={data}
              skip={skip}
              totalCount={count}
              limit={limit}
              showAvatar={false}
              updatePage={(e, val) => {
                dispatch(getReportsListRequest({ ...pagination, skip: val - 1, filter: filter }));
              }}
              actions={isCityStaff ? ["DOWNLOAD", "APPROVE", "REJECT"] : ["DOWNLOAD", "UPLOAD", "SEND"]}
              handleLinkClick={(e) => { viewDetailSwitch(e) }}
              linkFields={["property_name"]}
              handleActionClick={handleActionClick}
              showHideIcon={showHideIcon}
            />
            :
            <NoResult text="No Report Found" />
          }
        </Grid>
        <Dialog
          open={rejectDialog}
          handleDialogClose={() => { setRejectDialog(false); setRejectionComment("") }}
          confirmHandle={() => {
            handleRejection()
          }}
        >
          <Typography variant='h6' className="pb-1">Please provide the rejection reason</Typography>
          <TextField
            placeholder={"Rejection reason"}
            aria-label="Enter the reject reason"
            multiline
            value={rejectionComment}
            handleChange={e => { setRejectionComment(e.value) }}
          />
        </Dialog>

      </Grid>
      <Dialog
        open={!!fileUpload}
        handleDialogClose={() => { setFileUpload(false) }}
        confirmHandle={() => { handleFileUpload() }}
        successButton="Submit"
        failButton="Cancel"
        maxWidth={"sm"}
      >
        <Grid>
          <FileUpload
            multiple={false}
            accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
            onChange={(files) => {
              dispatch(setReportDocument(files))
            }}
          />
        </Grid>
      </Dialog>
    </>
  );
};

// default props
ListView.defaultProps = {
  classes: {},
};

// prop types
ListView.propTypes = {
  classes: PropTypes.object,
};

export default styled(ListView)(Style);
