import React, { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { InputBase, IconButton, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SearchIcon } from "../../assets/svg";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const TextField = ({ onClear, ...rest }) => {
    return (
        <InputBase
            {...rest}
            endAdornment={
                rest.value ? <IconButton onClick={onClear} aria-label="Close">
                    <Close />
                </IconButton> : null
            }
        />
    )
}

const StyledInputBase = styled(TextField)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create('width'),
        width: '98%',
        [theme.breakpoints.up('sm')]: {
            width: '0ch',
            '&.focus, &:focus': {
                width: '20ch',
                paddingLeft: '40px',
                border: '1px solid #ccc',
                borderRadius: '4px'
            },
        },
    },
}));

let idleTimeOut = null
export function Searchbar({ onEnter }) {
    const [value, setValue] = useState("")

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    useEffect(() => {
        clearTimeout(idleTimeOut)
        setIdleTimer()
    }, [value])

    const setIdleTimer = () => {
        idleTimeOut = setTimeout(onSearch, 1000)
    }

    const onSearch = () => {
        onEnter(value)
    }

    const onClear = () => {
        setValue("")
    }

    return (
        <Search>
            <SearchIconWrapper>
                <Tooltip title="Search" arrow>
                    <SearchIcon sx={{ fontSize: '1.2rem' }} />
                </Tooltip>
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search…"
                value={value}
                onChange={handleChange}
                onClear={onClear}
                inputProps={{
                    'aria-label': 'search',
                    'className': value ? 'focus' : '',
                }}
            />
        </Search>
    );
}

Searchbar.defaultProps = {
    onEnter: () => { }
}

