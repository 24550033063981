import { ofType } from 'redux-observable';
import { mergeMap, catchError, concatMap, delay, toArray } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { saveAs } from "file-saver";

//import service
import { reportsService } from '../service';

//import actions
import { successMessageApi, errorMessageApi } from "../reducer/uiReducer";

import {downloadReportsModelRequest, downloadReportsModelSuccess, downloadReportsModelFailure,
        getReportsListRequest, getReportsListSuccess, getReportsListFailure,
        getReportsListCountRequest, getReportsListCountSuccess, getReportsListCountFailure,
        reportsApprovalRequest, reportsApprovalSuccess, reportsApprovalFailure,
        downloadReportDocumentsRequest, downloadReportDocumentsSuccess, downloadReportDocumentsFailure,
        uploadReportRequest,uploadReportSuccess,uploadReportFailure,
        updateOverallReportLoaders
} from "../reducer/reportsReducer"

/**download reports model */
const downloadReportsModel = (action$) => action$.pipe(
    ofType(downloadReportsModelRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.downloadReportsModel(payload)).pipe(
            concatMap((res) => of(downloadReportsModelSuccess(res), successMessageApi({message: "The report list has been downloaded successfully"}))),
            catchError((res) => of(downloadReportsModelFailure(), errorMessageApi(res))),
        )
    }),
)

/**get reports list */
const getReportsList = (action$) => action$.pipe(
    ofType(getReportsListRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.getReportsList(payload)).pipe(
            concatMap((res) => of(getReportsListSuccess(res.data), updateOverallReportLoaders())),
            catchError((res) => of(getReportsListFailure(), updateOverallReportLoaders(), errorMessageApi(res))),
        )
    }),
)

/** get reports list total count  */
const getReportsListCount = (action$) => action$.pipe(
    ofType(getReportsListCountRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.getReportsListCount(payload)).pipe(
            concatMap((res) => (of(getReportsListCountSuccess(res.data), getReportsListRequest(payload), updateOverallReportLoaders()))),
            catchError((res) => of(getReportsListCountFailure(), updateOverallReportLoaders(), errorMessageApi(res))),
        )
    }),
)

/**Reports Approval */
const reportsApproval = (action$) => action$.pipe(
    ofType(reportsApprovalRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.reportsApproval(payload)).pipe(
            concatMap((res) => of(reportsApprovalSuccess({data: res.data, payload: payload }), successMessageApi(res))),
            catchError((res) => of(reportsApprovalFailure(), errorMessageApi(res))),
        )
    }),
)

/**download Documents */
const downloadReportDocuments = (action$) => action$.pipe(
    ofType(downloadReportDocumentsRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.downloadReportDocuments(payload)).pipe(
            concatMap((res) => {
                const docsList = res.data && res.data.data ? res.data.data : [];
                return from(docsList).pipe(
                    concatMap((m) =>
                        of(m).pipe(
                            delay(500),
                            concatMap(() => {
                                saveAs(m.document, m.report_name);
                                return of(m);
                            })
                        )
                    ),
                    toArray(),
                    concatMap((processedDocs) => of(downloadReportDocumentsSuccess({ data: processedDocs, payload })))
                );
            }),
            catchError((res) => of(downloadReportDocumentsFailure(), errorMessageApi(res))),
        )
    })
)

/**upload report */
const uploadReport = (action$) => action$.pipe(
    ofType(uploadReportRequest),
    mergeMap(({ payload }) => {
        return from(reportsService.uploadReport(payload)).pipe(
            concatMap((res) => of(uploadReportSuccess({data: res.data, payload: payload}), successMessageApi(res))),
            catchError((res) => of(uploadReportFailure(), errorMessageApi(res))),
        )
    }),
)

export const reportsEpic = [
    downloadReportsModel,
    getReportsList,
    getReportsListCount,
    reportsApproval,
    downloadReportDocuments,
    uploadReport
]