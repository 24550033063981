import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Autocomplete, Checkbox, Chip, Popper, Tooltip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from '@mui/material';

// components
import { TextField } from "../textField"
import style from './style';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectComponent = (props) => {

  // props
  const { className, customWidth, value, display, matchBy, placeholder, name, id, handleChange, apiCallback, options, initialValue, handleClose, limitTags } = props;

  // sate
  const [currentOptions, setCurrentOptions] = useState(options)
  const [open, setOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [load, setLoad] = useState(false)
  const [chipClose, setChipClose] = useState(false)

  useEffect(()=>{
    if(chipClose){
      handleClose()
      setChipClose(false)
    }
  },[chipClose])

  //val
  const loading = open && currentOptions.length === 0;

  const optionsOrder = () => {
    const optionsOrder = [
      ...(value || []),
      ...(currentOptions || []).filter(f => {
        const isFound = value.find(u => (u.id === f.id))
        return (isFound ? false : true)
      })
    ]
    return optionsOrder
  }

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if ((initialValue && initialValue.length) || options.length === 0) {
        setLoad(true);
        const response = await apiCallback("");
        if (active) {
          let respData = response && response.data && response.data.data ? response.data.data : [];
          if (value && value.length) {
            value.forEach((item, index) => {
              const indexFound = respData.findIndex(f => (f[matchBy] === item[matchBy])) > -1;
              if (!indexFound) {
                respData.push(item)
              }
            })
          }
          setCurrentOptions(respData)
        }
        setLoad(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    setCurrentOptions(options)
  }, [options])

  const handleApiCallback = async (val) => {
    setLoad(true);
    const response = await apiCallback(val)
    let respData = response && response.data && response.data.data ? response.data.data : [];
    if (value && value.length) {
      value.forEach((item, index) => {
        const indexFound = respData.findIndex(f => (f[matchBy] === item[matchBy])) > -1;
        if (!indexFound) {
          respData.push(item)
        }
      })
    }
    setCurrentOptions(respData)
    setLoad(false);
  }

  const customPopper = function (props) {
    return <Popper {...props} className={"poperComp"} sx={{ width: '600px !important' }} placement="bottom-start" />;
  };

  return (
    <Autocomplete
      className={className}
      PopperComponent={customWidth && customPopper}
      multiple
      id={id}
      open={open}
      onOpen={() => {
        setLoad(true);
        setOpen(true);
        setHasChanges(false)
        handleApiCallback("")
      }}
      onClose={() => {
        setOpen(false);
        if (hasChanges) {
          handleClose()
        }
      }}
      options={optionsOrder()}
      disableCloseOnSelect
      loading={false}
      value={value}
      noOptionsText={load ? "loading..." : "No Options"}
      getOptionLabel={(option) => {
        if (value && (value[matchBy] === 0 || value[matchBy])) {
          return option?.[display] || options.find(f => (f[matchBy].toString() === value[matchBy].toString()))?.[display] || "";
        } else {
          return option?.[display] || "";
        }
      }}
      limitTags={limitTags}
      disableClearable={true}
      ChipProps={{ onDelete: null }}
      ListboxProps={{
        sx: {
          "& .MuiCheckbox-root": { padding: "4px", marginRight: '5px' }
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option[display] || ''} key={option[matchBy]} arrow>
            <Chip
              label={option[display]}
              {...getTagProps({ index })}
              onDelete={()=>{
                handleChange({ name, value: value.filter((f) => f[matchBy] !== option[matchBy]) })
                setChipClose(true)
              }}
            />
          </Tooltip>
        ))
      }
      renderOption={(props, option, { selected }) => {
        return (
          <li
            className='listCheckBox'
            {...props}
            key={`key-${option[matchBy]}`}
            onClick={(e) => {
              setHasChanges(true)
              const isFound = value.find((f) => f[matchBy] === option[matchBy])
              if (isFound) {
                handleChange({ name, value: value.filter((f) => f[matchBy] !== option[matchBy]) });
              } else {
                handleChange({ name, value: [...value, option] });
              }
              handleApiCallback("")
            }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={value.findIndex(f => (f[matchBy] === option[matchBy])) > -1}
            // onClick={(e) => {
            //   setHasChanges(true)
            //   if (e.target.checked) {
            //     handleChange({ name, value: [...value, option] })
            //   } else {
            //     handleChange({ name, value: value.filter(f => (f.id !== option.id)) })
            //   }
            //   handleApiCallback("")
            // }}
            />
            {option[display]}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => { handleApiCallback(e.target.value) }}
          aria-label={placeholder}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {load ? (
                  <CircularProgress sx={{ position: 'absolute', right: '30px' }} color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

MultiSelectComponent.defaultProps = {
  display: "value",
  matchBy: "id",
  required: false,
  label: "",
  handleChange: () => { },
  name: "async",
  options: [],
  validation: null,
  placeholder: "",
  value: [],
  apiCallback: () => { },
  initialValue: [],
  id: "",
  handleClose: () => { },
  limitTags: 2,
  customWidth: false
};

MultiSelectComponent.propTypes = {
  display: PropTypes.string,
  matchBy: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array,
  validation: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  apiCallback: PropTypes.func,
  initialValue: PropTypes.array,
  id: PropTypes.string,
  handleClose: PropTypes.func,
  limitTags: PropTypes.number,
  customWidth: PropTypes.bool
};


export const MultiSelectAsync = styled(MultiSelectComponent)(style);
