import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Style from './style'

//Components
import { NoResult, Dialog, Table, FileUpload, Button } from "../../components";
import { UploadIcon } from "../../assets/svg";

// reducer
import { getApplicationsDocumentsRequest, uploadApplicationsDocumentsRequest, setCurrentDocuments } from "../../redux/reducer/applicationsReducer";

const Documents = (props) => {

    const { className } = props;
    //define dispatch
    const dispatch = useDispatch();

    const [fileUpload, setFileUpload] = useState(false)

    // reducer
    const { currentApplication: { general: applicationsDetail }, applicationDocs, currentApplicationDocs } = useSelector(s => (s.applications))
    const { loggedUser } = useSelector(s => (s.profile))
    // get application docs
    useEffect(() => {
        dispatch(getApplicationsDocumentsRequest({ id: applicationsDetail.id }))
    }, [dispatch, applicationsDetail.id])

    const onUploadIconClick = () => {
        // inputRef.current.click();
        setFileUpload(true)
    }

    const handleFileUploadPopup = () => {
        if (currentApplicationDocs && currentApplicationDocs.length > 0) {
            dispatch(uploadApplicationsDocumentsRequest({
                id: applicationsDetail.id,
                docs: [...currentApplicationDocs]
            }))
            
        }
        setFileUpload(false)
    }

    const handleFileUploadClose = () => {
        setFileUpload(false)
    }

    //variables
    const isCityStaff = loggedUser.user_group_id === 3 ? true : false

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Grid className="dflex alignCenter flexEnd">
                    {!isCityStaff && <Button variant="text" size="medium" onClick={onUploadIconClick} startIcon={<UploadIcon />} sx={{fontSize: '14px'}}  aria-label="Upload document">Upload Document</Button>}
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                {applicationDocs.length > 0 ?
                    <Grid className="bodyInner docTable">
                        <Table
                            header={["Document Name", "Size", "Uploaded Date", "Approval Status"]}
                            fields={["file_name", "file_size", "created_at", "ap_status"]}
                            linkFields={[]}
                            data={applicationDocs}
                            showpagination={false}
                            clipName={true}
                        />
                    </Grid>
                    :
                    <NoResult text="No Document Found" />
                } 
            </Grid>
            <Dialog
                open={fileUpload}
                handleDialogClose={handleFileUploadClose}
                confirmHandle={handleFileUploadPopup}
                successButton="Save"
                failButton="Cancel"
                maxWidth={"sm"}
            >
                <Grid>
                    <FileUpload
                        multiple={true}
                        onChange={(files) => {
                            dispatch(setCurrentDocuments(files))
                        }}
                    />
                </Grid>
            </Dialog>
        </Grid>
    );
}

// default props
Documents.defaultProps = {
    classes: {},
};

// prop types
Documents.propTypes = {
    classes: PropTypes.object,
};

export default styled(Documents)(Style);