import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  userProfile: {
    first_name: "",
    last_name: "",
    email: "",
    profile: {
      company_name: "",
      phone: "",
      alt_phone: "",
      address: "",
      city: { id: "", name: "" },
      state: { id: "", name: "" },
      zipcode: "",
      gender: "",
      other_gender: "",
      dob: null,
      email_notification: false,
      web_notification: true,
      user_flags: [],
      user_views: [],
    },
    old_password: "",
    password: "",
    confirm_password: "",
  },
  search: [],
  loggedUser: {},
  isDetailEdited:false
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    // get user profile
    getProfileRequest(state) {
      state.isLoading = true;
      state.isDetailEdited =false;
    },
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = {
        ...action.payload.data,
        old_password: "",
        password: "",
        confirm_password: "",
      };
      if (!state.userProfile.profile) {
        state.userProfile.profile = {
          ...initialState.userProfile.profile,
        };
      }
    },
    getProfileFailed(state) {
      state.isLoading = false;
    },

    // update profile reducer
    updateProfileDetails(state, action) {
      state.userProfile = action.payload
      state.isDetailEdited =true;
    },

    // update notification data
    updateNotificationDetails(state, action) {
      state.userProfile = action.payload
    },

    // update profile request
    updateProfileRequest(state) {
      state.isLoading = true;
      state.isDetailEdited =false;
    },
    updateProfileSuccess(state, action) {
      state.isLoading = false;
    },
    updateProfileFailed(state) {
      state.isLoading = false;
    },

    // updating notification toggle
    updateNotificationRequest(state) {
      state.isLoading = true;
    },
    updateNotificationSuccess(state) {
      state.isLoading = false;
    },
    updateNotificationFailure(state) {
      state.isLoading = false;
    },

    //get logged user
    loggedUserRequest() { },
    loggedUserSuccess(state, action) {
      state.loggedUser = action.payload
    },
    loggedUserFailure() { },
  }
});

export const {
  getProfileRequest, getProfileSuccess, getProfileFailed,
  updateProfileRequest, updateProfileSuccess, updateProfileFailed,
  loggedUserRequest, loggedUserSuccess, loggedUserFailure,
  updateProfileDetails,updateNotificationDetails,
  updateNotificationRequest,updateNotificationSuccess,updateNotificationFailure
} = profileSlice.actions;
export default profileSlice.reducer;