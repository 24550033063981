import React, { memo, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled, Typography, Grid, IconButton, Tooltip } from "@mui/material";
import _ from "lodash";

// Imports Styles
import Styles from "./style";
import { FilledFlagIcon, FlagIcon } from "../../assets/svg";

// Components
import { StatusDropDown } from "../statusDropDown";
import { Avatar } from "../avatar";

import { gets3URL } from "../../helpers";

const LeftPanelComponent = (props) => {

    //get props
    const { className, data, handleStatusChange, totalCount, fieldMap, statusOptions, statusViewOption, handleView, handleFlag, apicallback, viewId, disableStatusChange, isLoading } = props;

    const [width, setWidth] = useState();

    //set ref for scroll
    const scrollContent = useRef(null)

    const elementWidth = useRef(null);

    useLayoutEffect(() => {
        if(elementWidth?.current?.offsetWidth){
            setWidth(elementWidth.current.offsetWidth);
        }
      }, []);

    //scroll event
    const getScrollData = () => {
        const isBottom = parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight + 1) || parseInt(scrollContent.current.scrollHeight - scrollContent.current.scrollTop) === (scrollContent.current.clientHeight - 1);
        if (isBottom && data.length < totalCount && !isLoading) {
            // get the next set of data on call
            apicallback()
        }
    }

    //mapping value
    const getValue = (CONSTANT, data) => {
        return fieldMap && fieldMap[CONSTANT] && data[fieldMap[CONSTANT]] ? data[fieldMap[CONSTANT]] : ''
    }


    return (
        <Grid container className={className} ref={scrollContent} onScroll={() => { getScrollData() }}>
          {data.map((item, index) => (
            <Grid key={`left-panel-${index}`} item xs={12} onClick={() => { handleView(item) }} className={`listItem ${item.id === viewId && "active"} ${getValue("VIEWED", item) && "completed"}`}>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item className="leftSection" style={{ flexGrow: 1, minWidth: 0 }}>
                    <Grid className="leftActiveSection">
                        <Grid className="dot"></Grid>
                    </Grid>
                    <Avatar
                        className="avatarItem"
                        src={getValue("PHOTO", item) ? gets3URL(getValue("PHOTO", item)): ""}
                        alt={getValue("NAME", item)}
                    />
                    {/* <Grid className="MuiAvatar-root">
                            <BuildingIcon />
                        </Grid> */}
                    <Typography variant="body1" className="name oneLineEllip" style={{ flexGrow: 1, minWidth: 0 }}>{getValue("NAME", item)}</Typography>
                </Grid>
                <Grid item className="dflex alignCenter" ref={elementWidth} style={{ flexShrink: 0 }}>
                    <StatusDropDown
                        // Status Number to be added here
                        chipClassName="status number"
                        value={statusViewOption[getValue("STATUS", item)]}
                        onChange={(value) => handleStatusChange("priority", value, item)}
                        statusOptions={statusOptions(item)}
                        priority
                        disable={disableStatusChange ? disableStatusChange(item) : true}
                        data={item}
                    />
                    <Grid className="flags">
                        <Tooltip arrow title={getValue("FLAG", item) ? "Unflag" : "Flag"}>
                            <IconButton className="p5" onClick={() => { handleFlag(item) }} aria-label={getValue("FLAG", item) ? "Unflag" : "Flag"}>
                                {getValue("FLAG", item) ? <FilledFlagIcon /> : <FlagIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
    );
};

// default props
LeftPanelComponent.defaultProps = {
    variant: "outlined",
    fullWidth: false,
    label: '',
    className: "",
    size: "small",
    chipStatus: "",
    data: [],
    totalCount: 0,
    fieldMap: {},
    handleView: () => { },
    handleFlag: () => { },
    apicallback: () => { },
    statusOptions: ()=>{},
    statusViewOption: {},
    viewId: "",
    disableStatusChange: () => { },
    isLoading: false
};

// prop types
LeftPanelComponent.propTypes = {
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    chipStatus: PropTypes.string,
    data: PropTypes.array,
    handleStatusChange: PropTypes.func,
    apicallback: PropTypes.func,
    handleFlag: PropTypes.func,
    handleView: PropTypes.func,
    totalCount: PropTypes.number,
    fieldMap: PropTypes.object,
    statusOptions: PropTypes.func,
    statusViewOption: PropTypes.object,
    viewId: PropTypes.any,
    disableStatusChange: PropTypes.func,
    isLoading: PropTypes.bool
};

function areEqual(prevProps, nextProps) {
    return _.isEqual(prevProps, nextProps);
}

// export component
export const LeftPanel = memo(styled(LeftPanelComponent)(Styles), areEqual);
