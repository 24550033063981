import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

//style
import Styles from './style'

//Components
import { AutoComplete, DatePicker, Button, MultiSelect } from "../../components";
import Popover from "../../components/popover";

// reducer
import { updatePMFilter, clearPMFilter, revertPMFilter } from "../../redux/reducer/propertyManagerReducer";

// helpers
import { defaultFlagOptions, defaultPropertyCountOptions, pmStatusOptions } from "../../helpers";

const FilterDrawer = (props) => {
    //define dispatch
    const dispatch = useDispatch();

    // props
    const { openFilter, setOpenFilter, handleFilter, className } = props;

    // redux
    const { filter, clearfilter } = useSelector(s => (s.propertyManager))

    //set temporary filters to existing filter value
    useEffect(() => {
        dispatch(revertPMFilter())
    },[])

    // call api after clearing the filter
    useEffect(() => {
        if (clearfilter) {
            handleFilter()
            setOpenFilter(false)
        }
    }, [clearfilter])

    return (
        <Popover
            anchorEl={openFilter}
            onClose={() => { setOpenFilter(false) }}
            className={className}
            // maxWidth={"sm"}
            // open={openFilter}
            // handleDialogClose={() => { setOpenFilter(false) }}
            confirmHandle={() => {
                handleFilter()
                setOpenFilter(false)
            }}
            successButton="Apply"
            failButton="Close"
        >
            <Grid item xs={12} className="filterContainer">
                <Grid container>
                    <Grid container justifyContent={"space-between"}>
                        <Typography variant="h5" color="" className="fs-18">Filters</Typography>
                        <Typography
                            variant="body1"
                            size={"medium"}
                            color={"primary.dark"}
                            className="fw-500 pointer"
                            onClick={() => { dispatch(clearPMFilter()) }}
                        >
                            Clear Filter
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <Grid container justifyContent={"space-between"} spacing={4}>
                            <Grid item xs={12} sm={6} md={4} sx={{zIndex: 1}}>
                            <Typography variant="caption" color="textSecondary">Flag</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"flagFilter"}
                                    name={"flagFilter"}
                                    placeholder={"Flag"}
                                    className={`sm`}
                                    options = {defaultFlagOptions}
                                    value={filter.flagFilter}
                                    handleChange={(e) => { dispatch(updatePMFilter(e)) }}
                                    initialValue={filter.flagFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{zIndex: 1}}>
                            <Typography variant="caption" color="textSecondary">Property Count</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"propertyCountFilter"}
                                    name={"propertyCountFilter"}
                                    placeholder={"Property Count"}
                                    className={`sm`}
                                    options = {defaultPropertyCountOptions}
                                    value={filter.propertyCountFilter}
                                    handleChange={(e) => { dispatch(updatePMFilter(e)) }}
                                    initialValue={filter.propertyCountFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{zIndex: 1}}>
                            <Typography variant="caption" color="textSecondary">Status</Typography>
                                <MultiSelect
                                    variant={"outlined"}
                                    id={"statusFilter"}
                                    name={"statusFilter"}
                                    placeholder={"Status"}
                                    className={`sm`}
                                    options = {pmStatusOptions}
                                    value={filter.statusFilter}
                                    handleChange={(e) => { dispatch(updatePMFilter(e)) }}
                                    initialValue={filter.statusFilter}
                                    matchBy={"id"}
                                    display={"value"}
                                    limitTags={1}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8}>
                                <Typography variant="caption" color="textSecondary">Submitted Date</Typography>
                                <Grid container flexWrap={"wrap"} spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            id={"startDate"}
                                            className={` sm datePicker ${filter.startDate ? '' : ''}`}
                                            name="startDate"
                                            value={filter.startDate}
                                            handleChange={(e) => { dispatch(updatePMFilter(e)) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            id={"endDate"}
                                            className={` sm datePicker ${filter.endDate ? '' : ''}`}
                                            name="endDate"
                                            value={filter.endDate}
                                            handleChange={(e) => { dispatch(updatePMFilter(e)) }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} align="right">
                                <Button variant="text" size="medium" className="mr-1" onClick={() => { setOpenFilter(false)
                                dispatch(revertPMFilter())}} aria-label="Close">Close</Button>
                                <Button variant="contained" size="medium" color="primary" onClick={() => { handleFilter(); setOpenFilter(false)}} aria-label="Apply">Apply</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    );
}

// default props
FilterDrawer.defaultProps = {
    handleFilter: () => { },
    openFilter: false,
    setOpenFilter: () => { },
    className: ""
};

// prop types
FilterDrawer.propTypes = {
    handleFilter: PropTypes.func,
    openFilter: PropTypes.any,
    setOpenFilter: PropTypes.func,
    className: PropTypes.string
};

export default styled(FilterDrawer)(Styles);