export const defaultUnitDetails = {
    status: true,
    unit_no: "",
    affordability_level: null,
    min_ami: "",
    max_ami: "",
    max_rent_amt: null,
    min_rent_amt: null,
    lease_length: null,
    sqft: null,
    household_min_income: null,
    household_max_income: null,
}

export const defaultUnit = {
    unit_type: null,
    unit_count: 1,
    max_household_size: null,
    min_household_size:null,
    unitDetails: [
        { ...defaultUnitDetails }
    ],
    accordion: true
}

export const defaultExcelType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"