import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap } from "rxjs/operators";
import { of, from } from "rxjs";

import { errorMessageApi, successMessageApi } from "../reducer/uiReducer";
import {
    getWelcomePageRequest, getWelcomePageSuccess, getWelcomePageFailure,
    getPropertiesListRequest, getPropertiesListSuccess, getPropertiesListFailure,
    getPropertiesCountRequest, getPropertiesCountSuccess, getPropertiesCountFailure,
    updateGeneralRequest, updateGeneralSuccess, updateGeneralFailure,
    updateAmenitiesRequest, updateAmenitiesSuccess, updateAmenitiesFailure,
    updatePhotosRequest, updatePhotosSuccess, updatePhotosFailure,
    getPropertiesLeftPanelListRequest, getPropertiesLeftPanelListSuccess, getPropertiesLeftPanelListFailure,
    getPropertiesDetailRequest, getPropertiesDetailSuccess, getPropertiesDetailFailure,
    updatePropertiesFlagRequest, updatePropertiesFlagSuccess, updatePropertiesFlagFailure,
    propertiesApprovalRequest, propertiesApprovalSuccess, propertiesApprovalFailure,
    updateUnitsRequest, updateUnitsSuccess, updateUnitsFailure,
    getPropertiesReportRequest, getPropertiesReportSuccess, getPropertiesReportFailure,
    deletePropertiesRequest, deletePropertiesSuccess, deletePropertiesFailure,
    getPropertiesCommentsRequest, getPropertiesCommentsSuccess, getPropertiesCommentsFailure,
    updatePropertiesCommentsRequest, updatePropertiesCommentsSuccess, updatePropertiesCommentsFailure,
    deletePropertiesCommentRequest, deletePropertiesCommentSuccess, deletePropertiesCommentFailure,
    downloadPropertiesRequest, downloadPropertiesSuccess, downloadPropertiesFailure,
    updateUnitPhotosRequest, updateUnitPhotosSuccess, updateUnitPhotosFailure,
    getPropertiesUnitsRequest, getPropertiesUnitsSuccess, getPropertiesUnitsFailure,
    getPropertiesPhotosRequest, getPropertiesPhotosSuccess, getPropertiesPhotosFailure,
    getPropertiesAmenitiesRequest, getPropertiesAmenitiesSuccess, getPropertiesAmenitiesFailure,
    updatePropertyStatusRequest, updatePropertyStatusSuccess, updatePropertyStatusFailure,
    updatePropertyUnitsStatusRequest, updatePropertyUnitsStatusSuccess, updatePropertyUnitsStatusFailure,
    updateOverallPropertiesLoaders
} from "../reducer/propertiesReducer";

//import service
import { propertiesService } from "../service";

/*get welcome page*/
const getWelcomePage = (action$) => action$.pipe(
    ofType(getWelcomePageRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getWelcomePage(payload)).pipe(
            concatMap((res) => of(getWelcomePageSuccess(res.data.data))),
            catchError((res) => of(getWelcomePageFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

// get property list
const getPropertiesList = (action$) => action$.pipe(
    ofType(getPropertiesListRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesList(payload)).pipe(
            concatMap((res) => of(getPropertiesListSuccess(res.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesListFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    }),
)

// get total count of properties
const getPropertiesCount = (action$, state$) => action$.pipe(
    ofType(getPropertiesCountRequest),
    mergeMap(({ payload }) => {
        const viewType = state$.value.properties.viewType
        return from(propertiesService.getPropertiesCount(payload)).pipe(
            concatMap((res) => {
                if (viewType === "panel") {
                    return of(getPropertiesCountSuccess(res.data), getPropertiesLeftPanelListRequest(payload), updateOverallPropertiesLoaders())
                } else {
                    return of(getPropertiesCountSuccess(res.data), getPropertiesListRequest(payload), updateOverallPropertiesLoaders())
                }
            }),
            catchError((res) => of(getPropertiesCountFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**create or update general tab */
const updateGeneral = (action$) => action$.pipe(
    ofType(updateGeneralRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updateGeneral(payload.general)).pipe(
            concatMap((res) => {
                const propertyId = res.data.data && res.data.data.id ? res.data.data.id : null
                if (propertyId) {
                    return of(
                        updateGeneralSuccess({ data: res.data.data, payload: payload }),
                        updateAmenitiesRequest({ propertyId, amenities: payload.amenities }),
                        updatePhotosRequest({ propertyId, photos: payload.photos }),
                        successMessageApi(res),
                        updateOverallPropertiesLoaders()
                    )
                } else {
                    return of(updateGeneralSuccess(res.data.data), successMessageApi(res), updateOverallPropertiesLoaders())
                }
            }),
            catchError((res) => of(updateGeneralFailure(), errorMessageApi(res), updateOverallPropertiesLoaders()))
        )
    }),
)

/**create or update amenites tab  data*/
const updateAmenities = (action$) => action$.pipe(
    ofType(updateAmenitiesRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updateAmenities(payload)).pipe(
            concatMap((res) => of(updateAmenitiesSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(updateAmenitiesFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**create or update photos*/
const updatePhotos = (action$) => action$.pipe(
    ofType(updatePhotosRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updatePhotos(payload)).pipe(
            concatMap((res) => of(updatePhotosSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(updatePhotosFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**get properties left Panel List */
const getPropertiesLeftPanelList = (action$) => action$.pipe(
    ofType(getPropertiesLeftPanelListRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesLeftPanelList(payload)).pipe(
            concatMap((res) => {
                const data = res.data && res.data.data ? res.data.data : []
                if (payload.isFirst && data && data[0]) {
                    return of(
                        getPropertiesLeftPanelListSuccess(res.data),
                        getPropertiesDetailRequest(data[0]),
                        getPropertiesUnitsRequest(data[0]),
                        getPropertiesPhotosRequest(data[0]),
                        getPropertiesAmenitiesRequest(data[0]),
                        updateOverallPropertiesLoaders()
                    )
                } else {
                    return of(getPropertiesLeftPanelListSuccess(res.data), updateOverallPropertiesLoaders())
                }
            }),
            catchError((res) => of(getPropertiesLeftPanelListFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    })
)

/**update properties flag*/
const updatePropertiesFlag = (action$) => action$.pipe(
    ofType(updatePropertiesFlagRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updatePropertiesFlag(payload)).pipe(
            concatMap((res) => of(updatePropertiesFlagSuccess({ data: res.data.data, payload: payload }), updateOverallPropertiesLoaders())),
            catchError((res) => of(updatePropertiesFlagFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/**properties Approval */
const propertiesApproval = (action$) => action$.pipe(
    ofType(propertiesApprovalRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.propertiesApproval(payload)).pipe(
            concatMap((res) => of(propertiesApprovalSuccess({ data: res.data.data, payload: payload }), getPropertiesDetailRequest({ id: payload.property_id }), updateOverallPropertiesLoaders(), successMessageApi(res))),
            catchError((res) => of(propertiesApprovalFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    }),
)

/**get Properties details */
const getPropertiesDetail = (action$) => action$.pipe(
    ofType(getPropertiesDetailRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesDetail(payload)).pipe(
            concatMap((res) => of(getPropertiesDetailSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesDetailFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**update Properties Units */
const updateUnits = (action$, state$) => action$.pipe(
    ofType(updateUnitsRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updateUnits({ ...payload, propertyId: payload.general.id, approval_status: payload.approvalStatus })).pipe(
            concatMap((res) => {
                const updatedUnitIds = res.data.data.ids || {};
                const { unitPhotos } = state$.value.properties
                return of(
                    updateUnitsSuccess({ data: res.data.data, payload: payload }),
                    updateUnitPhotosRequest({ propertyId: payload.general.id, updatedUnitIds, photos: unitPhotos }),
                    successMessageApi(res),
                    updateOverallPropertiesLoaders()
                )
            }),
            catchError((res) => of(updateUnitsFailure(res), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

//get Properties report
const getPropertiesReport = (action$) => action$.pipe(
    ofType(getPropertiesReportRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesReport(payload)).pipe(
            concatMap((res) => of(getPropertiesReportSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesReportFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    }),
)

// delete property
const deleteProperties = (action$) => action$.pipe(
    ofType(deletePropertiesRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.deleteProperties(payload)).pipe(
            concatMap((res) => of(deletePropertiesSuccess({ data: res.data.data, payload: payload }), updateOverallPropertiesLoaders(), successMessageApi(res))),
            catchError((res) => of(deletePropertiesFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/*get properties Comments*/
const getPropertiesComments = (action$) => action$.pipe(
    ofType(getPropertiesCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesComments(payload)).pipe(
            concatMap((res) => of(getPropertiesCommentsSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesCommentsFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/*update properties Comments*/
const updatePropertiesComments = (action$) => action$.pipe(
    ofType(updatePropertiesCommentsRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updatePropertiesComments(payload)).pipe(
            concatMap((res) => of(updatePropertiesCommentsSuccess(res.data.data), getPropertiesCommentsRequest({ id: payload.property }), updateOverallPropertiesLoaders())),
            catchError((res) => of(updatePropertiesCommentsFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/*delete properties Comments*/
const deletePropertiesComments = (action$) => action$.pipe(
    ofType(deletePropertiesCommentRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.deletePropertiesComments(payload)).pipe(
            concatMap((res) => of(deletePropertiesCommentSuccess({ data: res.data.data, payload: payload }), updateOverallPropertiesLoaders())),
            catchError((res) => of(deletePropertiesCommentFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/**download Properties Report */
const downloadPropertiesReport = (action$) => action$.pipe(
    ofType(downloadPropertiesRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.downloadPropertiesReport(payload)).pipe(
            concatMap((res) => of(downloadPropertiesSuccess(res), updateOverallPropertiesLoaders(), successMessageApi({message: "The property list has been downloaded successfully"}))),
            catchError((res) => of(downloadPropertiesFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    }),
)


/**upload Properties units photos */
const uploadUnitPhotos = (action$) => action$.pipe(
    ofType(updateUnitPhotosRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.uploadUnitPhotos(payload)).pipe(
            concatMap((res) => of(updateUnitPhotosSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(updateUnitPhotosFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    }),
)

/**get Properties units list */
const getUnitDetails = (action$) => action$.pipe(
    ofType(getPropertiesUnitsRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getUnitDetails(payload)).pipe(
            concatMap((res) => of(getPropertiesUnitsSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesUnitsFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**get Properties units list */
const getPropertiesPhotos = (action$) => action$.pipe(
    ofType(getPropertiesPhotosRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesPhotos(payload)).pipe(
            concatMap((res) => of(getPropertiesPhotosSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesPhotosFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**get Properties Amenities */
const getPropertiesAmenities = (action$) => action$.pipe(
    ofType(getPropertiesAmenitiesRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.getPropertiesAmenities(payload)).pipe(
            concatMap((res) => of(getPropertiesAmenitiesSuccess(res.data.data), updateOverallPropertiesLoaders())),
            catchError((res) => of(getPropertiesAmenitiesFailure(), errorMessageApi(res), updateOverallPropertiesLoaders())),
        )
    }),
)

/**update property status by citystaff */
const updatePropertyStatus = (action$) => action$.pipe(
    ofType(updatePropertyStatusRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updatePropertyStatus(payload)).pipe(
            concatMap((res) => of(updatePropertyStatusSuccess(res.data.data), updateOverallPropertiesLoaders(), successMessageApi(res))),
            catchError((res) => of(updatePropertyStatusFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

/**update property units status by citystaff */
const updatePropertyUnitStatus = (action$) => action$.pipe(
    ofType(updatePropertyUnitsStatusRequest),
    mergeMap(({ payload }) => {
        return from(propertiesService.updatePropertyUnitStatus(payload)).pipe(
            concatMap((res) => of(updatePropertyUnitsStatusSuccess(res.data.data), updateOverallPropertiesLoaders(), successMessageApi(res))),
            catchError((res) => of(updatePropertyUnitsStatusFailure(), updateOverallPropertiesLoaders(), errorMessageApi(res))),
        )
    })
)

export const propertiesEpic = [
    getWelcomePage,
    getPropertiesList,
    getPropertiesCount,
    updateGeneral,
    updateAmenities,
    updatePhotos,
    getPropertiesLeftPanelList,
    updatePropertiesFlag,
    propertiesApproval,
    getPropertiesDetail,
    updateUnits,
    getPropertiesReport,
    deleteProperties,
    getPropertiesComments,
    updatePropertiesComments,
    deletePropertiesComments,
    downloadPropertiesReport,
    uploadUnitPhotos,
    getUnitDetails,
    getPropertiesPhotos,
    getPropertiesAmenities,
    updatePropertyStatus,
    updatePropertyUnitStatus
];
