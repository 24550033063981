// Import API Helper
import { buildFormData, getRequest, postRequest, putRequest } from "../../helpers";

/**
* download property manager reports
*/
async function downloadReportsModel(params) {
    const response = await postRequest(`reports/download/`, params, false, "arraybuffer");
    return response;
  }

async function getReportsList(params) {
    const response = await postRequest(`reports/list/?skip=${params.skip}&limit=${params.limit}`, { "filter": params.filter });
    return response;
}

/** get waiting list total count */
async function getReportsListCount(params) {
    const response = await postRequest(`reports/count/`, { "filter": params.filter });
    return response;
}

//reports Approval
async function reportsApproval(params) {
    const response = await postRequest(`reports/approval/`, params);
    return response;
  }

/** download report Documents */
async function downloadReportDocuments({ id }) {
  const response = await getRequest(`reports/download-documents/${id}/`);
  return response;
}

/*** upload report */
async function uploadReport(params) {
  let formData = new FormData();
  formData = buildFormData(formData, params.document);

  const response = await putRequest(`reports/upload/${params.reportId}/`, {
    body: formData,
    isFormData: true,
    responseType: "json",
    multipart: true
  });
  return response;
}

export const reportsService = {
    downloadReportsModel,
    getReportsList,
    getReportsListCount,
    reportsApproval,
    downloadReportDocuments,
    uploadReport
}