import { getRootContainerHeight } from "../../config/layout";
import BannerBg from '../../assets/images/banner.png';
import palette from "../../assets/themes/palette";
import { gets3URL } from "../../helpers";
import backImage from '../../assets/images/city_staff_home_background.png'

const styles = {
    width: "100%",
    height: getRootContainerHeight(),
    // background: `linear-gradient(60deg, rgb(203 48 102 / 90%) 0%, rgb(46 46 132 / 90%) 100%), url(https://images.unsplash.com/photo-1514565131-fce0801e5785?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTB8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80)`,
    backgroundImage: `url(${backImage})`,
    backgroundSize: 'cover',
    ".bannerCont": {
        display: "flex",
        height: "100%",
        width: "100%",
        margin: 0,
        padding: '100px',
        alignItems: "center",
        justifyContent: "center",
        '@media only screen and (max-width: 1400px)': {
            '&.MuiBox-root': {
                padding: '0px 70px',
            }
        }
    },
    ".imageCont": {
        alignItems: "center",
        width: "100%",
        display: 'inline-flex',
        backgroundColor: '#FFF',
        borderRadius: "7px",
        overflow: "hidden",
        height: "100%",
        '@media only screen and (max-width: 1400px)': {
            '&.MuiBox-root': {
                maxHeight: '517px !important',
            }
        }
    },
    ".imBgCont": {
        width: "100%",
        height: "100%",
        objectFit: 'cover'
    },
    ".mainCont": {
        width: "100%",
        padding: '20px 70px 10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media only screen and (min-width: 1500px)': {
            '&.MuiBox-root': {
                padding: '20px 100px 10px',
            }
        }
    },
    '.logoContainer': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '40px',
        justifyContent: 'center',
        '@media only screen and (max-width: 1400px)': {
            '&.MuiGrid-root': {
                marginBottom: '20px'
            }
        }
    },
    '.logoImg': {
        marginRight: 20,
        width: 115,
        height: 115
    },
    '.copyRightText': {
        color: palette.primary.main
    },
    '.topContainer': {
        height: 'calc(100% - 60px)',
        display: 'flex',
        alignItems: 'center',
        '& input': {
            fontSize: '13px',
            fontFamily: 'Public Sans, sans-serif',
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '5px 9px !important'
        }
    }
}

export default styles;