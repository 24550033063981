import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Select, MenuItem, Tooltip} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";

import HudsonCounty from '../../assets/images/hudson-county-seal.png';
import YorkStreet from '../../assets/images/yorkStreet.jpg';
import Hudsonhomeless from '../../assets/images/hcah-logo.jpg';
import JCCity from '../../assets/images/jc-city.webp';
import NJHousingResource from '../../assets/images/nj-housing-resource.jpg';
import GarderResource from '../../assets/images/garden-state.jpg';
import HRC from '../../assets/images/hrc.jpg';
import JC from '../../assets/images/jc.jpg';
import MapIcon from '../../assets/images/mapicon.png'
import womenRising from '../../assets/images/women_rising_logo.png'
import njcaLogo from '../../assets/images/njca_logo.png'
import waterFrontLogo from '../../assets/images/waterfront_logo.jpg'
import njLegalServiceLogo from '../../assets/images/nj_legal_service_logo.png'
import jerseyCity from '../../assets/images/jersey_city.png'
import calendarIcon from '../../assets/images/calendar.png'

//style
import Style from './style'
import { Button } from "../../components";

//helpers
import { gets3URL, referenceOptions } from "../../helpers";

const Resources = (props) => {

    //define navigate
    const navigate = useNavigate();
    const location = useLocation();

    // props
    const { className } = props;

    const resourceData = [
        {
            title: 'Comprehensive Affordable Housing Map',
            img: MapIcon,
            desc: 'Click here for detailed information on legacy affordable housing opportunities. These units will become available on the portal over time',
            phone: '',
            url: 'https://jerseycity-enterprise.carto.com/u/jerseycity/builder/2efaa493-9957-40c4-80ef-ad1dd8cc56dc/embed'
        },
        {
            title: 'Affordable Housing Lottery Calendar',
            img: calendarIcon,
            desc: 'Click here to access the date, time, and zoom link to all upcoming DOAH-Monitored Lotteries',
            phone: '',
            url: 'https://www.jerseycitynj.gov/cityhall/HousingAndDevelopment/affordable_housing/affordable_housing_lottery_calendar'
        },
        {
            title: 'List of documents to be submitted for Property manager review ',
            img: JC,
            desc: '',
            phone: '(201) 706 - 4600 ',
            url: `${gets3URL("assets/DOAH_Consolidated+Affordable+Housing+Portal_Required+Document(s)+and+Instructions_Resources+Page+(2).pdf")}`
        },
        {
            title: "Please visit DOAH's official City webpage for more resources",
            // title: 'List of documents to be submitted for Property manager review ',
            img: JC,
            desc: '',
            phone: '(201) 706 - 4600 ',
            url: 'https://www.jerseycitynj.gov/cityhall/HousingAndDevelopment/affordable_housing/housing_resources'
        },
        {
            title: '',
            // title: 'Jersey City Housing Authority',
            img: JCCity,
            desc: '',
            phone: '201-706-4799',
            url: 'https://www.jerseycityha.org/ '
        },
        {
            title: '',
            // title: 'NJ Housing Resource Center',
            img: NJHousingResource,
            desc: '',
            phone: '1-877-428-8844',
            url: 'https://www.nj.gov/njhrc/'
        },
        {
            title: '',
            // title: 'Garden State Community Development Corporation',
            img: GarderResource,
            desc: '',
            phone: '(201) 209-9301',
            url: 'https://gardenstatecdc.org/abouttheapartments'
        },
        {
            title: 'Hudson County Housing Resource Center',
            img: HRC,
            desc: '',
            phone: '201-795-5615',
            url: 'https://hudsonhrc.org/'
        },
        {
            title: 'Hudson County Office of Inclusion and Accessibility',
            // title: 'Hudson County Office of Disability Services ',
            img: HudsonCounty,
            desc: '',
            phone: '201-369-5280 ext. 4256',
            url: 'https://www.hcnj.us/health-and-human-services/inclusion-and-accessibility/'
        },
        {
            title: "",
            // title: "St. Mary's Residence",
            img: YorkStreet,
            desc: '',
            phone: '201-451-9838',
            url: 'https://yorkstreetproject.org/our-programs/st-marys-residence/'
        },
        {
            // title: 'The Hudson county alliance to end homelessness',
            title: '',
            img: Hudsonhomeless,
            desc: '',
            phone: '1-800-624-0287',
            url: 'https://hudsonhomeless.com/'
        },
        {
            title: "Division of Affordable Housing (DOAH) City Webpage",
            img: jerseyCity,
            desc: "Click here for more information on the Division of Affordable Housing, including income level definitions and limits.",
            url: "https://www.jerseycitynj.gov/CityHall/HousingAndDevelopment/affordable_housing"
        },
        {
            title: "WomenRising, Inc.",
            img: womenRising,
            desc: "Assist women and their families to achieve self-sufficiency and live safe, productive, and fulfilling lives through social services, supportive housing, economic development, and advocacy.",
            url: "https://www.womenrising.org/"
        },
        {
            title: "Hudson County Emergency Rental Assistance",
            img: HudsonCounty,
            desc: "",
            url:"https://www.hcnj.us/finance/housing-and-community-development/emergency-rental-assistance/",
        },
        {
            title:"New Jersey Citizen Action",
            img: njcaLogo,
            desc:"Services include financial coaching, advocacy, and fair housing services.",
            url: "https://www.njcitizenaction.org/housing-counseling"
        },
        {
            title: "The Waterfront Project",
            img: waterFrontLogo,
            desc:"",
            url: "https://thewaterfrontproject.org/"
        },
        {
            title: "Northeast New Jersey Legal Services",
            img: njLegalServiceLogo,
            desc:"",
            url: "https://www.northeastnjlegalservices.org/"
        }
    ]

    const handlePageChange = (value) => {
        if(value===1){
            navigate("/references")
        }
        else if(value===2){
            navigate("/references/income-rent")
        }
    }

    return (
        <Grid className={className}>
            <Grid item className="titleContainer">
                <Typography variant="h3" className="fw-500">Resources</Typography>
                <Grid className="dflex alignCenter">
                    <Select value={3} onChange={(e)=>{handlePageChange(e.target.value)}}>
                        {referenceOptions.map((item)=>(
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
            <Grid className="bodyContainer">
                <Grid className="bodyInner">
                    <Grid container spacing={2}>
                        {resourceData.map((item)=>(
                            <Grid item xs={4}>
                                <Grid className="card resource">
                                    <img src={item.img}  alt="resource Image" />
                                    <Typography variant="h6" className="twoLineEllip" align={"center"}>{item.title}</Typography>
                                    <Tooltip title={item.desc} arrow><Typography variant="body1" className="mb15 twoLineEllip">{item.desc}</Typography></Tooltip>
                                    <Button variant={"contained"} color="primary" className={"mt-2"} size="medium" href={item.url} target="_blank">Visit Site</Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
Resources.defaultProps = {
    classes: {},
};

// prop types
Resources.propTypes = {
    classes: PropTypes.object,
};

export default styled(Resources)(Style);