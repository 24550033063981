import React, { useState } from "react";
import PropTypes from "prop-types";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as Core } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";

// component
import { TextField } from "../textField";

const DatePickerComponent = (props) => {
  //get state
  const { classes, value, handleChange, name, id, label, disabled, required, validation, inputFormat, placeholder, disablePast, disableFuture, ...rest } = props;

  let formatProps = {};
  if (props.views) {
    formatProps.views = props.views
  } else {
    formatProps.inputFormat = inputFormat
  }

  // state
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Core
        classes={classes}
        name={name}
        id={id}
        label={label}
        value={value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(e) => {
          handleChange({
            name: name,
            value:
              e && (e._d != "Invalid Date" && e._d != "Invalid date")
                ? moment(e._d).format("YYYY-MM-DD")
                : null,
          });
        }}
        disabled={disabled}
        disableOpenPicker={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        inputProps={{
          placeholder: placeholder
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            aria-label="Select the date"
            variant="outlined"
            className="custom-form-input date-picker"
            InputLabelProps={{
              required,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
            }}
            validation={validation}
            {...rest}
          />
        )}
        components={{ OpenPickerIcon: CalendarMonthIcon }}
        {...rest}
        {...formatProps}
      />
    </LocalizationProvider>
  );
};

//default props
DatePickerComponent.defaultProps = {
  name: "",
  id: "",
  label: "",
  value: "",
  handleChange: () => { },
  disabled: false,
  validation: "",
  classes: {},
  required: false,
  inputFormat: "MM/DD/YYYY",
  placeholder: "MM/DD/YYYY",
  disableFuture: false,
  disablePast: false,
};

//prop Types
DatePickerComponent.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  disabled: PropTypes.any,
  validation: PropTypes.any,
  classes: PropTypes.object,
  required: PropTypes.bool,
  inputFormat: PropTypes.string,
  placeholder: PropTypes.string,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

/*Export component */
export const DatePicker = DatePickerComponent;