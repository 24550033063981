import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from "@mui/material";

const TabPanelComponent = (props) => {
    /**
     * Define Props
     */
    const { children, value, index, height, boxClass, ...other } = props;

    return (
        <Grid
            component="div"
            className="tabBodyContainer"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-label={`full-width-tab-${index}`}
            {...other}
        >
            <Box className={boxClass} sx={{ padding: '30px 30px 20px', overflowY: 'auto' }} height={height}>
                {children}
            </Box>
        </Grid>
    );
};

// default props
TabPanelComponent.defaultProps = {
    children: "",
    value: 0,
    index: 0,
    height: "",
    boxClass: ""
};


TabPanelComponent.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number,
    height: PropTypes.string,
    boxClass: PropTypes.string
};

export const TabPanel = TabPanelComponent;