import { ofType } from "redux-observable";
import { mergeMap, catchError, concatMap } from "rxjs/operators";
import { of, from } from "rxjs";

import { errorMessageApi, successMessageApi } from "../reducer/uiReducer";

import {
    getApplicationsLeftPanelListRequest, getApplicationsLeftPanelListSuccess, getApplicationsLeftPanelListFailure,
    getApplicationsCountRequest, getApplicationsCountSuccess, getApplicationsCountFailure,
    getApplicationsDetailRequest, getApplicationsDetailSuccess, getApplicationsDetailFailure,
    applicationsApprovalRequest, applicationsApprovalSuccess, applicationsApprovalFailure,
    updateApplicationsFlagRequest, updateApplicationsFlagSuccess, updateApplicationsFlagFailure,
    getApplicationsListRequest, getApplicationsListSuccess, getApplicationsListFailure,
    downloadApplicationsRequest, downloadApplicationsSuccess, downloadApplicationsFailure,
    getApplicationsCommentsRequest, getApplicationsCommentsSuccess, getApplicationsCommentsFailure,
    updateApplicationsCommentsRequest, updateApplicationsCommentsSuccess, updateApplicationsCommentsFailure,
    deleteApplicationsCommentRequest, deleteApplicationsCommentSuccess, deleteApplicationsCommentFailure,
    getApplicationsDocumentsRequest, getApplicationsDocumentsSuccess, getApplicationsDocumentsFailure,
    uploadApplicationsDocumentsRequest, uploadApplicationsDocumentsSuccess, uploadApplicationsDocumentsFailure,
    getApplicationsLogsRequest, getApplicationsLogsSuccess, getApplicationsLogsFailure,
    rejectApplicationRequest, rejectApplicationSuccess, rejectApplicationFailure,
    updateOverallApplicationsLoaders
}
    from "../reducer/applicationsReducer"

//service
import { applicationsService } from "../service";

// get total count of applications
const getApplicationsCount = (action$, state$) => action$.pipe(
    ofType(getApplicationsCountRequest),
    mergeMap(({ payload }) => {
        const viewType = state$.value.applications.viewType
        return from(applicationsService.getApplicationsCount(payload)).pipe(
            concatMap((res) => {
                if (viewType === "panel") {
                    return of(getApplicationsCountSuccess(res.data), getApplicationsLeftPanelListRequest(payload), updateOverallApplicationsLoaders())
                }
                else {
                    return of(getApplicationsCountSuccess(res.data), getApplicationsListRequest(payload), updateOverallApplicationsLoaders())
                }
            }),
            catchError((res) => of(getApplicationsCountFailure(), updateOverallApplicationsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**get applications left Panel List */
const getApplicationsLeftPanelList = (action$) => action$.pipe(
    ofType(getApplicationsLeftPanelListRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationsLeftPanelList(payload)).pipe(
            concatMap((res) => {
                const data = res.data && res.data.data ? res.data.data : []
                if (payload.isFirst && data && data[0]) {
                    return of(
                        getApplicationsLeftPanelListSuccess(res.data),
                        getApplicationsDetailRequest(data[0]),
                        // getApplicationsUnitsRequest(data[0]),
                        // getApplicationsPhotosRequest(data[0]),
                        // getApplicationsAmenitiesRequest(data[0]),
                        updateOverallApplicationsLoaders()
                    )
                } else {
                    return of(getApplicationsLeftPanelListSuccess(res.data), updateOverallApplicationsLoaders())
                }
            }),
            catchError((res) => of(getApplicationsLeftPanelListFailure(), updateOverallApplicationsLoaders(), errorMessageApi(res))),
        )
    })
)

/**get Applications details */
const getApplicationsDetail = (action$) => action$.pipe(
    ofType(getApplicationsDetailRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationsDetail(payload)).pipe(
            concatMap((res) => of(getApplicationsDetailSuccess(res.data.data), updateOverallApplicationsLoaders())),
            catchError((res) => of(getApplicationsDetailFailure(), updateOverallApplicationsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**applications Approval */
const applicationsApproval = (action$) => action$.pipe(
    ofType(applicationsApprovalRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.applicationsApproval(payload)).pipe(
            concatMap((res) => of(applicationsApprovalSuccess({ data: res.data.data, payload: payload }), getApplicationsDetailRequest({ id: payload.application_id }), updateOverallApplicationsLoaders(), successMessageApi(res))),
            catchError((res) => of(applicationsApprovalFailure(), updateOverallApplicationsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**update properties flag*/
const updateApplicationsFlag = (action$) => action$.pipe(
    ofType(updateApplicationsFlagRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.updateApplicationsFlag(payload)).pipe(
            concatMap((res) => of(updateApplicationsFlagSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(updateApplicationsFlagFailure(), errorMessageApi(res))),
        )
    })
)

// get applications list
const getApplicationsList = (action$) => action$.pipe(
    ofType(getApplicationsListRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationsList(payload)).pipe(
            concatMap((res) => of(getApplicationsListSuccess(res.data), updateOverallApplicationsLoaders())),
            catchError((res) => of(getApplicationsListFailure(), updateOverallApplicationsLoaders(), errorMessageApi(res))),
        )
    }),
)

/**download applications Report */
const downloadApplicationsReport = (action$) => action$.pipe(
    ofType(downloadApplicationsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.downloadApplicationsReport(payload)).pipe(
            concatMap((res) => of(downloadApplicationsSuccess(res), successMessageApi({message: "The application list has been downloaded successfully"}))),
            catchError((res) => of(downloadApplicationsFailure(), errorMessageApi(res))),
        )
    }),
)

/*get applications Comments*/
const getApplicationsComments = (action$) => action$.pipe(
    ofType(getApplicationsCommentsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationsComments(payload)).pipe(
            concatMap((res) => of(getApplicationsCommentsSuccess(res.data.data))),
            catchError((res) => of(getApplicationsCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*update applications Comments*/
const updateApplicationsComments = (action$) => action$.pipe(
    ofType(updateApplicationsCommentsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.updateApplicationsComments(payload)).pipe(
            concatMap((res) => of(updateApplicationsCommentsSuccess(res.data.data), getApplicationsCommentsRequest({ id: payload.application }))),
            catchError((res) => of(updateApplicationsCommentsFailure(), errorMessageApi(res))),
        )
    })
)

/*delete applications Comments*/
const deleteApplicationsComments = (action$) => action$.pipe(
    ofType(deleteApplicationsCommentRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.deleteApplicationsComments(payload)).pipe(
            concatMap((res) => of(deleteApplicationsCommentSuccess({ data: res.data.data, payload: payload }))),
            catchError((res) => of(deleteApplicationsCommentFailure(), errorMessageApi(res))),
        )
    })
)

//get application documents
const getApplicationsDocuments = (action$) => action$.pipe(
    ofType(getApplicationsDocumentsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationsDocuments(payload)).pipe(
            concatMap((res) => of(getApplicationsDocumentsSuccess(res.data.data))),
            catchError((res) => of(getApplicationsDocumentsFailure(), errorMessageApi(res))),
        )
    })
)

//upload application documents
const uploadApplicationsDocuments = (action$) => action$.pipe(
    ofType(uploadApplicationsDocumentsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.uploadApplicationsDocuments(payload)).pipe(
            concatMap((res) => of(uploadApplicationsDocumentsSuccess({ data: res.data.data, payload: payload }), getApplicationsDocumentsRequest({ id: payload.id }), successMessageApi(res))),
            catchError((res) => of(uploadApplicationsDocumentsFailure(), errorMessageApi(res))),
        )
    })
)

//get application logs
const getApplicationLogs = (action$) => action$.pipe(
    ofType(getApplicationsLogsRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.getApplicationLogs(payload)).pipe(
            concatMap((res) => of(getApplicationsLogsSuccess(res.data.data))),
            catchError((res) => of(getApplicationsLogsFailure(), errorMessageApi(res))),
        )
    })
)

/**reject application */
const rejectApplication = (action$) => action$.pipe(
    ofType(rejectApplicationRequest),
    mergeMap(({ payload }) => {
        return from(applicationsService.rejectApplication(payload)).pipe(
            concatMap((res) => of(rejectApplicationSuccess(payload), successMessageApi(res))),
            catchError((res) => of(rejectApplicationFailure(), errorMessageApi(res))),
        )
    }),
)

export const applicationsEpic = [
    getApplicationsCount,
    getApplicationsLeftPanelList,
    getApplicationsDetail,
    applicationsApproval,
    updateApplicationsFlag,
    getApplicationsList,
    downloadApplicationsReport,
    getApplicationsComments,
    updateApplicationsComments,
    deleteApplicationsComments,
    getApplicationsDocuments,
    uploadApplicationsDocuments,
    getApplicationLogs,
    rejectApplication,
];