import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import 'moment-timezone'

//style
import Styles from './style'

//Components
import { FilterPanel, MultiSelectAsync } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import FilterDrawer from "./filterDrawer";

//reducer
import { downloadReportsModelRequest, updateReportsFilter, clearReportsFilter } from "../../redux/reducer/reportsReducer";

//service
import { globalFilterService } from "../../redux/service";

//helpers
import { hasFilter } from "../../helpers";

const TopPanel = (props) => {
    // define dispatch
    const dispatch = useDispatch()

    // props
    const { className, IconsList, handleFilter } = props;

    //redux
    const { listView: { pagination: {count} }, filter, clearfilter, tmpfilter } = useSelector(s => (s.reports))
    const { loggedUser } = useSelector(s => (s.profile))

    // variable
    const isCityStaff = loggedUser.user_group_id === 3;
    const tz = moment.tz.guess()

    // state
    const [openFilter, setOpenFilter] = useState(false)
    
      // call api whenever clear filter is triggered
      useEffect(() => {
        if (clearfilter) {
            handleFilter()
        }
        }, [clearfilter])

    const getAddressFilter = async (value) => {
        const response = await globalFilterService.getReportsAddressFilterList({ address: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getYearFilter = async (value) => {
        const response = await globalFilterService.getReportsYearFilterList({ year: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getReportPeriodFilter = async (value) => {
        const response = await globalFilterService.getReportPeriodFilterList({ period: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    const getManagerFilter = async (value) => {
        const response = await globalFilterService.getReportsManagerFilterList({ manager: value || "" })
        return response
    }

    //get statuses of report documents
    const getApprovalStatusFilter = async (value) => {
        const response = await globalFilterService.getReportsStatusFilterList({ approval_status: value || "", user_id: isCityStaff ? "" : loggedUser.id })
        return response
    }

    return (
        <FilterPanel
            IconsList={IconsList}
            isFilterApplied={hasFilter(tmpfilter)}
            handleFilter={(event) => setOpenFilter(event.currentTarget)}
            handleDownload={() => { dispatch(downloadReportsModelRequest({ filter: filter, timezone: tz })) }}
            handleClearFilter={() => { dispatch(clearReportsFilter()) }}
            downloadDisabled={count===0}
        >
            <Grid className="filterFields" container spacing={2} sx={{ pl: 2 }}>
                <Grid item xs={3} className="pt-0">
                    <MultiSelectAsync
                        customWidth
                        variant={"outlined"}
                        id={"addressFilter"}
                        name={"addressFilter"}
                        placeholder={"Property Address or Name"}
                        className={`sm`}
                        apiCallback={getAddressFilter}
                        value={filter.addressFilter}
                        handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                        initialValue={filter.addressFilter}
                        matchBy={"id"}
                        display={"address_string"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={2} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"yearFilter"}
                        name={"yearFilter"}
                        placeholder={"Year"}
                        className={`sm`}
                        apiCallback={getYearFilter}
                        value={filter.yearFilter}
                        handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                        initialValue={filter.yearFilter}
                        matchBy={"report_year"}
                        display={"report_year"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                <Grid item xs={2} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"periodFilter"}
                        name={"periodFilter"}
                        placeholder={"Period"}
                        className={`sm`}
                        apiCallback={getReportPeriodFilter}
                        value={filter.periodFilter}
                        handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                        initialValue={filter.periodFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                {isCityStaff && <Grid item xs={2} className="pt-0">
                    <MultiSelectAsync
                        customWidth
                        variant={"outlined"}
                        id={"managerFilter"}
                        name={"managerFilter"}
                        placeholder={"Property Manager"}
                        className={`sm`}
                        apiCallback={getManagerFilter}
                        value={filter.managerFilter}
                        handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                        initialValue={filter.managerFilter}
                        matchBy={"id"}
                        display={"name"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
                }
                <Grid item xs={2} className="pt-0">
                    <MultiSelectAsync
                        variant={"outlined"}
                        id={"approvalStatusFilter"}
                        name={"approvalStatusFilter"}
                        placeholder={"Status"}
                        className={`sm`}
                        apiCallback={getApprovalStatusFilter}
                        value={filter.approvalStatusFilter}
                        handleChange={(e) => { dispatch(updateReportsFilter(e)) }}
                        initialValue={filter.reportNameFilter}
                        matchBy={"id"}
                        display={"ap_status"}
                        limitTags={1}
                        handleClose={() => { handleFilter() }}
                    />
                </Grid>
            </Grid>
            {openFilter && <FilterDrawer openFilter={openFilter} setOpenFilter={setOpenFilter} handleFilter={handleFilter} />}
        </FilterPanel>);
}

// default props
TopPanel.defaultProps = {
    className: "",
    IconsList: [],
    handleFilter: () => { },
    handleSorting: () => { },
    sortValue: ""
};

// prop types
TopPanel.propTypes = {
    className: PropTypes.string,
};

export default styled(TopPanel)(Styles);