export const propertiesGeneralComp = (oldData = {}, newData = {}) => {
    try {
        const propertyFields = [
            "name", "desc", "status", "address", "state", "city", "zipcode", "lat_long", "built_year",
            "available_at", "offer_valid_at", "application_date", "application_deadline", "lottery_date",
            "preference", "is_ada", "report_period", "ami_rmi"
        ]
        const displayNames = {
            "name": "Name",
            "desc": "Description",
            "status": "Status",
            "address": "Address",
            "state": "State",
            "city": "City",
            "zipcode": "Zipcode",
            "lat_long": "Latitude-Longitude",
            "built_year": "Built Year",
            "available_at": "Earliest Move-In Date",
            "offer_valid_at": "Document Submission Period",
            "application_date": "Application Start Date",
            "application_deadline": "Application Deadline",
            "lottery_date": "Lottery Date",
            "preference": "Preference",
            "is_ada": "ADA Accessibility Requirement",
            "report_period": "Report Period",
            "ami_rmi": "Standard"
        }
        const specialFields = ["lat_long", "state", "city"]
        let hasChanges = false
        let changedFields = []
        propertyFields.forEach((item) => {
            if (!specialFields.includes(item) && oldData[item] + "" !== newData[item] + "") {
                hasChanges = true
                changedFields.push(displayNames[item])
            } else if (["state", "city"].includes(item)) {
                if (oldData[item] && (!newData[item] || oldData[item].id !== newData[item].id)) {
                    hasChanges = true
                    changedFields.push(displayNames[item])
                }
            } else if (item === "lat_long") {
                if ((oldData[item][0] !== newData[item][0]) || (oldData[item][1] !== newData[item][1])) {
                    hasChanges = true
                    changedFields.push(displayNames[item])
                }
            }
        })
        return { hasChanges, changedFields: changedFields }
    } catch (err) {
        return { hasChanges: true, changedFields: "" }
    }
}

// amennties comparison
export const propertiesAmenitiesComp = (oldData = [], newData = []) => {
    try {
        if (oldData.length !== newData.length) {
            return true
        } else {
            let hasChanges = false
            // compare amentity id in both arrays
            const oldMasterId = oldData.map(m => { return (m.master_amenity || m.master_amenity_id) })
            const newMasterId = newData.map(m => { return (m.master_amenity || m.master_amenity_id) })
            oldMasterId.forEach(m => {
                if (!newMasterId.includes(m)) {
                    hasChanges = true
                }
            })

            // check for condition false
            if (!hasChanges) {
                newMasterId.forEach(m => {
                    if (!oldMasterId.includes(m)) {
                        hasChanges = true
                    }
                })
            }
            return hasChanges
        }
    } catch (err) {
        return true
    }
}

export const propertiesUnitsComp = (oldData = [], newData = []) => {
    try {
        if (oldData.length !== newData.length) {
            return { hasChanges: true, changedFields: [] }
        } else {
            let hasChanges = false;
            let changedFields = [];
            const unitsFields = ["unit_type", "unit_count"]
            const unitsDetailFields = ["status", "unit_no", "affordability_level", "min_rent_amt", "max_rent_amt", "lease_length", "sqft", "household_min_income", "household_max_income"]
            const displayNames = {
                "unit_type": "Unit Type",
                "unit_count": "Unit Count",
                "status": "Status",
                "unit_no": "Unit Number",
                "affordability_level": "Affordability Level",
                "min_rent_amt": "Monthly Rent Range",
                "max_rent_amt": "Monthly Rent Range",
                "lease_length": "Lease Length",
                "sqft": "Sq. Ft",
                "household_min_income": "Annual Household Income",
                "household_max_income": "Annual Household Income"
            }
            oldData.forEach((oldUnit, unitIndex) => {
                // check for changes in unit level
                const newUnit = newData[unitIndex] || {}
                unitsFields.forEach(field => {
                    if (oldUnit[field] + "" !== newUnit[field] + "") {
                        hasChanges = true
                        if (!changedFields.includes(displayNames[field])) {
                            changedFields.push(displayNames[field])
                        }
                    }
                })

                // check for changes in unit details level
                const oldUnitDetails = oldUnit.unitDetails || []
                const newUnitDetails = newUnit.unitDetails || []

                // comapre the length of old and new array length ofunit details
                if (oldUnitDetails.length !== newUnitDetails.length) {
                    hasChanges = true
                }

                // check for changes in unit details level
                oldUnitDetails.forEach((oldUnitDetail, detailIndex) => {
                    const newUnitDetail = newUnitDetails[detailIndex];
                    unitsDetailFields.forEach(field => {
                        if (oldUnitDetail[field] + "" !== newUnitDetail[field] + "") {
                            hasChanges = true
                            if (!changedFields.includes(displayNames[field])) {
                                changedFields.push(displayNames[field])
                            }
                        }
                    })
                })
            })
            return { hasChanges, changedFields }
        }
    } catch (err) {
        console.log(err)
        return { hasChanges: true, changedFields: [] }
    }
}