import React from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import NoPropertyImage from "../../assets/svg/NoPropertyFound.svg"

const NoResultComponent = (props) => {

    const { noPropertyImg, text } = props;

    const {
        auth: { isLoading: authLoading },
        profile: { isLoading: profileLoading },
        propertyManager: { isLoading: propertyManagerLoading },
        global: { isLoading: globalLoading },
        properties: { isLoading: propertiesLoading },
        waitingList: { isLoading: waitingListLoading },
        runLottery: { isLoading: runLotteryLoading },
        applications: { isLoading: applicationsLoading },
        globalFilter: { isLoading: globalFilterLoading }
    } = useSelector((s) => s);

    const loading = (
        authLoading || profileLoading || propertyManagerLoading || globalLoading || propertiesLoading ||
        waitingListLoading || runLotteryLoading || applicationsLoading || globalFilterLoading
    )

    return (
        <>
            {!loading &&
                <Grid container alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 200px)' }}>
                    <Grid item xs={12} align="center">
                        {
                            noPropertyImg && <img className="mb-2" src={NoPropertyImage} alt="No property image" />
                        }
                        <Typography textAlign={"center"} variant="h3">{text}</Typography>
                    </Grid>
                </Grid>
            }
        </>
    )
}

// default props
NoResultComponent.defaultProps = {
    noPropertyImg: true,
    text: "No Results Found",
};

// prop types
NoResultComponent.propTypes = {
    noPropertyImg: PropTypes.bool,
    text: PropTypes.string
};

export const NoResult = NoResultComponent