import palette from "../../assets/themes/palette";

const styles = {
    '.filterFields': {
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important',
                },
                '& .MuiFormControl-root': {
                    border: '2px solid #2e2e84  !important',
                    borderRadius: '4px',
                    '& fieldset': {
                        border: 'none !important',
                        borderWidth: '0px !important'
                    }
                }
            }
        },
        '& .MuiInputBase-root': {
            maxHeight: '250px',
            overflowY: 'auto',
        },
        '& .MuiFormControl-root': {
            position: 'absolute',
            background: '#fff',
            zIndex: 9
        },
        '& .MuiOutlinedInput-root': {
            padding: '0px'
        },
        '& .MuiChip-root': {
            height: '24px'
        },
        '& .MuiChip-deleteIcon': {
            fontSize: '20px'
        },
        '& .selectBox': {
            '& .MuiFormControl-root': {
                margin: '0px'
            },
            '& .MuiSelect-select': {
                height: '34px',
                padding: '0.5px 6px',
                lineHeight: '32px',
                paddingRight: '34px'
            },
            '& .MuiCheckbox-root': {
                padding: '6px',
                marginRight: '4px'
            }
        }
    },
    '.tableContainer': {
        height: 'calc(100vh - 212px)'
    },
    '.pagination': {
        '&  .MuiButtonBase-root': {
            minWidth: '26px',
            height: '26px',
            background: '#fff',
        },
        '& .Mui-selected': {
            background: palette.primary.main
        }
    },
    '.sideBarList': {
        '& .sidebarInner': {
            height: 'calc(100vh - 133px)',
            overflowY: 'auto',
        }
    },
    '.filterContainer': {
        // padding: '30px',
        maxWidth: 700,
        '& .MuiInputBase-root': {
            minHeight: '34px'
        },
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '& .MuiInputAdornment-positionStart': {
            display: 'none'
        },
        // for auto complete
        '& .MuiAutocomplete-tag': {
            maxWidth: '45%'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 13
        },
        '& .MuiAutocomplete-root': {
            position: 'relative',
            '&.Mui-focused': {
                '& .MuiInputBase-input': {
                    width: '100% !important'
                }
            },
            '& .MuiFormControl-root': {
                position: 'absolute',
                background: '#fff',
                // zIndex: 9
            },
            '& .MuiOutlinedInput-root': {
                padding: '0px'
            },
            '& .MuiChip-root': {
                height: '24px'
            },
            '& .MuiChip-deleteIcon': {
                fontSize: '20px'
            },
        },
        '& .MuiGrid-grid-md-6': {
            height: '90px'
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EAEAF3'
        }
    },
    '& .MuiInputAdornment-positionStart': {
        display: 'none'
    },
    '& .MuiOutlinedInput-root': {
        paddingLeft: '0px'
    },
    '.radioGroup': {
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiFormGroup-root': {
            justifyContent: 'space-between',
            width: '100%'
        },
        '& .MuiAutocomplete-root': {
            '& input': {
                paddingRight: '30px !important'
            }
        }
    },
    '.reqDoc': {
        '& .nodata .MuiInputBase-root': {
            background: palette.greyshades.inputBg
        },
        '&.inlineEdit .MuiInputBase-root:hover': {
            border: '2px solid #2e2e84 !important'
        }
    }
}
export default styles;