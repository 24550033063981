const Styles = {
    '&.MuiButton-sizeSmall': {
        height: 30,
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400
    },
    '&.MuiButton-sizeMedium': {
        height: 35,
        fontWeight: 500
    }
}

export default Styles;