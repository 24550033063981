import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Snackbar as Core, Slide, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';

// Import Styles
import style from "./style";

//import actions
import { clearMessage } from "../../redux/reducer/uiReducer"

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

function SnackBarComponent(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes, className } = props;

    //get reducer data
    const { alertMessage: { type, message } } = useSelector((s) => s.ui);

    //close alert and clear message
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(clearMessage());
    };

    return (
        <Core
            key={type || undefined}
            className={`${className} ${type}`}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            ContentProps={{
                classes: {
                    root: classes[type]
                }
            }}
            open={!!type}
            TransitionComponent={TransitionDown}
            autoHideDuration={3000}
            onClose={handleClose}
            message={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
            action={
                <React.Fragment>
                    <Tooltip arrow title="Close">
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            }
        />
    );
}

// default props
SnackBarComponent.defaultProps = {
    classes: {}
};

// prop types
SnackBarComponent.propTypes = {
    classes: PropTypes.object
};

// Export component
export const SnackBar = styled(SnackBarComponent)(style);